<div class="total-accordion__wrapper grid grid-cols-1 divide-y bg-white">
  @if (!(isFetchingAcctInfo$ | async) && !(isFetchingBankList$ | async)) {
    <div class="hidden"></div>
    <app-view-company-details
      [companyDetails]="companyDetails"
    ></app-view-company-details>
    <app-view-bank-details
      [bankDetails]="bankDetails"
      [bankName]="bankName"
    ></app-view-bank-details>
    <app-view-director-details
      [directorDetails]="directorDetails"
    ></app-view-director-details>
    <app-view-documents [kycDocuments]="documents"></app-view-documents>
    <div></div>
  }

  @if (isFetchingAcctInfo$ | async) {
    <div class="flex flex-col">
      <div *ngFor="let in of [0, 1, 2, 3]; let i = index">
        <ngx-skeleton-loader
          [theme]="{ height: '5rem', width: '100%' }"
        ></ngx-skeleton-loader>
      </div>
      <!-- <div class="flex justify-end">
        <ngx-skeleton-loader
          [theme]="{ height: '2.5rem', width: '150px' }"
        ></ngx-skeleton-loader>
      </div> -->
    </div>
  }
</div>
