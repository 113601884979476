import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-corporate-tabs',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, CommonModule],
  templateUrl: './corporate-tabs.component.html',
  styleUrls: ['./corporate-tabs.component.scss'],
})
export class CorporateTabsComponent implements OnInit {
  @Input() accountId!: string;
  tabs: { path: string; label: string; route: string }[] = [];

  router = inject(Router);

  pathMap = new Map<string, string>([
    ['details', 'Details'],
    ['signatories', 'Signatories'],
    ['wallet', 'Wallet'],
    ['portfolio', 'Portfolio'],
    ['orders', 'Orders'],
    ['audit-log', 'Audit Log'],
    ['rm-list', 'Relationship Manager(s)'],
  ]);

  ngOnInit(): void {
    this.initializeTabs();
  }

  private initializeTabs(): void {
    this.tabs = Array.from(this.pathMap, ([path, label]) => ({
      path,
      label,
      route: this.getRoutePath(path),
    }));
  }

  private getRoutePath(path: string): string {
    if (!this.accountId) return '';

    const baseRoute = this.router.url.split('/').slice(0, -1).join('/');
    return `${baseRoute}/${path}`;
  }
}
