<app-kyc-accordion
  accordionKey="signatoryDetails"
  accordionTitle="Signatory Details"
>
  <p *ngIf="signatoriesList.length === 0" class="mb-10">
    Invite a Signatory using the form below:
  </p>
  <div
    *ngIf="signatoriesList.length > 0"
    class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5"
  >
    @for (signatory of signatoriesList; track $index) {
      <div
        class="signatory__wrapper rounded-[0.625rem] bg-mango-orangeFF px-[0.88rem] py-[0.62rem]"
      >
        <div
          class="inline-flex w-full items-center justify-between gap-3 xl:px-[0.88rem]"
        >
          <div class="left inline-flex gap-3">
            <span
              class="flex items-center justify-center self-center rounded-2xl bg-mango px-2 text-white"
            >
              <span class="translate-y-px">{{ $index + 1 }}</span>
            </span>
            <span class="text-base font-bold text-mango-black17">
              {{ signatory.user.first_name }}
              {{ signatory.user.last_name }}
            </span>
          </div>
          <div class="right flex items-center gap-2">
            <img
              title="Edit Signatory"
              src="assets/images/svgs/pen-in-square--yellow.svg"
              alt=""
              class="h-5 w-6 shrink-0 cursor-pointer"
              (click)="editSignatory(signatory)"
            />
            <div
              class="flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center self-start rounded-full border border-solid border-mango-red707"
            >
              <img
                src="assets/images/svgs/x-close--red.svg"
                alt=""
                class="h-3.5 w-3.5"
                (click)="openDialog(signatory)"
                title="Delete Signatory"
              />
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  <hr *ngIf="signatoriesList.length > 0" class="my-10" />

  <form [formGroup]="signatoryDetailsForm" (ngSubmit)="inviteSignatory()">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- First Name -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >First Name</app-input-label
        >
        <app-input
          placeholder="Enter First Name"
          [inputClass]="'input--padding'"
          formControlName="first_name"
        >
        </app-input>
      </div>

      <!-- Last Name -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Last Name</app-input-label
        >
        <app-input
          placeholder="Enter Last Name"
          [inputClass]="'input--padding'"
          formControlName="last_name"
        >
        </app-input>
      </div>

      <!-- Email -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Email</app-input-label
        >
        <app-input
          placeholder="Enter Email"
          [inputClass]="'input--padding'"
          formControlName="email"
        >
        </app-input>
      </div>

      <!-- Class of signatory -->
      <div>
        <app-input-label
          labelFor="classOfSignatory"
          labelClass="label--bottom-margin"
          >Class of signatory</app-input-label
        >
        <select
          name="classOfSignatory"
          required
          base-select
          formControlName="signatory_class"
        >
          <option value="" disabled selected>Select Signatory Class</option>
          <option
            *ngFor="let sigClass of signatoryClass"
            [value]="sigClass.value"
          >
            {{ sigClass.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': isLoading
        }"
      >
        <button
          type="submit"
          mango-button
          [loading]="isLoading"
          [disabled]="isLoading"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span *ngIf="isLoading" spin></span>
          @if (!isLoading) {
            Invite New Signatory
          }
        </button>
      </div>
    </div>
  </form>

  <hr *ngIf="signatoriesList.length > 0" class="my-10" />

  <form
    [formGroup]="
      (hasMandate$ | async) === true ? editMandateForm : addMandateForm
    "
  >
    <!-- Signature Mandate -->
    <div>
      <app-input-label labelClass="label--bottom-margin"
        >Signature Mandate</app-input-label
      >

      <div>
        <textarea
          formControlName="mandate_note"
          textarea-style
          placeholder="Enter Signature Mandate"
        >
        </textarea>
      </div>
    </div>

    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed':
            (hasMandate$ | async) === true
              ? (isUpdatingMandate$ | async) === true
              : (isSavingMandate$ | async) === true
        }"
      >
        <button
          type="submit"
          mango-button
          (click)="submitMandate()"
          [loading]="
            (hasMandate$ | async) === true
              ? (isUpdatingMandate$ | async) === true
              : (isSavingMandate$ | async) === true
          "
          [disabled]="
            (hasMandate$ | async) === true
              ? (isUpdatingMandate$ | async) === true
              : (isSavingMandate$ | async) === true
          "
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span
            *ngIf="
              (isUpdatingMandate$ | async) === true ||
              (isSavingMandate$ | async) === true
            "
            spin
            [loadingText]="
              (hasMandate$ | async) === true
                ? 'Updating Mandate...'
                : 'Saving Mandate...'
            "
          ></span>
          @if (!(isSavingMandate$ | async) && !(isUpdatingMandate$ | async)) {
            {{
              (hasMandate$ | async) === true ? 'Update Mandate' : 'Save Mandate'
            }}
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>

<router-outlet></router-outlet>

<app-confirm-dialog
  [confirmQuestion]="
    'Do you want to delete this signatory: ' +
    this.signatoryName +
    '? This action cannot be undone'
  "
  confirmText="Yes, Delete"
  [isOpen]="isDialogOpen"
  bgConfirmButton="danger"
  [isDialogLoading]="isDialogLoading"
  dialogLoadingText="Deleting Signatory, please wait..."
  (close)="closeDialog()"
  (dialogAction)="deleteSignatory()"
>
</app-confirm-dialog>

<!-- (close)="openEditDialogSubject$.next(false);" -->
<app-confirm-dialog
  confirmText="Update Signatory"
  [isOpen]="(openEditDialog$ | async) === true"
  [showQuestionGif]="false"
  (close)="this.openEditDialogSubject$.next(false)"
  (dialogAction)="updateSignatory()"
  [isDialogLoading]="(isUpdatingSignatory$ | async) === true"
  dialogLoadingText="Updating Signatory, please wait..."
>
  <h2 class="mb-4 font-bold">
    Edit Signatory: {{ editSignatoryName$.getValue() }}
  </h2>
  <form [formGroup]="editSignatoryForm" class="flex flex-col gap-5">
    <!-- First Name -->
    <div>
      <app-input-label labelClass="label--bottom-margin"
        >First Name</app-input-label
      >
      <app-input
        placeholder="Enter First Name"
        [inputClass]="'input--padding'"
        formControlName="first_name"
      >
      </app-input>
    </div>

    <!-- Last Name -->
    <div>
      <app-input-label labelClass="label--bottom-margin"
        >Last Name</app-input-label
      >
      <app-input
        placeholder="Enter Last Name"
        [inputClass]="'input--padding'"
        formControlName="last_name"
      >
      </app-input>
    </div>

    <!-- Email -->
    <div>
      <app-input-label labelClass="label--bottom-margin">Email</app-input-label>
      <app-input
        placeholder="Enter Email"
        [inputClass]="'input--padding'"
        formControlName="email"
      >
      </app-input>
    </div>

    <!-- Class of signatory -->
    <div>
      <app-input-label
        labelFor="classOfSignatory"
        labelClass="label--bottom-margin"
        >Class of signatory</app-input-label
      >
      <select
        name="classOfSignatory"
        required
        base-select
        formControlName="signatory_class"
      >
        <option value="" disabled selected>Select Signatory Class</option>
        <option
          *ngFor="let sigClass of signatoryClass"
          [value]="sigClass.value"
        >
          {{ sigClass.label }}
        </option>
      </select>
    </div>
  </form>
</app-confirm-dialog>
