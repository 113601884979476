<app-kyc-accordion
  accordionTitle="Location Details"
  accordionKey="locationDetails"
>
  <form class="" [formGroup]="locationForm">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- Address -->
      <div>
        <app-input-label
          labelFor="address"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Address</app-input-label
        >

        <div>
          <textarea
            formControlName="address"
            textarea-style
            placeholder="Enter Address"
          >
          </textarea>
        </div>
      </div>

      <!-- Country -->
      <div>
        <app-input-label
          labelFor="country"
          labelClass="label--bottom-margin"
          
          >Country</app-input-label
        >
        <select
          name="country"
          
          base-select
          formControlName="country"
          #country
          (change)="getCountryStates(country.value)"
        >
          <option value="" disabled selected>Select country</option>

          @for (country of countries; track $index) {
            <option [value]="country.name">{{ country.name }}</option>
          }
        </select>
      </div>

      <!-- State -->
      <div>
        <app-input-label
          labelFor="state"
          labelClass="label--bottom-margin"
         
          >State</app-input-label
        >
        <div
          [ngClass]="{
            'cursor-not-allowed': states.length === 0
          }"
        >
          <select
            name="state"
           
            base-select
            formControlName="state"
            [ngClass]="{
              'pointer-events-none bg-gray-50 text-gray-500 ring-gray-200':
                states.length === 0
            }"
          >
            <option value="" disabled selected>Select State</option>
            @for (state of states; track $index) {
              <option [value]="state.name">{{ state.name }}</option>
            }
          </select>
        </div>
      </div>

      <!-- City/Town -->
      <div>
        <app-input-label
          labelFor="city-town"
          labelClass="label--bottom-margin"
         
          >City/Town</app-input-label
        >

        <app-input
          placeholder="Enter city"
          [inputClass]="'input--padding'"
          formControlName="city"
        ></app-input>
      </div>

      <!-- Dual Citizenship -->
      <div>
        <app-input-label
          labelFor="dual-citizenship"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Dual Citizenship?</app-input-label
        >
        <select
          name="country"
          required
          base-select
          formControlName="citizenship_status"
        >
          <option value="" disabled selected>Select</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>

      <!-- Second Country -->
      <div>
        <app-input-label labelFor="country" labelClass="label--bottom-margin"
          >Second Country</app-input-label
        >
        <select
          name="country"
          required
          base-select
          formControlName="second_country"
        >
          <option value="" disabled selected>Select country</option>

          @for (country of countries; track $index) {
            <option [value]="country.name">{{ country.name }}</option>
          }
        </select>
      </div>

      <!-- Residency Status -->
      <div>
        <app-input-label
          labelFor="residency-status"
          labelClass="label--bottom-margin"
          >Residency Status</app-input-label
        >

        <app-input
          placeholder="Enter Residency Status"
          [inputClass]="'input--padding'"
          formControlName="residency_status"
        ></app-input>
      </div>

      <!-- Residence Permit Number -->
      <div>
        <app-input-label
          labelFor="residence-permit-number"
          labelClass="label--bottom-margin"
          >Residence Permit Number</app-input-label
        >

        <app-input
          placeholder="Enter Permit Number"
          [inputClass]="'input--padding'"
          formControlName="residence_permit"
        ></app-input>
      </div>

      <!-- Permit Issue Date -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Permit Issue Date</app-input-label
        >
        <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
        <input
          type="date"
          input-date
          class="input--padding"
          formControlName="permit_issue_date"
        />
      </div>

      <!-- Permit Expiry Date -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Permit Expiry Date</app-input-label
        >
        <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
        <input
          type="date"
          input-date
          class="input--padding"
          formControlName="permit_expiry_date"
        />
      </div>
    </div>

    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': (isUpdating$ | async) || (isCreating$ | async)
        }"
      >
        <button
          type="button"
          mango-button
          [loading]="(isUpdating$ | async)!! || (isCreating$ | async)!!"
          [disabled]="(isUpdating$ | async)!! || (isCreating$ | async)!!"
          (click)="saveLocation()"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span
            *ngIf="(isUpdating$ | async) || (isCreating$ | async)"
            spin
          ></span>
          @if (!(isUpdating$ | async) && !(isCreating$ | async)) {
            {{
              (isLocationCreated$ | async) === true
                ? 'Update Location'
                : 'Add Location'
            }}
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>
