import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { Accountbankinfo } from '@core/models/admin/account';
import { JointAccountbankinfo } from '@core/models/admin/account/joint';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BankRegion, CURRENCY } from 'app/app.constants';
import { ActivatedRoute } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { CommonService } from '@core/services/common.service';
import { IndividualRegistrationService } from '@core/services/individual-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { EditBankDetailsComponent } from '@accounts/individual-account/edit-account/pages/edit-bank-details/edit-bank-details.component';

@Component({
  selector: 'app-edit-joint-bank',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    SpinDirective,
    BaseSelectDirective,
  ],
  templateUrl: './edit-joint-bank.component.html',
  styleUrl: './edit-joint-bank.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointBankComponent extends EditBankDetailsComponent {
  @Input() override bankDetails!: JointAccountbankinfo[];

  constructor() {
    super();
  }
}
