<div class="currency__wrapper flex flex-col gap-[0.94rem] md:flex-row">
  <!-- Naira -->
  <div
    class="flex min-w-[17.8125rem] flex-col rounded-[0.9375rem] bg-gradient-to-r from-mango-grey to-[#786440] px-5 py-[1.19rem] text-white"
  >
    <p class="text-xs font-normal leading-[1.125rem]">Naira Wallet</p>
    <p class="text-3xl font-extrabold leading-[3.75rem]">
      ₦ {{ nairaWalletBalance / 100 | number: '1.2-2' }}
    </p>
  </div>

  <!-- Dollar  -->
  <div
    class="flex min-w-[17.8125rem] flex-col rounded-[0.9375rem] bg-gradient-to-r from-mango-grey to-[#786440] px-5 py-[1.19rem] text-white"
  >
    <p class="text-xs font-normal leading-[1.125rem]">Dollar Wallet Balance</p>
    <p class="text-3xl font-extrabold leading-[3.75rem]">
      $ {{ dollarWalletBalance / 100 | number: '1.2-2' }}
    </p>
  </div>
</div>

<!--Search input and Filter-->
<app-filters
[filters]="filterStructure"
></app-filters>

<!-- Table -->
<div class="rounded-lg border border-solid border-mango-gray200 bg-white mt-5">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                S/N
              </th>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Date Created
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Description
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Transaction Channel
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Currency
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Current Balance
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Transaction Type
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Amount
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Status
              </th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <!-- Add table body here -->
            @for (transaction of walletTransactionList; track $index) {
              <tr>
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ $index + 1 }}
                </td>
                <!-- Date Created -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ transaction.created_at | date: 'full' }}
                </td>

                <!-- Description -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ transaction.tran_remarks }}
                </td>

                <!-- Transaction Channel -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ transaction.transaction_channel }}
                </td>

                <!-- Currency -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ transaction.currency }}
                </td>

                <!-- Current Balance -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  @if (transaction.currency === 'NGN') {
                    ₦ {{ transaction.current_balance / 100 | number: '1.2-2' }}
                  } @else {
                    $ {{ transaction.current_balance / 100 | number: '1.2-2' }}
                  }
                </td>

                <!-- Transaction Type -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ transaction.transaction_type }}
                </td>

                <!-- Amount -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  @if (transaction.currency === 'NGN') {
                    ₦
                    {{ transaction.transaction_amount / 100 | number: '1.2-2' }}
                  } @else {
                    $
                    {{ transaction.transaction_amount / 100 | number: '1.2-2' }}
                  }
                </td>

                <!-- Status -->
                <td
                  class="flex flex-wrap gap-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                >
                  <span
                    [ngClass]="[
                      transaction.status === 'denied'
                        ? 'bg-mango-shellRed text-mango-red707'
                        : '',
                      transaction.status === 'completed'
                        ? 'bg-green-100 text-mango-pineGreen'
                        : '',
                      transaction.status === 'pending' ||
                      transaction.status === 'withheld'
                        ? 'bg-mango-paleBlue text-indigo-700'
                        : ''
                    ]"
                    class="w-fit items-center rounded-full px-2 py-1 text-xs font-medium"
                  >
                    {{ transaction.status | uppercase }}
                  </span>
                </td>

                <!-- 3-vertical dots icon -->
                <td
                  class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                >
                  <div
                    class="relative inline-block w-fit cursor-pointer text-left"
                  >

                    <app-dropdown
                      [id]="transaction.id"
                      [actionList]="walletDropdownActions(transaction)"
                    >
                    </app-dropdown>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>

        <div
          *ngIf="walletTransactionList.length === 0 && isLoading === false"
          class="flex w-full px-6 pb-6 pt-6"
        >
          No transactions found for this account.
        </div>

        <div *ngIf="isLoading === true" class="flex w-full px-6 pb-6 pt-6">
          ...Fetching transactions for this account. Please wait.
        </div>

        <app-table-navigator
          *ngIf="totalCount"
          [total]="totalCount"
          (onNavigation)="navigate($event)"
        >
        </app-table-navigator>
      </div>
    </div>
  </div>
</div>

<app-confirm-dialog
  confirmQuestion="Are you sure you want to reject this transaction? This action cannot be undone"
  confirmText="Yes, Reject Transaction"
  [isOpen]="isRejectDialogOpen"
  bgConfirmButton="danger"
  [isDialogLoading]="isRejectDialogLoading"
  dialogLoadingText="Rejecting Transaction, please wait..."
  (close)="isRejectDialogOpen = false; transactionId = null"
  (dialogAction)="rejectTransaction()"
>
</app-confirm-dialog>

<app-confirm-dialog
  [confirmQuestion]="approveDialogQuestion"
  confirmText="Yes, Approve Transaction"
  [isOpen]="isApproveDialogOpen"
  [isDialogLoading]="isApproveInProgress"
  [dialogLoadingText]="isApproveLoadingText"
  (close)="
    isApproveDialogOpen = false;
    transactionId = null;
    approveDialogQuestion = null;
    beneficiaryDetails = null
  "
  (dialogAction)="approveTransaction()"
  [disableActionButton]="disableApproveAction"
  [isHTMLQuestion]="true"
>
</app-confirm-dialog>

<router-outlet></router-outlet>
