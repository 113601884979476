<div class="total-accordion__wrapper grid grid-cols-1 divide-y bg-white">
  @if (accountData && !(isFetchingBankList$ | async)) {
    <div class="hidden"></div>
    <app-ind-location-details
      [locationDetails]="locationDetails"
    ></app-ind-location-details>
    <app-ind-means-of-id [meansOfId]="meansOfIdDetails"></app-ind-means-of-id>
    <app-ind-employment-details
      [employmentDetails]="employmentDetails"
    ></app-ind-employment-details>
    <app-ind-next-of-kin [nextOfKin]="nextOfKinDetails"></app-ind-next-of-kin>
    <app-ind-bank-details
      [bankDetails]="bankDetails"
      [bankName]="bankName"
    ></app-ind-bank-details>
    <app-ind-documents [kycDocuments]="documentDetails"></app-ind-documents>
    <div></div>
  }
</div>
