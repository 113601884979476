import { CommonModule, formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { COUNTRIES, State, States2 } from '@core/data/countries-states';
//
import { NotificationFacade } from '@core/facades/notification.facade';
import { Locationkyc } from '@core/models/admin/account';
import { JointLocationkyc } from '@core/models/admin/account/joint';
import { IndividualRegistrationService } from '@core/services/individual-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-location-details',
  standalone: true,
  imports: [
    KycAccordionComponent,
    CommonModule,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    InputDateDirective,
    BaseSelectDirective,
    SpinDirective,
  ],
  templateUrl: './edit-location-details.component.html',
  styleUrl: './edit-location-details.component.scss',
})
export class EditLocationDetailsComponent implements OnInit, OnDestroy {
  @Input() locationDetails!: Locationkyc | JointLocationkyc;

  locationForm!: FormGroup;
  // cities: string[] | null = [];
  states: (State | States2)[] = [];
  countries = COUNTRIES.data;
  isUpdating$!: Observable<boolean>;
  isCreating$!: Observable<boolean>;
  subs: Subscription[] = [];

  isLocationCreatedSubject$ = new BehaviorSubject<boolean>(false);
  accountIdSubject$ = new BehaviorSubject<string | null>(null);

  isLocationCreated$ = this.isLocationCreatedSubject$.asObservable();

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  loadingService = inject(LoadingService);
  toast = inject(NotificationFacade);
  private individualService = inject(IndividualRegistrationService);

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      'update-location-kyc'
    );
    this.isCreating$ = this.loadingService.getLoadingObservable(
      'create-location-kyc'
    );
  }

  ngOnInit(): void {
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(this.route.snapshot.params['accountId']);
    }

    this.createForm();
    if (this.locationForm) {
      this.setFormValues();
    }
  }

  saveLocation() {
    this.isLocationCreatedSubject$.value
      ? this.updateLocation()
      : this.createNewLocation();
  }

  updateLocation() {
    const sub = this.individualService
      .updateLocationDetails(this.locationForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated Location details');
        },
        error: () => {
          this.toast.error('Failed to update Location details');
        },
      });

    this.subs.push(sub);
  }

  createNewLocation() {
    const sub = this.individualService
      .addCustomerLocation(this.locationForm.getRawValue())
      .subscribe({
        next: () => {
          this.isLocationCreatedSubject$.next(true);
          this.toast.success('Successfully added Location details');
        },
        error: () => {
          this.toast.error('Failed to add Location details');
        },
      });

    this.subs.push(sub);
  }

  createForm() {
    this.locationForm = this.fb.nonNullable.group({
      country: [''],
      state: [''],
      city: [''],
      // house_number: [''],
      address: [''],
      citizenship_status: [''],
      second_country: [''],
      residency_status: [''],
      residence_permit: [''],
      permit_issue_date: [''],
      permit_expiry_date: [''],
      // DO NOT REMOVE
      // account_type: ['individual'],
      account_id: [''],
    });
  }

  setFormValues() {
    if (!this.locationDetails) {
      this.locationForm.patchValue({
        account_id: this.accountIdSubject$.value,
      });
      return;
    }

    this.isLocationCreatedSubject$.next(true);

    if (this.locationDetails.country) {
      this.getCountryStates(this.locationDetails.country);
    }

    this.locationForm.patchValue({
      country: this.locationDetails.country || '',
      state: this.locationDetails.state || '',
      city: this.locationDetails.city || '',
      address: this.locationDetails.address || '',
      citizenship_status: this.locationDetails.citizenship_status || '',
      second_country: this.locationDetails.second_country || '',
      residency_status: this.locationDetails.residency_status || '',
      residence_permit: this.locationDetails.residence_permit || '',
      account_id: this.accountIdSubject$.value,
    });

    this.locationForm.controls['permit_issue_date'].setValue(
      formatDate(this.locationDetails.permit_issue_date, 'yyyy-MM-dd', 'en')
    );
    this.locationForm.controls['permit_expiry_date'].setValue(
      formatDate(this.locationDetails.permit_expiry_date, 'yyyy-MM-dd', 'en')
    );
  }

  getCountryStates(countryName: string) {
    this.states = this.countries.filter(
      (country) => country.name === countryName
    )[0].states;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
