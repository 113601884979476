import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { AuditLogDatum } from '@core/models/admin/account';
import { Pagination } from '@core/models/pagination.model';
import { AccountManagementService } from '@core/services/account-management.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { CorporateTabsComponent } from '@shared/ui/corporate-tabs/corporate-tabs.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { Subscription } from 'rxjs';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { IFILTER } from 'app/app.constants';
import { BreadCrumbService } from '@core/services/bread-crumb.service';

@Component({
  selector: 'app-audit-log',
  standalone: true,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './audit-log.component.html',
  styleUrl: './audit-log.component.scss',
  animations: [dropdownMenuAnimation],
  imports: [
    RouterLink,
    CommonModule,
    RouterOutlet,
    InputLabelComponent,
    BaseSelectDirective,
    ButtonDirective,
    InputDateDirective,
    CorporateTabsComponent,
    TableNavigatorComponent,
    FiltersComponent,
  ],
})
export class AuditLogComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  currentPage: number = 1;
  totalCount: number = 0;
  subs: Subscription[] = [];
  auditList: any[] = [];
  accountId!: string;
  auditLogs: AuditLogDatum[] = [];
  auditLogsTotalCount: number = 0;
  isLoading!: boolean;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private change = inject(ChangeDetectorRef);
  private accountService = inject(AccountManagementService);
  private cdr = inject(ChangeDetectorRef);
  private toast = inject(NotificationFacade);
  private breadCrumbService = inject(BreadCrumbService);

  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
  ];

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.route);

    this.getAccountIdInfo();
    if (this.accountId) {
      this.getAccountAuditLogs();
    }
    this.auditLogsFiltersEventListener();
  }

  getAccountIdInfo() {
    const accountId = this.route.snapshot.parent?.params['accountId'];
    if (accountId) {
      this.accountId = accountId;
    }
  }

  toggleFilterMenu() {
    this.filterMenuState =
      this.filterMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  auditLogsFiltersEventListener() {
    this.route.queryParams.subscribe((params) => {
      let query = '';
      console.log(params['start_date']);
      if (params[this.filterStructure[0].filterKey]) {
        query = `start_date=${params['start_date']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `end_date=${params['end_date']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}`;
      }
      if (query) {
        const sub = this.accountService
          .filterAccountAuditLogs(query, this.accountId)
          .subscribe({
            next: (res) => {
              this.isLoading = false;
              this.auditLogs = res.data.data;
              this.auditLogsTotalCount = 10; // 10 to remove pagination

              this.change.detectChanges();
            },
            error: (err) => {},
          });
        this.subs.push(sub);
      } else {
        this.getAccountAuditLogs();
      }
    });
  }

  getAccountAuditList(page: Pagination = new Pagination()) {
    const sub = this.accountService.viewAuditList(page).subscribe((res) => {
      this.auditList = res.data.data;
      this.totalCount = res.data.total_count;

      this.change.detectChanges();
    });
    this.subs.push(sub);
  }

  getAccountAuditLogs(page: Pagination = new Pagination()) {
    this.isLoading = true;
    const sub = this.accountService
      .getAccountAuditLogs(this.accountId, page)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.auditLogs = response.data.data;
          this.auditLogsTotalCount = response.data.total_count;

          this.cdr.detectChanges();
        },
        error: (response) => {
          this.isLoading = false;
          this.cdr.detectChanges();

          this.toast.error(
            'Failed to fetch audit logs. Please try again later.'
          );
        },
      });

    this.subs.push(sub);
  }

  navigate(currentPage: number = 1) {
    const skip = currentPage * 10 - 10;
    this.getAccountAuditLogs(new Pagination({ skip }));
  }

  get totalPage() {
    const remain = this.totalCount % 10; //.4
    const round = Number.parseInt((this.totalCount / 10).toFixed(0));
    return remain > 0 ? round + 1 : round;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
