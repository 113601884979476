import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileAccountsComponent } from '@shared/ui/profile-accounts/profile-accounts.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-individual-accounts',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule, ProfileAccountsComponent],
  templateUrl: './individual-accounts.component.html',
  styleUrl: './individual-accounts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndividualAccountsComponent implements OnInit {
  accountId!: string;

  private route = inject(ActivatedRoute);

  ngOnInit(): void {
    console.log('Individual Accounts');
    this.accountId = this.route.snapshot.params['accountId'];
  }
}
