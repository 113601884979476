<app-kyc-accordion
  accordionTitle="Employment Details"
  accordionKey="employmentDetails"
>
  <form class="" [formGroup]="employerForm">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- Employment status -->
      <div>
        <app-input-label
          labelFor="employmentStatus"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Employment status</app-input-label
        >
        <select
          name="employmentStatus"
          required
          base-select
          formControlName="status"
        >
          <option value="" disabled selected>Select employment status</option>
          <option
            *ngFor="let status of employmentStatus"
            [value]="status.value"
          >
            {{ status.label }}
          </option>
        </select>
      </div>

      <!-- Employer's Name -->
      <div>
        <app-input-label labelClass="label--bottom-margin" [isRequired]="true"
          >Employer's Name</app-input-label
        >

        <app-input
          placeholder="Enter Employer's Name"
          [inputClass]="'input--padding'"
          formControlName="name"
        ></app-input>
      </div>

      <!-- Nature of Business -->
      <div>
        <app-input-label
          labelFor="natureOfBusiness"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Nature of Business</app-input-label
        >

        <app-input
          name="natureOfBusiness"
          placeholder="Enter Nature of Business"
          [inputClass]="'input--padding'"
          formControlName="nature_of_business"
        ></app-input>
      </div>

      <!-- Address-->
      <div>
        <app-input-label
          labelFor="address"
          labelClass="label--bottom-margin"
          [isRequired]="true"
        >
          Address</app-input-label
        >

        <div>
          <textarea
            formControlName="address"
            textarea-style
            placeholder="Enter Address"
          >
          </textarea>
        </div>
      </div>

      <!-- Country -->
      <div>
        <app-input-label
          labelFor="country"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Country</app-input-label
        >
        <select
          name="country"
          required
          base-select
          formControlName="country"
          #country
          (change)="getCountryStates(country.value)"
        >
          <option value="" disabled selected>Select country</option>

          @for (country of countries; track $index) {
            <option [value]="country.name">{{ country.name }}</option>
          }
        </select>
      </div>

      <!-- State -->
      <div>
        <app-input-label
          labelFor="state"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >State</app-input-label
        >
        <div
          [ngClass]="{
            'cursor-not-allowed': states.length === 0
          }"
        >
          <select
            name="state"
            required
            base-select
            formControlName="state"
            [ngClass]="{
              'pointer-events-none bg-gray-50 text-gray-500 ring-gray-200':
                states.length === 0
            }"
          >
            <option value="" disabled selected>Select State</option>
            @for (state of states; track $index) {
              <option [value]="state.name">{{ state.name }}</option>
            }
          </select>
        </div>
      </div>

      <!-- City/Town -->
      <div>
        <app-input-label
          labelFor="city-town"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >City/Town</app-input-label
        >

        <app-input
          placeholder="Enter city"
          [inputClass]="'input--padding'"
          formControlName="city"
        ></app-input>
      </div>

      <div>
        <app-input-label
          labelFor="phone-number"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Office Phone Number</app-input-label
        >
        <app-input
          placeholder="Enter Phone Number"
          [inputClass]="'input--padding'"
          formControlName="phone_number"
        ></app-input>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': (isUpdating$ | async) || (isCreating$ | async)
        }"
      >
        <button
          type="button"
          mango-button
          [loading]="(isUpdating$ | async)!! || (isCreating$ | async)!!"
          [disabled]="(isUpdating$ | async)!! || (isCreating$ | async)!!"
          (click)="submitForm()"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span
            *ngIf="(isUpdating$ | async) || (isCreating$ | async)"
            spin
            [loadingText]="
              (hasEmployment$ | async) === true
                ? 'Updating Employment Info...'
                : 'Saving Employment Info...'
            "
          ></span>
          @if (!(isUpdating$ | async) && !(isCreating$ | async)) {
            {{
              (hasEmployment$ | async) === true
                ? 'Update Employment'
                : 'Add Employment'
            }}
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>
