import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { TabsComponent } from '@shared/ui/tabs/tabs.component';
import { JointPersonaldetails } from '@core/models/admin/account/joint';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CustomValidators } from '@core/validators/custom-validator';
import { JointRegistrationService } from '@core/services/joint-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { NIGERIAN_STATES } from '@core/data/nigerian-states';
import { COUNTRIES } from '@core/data/countries-states';
import {
  ANNUAL_INCOME,
  GENDER,
  MARITAL_STATUS,
  NIGERIAN_TITLES,
} from 'app/app.constants';

@Component({
  selector: 'app-edit-joint-customer-information',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    BaseSelectDirective,
    InputDateDirective,
    TabsComponent,
    SpinDirective,
  ],
  templateUrl: './edit-joint-customer-information.component.html',
  styleUrl: './edit-joint-customer-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointCustomerInformationComponent
  implements OnInit, OnDestroy
{
  @Input() personalDetails!: JointPersonaldetails[];

  @ViewChild('profileOneTemplate', { static: true })
  profileOneTemplate!: TemplateRef<any>;
  @ViewChild('profileTwoTemplate', { static: true })
  profileTwoTemplate!: TemplateRef<any>;

  isUpdatingProfileOneSubject$ = new BehaviorSubject<boolean>(false);
  isUpdatingProfileTwoSubject$ = new BehaviorSubject<boolean>(false);
  isCreatingProfileTwoSubject$ = new BehaviorSubject<boolean>(false);
  hasAccountTwoSubject$ = new BehaviorSubject<boolean>(false);

  isUpdatingProfileOne$ = this.isUpdatingProfileOneSubject$.asObservable();
  isUpdatingProfileTwo$ = this.isUpdatingProfileTwoSubject$.asObservable();
  isCreatingProfileTwo$ = this.isCreatingProfileTwoSubject$.asObservable();
  hasAccountTwo$ = this.hasAccountTwoSubject$.asObservable();

  profileTabs: { label: string; contentTemplate: TemplateRef<any> }[] = [];
  formAccountOne!: FormGroup;
  formAccountTwo!: FormGroup;
  subs: Subscription[] = [];
  nigerianStates = NIGERIAN_STATES;
  countries = COUNTRIES.data;
  titles = Object.entries(NIGERIAN_TITLES).map(([key, value]) => ({
    key,
    value,
  }));
  annualIncomeRange = ANNUAL_INCOME;
  maritalStatus = MARITAL_STATUS;
  genderList = GENDER;
  isLoading$!: Observable<boolean>;
  accountId!: string;

  private jointRegService = inject(JointRegistrationService);
  private loadingService = inject(LoadingService);
  private fb = inject(FormBuilder);
  private toast = inject(NotificationFacade);
  private route = inject(ActivatedRoute);

  constructor() {}

  ngOnInit(): void {
    this.profileTabs = [
      { label: 'Profile 1', contentTemplate: this.profileOneTemplate },
      { label: 'Profile 2', contentTemplate: this.profileTwoTemplate },
    ];

    this.accountId = this.route.snapshot.params['accountId'];
    this.createForm();

    if (this.formAccountOne && this.formAccountTwo) {
      this.setFormValues();
    }
  }

  submitAcctTwoForm() {
    this.hasAccountTwoSubject$.value
      ? this.updateProfile('accountTwo')
      : this.createSecondAccount();
  }

  createForm(): void {
    this.formAccountOne = this.fb.group({
      title: [''],
      first_name: ['', Validators.minLength(2)],
      last_name: ['', Validators.minLength(2)],
      middle_name: ['', Validators.minLength(2)],
      gender: [''],
      email: [''],
      phone_number_1: [''],
      phone_number_2: [''],
      dob: [new Date()],
      marital_status: [''],
      nationality: [''],
      annual_income: [''],
      state_of_origin: [''],      
      political_exposure: [''],
      place_of_birth: [''],
      bvn: ['', [Validators.required, CustomValidators.bvn()]],
      profile_id: [''],
    });

    this.formAccountTwo = this.fb.group({
      title: [''],
      first_name: ['', Validators.minLength(2)],
      last_name: ['', Validators.minLength(2)],
      middle_name: ['', Validators.minLength(2)],
      gender: [''],
      email: [''],
      phone_number_1: [''],
      phone_number_2: [''],
      dob: [new Date()],
      marital_status: [''],
      nationality: [''],
      annual_income: [''],
      state_of_origin: [''],      
      political_exposure: [''],
      place_of_birth: [''],
      bvn: ['', [Validators.required, CustomValidators.bvn()]],
      profile_id: [''],
    });
  }

  setFormValues(): void {
    if (this.personalDetails[0]) {
      const personalDetailsOne = this.personalDetails[0];
      this.profileTabs[0].label = `${personalDetailsOne.first_name} ${personalDetailsOne.last_name}`;
      this.formAccountOne.patchValue({
        title: personalDetailsOne.title || '',
        first_name: personalDetailsOne.first_name,
        last_name: personalDetailsOne.last_name,
        middle_name: personalDetailsOne.middle_name,
        email: personalDetailsOne.email,
        gender: personalDetailsOne.gender || '',
        phone_number_1: personalDetailsOne.phone_number_1 || '',
        phone_number_2: personalDetailsOne.phone_number_2 || '',
        marital_status: personalDetailsOne.marital_status || '',
        nationality: personalDetailsOne.nationality || '',
        annual_income: personalDetailsOne.annual_income || '',
        state_of_origin: personalDetailsOne.state_of_origin || '',
        bvn: personalDetailsOne.bvn || '',
        political_exposure: personalDetailsOne.political_exposure || '',
        place_of_birth: personalDetailsOne.place_of_birth || '',
        profile_id: personalDetailsOne.id,
      });

      // setting this separately because it is a date
      this.formAccountOne.controls['dob'].setValue(
        formatDate(personalDetailsOne.dob, 'yyyy-MM-dd', 'en')
      );
    }

    if (this.personalDetails[1]) {
      this.hasAccountTwoSubject$.next(true);
      const personalDetailsTwo = this.personalDetails[1];
      this.profileTabs[1].label = `${personalDetailsTwo.first_name} ${personalDetailsTwo.last_name}`;
      this.formAccountTwo.patchValue({
        title: personalDetailsTwo.title || '',
        first_name: personalDetailsTwo.first_name,
        last_name: personalDetailsTwo.last_name,
        middle_name: personalDetailsTwo.middle_name,
        email: personalDetailsTwo.email,
        gender: personalDetailsTwo.gender || '',
        phone_number_1: personalDetailsTwo.phone_number_1 || '',
        phone_number_2: personalDetailsTwo.phone_number_2 || '',
        marital_status: personalDetailsTwo.marital_status || '',
        nationality: personalDetailsTwo.nationality || '',
        annual_income: personalDetailsTwo.annual_income || '',
        state_of_origin: personalDetailsTwo.state_of_origin || '',
        bvn: personalDetailsTwo.bvn || '',
        political_exposure: personalDetailsTwo.political_exposure || '',
        place_of_birth: personalDetailsTwo.place_of_birth || '',
        profile_id: personalDetailsTwo.id,
      });

      // setting this separately because it is a date
      this.formAccountOne.controls['dob'].setValue(
        formatDate(personalDetailsTwo.dob, 'yyyy-MM-dd', 'en')
      );
    } else {
      this.hasAccountTwoSubject$.next(false);
      this.formAccountTwo.removeControl('profile_id');
      this.formAccountTwo.addControl(
        'account_id',
        new FormControl(this.accountId)
      );
    }
  }

  updateProfile(acccountType: 'accountOne' | 'accountTwo') {
    acccountType === 'accountOne'
      ? this.isUpdatingProfileOneSubject$.next(true)
      : this.isUpdatingProfileTwoSubject$.next(true);

    const formValue =
      acccountType === 'accountOne'
        ? this.formAccountOne.getRawValue()
        : this.formAccountTwo.getRawValue();

    this.jointRegService.updateCustomerProfile(formValue).subscribe({
      next: () => {
        if (acccountType === 'accountOne') {
          this.isUpdatingProfileOneSubject$.next(false);
          this.profileTabs[0].label = `${formValue.first_name} ${formValue.last_name}`;
        }
        if (acccountType === 'accountTwo') {
          this.isUpdatingProfileTwoSubject$.next(false);
          this.profileTabs[1].label = `${formValue.first_name} ${formValue.last_name}`;
        }
        this.toast.success('Successfully updated profile');
      },
      error: () => {
        acccountType === 'accountOne'
          ? this.isUpdatingProfileOneSubject$.next(false)
          : this.isUpdatingProfileTwoSubject$.next(false);
        this.toast.error('Failed to update profile');
      },
    });
  }

  createSecondAccount() {
    this.isCreatingProfileTwoSubject$.next(true);
    const sub = this.jointRegService
      .addJointUserProfile(this.formAccountTwo.getRawValue())
      .subscribe({
        next: (res) => {
          this.isCreatingProfileTwoSubject$.next(false);
          this.hasAccountTwoSubject$.next(true);
          this.profileTabs[1].label = `${res.first_name} ${res.last_name}`;
          this.formAccountTwo.removeControl('account_id');
          this.formAccountTwo.addControl('profile_id', new FormControl(res.id));
          this.toast.success(
            'Successfully added an additional joint account profile'
          );
        },
        error: () => {
          this.isCreatingProfileTwoSubject$.next(false);
          this.toast.error('Failed to add additional joint account profile');
        },
      });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
