import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Pagination } from '@core/models/pagination.model';
import { ApprovalService } from '@core/services/approval.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { Subscription } from 'rxjs';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { environment } from '@core/environments/environment';
import { IFILTER } from 'app/app.constants';
import { FiltersComponent } from '@shared/ui/filters/filters.component';

@Component({
  selector: 'app-approvals',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './approvals.component.html',
  styleUrl: './approvals.component.scss',
  animations: [dropdownMenuAnimation],
  imports: [
    RouterLink,
    CommonModule,
    DropdownComponent,
    RouterLink,
    InputLabelComponent,
    BaseSelectDirective,
    ButtonDirective,
    InputDateDirective,
    FormsModule,
    ReactiveFormsModule,
    SearchPipe,
    TableNavigatorComponent,
    FiltersComponent
  ],
})
export class ApprovalsComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';

  isDropdownVisible = true;
  showDropdown = false;
  subs: Subscription[] = [];
  documentList: any[] = [];
  actionList: DropDownModel[] = [];
  totalPending = 0;
  totalApproved = 0;
  totalReq = 0;
  totalCount!: number;
  date = new FormControl('', [Validators.required]);

  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  notify = inject(NotificationFacade);
  approval = inject(ApprovalService);
  change = inject(ChangeDetectorRef);
  breadCrumbService = inject(BreadCrumbService);
  $index: any;

  isFilterActive: boolean = false;


  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
    {
      filterKey: 'status',
      label: 'Status',
      type: 'dropdown',
      dropdown: [
        { key: 'pending', value: 'Pending' },
        { key: 'approved', value: 'Approved' },
        { key: 'rejected', value: 'Rejected' },
      ],
    },
  ];

  toggleFilterMenu() {
    this.filterMenuState =
      this.filterMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  initDropdownList(param: any) {
    //
    // private
    // archived
    // public_archived
    return (this.actionList = [
      {
        action: () => {
          this.openDocument(param);
        },
        title: 'Open Document',
        show: true,
      },
      {
        action: () => {
          this.approve(param);
        },
        title: 'Approve',
        show: param.status !== 'approved',
      },
      {
        action: () => {
          this.reject(param);
        },
        title: 'Reject',
        show: param.status !== 'rejected',
      },
    ]);
  }

  approve(data: any) {
    const sub = this.approval.approveDocument(data.id).subscribe({
      next: async (resp: any) => {
        this.notify.success(resp.message);

        this.navigate();
      },
    });
    this.subs.push(sub);
  }

  reject(data: any) {
    const sub = this.approval.rejectDocument(data.id).subscribe({
      next: async (resp: any) => {
        this.notify.success(resp.message);

        this.navigate();
      },
    });
    this.subs.push(sub);
  }

  openDocument(data: any) {
    window.open(`${environment.FILE_BASE_URL}${data.link}`, '_blank');
  }

  toggle() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }


  approvalsFilterEventListener() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let query = "";

      if (params[this.filterStructure[0].filterKey]) {
        query = `start_date=${params['start_date']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `end_date=${params['end_date']}&`;
      }
      if (params[this.filterStructure[2].filterKey]) {
        query += `status=${params['status']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}`;
      }
      if (query) {
        this.isFilterActive = true;
        const sub = this.approval.filterDocumentsList(query).subscribe({
          next: (res: any) => {
            this.documentList = res.data.data;
            this.totalCount = 10; // 10 to remove pagination

  
            this.change.detectChanges();
          },
          error: (err) => {},
        });
        this.subs.push(sub);
      } else {
        this.getApprovalList(new Pagination());
      }
    });
  }
  getApprovalList(params: Pagination) {
    const sub = this.approval.getDocumentsList(params).subscribe({
      next: (resp: any) => {
        this.totalCount = resp.data.total_count;
        this.documentList = resp.data.data;
        this.change.detectChanges();
      },
    });
    this.subs.push(sub);
  }

  getDocumentsMetaData() {
    const sub = this.approval.getDocumentsMetaData().subscribe({
      next: (resp: any) => {
        console.log(resp);
        this.totalReq = resp.data.total_docs
        this.totalPending = resp.data.pendind_doc_count
        this.totalApproved = resp.data.approved_doc_count

        this.change.detectChanges();
      },
    });
    this.subs.push(sub);
  }



  navigate(currentPage: number = 1) {
    const skip = currentPage * 10 - 10;
    if (this.isFilterActive) {
      // Append page param only

      this.router.navigate([], {
        queryParams: { page: currentPage },

        queryParamsHandling: 'merge',
      });
    } else {
      // Normal pagination when no filter is active
      
      this.getDocumentsMetaData();
    this.getApprovalList(new Pagination({ skip }));
    }
  }

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);
    this.getDocumentsMetaData();
    this.getApprovalList(new Pagination());
    this.approvalsFilterEventListener();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
