<img
  class="image fixed right-0 top-0 hidden h-[104px] w-[223px] sm:hidden sm:max-w-screen-sm md:hidden lg:block xl:block"
  src="/assets/images/image-21@2x.png"
  alt="image-"
/>
<div
  class="right__content sm:bg-#fcfcfc rounded-lg !bg-white p-[1.8rem] shadow-md sm:max-w-screen-sm sm:p-[2.9rem] sm:shadow-none xl:p-[3.12rem]"
>
  <h1 class="mb-[20px] text-2xl">Forgot Password?</h1>

  <p class="mb-[20px] text-[16px]">
    Enter your email address to reset your password.<br />
    A reset link will be sent to your registered email.
  </p>

  <div class="flex flex-col gap-5">
    <div class="flex flex-col" style="gap: 0.38rem">
      <app-input-label labelClass="label--bottom-margin">Email</app-input-label>
      <app-input
        placeholder="Enter Email"
        [inputClass]="'input--padding'"
        [formControl]="email"
      ></app-input>
    </div>

    <button
      (click)="sendResetRequest()"
      class="rounded-md {{
        email.valid ? 'bg-mango hover:bg-mango ' : 'bg-gray-200 text-gray-400'
      }} px-3.5 py-2.5 text-sm text-center font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mango"
    >
      Reset Password
    </button>

    <a
      (click)="goBack()"
      type="button"
      class="cursor-pointer text-center text-mango"
    >
      Back to Login
    </a>
  </div>
</div>
