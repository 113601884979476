import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewAdminListResponse } from '@core/models/admin/admin-management';
import { AdminManagementService } from '@core/services/admin-management.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { LoadingService } from '@core/services/loading.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, CommonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnDestroy {
  isLoading$!: Observable<boolean>;
  totalAdminSubject = new BehaviorSubject<number>(0);
  totalAdminCount$ = this.totalAdminSubject.asObservable();
  fullyRegisteredSubject = new BehaviorSubject<number>(0);
  fullRegisteredCount$ = this.fullyRegisteredSubject.asObservable();
  adminList$!: Observable<ViewAdminListResponse>;
  subs: Subscription[] = [];

  breadCrumbService = inject(BreadCrumbService);
  route = inject(ActivatedRoute);
  adminService = inject(AdminManagementService);
  private loadingService = inject(LoadingService);

  ngOnInit(): void {
    this.breadCrumbService.setPageBreadCrumb(this.route);
    this.isLoading$ =
      this.loadingService.getLoadingObservable('view-admin-list');

    this.getAdminList();
  }

  getAdminList() {
    this.adminList$ = this.adminService.viewAdminList();

    const sub = this.adminList$.subscribe({
      next: (response: ViewAdminListResponse) => {
        this.totalAdminSubject.next(response.data.total_count);
        let totalRegistered =
          response.data.data.filter((admin) => admin.status === 'activated')
            .length || 0;
        this.fullyRegisteredSubject.next(totalRegistered);
      },
    });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
