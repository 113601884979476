<!--Search input and Filter-->
<app-filters
[filters]="filterStructure"
></app-filters>

<!-- Audit Log Table -->
<div
  class="mt-[0.94rem] rounded-lg border border-solid border-mango-gray200 bg-white"
>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                S/N
              </th>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Action
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            @for (log of auditLogs; track $index) {
              <tr>
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                >
                  {{ $index + 1 }}
                </td>
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                >
                  {{ log.name }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ log.action }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ log.date | date: 'full' }}
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div
          *ngIf="(!auditLogs || auditLogs.length === 0) && !isLoading"
          class="flex w-full px-6 pt-6"
        >
          No logs for this account
        </div>
        <div *ngIf="isLoading === true" class="flex w-full px-6 pt-6">
          ...Fetching Audit Logs
        </div>

        @if (auditLogs && auditLogsTotalCount > 0 && !isLoading) {
          <app-table-navigator
            [total]="auditLogsTotalCount"
            (onNavigation)="navigate($event)"
          ></app-table-navigator>
        }
      </div>
    </div>
  </div>
</div>
