import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { CommonModule } from '@angular/common';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import { RolesAndPermissionsService } from '@core/services/roles-and-permissions.service';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { NotificationFacade } from '@core/facades/notification.facade';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { Pagination } from '@core/models/pagination.model';
import { AllRolesDatum } from '@core/models/admin/roles-and-permissions';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { IFILTER } from 'app/app.constants';
@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    RouterLink,
    RouterOutlet,
    InputLabelComponent,
    ButtonDirective,
    BaseSelectDirective,
    DropdownComponent,
    ConfirmDialogComponent,
    TableNavigatorComponent,
    FiltersComponent
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss',
  animations: [dropdownMenuAnimation],
})
export class RolesComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  menuRow!: number | null;
  isLoading: boolean = false;
  isDialogOpen: boolean = false;
  isDialogLoading: boolean = false;
  subs: Subscription[] = [];
  rolesList: AllRolesDatum[] = [];
  roleId!: string | null;
  roleName!: string | null;
  totalCount!: number;
  currentPage: number = 1;

  breadCrumbService = inject(BreadCrumbService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  cdr = inject(ChangeDetectorRef);
  rolePermissionService = inject(RolesAndPermissionsService);
  notify = inject(NotificationFacade);


  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
    {
      filterKey: 'type',
      label: 'Type',
      type: 'dropdown',
      dropdown: [
        { key: 'admin', value: 'Admin' },
        { key: 'client', value: 'Client' },
      ],
    },
    
  ];

  ngOnInit(): void {
    this.breadCrumbService.setPageBreadCrumb(this.route);
    this.getAllRoles();

    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.getCurrentPageRoles();
    //   }
    // });
    this.rolesFiltersEventListener();
  }


  rolesFiltersEventListener() {
    this.route.queryParams.subscribe((params) => {
      let query = "";
      if (params[this.filterStructure[0].filterKey]) {
        query = `start_date=${params['start_date']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `end_date=${params['end_date']}&`;
      }
      if (params[this.filterStructure[2].filterKey]) {
        query += `type=${params['type']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}`;
      }
      if (query) {
        const sub = this.rolePermissionService.filterAllRoles(query).subscribe({
          next: (res) => {
            this.rolesList = res.data.data;
            
            if (res.data.data.length === 0) {
              this.totalCount = 0;
            } else {
              this.totalCount = 10; // 10 to remove pagination
            }
            this.isLoading = false;

            this.cdr.detectChanges();
          },
          error: (err) => {},
        });
        this.subs.push(sub);
      } else {
        this.getAllRoles();
      }
    });
  }

  toggleFilterMenu() {
    this.filterMenuState =
      this.filterMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  roleDropdownActions(role: AllRolesDatum): DropDownModel[] {
    return [
      {
        title: 'View Details',
        action: () => {
          this.router.navigate([`admin/roles/view-role/${role.id}`], {
            state: { role },
          });
        },
        show: true,
      },
      {
        title: 'Edit',
        action: () => {
          this.router.navigate([`admin/roles/edit-role/${role.id}`], {
            state: { role },
          });
        },
        show: true,
      },
      {
        title: 'Delete',
        action: () => {
          this.roleId = role.id;
          this.isDialogOpen = true;
          this.roleName = role.name;
        },
        show: true,
      },
    ];
  }

  getCurrentPageRoles() {
    const sub = this.route.queryParams.subscribe({
      next: (param) => {
        this.currentPage = Number.parseInt(param['page'] || '1');
        const skip = this.currentPage === 1 ? 0 : this.currentPage * 10 - 10;
        this.getAllRoles(new Pagination({ skip }));
      },
    });
    this.subs.push(sub);
  }

  getAllRoles(page: Pagination = new Pagination()) {
    this.isLoading = true;
    const sub = this.rolePermissionService.getAllRoles(page).subscribe({
      next: (response) => {
        this.rolesList = response.data.data;
        this.totalCount = response.data.total_count;

        this.isLoading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.isLoading = false;

        this.cdr.detectChanges();
      },
    });

    this.subs.push(sub);
  }

  deleteRole() {
    this.isDialogLoading = true;
    let roleId = '';
    this.roleId ? (roleId = this.roleId) : null;

    const sub = this.rolePermissionService.deleteRole(roleId).subscribe({
      next: (response) => {
        this.resetDialog();
        this.getCurrentPageRoles();
        this.cdr.detectChanges();
        this.notify.success('Role deleted successfully');
      },
      error: (err) => {
        this.resetDialog();

        this.cdr.detectChanges();
      },
    });

    this.subs.push(sub);
  }

  resetDialog() {
    this.isDialogLoading = false;
    this.isDialogOpen = false;
    this.roleId = null;
  }

  navigate(currentPage: number) {
    this.getAllRoles(new Pagination({ skip: currentPage * 10 - 10 }));
  }

  ngOnDestroy(): void {}
}
