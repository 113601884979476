import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { ErrorDialogService } from '@core/services/error-dialog.service';
import { Observable, tap } from 'rxjs';

/**
 * Any additional headers will make the API return 403
 */
//TODO: remove the bypass on implementing the auth service
export const AuthInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  let headers = { 'Content-Type': 'application/json'};

  const authService = inject(AuthService);

  const token = authService.getToken();

  if (req.url.indexOf('/auth/') === -1) {
    Object.assign(headers, {
      Authorization: 'Bearer ' + token,
    });
  }

  req = req.clone({
    setHeaders: headers,
    setParams: {},
  });

  return next(req);
};
