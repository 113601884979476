<div
  class="flex grid-cols-2 flex-col items-center gap-4 md:grid md:justify-between"
>
  <h2
    class="text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
  >
    Accounts
  </h2>

  <div class="flex justify-end items-center gap-2">
    <app-dropdown
      class="w-fit"
      [id]="'addCustomerDropdown'"
      [buttonTitle]="'+ Add customer'"
      [actionList]="registerationDropdownActions()"
    ></app-dropdown>
    <button
     mango-button
     (click) = "exportReport()"
     > Export Report</button>
  </div>
</div>

<!-- Account meta data -->
<div class="mt-[0.94rem] grid gap-5 lg:grid-cols-3">
  <div
    class="rounded-lg border border-solid border-mango-gray200 bg-mango-paleBlue p-[38px]"
  >
    <!-- Your chart component goes here -->
    <div>
      <p>Total number of accounts</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">
        {{ (accountMetadata$ | async)?.total_account || 0 }}
      </h1>
    </div>
  </div>
  <div
    class="rounded-lg border border-solid border-mango-gray200 bg-mango-paleGreen p-[38px]"
  >
    <!-- Your chart component goes here -->
    <div>
      <p>Total incomplete</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">
        {{ (accountMetadata$ | async)?.incomplete_account || 0 }}
      </h1>
    </div>
  </div>
  <div
    class="rounded-lg border border-solid border-mango-gray200 bg-mango-lightOrange p-[38px]"
  >
    <!-- Your chart component goes here -->
    <div>
      <p>Total completed</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">
        {{ (accountMetadata$ | async)?.completed_account || 0 }}
      </h1>
    </div>
  </div>
</div>

<app-filters [filters]="filterStructure"></app-filters>

<!-- table skeleton loader -->
<div *ngIf="isFetchingAccountList$ | async" class="mt-[0.94rem]">
  <app-table-skeleton-loader></app-table-skeleton-loader>
</div>

<!-- Table -->
<div
  *ngIf="!(isFetchingAccountList$ | async)"
  class="mt-[0.94rem] rounded-lg border border-solid border-mango-gray200 bg-white"
>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="flex border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold text-gray-600"
              >
                S/N
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99998 3.33301V12.6663M7.99998 12.6663L12.6666 7.99967M7.99998 12.6663L3.33331 7.99967"
                    stroke="#667085"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Date Created
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Account Name
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Account No.
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Account Type
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Status
              </th>

              <th
                scope="col"
                class="relative border-b-2 border-gray-200 bg-gray-100 py-3.5 pl-3 pr-4 sm:pr-6"
              ></th>
            </tr>
          </thead>

          <tbody>
            @if ((accountsList$ | async).length > 0) {
              @for (
                account of accountsList$ | async;
                track account.account_id;
                let i = $index
              ) {
                <ng-container
                  *ngTemplateOutlet="
                    accountRow;
                    context: { data: account, i: i }
                  "
                ></ng-container>
              }
            }
          </tbody>
        </table>

        <app-table-navigator
          *ngIf="totalCount$ | async as totalCount"
          [total]="totalCount"
          (onNavigation)="navigate($event)"
        ></app-table-navigator>
      </div>
    </div>
  </div>
</div>

<ng-template #accountRow let-account="data" let-i="i">
  <tr>
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p>{{ i + 1 }}</p>
    </td>
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p class="whitespace-no-wrap text-gray-900">
        {{ account.created_at | date: 'medium' }}
      </p>
    </td>
    <td
      (click)="viewAccountDetails(account)"
      class="cursor-pointer border-b border-gray-200 bg-white px-5 py-5 text-sm"
    >
      <p class="whitespace-no-wrap text-gray-900">
        @if (account.account_type === 'corporate') {
          {{ account.corporate_kyc?.name || '-' | uppercase }}
        }
        @if (account.account_type === 'joint') {
          {{ getJointAccountName(account.joint_kyc) || '-' }}
        }
        @if (account.account_type === 'individual') {
          {{ account.user_profile.last_name | uppercase }}
          {{ account.user_profile.first_name }}
          {{ account.user_profile?.middle_name || '' }}
        }
      </p>
    </td>
    <td
      (click)="viewAccountDetails(account)"
      class="cursor-pointer border-b border-gray-200 bg-white px-5 py-5 text-sm"
    >
      <p class="whitespace-no-wrap text-gray-900">
        {{ account.mango_account_number }}
      </p>
    </td>
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p class="whitespace-no-wrap text-gray-900">
        {{ account.account_type | uppercase }}
      </p>
    </td>

    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <span
        class="relative inline-block px-2 py-1 font-semibold leading-tight text-green-900 sm:px-2"
      >
        <span
          aria-hidden
          class="absolute inset-0 {{
            account.kyc_status === 'approved'
              ? 'bg-green-400'
              : account.kyc_status === 'rejected'
                ? 'bg-red-300'
                : account.kyc_status === 'completed'
                  ? 'bg-blue-300'
                  : 'bg-yellow-400'
          }} opacity-50 rounded-full"
        ></span>
        <span
          class="relative flex  {{
            account.kyc_status === 'approved'
              ? 'text-green-700'
              : account.kyc_status === 'rejected'
                ? 'text-red-900'
                : account.kyc_status === 'completed'
                  ? 'text-blue-700'
                  : 'text-yellow-900'
          }} sm:text-[11px]"
        >
          {{ account.kyc_status | uppercase }}</span
        >
      </span>
    </td>

    <td
      class="relative whitespace-nowrap border-b py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
    >
      <div
        class="relative inline-block w-fit cursor-pointer border-gray-200 bg-white text-left"
      >
        <app-dropdown
          [id]="account.id"
          [actionList]="initDropdownList(account)"
        >
        </app-dropdown>
      </div>
    </td>
  </tr>
</ng-template>

<app-confirm-dialog
  [confirmQuestion]="
    isExportInProgress
      ? 'Exporting KYC for ' +
        (accountType | titlecase) +
        ' account: ' +
        (accountName | titlecase) +
        '.Please do not close this page.'
      : 'KYC File for ' +
        (accountType | titlecase) +
        ' account: ' +
        ' \'' +
        (accountName | titlecase) +
        '\' exported successfully'
  "
  confirmText="Open Exported KYC"
  [isDialogLoading]="isExportInProgress"
  [isOpen]="isExportDialogOpen"
  dialogLoadingText="Exporting KYC..."
  [showQuestionGif]="false"
  (close)="
    isExportDialogOpen = false;
    accountName = null;
    exportedKycUrl = null;
    accountType = null
  "
  (dialogAction)="openKycDocument()"
  [showCancelButton]="true"
>
</app-confirm-dialog>

<router-outlet></router-outlet>
