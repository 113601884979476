<div class="flex flex-col gap-[0.94rem]">
  <div
    class="grid grid-cols-2 justify-between gap-4 md:grid md:justify-between"
  >
    <h2
      class="translate-y-[4px] text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
    >
      Roles
    </h2>

    <button
      type="button"
      mango-button
      routerLink="/admin/roles/add-role"
      class="w-fit justify-self-end"
    >
      + Add New Role
    </button>
  </div>

  <!-- Search Input and Filters -->
  <app-filters
  [filters]="filterStructure"
  ></app-filters>

  <div class="rounded-lg border border-solid border-mango-gray200 bg-white">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  S/N
                </th>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Type
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Permission
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date Created
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody
              *ngIf="rolesList.length > 0"
              class="divide-y divide-gray-200 bg-white"
            >
              @for (role of rolesList; track $index) {
                <tr>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ $index + 1 }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ role.name | titlecase }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ role.type | titlecase }}
                  </td>

                  @if (role.permissions.length > 0) {
                    <td
                      class="flex flex-wrap gap-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      @for (permission of role.permissions; track $index) {
                        <span
                          class="w-fit items-center rounded-full bg-green-100 px-2 py-1 font-medium text-mango-pineGreen"
                        >
                          {{ permission.permission.name }}
                        </span>
                      }
                    </td>
                  } @else {
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                    >
                      No permission
                    </td>
                  }

                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ role.created_at | date: 'medium' }}
                  </td>

                  <!-- 3-vertical dots icon -->
                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <div
                      class="relative inline-block w-fit cursor-pointer text-left"
                    >
                      <app-dropdown
                        [id]="role.id"
                        [actionList]="roleDropdownActions(role)"
                      >
                      </app-dropdown>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <div
          
            *ngIf="(!rolesList || totalCount === 0) && isLoading === false"
            class="flex w-full px-6 pb-6 pt-6"
          >

            No roles found.
          </div>

          <div *ngIf="isLoading === true" class="flex w-full px-6 pb-6 pt-6">
            ...Fetching roles. Please wait.
          </div>

          <app-table-navigator
            *ngIf="totalCount"
            [total]="totalCount"
            (onNavigation)="navigate($event)"
          >
          </app-table-navigator>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-dialog
  [confirmQuestion]="
    'Are you sure you want to delete the: \'' +
    roleName +
    '\' role? This action cannot be undone.'
  "
  confirmText="Yes, Delete Role"
  bgConfirmButton="danger"
  [isOpen]="isDialogOpen"
  [isDialogLoading]="isDialogLoading"
  (dialogAction)="deleteRole()"
  (close)="isDialogOpen = false; roleId = null; roleName = null"
  dialogLoadingText="Deleting Role..."
>
</app-confirm-dialog>

<router-outlet></router-outlet>
