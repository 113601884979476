import { EditSignatoryDetailsComponent } from '@accounts/company-account/edit-account/edit-signatory-details/edit-signatory-details.component';
import { EditSignatoryComponent } from '@accounts/company-account/pages/signatory-list/pages/edit-signatory/edit-signatory.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-edit-joint-signatory',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    SpinDirective,
    ConfirmDialogComponent,
    RouterOutlet,
  ],
  templateUrl: './edit-joint-signatory.component.html',
  styleUrl: './edit-joint-signatory.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointSignatoryComponent extends EditSignatoryDetailsComponent {
  constructor() {
    super();
  }
}
