<div
  class="flex grid-cols-1 flex-col items-center gap-4 md:grid md:justify-between"
>
  <h2
    class="text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
  >
    Mutual Funds
  </h2>

  <div class="flex items-center justify-end gap-2">
    <app-dropdown
      class="w-fit"
      [id]="'actionDropdown'"
      [buttonTitle]="'+ Mutual Funds Actions'"
      [actionList]="mutualFundsActions()"
    ></app-dropdown>
    <!-- <button mango-button (click)="addTransaction()">+ Add Transaction</button> -->
  </div>
</div>

<!-- Mutual funds meta data -->
<div class="mt-[0.94rem] grid gap-5 lg:grid-cols-3">
  <div
    class="rounded-lg border border-solid border-mango-gray200 bg-mango-paleBlue p-[38px]"
  >
    <!-- Total Principal -->
    <div>
      <p>Total Principal</p>
    </div>
    <app-tooltip-overlay
      [displayValue]="(totalPrincipal$ | async) / 100 | number: '1.2-2'"
      [fullValue]="(totalPrincipal$ | async) / 100 | number"
    >
    </app-tooltip-overlay>
  </div>

  <div
    class="rounded-lg border border-solid border-mango-gray200 bg-mango-paleGreen p-[38px]"
  >
    <!-- Total Weight -->
    <div>
      <p>Total Weight</p>
    </div>
    <app-tooltip-overlay
      [displayValue]="
        globalDate
          ? (totalWeight$ | async | number: '1.2-2') + '%'
          : 'Select a date'
      "
      [fullValue]="globalDate ? (totalWeight$ | async) + '%' : 'Select a date'"
    >
    </app-tooltip-overlay>
  </div>
  <div
    class="rounded-lg border border-solid border-mango-gray200 bg-mango-lightOrange p-[38px]"
  >
    <!-- Daily Dividend -->
    <div>
      <p>
        Daily Dividend
        {{ globalDate ? '(' + (globalDate | date: 'shortDate') + ')' : '' }}
      </p>
    </div>

    <app-tooltip-overlay
      [displayValue]="
        globalDate
          ? ((dailyDividend$ | async) / 100 | number: '1.2-2')
          : 'Select a date'
      "
      [fullValue]="globalDate ? '' + (dailyDividend$ | async) : 'Select a date'"
    >
    </app-tooltip-overlay>
  </div>

  <div>
    <!-- Tabs for mobile -->
    <div class="scroll-wrapper w-full overflow-hidden">
      <div
        class="scroll-container box-border w-full overflow-x-auto whitespace-nowrap p-[10px] sm:hidden"
      >
        <div class="scroll-content inline-block">
          @for (path of pathMap; track $index) {
            <a
              [routerLink]="path.route"
              [ngClass]="{
                'bg-mango-lightOrange text-mango': router.url.includes(
                  path.route
                ),
              }"
              class="badge mr-[10px] inline-block cursor-pointer whitespace-nowrap text-wrap rounded-[100px] border border-solid border-gray-400 px-[21px] py-[9px] text-gray-500"
            >
              {{ path.label | titlecase }}
            </a>
          }
        </div>
      </div>
    </div>

    <!-- Tabs for desktop -->
    <div class="hidden w-max rounded-lg bg-white sm:block">
      <nav class="flex w-fit space-x-4 p-[0.31rem]" aria-label="Tabs">
        @for (path of pathMap; track $index) {
          <a
            [routerLink]="path.route"
            [ngClass]="{
              'bg-mango-lightOrange text-mango': router.url.includes(
                path.route
              ),
            }"
            class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
            ><span>{{ path.label }}</span></a
          >
        }
      </nav>
    </div>
  </div>
</div>
<app-filters [filters]="filterStructure"></app-filters>

<router-outlet></router-outlet>
