import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { OrderService } from '@core/services/order.service';
import { TableSkeletonLoaderComponent } from '../../../shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { CommonModule } from '@angular/common';
import { TableNavigatorComponent } from '../../../shared/ui/table-navigator/table-navigator.component';
import { DropdownComponent } from '../../../shared/ui/dropdown/dropdown.component';
import { Pagination } from '@core/models/pagination.model';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { IFILTER } from 'app/app.constants';
import { BreadCrumbService } from '@core/services/bread-crumb.service';

@Component({
  selector: 'app-orders-by-account',
  standalone: true,
  imports: [
    TableSkeletonLoaderComponent,
    CommonModule,
    TableNavigatorComponent,
    DropdownComponent,
    FiltersComponent,
  ],
  templateUrl: './orders-by-account.component.html',
  styleUrl: './orders-by-account.component.scss',
})
export class OrdersByAccountComponent implements OnInit, OnDestroy {
  accountId: string = '';
  currentPage = 1;
  subs: Subscription[] = []; // Array to store subscriptions

  orderService = inject(OrderService);
  loadingService = inject(LoadingService);
  activatedRoute = inject(ActivatedRoute);
  private route = inject(ActivatedRoute);
  private breadCrumbService = inject(BreadCrumbService);

  // BehaviorSubject for handling orders
  private ordersSubject = new BehaviorSubject<any>(null);
  orders$ = this.ordersSubject.asObservable(); // Observable that the UI listens to

  // Filter structure for the filters component
  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
    {
      filterKey: 'type',
      label: 'Order Type',
      type: 'dropdown',
      dropdown: [
        { key: 'invest', value: 'Invest' },
        { key: 'liquidate', value: 'Liquidate' },
      ],
    },
    {
      filterKey: 'status',
      label: 'Status',
      type: 'dropdown',
      dropdown: [
        { key: 'initiated', value: 'Initiated' },
        { key: 'in_process', value: 'In Process' },
        { key: 'completed', value: 'Completed' },
        { key: 'terminated', value: 'Terminated' },
      ],
    },
    {
      filterKey: 'product_id',
      label: 'Product',
      type: 'dropdown',
      dropdown: [],
    },
  ];

  loading$ = this.loadingService.getLoadingObservable(
    this.orderService.ordersLoadingStates.getOrdersByAccountId
  );

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.route);

    this.getAccountIdInfo();
    this.fetchOrders(); // Initial fetch of orders
    this.ordersfilterEventListener(); // Set up filter listener
  }

  ngOnDestroy() {
    // Clean up subscriptions to avoid memory leaks
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // Retrieve account ID from route parameters
  getAccountIdInfo() {
    const accountId = this.activatedRoute.snapshot.parent?.params['accountId'];
    if (accountId) {
      this.accountId = accountId;
    }
  }

  // Fetch the orders by account ID and push the result into ordersSubject
  fetchOrders() {
    const sub = this.orderService
      .getOrdersByAccountId(this.accountId)
      .subscribe({
        next: (res) => this.ordersSubject.next(res.data), // Emit data into ordersSubject
        error: (err) => console.error('Error fetching orders:', err),
      });
    this.subs.push(sub);
  }

  // Listen to queryParams changes and filter the orders based on filters
  ordersfilterEventListener() {
    const sub = this.activatedRoute.queryParams.subscribe((params) => {
      let query = '';

      // Construct query based on filters
      if (params[this.filterStructure[0].filterKey]) {
        query += `start_date=${params['start_date']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `end_date=${params['end_date']}&`;
      }
      if (params[this.filterStructure[2].filterKey]) {
        query += `type=${params['type']}&`;
      }
      if (params[this.filterStructure[3].filterKey]) {
        query += `status=${params['status']}&`;
      }
      if (params[this.filterStructure[4].filterKey]) {
        query += `product_id=${params['product']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}&`;
      }
      query += `account_id=${this.accountId}`;

      // Perform filtering or refetch the original data if no filters
      if (query) {
        const sub = this.orderService.filterOrdersByAccountID(query).subscribe({
          next: (res) => this.ordersSubject.next(res.data), // Push filtered data to orders$
          error: (err) => {},
        });
        this.subs.push(sub);
      } else {
        this.fetchOrders(); // Fetch original data if no filters are applied
      }
    });
    this.subs.push(sub); // Add this subscription to subs array
  }

  // Handle pagination and emit new data into orders$
  navigate(page: number) {
    const skip = page === 1 ? 0 : (page - 1) * 10;
    const sub = this.orderService
      .getOrdersByAccountId(this.accountId, new Pagination({ skip }))
      .subscribe({
        next: (res) => this.ordersSubject.next(res.data), // Push paginated data to orders$
        error: (err) => console.error('Error paginating orders:', err),
      });
    this.subs.push(sub); // Store the subscription
  }
}
