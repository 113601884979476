<app-slide-in-right-modal
  [modalTitle]="
    admin ? (admin.first_name + ' ' + admin.last_name | titlecase) : ''
  "
  iconImageSrc="assets/images/svgs/user--outline.svg"
  [slideOpen]="true"
  returnPath="/admin/users"
>
  <div *ngIf="admin && !isLoading" class="flex flex-col gap-5">
    <div class="flex justify-between text-mango-darkBlack">
      <p class="text-base font-medium">First Name</p>
      <p class="font-normal">
        {{ admin.first_name | titlecase }}
      </p>
    </div>
    <div class="flex justify-between text-mango-darkBlack">
      <p class="text-base font-medium">Middle Name</p>
      <p class="font-normal">
        {{ (admin.middle_name | titlecase) || 'not provided' }}
      </p>
    </div>
    <div class="flex justify-between text-mango-darkBlack">
      <p class="text-base font-medium">Last Name</p>
      <p class="font-normal">
        {{ admin.last_name | titlecase }}
      </p>
    </div>
    <div class="flex justify-between text-mango-darkBlack">
      <p class="text-base font-medium">Email</p>
      <p class="font-normal">
        {{ admin.email | titlecase }}
      </p>
    </div>
    <div class="flex justify-between text-mango-darkBlack">
      <p class="text-base font-medium">Status</p>
      <span
        [ngClass]="[
          admin.status === 'activated'
            ? 'bg-green-100 text-mango-pineGreen'
            : 'bg-mango-shellRed text-mango-red707'
        ]"
        class="w-fit items-center rounded-full px-2 py-1 text-xs font-medium uppercase"
      >
        {{ admin.status | lowercase }}
      </span>
    </div>
    <div class="flex justify-between text-mango-darkBlack">
      <p class="text-base font-medium">Phone Number</p>
      <p class="font-normal">
        {{ admin.phone_number || 'not provided' }}
      </p>
    </div>

    <div class="grid grid-cols-2 text-mango-darkBlack">
      <p class="text-base font-medium">Role(s)</p>
      <p class="flex flex-row-reverse flex-wrap gap-1 font-normal">
        @for (role of admin.admin_roles; track $index) {
          <span
            class="whitespace-no-wrap rounded-full bg-green-100 px-2 py-1 font-medium text-mango-pineGreen"
          >
            {{ role.role.name }}
          </span>
        }
      </p>
    </div>

    <div class="inline-flex justify-end gap-[0.94rem]">
      <button
        (click)="editAdmin()"
        type="button"
        class="flex gap-2 rounded-lg border border-solid border-mango-gray300 bg-white px-4 py-2 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] hover:bg-gray-50"
      >
        <img src="assets/images/svgs/edit-pen.svg" alt="" class="h-5 w-5" />
        <span>Edit</span>
      </button>
      <div
        [ngClass]="{
          'cursor-not-allowed': isLoading
        }"
      >
        <button
          (click)="isDialogOpen = true"
          mango-button
          type="button"
          class="items-center"
          [loading]="isLoading"
          [disabled]="isLoading"
        >
          <span *ngIf="isLoading" spin></span>
          @if (!isLoading) {
            Delete
          }
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!admin && isLoading">
    <p>...Fetching admin profile</p>
  </div>
</app-slide-in-right-modal>

<app-confirm-dialog
  [confirmQuestion]="confirmQuestion"
  confirmText="Yes, Delete Admin"
  (dialogAction)="deleteAdmin()"
  [isOpen]="isDialogOpen"
  [isDialogLoading]="isDeleteInProgress"
  bgConfirmButton="danger"
  (close)="isDialogOpen = false; isDeleteInProgress = false"
  [dialogLoadingText]="'Deleting Admin: ' + adminName"
>
</app-confirm-dialog>
