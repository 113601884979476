<app-kyc-accordion accordionTitle="Means Of Id" accordionKey="meansOfId">
  <form class="" [formGroup]="meansOfIdForm">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- ID Type -->
      <div>
        <app-input-label labelFor="idType" labelClass="label--bottom-margin"
          >ID Type</app-input-label
        >
        <select name="idType" required base-select formControlName="type">
          <option value="" disabled selected>Select ID type</option>
          <option *ngFor="let idType of meansOfIdTypes" [value]="idType.value">
            {{ idType.label }}
          </option>
        </select>
      </div>

      <!-- ID Number -->
      <div>
        <app-input-label
          labelFor="id_number"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >ID Number</app-input-label
        >

        <app-input
          placeholder="Enter ID Number"
          [inputClass]="'input--padding'"
          formControlName="number"
        ></app-input>
      </div>

      <!-- Issue Date -->
      <div>
        <app-input-label labelClass="label--bottom-margin" [isRequired]="requiresExpiry"
          >Issue Date</app-input-label
        >
        <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
        <input
          type="date"
          input-date
          class="input--padding"
          formControlName="issue_date"
        />
      </div>

      <!-- Exipry Date -->
      <div>
        <app-input-label labelClass="label--bottom-margin" [isRequired]="requiresExpiry"
          >Exipry Date</app-input-label
        >
        <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
        <input
          type="date"
          input-date
          class="input--padding"
          formControlName="expiry_date"
        />
      </div>
    </div>
    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': (isUpdating$ | async) || (isCreating$ | async)
        }"
      >
        <button
          type="button"
          mango-button
          [loading]="(isUpdating$ | async)!! || (isCreating$ | async)!!"
          [disabled]="(isUpdating$ | async)!! || (isCreating$ | async)!!"
          (click)="submitForm()"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span
            *ngIf="(isUpdating$ | async) || (isCreating$ | async)"
            spin
          ></span>
          @if (!(isUpdating$ | async) && !(isCreating$ | async)) {
            Save Changes
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>
