import { CommonModule, formatDate } from '@angular/common';
import { Component, Input, OnDestroy, inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { COUNTRIES, State, States2 } from '@core/data/countries-states';
//
import { NotificationFacade } from '@core/facades/notification.facade';
import { Nextofkin } from '@core/models/admin/account';
import { JointNextofkin } from '@core/models/admin/account/joint';
import { IndividualRegistrationService } from '@core/services/individual-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import {
  COUNTRY_ALPHA_CODE,
  GENDER,
  KIN_RELATIONSHIPS,
  NIGERIAN_TITLES,
} from 'app/app.constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-next-of-kin',
  standalone: true,
  imports: [
    KycAccordionComponent,
    CommonModule,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    BaseSelectDirective,
    InputDateDirective,
    SpinDirective,
  ],
  templateUrl: './edit-next-of-kin.component.html',
  styleUrl: './edit-next-of-kin.component.scss',
})
export class EditNextOfKinComponent implements OnDestroy {
  @Input() nextOfKinDetails!: Nextofkin | JointNextofkin;

  nextOfKinForm!: FormGroup;
  // cities: string[] | null = [];
  // accountId!: string;
  states: (State | States2)[] = [];
  subs: Subscription[] = [];

  isUpdating$!: Observable<boolean>;
  isCreating$!: Observable<boolean>;
  hasNextOfKinSubject$ = new BehaviorSubject<boolean>(false);
  accountIdSubject$ = new BehaviorSubject<string | null>(null);

  countries = COUNTRIES.data;
  titles = Object.entries(NIGERIAN_TITLES).map(([key, value]) => ({
    key,
    value,
  }));
  countryAlphaCode = COUNTRY_ALPHA_CODE;
  kinRelationships = KIN_RELATIONSHIPS;
  genderList = GENDER;

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  loadingService = inject(LoadingService);
  toast = inject(NotificationFacade);
  private individualService = inject(IndividualRegistrationService);

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      'update-next-of-kin-kyc'
    );
    this.isCreating$ = this.loadingService.getLoadingObservable(
      'add-next-of-kin-kyc'
    );
  }

  ngOnInit(): void {
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(accountId);
    }

    this.createForm();
    if (this.nextOfKinForm) {
      this.setFormValues();
    }
  }

  submitForm() {
    this.hasNextOfKinSubject$.value
      ? this.updateNextOfKin()
      : this.createNextOfKin();
  }

  updateNextOfKin() {
    const sub = this.individualService
      .updateNextOfKin(this.nextOfKinForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated Next of Kin details');
        },
        error: () => {
          this.toast.error('Failed to update Next of Kin details');
        },
      });

    this.subs.push(sub);
  }

  createNextOfKin() {
    const sub = this.individualService
      .addNextOfKin(this.nextOfKinForm.getRawValue())
      .subscribe({
        next: () => {
          this.hasNextOfKinSubject$.next(true);
          this.toast.success('Successfully created Next of Kin details');
        },
        error: () => {
          this.toast.error('Failed to create Next of Kin details');
        },
      });

    this.subs.push(sub);
  }

  createForm() {
    this.nextOfKinForm = this.fb.nonNullable.group({
      title: [''],
      gender: [''],
      first_name: [''],
      last_name: [''],
      email: [''],
      relationship_with_nok: [''],
      dob: [new Date()],
      phone_number: [''],
      country: [''],
      state: [''],
      city: [''],
      address: [''],

      // DO NOT REMOVE
      // account_type: ['corporate'],
      account_id: [''],
    });
  }

  setFormValues() {
    if (!this.nextOfKinDetails) {
      this.hasNextOfKinSubject$.next(false);
      this.nextOfKinForm.patchValue({
        account_id: this.accountIdSubject$.value,
      });
      return;
    }

    if (this.nextOfKinDetails) {
      if (this.nextOfKinDetails.country) {
        this.getCountryStates(this.nextOfKinDetails.country);
      }

      this.nextOfKinForm.patchValue({
        title: this.nextOfKinDetails.title,
        gender: this.nextOfKinDetails.gender,
        first_name: this.nextOfKinDetails.first_name,
        last_name: this.nextOfKinDetails.last_name,
        email: this.nextOfKinDetails.email,
        relationship_with_nok: this.nextOfKinDetails.relationship_with_nok,
        phone_number: this.nextOfKinDetails.phone_number,
        country: this.nextOfKinDetails.country,
        state: this.nextOfKinDetails.state,
        city: this.nextOfKinDetails.city,
        house_number: this.nextOfKinDetails.house_number,
        account_id: this.accountIdSubject$.value,
      });
      this.hasNextOfKinSubject$.next(true);

      // setting this separately because it is a date
      this.nextOfKinForm.controls['dob'].setValue(
        formatDate(this.nextOfKinDetails.dob, 'yyyy-MM-dd', 'en')
      );
    }
  }

  getCountryStates(countryName: string) {
    this.states = this.countries.filter(
      (country) => country.name === countryName
    )[0].states;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
