import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { environment } from '@core/environments/environment';
import { DocKyc } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

interface IDocTitle {
  [key: string]: string;
}

const DocumentTitle: IDocTitle = {
  signature: 'Signature',
  passport_photograph: 'Passport Photograph',
  means_of_id: 'Means of Identification',
  utility_bill: 'Utility Bill',
};
@Component({
  selector: 'app-ind-documents',
  standalone: true,
  imports: [KycAccordionComponent, CommonModule],
  templateUrl: './ind-documents.component.html',
  styleUrl: './ind-documents.component.scss',
})
export class IndDocumentsComponent {

  @Input() kycDocuments!: DocKyc[];
  isKycComplete: boolean = false;
  DocumentTitle = DocumentTitle;

  openDocument(link: string) {
    window.open(`${environment.FILE_BASE_URL}${link}`, '_blank');
  }

  ngOnInit(): void {
    this.checkKycStatus();
  }

  private checkKycStatus(): void {
    // Check if there are no KYC documents or if the KYC documents array is empty
    if (!this.kycDocuments || this.kycDocuments.length === 0) {
      this.isKycComplete = false;
      return;
    }
  
    // Check if there is at least one approved general KYC document
    const hasApprovedGeneralKyc = this.kycDocuments.some(
      doc => doc.document_type === 'general_kyc' && doc.status === 'approved'
    );
  
    // Determine if KYC is complete
    this.isKycComplete = hasApprovedGeneralKyc || (
      // Check if there are 4 or more documents and all are approved
      this.kycDocuments.length >= 4 &&
      this.kycDocuments.every(doc => doc.status === 'approved')
    );
  }
  
}
