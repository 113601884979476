<div
  *ngIf="!(isLoading$ | async)"
  class="flex grid-cols-2 flex-col gap-4 md:grid md:justify-between"
>
  <h2
    class="text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
  >
    Dashboard
  </h2>
</div>

<!-- Cards -->
<div
  *ngIf="!(isLoading$ | async)"
  class="mt-[0.94rem] grid gap-6 lg:grid-cols-3"
>
  <div
    class="card__wrapper flex flex-col gap-2 rounded-lg border border-solid border-mango-gray200 bg-mango-paleGray p-6"
  >
    <p class="text-sm font-medium leading-5 text-mango-gray500">
      Total Admin Users
    </p>
    <p
      class="text-4xl font-extrabold leading-[2.75rem] tracking-[-0.045rem] text-mango-gray900"
    >
      {{ totalAdminCount$ | async }}
    </p>
  </div>
  <div
    class="card__wrapper flex flex-col gap-2 rounded-lg border border-solid border-mango-gray200 bg-mango-lightOrange p-6"
  >
    <p class="text-sm font-medium leading-5 text-mango-gray500">
      Fully Registered Admins
    </p>
    <p
      class="text-4xl font-extrabold leading-[2.75rem] tracking-[-0.045rem] text-mango-gray900"
    >
      {{ fullRegisteredCount$ | async }}
    </p>
  </div>
  <div
    class="card__wrapper flex flex-col gap-2 rounded-lg border border-solid border-mango-gray200 bg-mango-paleGray p-6"
  >
    <p class="text-sm font-medium leading-5 text-mango-gray500">Active Now</p>
    <p
      class="text-4xl font-extrabold leading-[2.75rem] tracking-[-0.045rem] text-mango-gray900"
    >
      316
    </p>
  </div>
</div>

@if (isLoading$ | async) {
  <div>
    <ngx-skeleton-loader
      [theme]="{ height: '2rem', width: '200px' }"
    ></ngx-skeleton-loader>
  </div>
  <div class="grid grid-cols-3 gap-6">
    <ngx-skeleton-loader
      *ngFor="let in of [0, 1, 2]; let i = index"
      [theme]="{ height: '7rem', width: '100%' }"
    ></ngx-skeleton-loader>
  </div>
}
