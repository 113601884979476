<app-kyc-accordion accordionKey="bankDetails" accordionTitle="Bank Details" [isKycComplete]="isKycComplete">
  <div *ngIf="!bankDetails?.length">
    <p>No bank details</p>
  </div>
  <div
    *ngIf="bankDetails?.length"
    class="grid grid-cols-1 gap-y-5 md:grid-cols-4"
  >
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Bank name</li>
      <li class="text-sm font-light">
        {{ bankName || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Account number</li>
      <li class="text-sm font-light">
        {{ bankDetails[0].bank_account_number || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Account name</li>
      <li class="text-sm font-light">
        {{ bankDetails[0].bank_account_name || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Bank Branch</li>
      <li class="text-sm font-light">
        {{ bankDetails[0].bank_branch || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Currency</li>
      <li class="text-sm font-light">{{ bankDetails[0].currency || '—' }}</li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Bank Sort Code</li>
      <li class="text-sm font-light">
        {{ bankDetails[0].bank_sort_code || '—' }}
      </li>
    </ul>
  </div>
</app-kyc-accordion>
