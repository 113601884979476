import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Accountbankinfo } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-ind-bank-details',
  standalone: true,
  imports: [KycAccordionComponent, CommonModule],
  templateUrl: './ind-bank-details.component.html',
  styleUrl: './ind-bank-details.component.scss',
})
export class IndBankDetailsComponent {
  @Input() bankDetails!: Accountbankinfo[];
  @Input() bankName!: string;

  isKycComplete: boolean = false;

  ngOnInit(): void {
    this.checkKycStatus();
  }

  private checkKycStatus(): void {
    if (!this.bankDetails || this.bankDetails.length === 0) {
      this.isKycComplete = false;
      return;
    }

    this.isKycComplete = this.bankDetails.every(acc => 
      acc.bank_account_name && 
      acc.bank_account_number &&
      acc.currency
    );
      
  }
}
