import { CommonModule, formatDate } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { MeansOfIdkyc } from '@core/models/admin/account';
import { CentralDocumentUploadService } from '@core/services/central-document-upload.service';
import { IndividualRegistrationService } from '@core/services/individual-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { InputFileComponent } from '@shared/ui/input-file/input-file.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { MEANS_OF_ID } from 'app/app.constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-means-of-id',
  standalone: true,
  imports: [
    KycAccordionComponent,
    CommonModule,
    ReactiveFormsModule,
    BaseSelectDirective,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    InputDateDirective,
    SpinDirective,
    InputFileComponent,
    InputComponent,
  ],
  templateUrl: './edit-means-of-id.component.html',
  styleUrl: './edit-means-of-id.component.scss',
})
export class EditMeansOfIdComponent implements OnDestroy {
  @Input() meansOfId!: MeansOfIdkyc;

  meansOfIdForm!: FormGroup;
  accountId!: string;
  subs: Subscription[] = [];
  meansOfIdFile: File | null = null;
  meansOfIdTypes = MEANS_OF_ID;

  hasMeansOfIdSubject$ = new BehaviorSubject<boolean>(false);
  accountIdSubject$ = new BehaviorSubject<string | null>(null);

  isUpdating$!: Observable<boolean>;
  isCreating$!: Observable<boolean>;
  isUploadingDoc$!: Observable<boolean>;

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  private loadingService = inject(LoadingService);
  private toast = inject(NotificationFacade);
  individualService = inject(IndividualRegistrationService);
  documentService = inject(CentralDocumentUploadService);
  change = inject(ChangeDetectorRef);
  router = inject(Router);

  requiresExpiry = true;

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      'update-means-of-identity-kyc'
    );
    this.isCreating$ = this.loadingService.getLoadingObservable(
      'add-means-of-identity-kyc'
    );
  }

  isNIN(): void {
    const sub = this.meansOfIdForm.valueChanges.subscribe(
      (val) => {
        this.requiresExpiry = (val.type === 'nin') ? false : true
      }
    )
    this.subs.push(sub);
  }

  ngOnInit(): void {
    this.createForm();
    this.isNIN()
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(accountId);
    }

    if (this.meansOfIdForm) {
      this.setFormValues();
    }
  }

  submitForm() {
    this.hasMeansOfIdSubject$.value
      ? this.updateMeansOfId()
      : this.addMeansOfId();
  }

  updateMeansOfId() {
    const sub = this.individualService
      .updateMeansOfIdentification(this.meansOfIdForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated Means of Identification');
        },
        error: () => {
          this.toast.error('Failed to update Means of Identification');
        },
      });
    this.subs.push(sub);
  }

  addMeansOfId(): void {
    const sub = this.individualService
      .addMeansOfId(this.meansOfIdForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.hasMeansOfIdSubject$.next(true);
          this.toast.success(response.message);
        },
        error: (resp) => {
          this.toast.error(
            resp?.error?.message ||
            'Failed to add means of identification details'
          );
        },
      });
    this.subs.push(sub);
  }

  createForm() {
    this.meansOfIdForm = this.fb.nonNullable.group({
      type: [''],
      number: [''],
      issue_date: [new Date()],
      expiry_date: [new Date()],
      account_id: [''],
    });
  }

  setFormValues() {
    if (!this.meansOfId) {
      this.meansOfIdForm.patchValue({
        account_id: this.accountIdSubject$.value,
      });
      return;
    }

    this.hasMeansOfIdSubject$.next(true);
    this.meansOfIdForm.patchValue({
      type: this.meansOfId.type,
      number: this.meansOfId.number,
      issue_date: this.meansOfId.issue_date,
      expiry_date: this.meansOfId.expiry_date,
      account_id: this.accountIdSubject$.value,
    });

    if (this.meansOfId.issue_date) {
      this.meansOfIdForm.controls['issue_date'].setValue(
        formatDate(this.meansOfId.issue_date, 'yyyy-MM-dd', 'en')
      );
    }

    if (this.meansOfId.expiry_date) {
      this.meansOfIdForm.controls['expiry_date'].setValue(
        formatDate(this.meansOfId.expiry_date, 'yyyy-MM-dd', 'en')
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
