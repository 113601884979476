import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-individual-tabs',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './individual-tabs.component.html',
  styleUrl: './individual-tabs.component.scss',
})
export class IndividualTabsComponent implements OnInit {
  accountIdTab!: string;
  setRouteUrls: string[] = [];
  cdr = inject(ChangeDetectorRef);
  router = inject(Router);
  pathMap = new Map<string, string>()
    .set('details', 'Details')
    .set('wallet', 'Wallet')
    .set('portfolio', 'Porfolio')
    .set('orders', 'Orders')
    .set('audit-log', 'Audit logs')
    .set('rm-list', 'Relationship Manager(s)');

  ngOnInit(): void {
    const paths = Array.from(this.pathMap, ([key, val]) => key);
    paths.forEach((path) => this.setRouteUrls.push(path));
    this.cdr.detectChanges();
  }

  getRoutePath(path: string) {
    const currentPath = this.router.url;

    const arr = currentPath.split('/');
    return currentPath.replace(`${arr[arr.length - 1]}`, path);
  }

  getCurrentMobileTab(value: string) {
    this.router.navigateByUrl(value);
  }
}
