import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MeansOfIdkyc } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-ind-means-of-id',
  standalone: true,
  imports: [KycAccordionComponent, CommonModule],
  templateUrl: './ind-means-of-id.component.html',
  styleUrl: './ind-means-of-id.component.scss',
})
export class IndMeansOfIdComponent {
  @Input() meansOfId!: MeansOfIdkyc;

  isKycComplete: boolean = false;
  

  ngOnInit(): void {
     this.checkKycStatus();
   }
 
   private checkKycStatus(): void {
     if (!this.meansOfId) {
       this.isKycComplete = false;
       return;
     }
 
     this.isKycComplete = !!(
       this.meansOfId.type &&
       this.meansOfId.number
     );
   }
}
