import { Component, inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { OrderService } from '@core/services/order.service';
import { TableSkeletonLoaderComponent } from '../../../shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { CommonModule, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { TableNavigatorComponent } from '../../../shared/ui/table-navigator/table-navigator.component';
import { DropdownComponent, DropDownModel } from '../../../shared/ui/dropdown/dropdown.component';
import { Pagination } from '@core/models/pagination.model';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { IFILTER } from 'app/app.constants';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { AllOrdersDatum } from '@core/models/admin/orders';
import { NotificationFacade } from '@core/facades/notification.facade';
import { ConfirmDialogComponent } from "../../../shared/ui/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-orders-by-account',
  standalone: true,
  imports: [
    TableSkeletonLoaderComponent,
    CommonModule,
    TableNavigatorComponent,
    DropdownComponent,
    FiltersComponent,
    RouterOutlet,
    ConfirmDialogComponent,
  ],
  templateUrl: './orders-by-account.component.html',
  styleUrl: './orders-by-account.component.scss',
  providers: [TitleCasePipe, DecimalPipe, DatePipe],
})
export class OrdersByAccountComponent implements OnInit, OnDestroy {
  accountId: string = '';
  currentPage = 1;
  currentPath = '';
  orderId!: string | null;
  subs: Subscription[] = []; // Array to store subscriptions

  dialogQuestion!: any;
  actionList: DropDownModel[] = [];
  orderService = inject(OrderService);
  loadingService = inject(LoadingService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  private notify = inject(NotificationFacade);
  private route = inject(ActivatedRoute);
  private breadCrumbService = inject(BreadCrumbService);
  private decimalPipe = inject(DecimalPipe);
  private titleCasePipe = inject(TitleCasePipe);
  private datePipe = inject(DatePipe);
  private change = inject(ChangeDetectorRef);

  // BehaviorSubject for handling orders
  private ordersSubject = new BehaviorSubject<any>(null);
  orders$ = this.ordersSubject.asObservable(); // Observable that the UI listens to

  // Filter structure for the filters component
  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
    {
      filterKey: 'type',
      label: 'Order Type',
      type: 'dropdown',
      dropdown: [
        { key: 'invest', value: 'Invest' },
        { key: 'liquidate', value: 'Liquidate' },
      ],
    },
    {
      filterKey: 'status',
      label: 'Status',
      type: 'dropdown',
      dropdown: [
        { key: 'initiated', value: 'Initiated' },
        { key: 'in_process', value: 'In Process' },
        { key: 'completed', value: 'Completed' },
        { key: 'terminated', value: 'Terminated' },
      ],
    },
    {
      filterKey: 'product_id',
      label: 'Product',
      type: 'dropdown',
      dropdown: [],
    },
  ];

  loading$ = this.loadingService.getLoadingObservable(
    this.orderService.ordersLoadingStates.getOrdersByAccountId
  );

  isRejectDialogOpen: boolean = false;
  isRejectInProgress: boolean = false;
  isApproveDialogOpen: boolean = false;
  isApproveInProgress: boolean = false;

  ordersList: AllOrdersDatum[] = [];
  ngOnInit() {
    this.initOrdersList(); // Initialize orders list
    this.breadCrumbService.setPageBreadCrumb(this.route);
    this.getAccountIdInfo();
    this.fetchOrders(); // Initial fetch of orders
    this.ordersfilterEventListener(); // Set up filter listener
    // this.initDropdownList(); // Initialize dropdown list
    this.currentPath = this.activatedRoute.snapshot.pathFromRoot.reduce((acc, route) => acc + '/' + route.url.join('/'), '').replace("//", "/");
  }

  ngOnDestroy() {
    // Clean up subscriptions to avoid memory leaks
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  initOrdersList() {
    const sub = this.orders$.subscribe((data) => {
      this.ordersList = data;
    });
    this.subs.push(sub);
  }

  // Retrieve account ID from route parameters
  getAccountIdInfo() {
    const accountId = this.activatedRoute.snapshot.parent?.params['accountId'];
    if (accountId) {
      this.accountId = accountId;
    }
  }

  // Fetch the orders by account ID and push the result into ordersSubject
  fetchOrders() {
    const sub = this.orderService
      .getOrdersByAccountId(this.accountId)
      .subscribe({
        next: (res) => this.ordersSubject.next(res.data), // Emit data into ordersSubject
        error: (err) => console.error('Error fetching orders:', err),
      });
    this.subs.push(sub);
  }

  // Listen to queryParams changes and filter the orders based on filters
  ordersfilterEventListener() {
    const sub = this.activatedRoute.queryParams.subscribe((params) => {
      let query = '';

      // Construct query based on filters
      if (params[this.filterStructure[0].filterKey]) {
        query += `start_date=${params['start_date']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `end_date=${params['end_date']}&`;
      }
      if (params[this.filterStructure[2].filterKey]) {
        query += `type=${params['type']}&`;
      }
      if (params[this.filterStructure[3].filterKey]) {
        query += `status=${params['status']}&`;
      }
      if (params[this.filterStructure[4].filterKey]) {
        query += `product_id=${params['product']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}&`;
      }
      query += `account_id=${this.accountId}`;

      // Perform filtering or refetch the original data if no filters
      if (query) {
        const sub = this.orderService.filterOrdersByAccountID(query).subscribe({
          next: (res) => this.ordersSubject.next(res.data), // Push filtered data to orders$
          error: (err) => { },
        });
        this.subs.push(sub);
      } else {
        this.fetchOrders(); // Fetch original data if no filters are applied
      }
    });
    this.subs.push(sub); // Add this subscription to subs array
  }

  // Handle pagination and emit new data into orders$
  navigate(page: number) {
    const skip = page === 1 ? 0 : (page - 1) * 10;
    const sub = this.orderService
      .getOrdersByAccountId(this.accountId, new Pagination({ skip }))
      .subscribe({
        next: (res) => this.ordersSubject.next(res.data), // Push paginated data to orders$
        error: (err) => console.error('Error paginating orders:', err),
      });
    this.subs.push(sub); // Store the subscription
  }

  initDropdownList(order: AllOrdersDatum) {
    return (this.actionList = [
      {
        action: () => {
          this.router.navigateByUrl(`${this.currentPath}/${order.id}`);
        },
        title: 'View Details',
        show: true,
      },
      {
        action: () => {
          this.orderId = order.id;
          this.isApproveDialogOpen = true;
          this.dialogQuestion = this.setDialogQuestion(order, 'approve');
          this.change.detectChanges();
        },
        title: 'Approve',
        show: order.status !== 'completed',
      },
      {
        action: () => {
          this.orderId = order.id;
          this.isRejectDialogOpen = true;
          this.dialogQuestion = this.setDialogQuestion(order, 'reject');
          this.change.detectChanges();
        },
        title: 'Reject',
        show: order.status !== 'terminated',
      },
    ]);
  }
  updateOrders(status: 'completed' | 'terminated',) {
    if (!this.orderId) {
      this.notify.info('Order ID is required');
      return;
    }
    status === 'completed'
      ? (this.isApproveInProgress = true)
      : (this.isRejectInProgress = true);

    const sub = this.orderService.updateOrder(this.orderId, status).subscribe({
      next: async (resp: any) => {
        status === 'completed'
          ? (this.isApproveInProgress = false)
          : (this.isRejectInProgress = false);
        status === 'completed'
          ? (this.isApproveDialogOpen = false)
          : (this.isRejectDialogOpen = false);

        this.ordersList.find((order) => order.id === this.orderId)!.status =
          status;
        this.ordersSubject.next(this.ordersList);
        this.change.detectChanges();

        this.notify.success(resp.message);
      },
      error: (err: any) => {
        status === 'completed'
          ? (this.isApproveInProgress = false)
          : (this.isRejectInProgress = false);
        this.change.detectChanges();
        this.notify.success(err.error.message);
      },
    });
    this.subs.push(sub);
  }
  setDialogQuestion(order: AllOrdersDatum, type: 'approve' | 'reject') {
    const accountName =
      order.account?.account_type === 'corporate'
        ? order.account?.corporate_kyc?.name || '—'
        : `${this.titleCasePipe.transform(order.account.user_profile?.first_name)} ${this.titleCasePipe.transform(order.account.user_profile?.last_name)}`;
    const productName = order?.product ? order?.product?.name : 'not provided';
    let amount = this.decimalPipe.transform(order.amount / 100, '1.2-2');
    let date = this.datePipe.transform(order.created_at, 'medium');

    let orderTypeClass =
      order.type === 'invest'
        ? 'bg-green-100 text-mango-pineGreen'
        : 'bg-mango-shellRed text-mango-red707';

    return `
          <p>Are you sure you want to ${type} this Order? This action cannot be undone. </p>

          <div class="flex flex-col text-sm gap-1 mt-1">
            <h5>Recipient Details</h5>
            <div class="flex justify-between">
              <div class="text-gray-500"> User/Organization: </div>
              <div class="font-bold">${accountName}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Order Type: </div>
              <span
                class="inline-flex w-fit items-center gap-1 rounded-full px-2 py-1 text-xs font-medium uppercase ${orderTypeClass}"
              >
              ${order.type ? order.type : 'not provided'}
              </span>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Products: </div>
              <div class="font-bold"> ${productName}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Amount: </div>
              <div class="font-bold">₦${amount}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Date: </div>
              <div class="font-bold">${date}</div>
            </div>
          </div>
          `;
  }
}
