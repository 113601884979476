import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { COUNTRIES, State, States2 } from '@core/data/countries-states';
//
import { NotificationFacade } from '@core/facades/notification.facade';
import { Employerkyc } from '@core/models/admin/account';
import { JointEmployerkyc } from '@core/models/admin/account/joint';
import { IndividualRegistrationService } from '@core/services/individual-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { EMPLOYMENT_STATUS } from 'app/app.constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-employment-details',
  standalone: true,
  imports: [
    KycAccordionComponent,
    CommonModule,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    BaseSelectDirective,
    SpinDirective,
    InputDateDirective,
  ],
  templateUrl: './edit-employment-details.component.html',
  styleUrl: './edit-employment-details.component.scss',
})
export class EditEmploymentDetailsComponent implements OnInit, OnDestroy {
  @Input() employmentDetails!: Employerkyc;

  employerForm!: FormGroup;
  isCreating$!: Observable<boolean>;
  isUpdating$!: Observable<boolean>;
  // cities: string[] | null = [];
  countries = COUNTRIES.data;
  states: (State | States2)[] = [];
  subs: Subscription[] = [];
  employmentStatus = EMPLOYMENT_STATUS;

  accountIdSubject$ = new BehaviorSubject<string | null>(null);
  hasEmploymentSubject$ = new BehaviorSubject<boolean>(false);

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  loadingService = inject(LoadingService);
  toast = inject(NotificationFacade);
  private individualService = inject(IndividualRegistrationService);

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      'update-employment-details'
    );
    this.isCreating$ = this.loadingService.getLoadingObservable(
      'add-employment-details'
    );
  }

  ngOnInit(): void {
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(accountId);
    }

    this.createForm();
    if (this.employerForm) {
      this.setFormValues();
    }
  }

  submitForm() {
    this.hasEmploymentSubject$.value
      ? this.updateEmploymentDetails()
      : this.addEmploymentDetails();
  }

  updateEmploymentDetails() {
    this.individualService
      .updateEmploymentDetails(this.employerForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated Employment details');
        },
        error: () => {
          this.toast.error('Failed to update Employment details');
        },
      });
  }

  addEmploymentDetails() {
    this.individualService
      .addEmploymentDetails(this.employerForm.getRawValue())
      .subscribe({
        next: () => {
          this.hasEmploymentSubject$.next(true);
          this.toast.success('Successfully added Employment details');
        },
        error: () => {
          this.toast.error('Failed to add Employment details');
        },
      });
  }

  createForm() {
    this.employerForm = this.fb.nonNullable.group({
      status: [''],
      name: [''],
      nature_of_business: [''],
      country: [''],
      state: [''],
      city: [''],
      address: [''],
      phone_number: [''],
      // DO NOT REMOVE
      account_id: [''],
    });
  }

  setFormValues() {
    if (!this.employmentDetails) {
      this.employerForm.patchValue({
        account_id: this.accountIdSubject$.value,
      });
      return;
    }

    this.hasEmploymentSubject$.next(true);
    if (this.employmentDetails.country) {
      this.getCountryStates(this.employmentDetails.country);
    }
    this.employerForm.patchValue({
      status: this.employmentDetails.status,
      name: this.employmentDetails.name,
      nature_of_business: this.employmentDetails.nature_of_business,
      country: this.employmentDetails.country,
      state: this.employmentDetails.state,
      city: this.employmentDetails.city,
      address: this.employmentDetails.address,
      phone_number: this.employmentDetails.phone_number,
      account_id: this.accountIdSubject$.value,
    });
  }

  getCountryStates(countryName: string) {
    this.states = this.countries.filter(
      (country) => country.name === countryName
    )[0].states;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
