import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  private _showDialog = new BehaviorSubject<boolean>(false);
  public showDialog$ = this._showDialog.asObservable();

  triggerDialog() {
    this._showDialog.next(true);
  }

  closeDialog() {
    this._showDialog.next(false);
  }
}
