import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { PasswordValidityComponent } from '@shared/ui/password-validity/password-validity.component';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-newpassword',
  standalone: true,
  providers: [],
  imports: [
    RouterModule,
    CommonModule,
    PasswordValidityComponent,
    FormsModule,
    ReactiveFormsModule,
    SpinDirective,
    ButtonDirective,
  ],
  templateUrl: './newpassword.component.html',
  styleUrl: './newpassword.component.scss',
})
export class NewpasswordComponent implements OnDestroy, OnInit {
  newPassword = new FormControl('', [Validators.required]);
  confirmPassord = new FormControl('', [Validators.required]);
  isLoading: boolean = false;
  subs: Subscription[] = [];

  _toast = inject(NotificationFacade);
  cdr = inject(ChangeDetectorRef);
  private route = inject(Router);
  private _auth = inject(AuthService);
  private active = inject(ActivatedRoute);

  isPasswordVisible: boolean = false;
  PasswordVisible: boolean = false;

  // password validation
  isAtLeastEightChars!: boolean;
  hasOneUppercaseChar!: boolean;
  hasOneLowercaseChar!: boolean;
  hasOneNumber!: boolean;
  hasOneSpecialChar!: boolean;
  ruleListComplete!: boolean;
  token!: string;

  constructor() {}
  ngOnInit(): void {
    this.fetchToken();
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  fetchToken() {
    const sub = this.active.queryParams.subscribe((param) => {
      this.token = param['token'];
    });
    this.subs.push(sub);
  }

  submitPassword() {
    // Create new password via email link with ?token query param
    if (this.isCreatePassword()) {
      this.setNewAccountPassword();
    }
    // Reset password via email link with ?token query param
    if (this.isResetPassword()) {
      this.resetPassword();
    }
  }

  setNewAccountPassword() {
    this.isLoading = true;
    const sub = this._auth
      .createNewPassword(this.token, this.confirmPassord.value as string)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.cdr.detectChanges();
          this._toast.success(res?.message || 'Password created');

          this.route.navigate(['/auth/login']).then(() => {
            window.location.reload();
          });
        },
        error: (res) => {
          this.isLoading = false;
          this.cdr.detectChanges();
          this._toast.error(res?.error?.message || 'Bad request');
        },
      });
    this.subs.push(sub);
  }

  resetPassword() {
    this.isLoading = true;
    const sub = this._auth
      .resetPassword(this.token, this.confirmPassord.value as string)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.cdr.detectChanges();
          this._toast.success(res?.message || 'Password reset successful');
          this.route.navigate(['/auth/login']).then(() => {
            window.location.reload();
          });
        },
        error: (res) => {
          this.isLoading = false;
          this.cdr.detectChanges();
          this._toast.error(res?.error?.message || 'Bad request');
        },
      });
    this.subs.push(sub);
  }

  togglePasswordVisible() {
    this.PasswordVisible = !this.PasswordVisible;
  }

  isResetPassword(): boolean {
    return this.route.url.indexOf('/auth/reset-password') > -1;
  }
  isCreatePassword(): boolean {
    return this.route.url.indexOf('/auth/create-new-password') > -1;
  }

  checkPasswordStrength(event: any): void {
    const password = event?.target?.value;
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,30}$/;

    this.isAtLeastEightChars = password.length >= 8;
    this.hasOneUppercaseChar = /[A-Z]/.test(password);
    this.hasOneLowercaseChar = /[a-z]/.test(password);
    this.hasOneNumber = /\d/.test(password);
    this.hasOneSpecialChar = /[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]/.test(
      password
    );

    this.ruleListComplete = passwordPattern.test(password);
  }

  matchPassword(): boolean {
    return this.newPassword.value === this.confirmPassord.value;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub?.unsubscribe());
  }
}
