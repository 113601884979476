import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AccountManagementService } from '@core/services/account-management.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { CorporateTabsComponent } from '@shared/ui/corporate-tabs/corporate-tabs.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { LoadingService } from '@core/services/loading.service';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import Chart from 'chart.js/auto';
import { PortfolioService } from '@core/services/portfolio.service';
import { PortfolioListDatum } from '@core/models/admin/portfolio';
import { IFILTER } from 'app/app.constants';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { BreadCrumbService } from '@core/services/bread-crumb.service';

@Component({
  selector: 'app-portfolio',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    CorporateTabsComponent,
    CorporateTabsComponent,
    InputLabelComponent,
    BaseSelectDirective,
    ButtonDirective,
    InputDateDirective,
    TableSkeletonLoaderComponent,
    FiltersComponent,
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './portfolio.component.html',
  styleUrl: './portfolio.component.scss',
  animations: [dropdownMenuAnimation],
})
export class PortfolioComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  currentPage: number = 1;
  totalCount: number = 0;
  subs: Subscription[] = [];
  porfolioList: PortfolioListDatum[] = [];
  portfolio: any;
  accountData: any;
  accountId!: string;
  nairaWalletBalance: number = 0;
  dollarWalletBalance: number = 0;
  ngnCanvasId = 'ngnCanvas';
  usdCanvasId = 'usdCanvas';

  isEmptyNairaChartSubject$ = new BehaviorSubject<boolean>(false);
  isEmptyDollarChartSubject$ = new BehaviorSubject<boolean>(false);

  isFetchingPortfolioList$!: Observable<boolean>;
  isFetchingPortfolioBalance$!: Observable<boolean>;
  isEmptyNairaChart$ = this.isEmptyNairaChartSubject$.asObservable();
  isEmptyDollarChart$ = this.isEmptyDollarChartSubject$.asObservable();

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private accountService = inject(AccountManagementService);
  private change = inject(ChangeDetectorRef);
  private toast = inject(NotificationFacade);
  private portfolioService = inject(PortfolioService);
  private loadingService = inject(LoadingService);
  private breadCrumbService = inject(BreadCrumbService);

  filterStructure: IFILTER[] = [
    {
      filterKey: 'ccy',
      label: 'CCY',
      type: 'dropdown',
      dropdown: [
        { key: 'USD', value: 'USD' },
        { key: 'NGN', value: 'NGN' },
      ],
    },
  ];

  constructor() {
    this.isFetchingPortfolioList$ =
      this.loadingService.getLoadingObservable('get-portfolio-list');
    this.isFetchingPortfolioBalance$ = this.loadingService.getLoadingObservable(
      'get-portfolio-balance'
    );
  }

  ngOnInit() {
    this.accountId = this.route.snapshot.parent?.params['accountId'];
    this.breadCrumbService.setPageBreadCrumb(this.route);
    if (this.accountId) {
      this.getPortfolioList();
      this.getPortfolioBalance();
      this.getPortfolioCharts();
      this.portfolioFiltersEventListener();
    }
  }

  portfolioFiltersEventListener() {
    this.route.queryParams.subscribe((params) => {
      let query = '';

      if (params[this.filterStructure[0].filterKey]) {
        query = `ccy=${params['ccy']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}`;
      }
      if (query) {
        const sub = this.portfolioService
          .filterPortfolioList(query, this.accountId)
          .subscribe({
            next: (res) => {
              this.porfolioList = res.data.data;
              this.totalCount = 10; // 10 to remove pagination

              this.change.detectChanges();
            },
            error: (err) => {},
          });
        this.subs.push(sub);
      } else {
        this.getPortfolioList();
      }
    });
  }

  toggleFilterMenu() {
    this.filterMenuState =
      this.filterMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  getPortfolioList() {
    const sub = this.portfolioService
      .getPortfolioList(this.accountId)
      .subscribe({
        next: (res) => {
          this.porfolioList = res.data.data;

          this.change.detectChanges();
        },
        error: () => {
          this.toast.error('Failed to fetch portfolio data');
          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }
  getPortfolioBalance() {
    const sub = forkJoin({
      nairaRequest: this.portfolioService.getPorfolioBalance({
        account_id: this.accountId,
        currency: 'NGN',
      }),
      dollarRequest: this.portfolioService.getPorfolioBalance({
        account_id: this.accountId,
        currency: 'USD',
      }),
    }).subscribe(({ nairaRequest, dollarRequest }) => {
      this.nairaWalletBalance = nairaRequest.data.balance;
      this.dollarWalletBalance = dollarRequest.data.balance;

      this.change.detectChanges();
    });

    this.subs.push(sub);
  }

  getPortfolioCharts() {
    const sub = forkJoin({
      nairaResponse: this.portfolioService.getPortfolioChart({
        account_id: this.accountId,
        currency: 'NGN',
      }),
      dollarResponse: this.portfolioService.getPortfolioChart({
        account_id: this.accountId,
        currency: 'USD',
      }),
    }).subscribe(({ nairaResponse, dollarResponse }) => {
      if (!nairaResponse) {
        this.isEmptyNairaChartSubject$.next(true);
      } else {
        this.createDoughnutChart(
          this.ngnCanvasId,
          nairaResponse,
          'NGN Portfolio'
        );
      }

      if (!dollarResponse) {
        this.isEmptyDollarChartSubject$.next(true);
      } else {
        this.createDoughnutChart(
          this.usdCanvasId,
          dollarResponse,
          'USD Portfolio'
        );
      }
    });

    this.subs.push(sub);
  }

  createDoughnutChart(
    canvasId: string,
    data: { pms_sum: number; lcm_sum: number; cashOnHand: number },
    chartLabel: string
  ): void {
    const chartData = [data.lcm_sum, data.pms_sum, data.cashOnHand];

    new Chart(canvasId, {
      type: 'doughnut',
      data: {
        labels: [
          'Liquidity and Cash Management (LCM)',
          'Portfolio Management Service (PMS)',
          'Cash On Hand',
        ],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: [
              'rgb(114, 115, 116)',
              'rgb(255, 204, 124)',
              'rgb(244, 160, 27)',
            ],
            hoverOffset: 4,
          },
        ],
      },
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
