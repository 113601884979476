import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-validity',
  standalone: true,
  imports: [],
  templateUrl: './password-validity.component.html',
  styleUrl: './password-validity.component.scss'
})
export class PasswordValidityComponent {
  @Input({alias:'valid',required:true})valid = false;
}
