<app-slide-in-right-modal modalTitle="Add Mutual Funds Transaction"
    iconImageSrc="assets/images/svgs/document--outline.svg" [slideOpen]="true" [showBackLink]="false" returnPath="">
    <div class="page__container flex flex-col pb-8">
        <p class="cta__text text-sm text-mango-gray74">
            Kindly input transaction details
        </p>

        <form class="flex flex-col gap-5 px-0 pt-5" [formGroup]="mutualFundsTransactionForm">

            <!-- DATE -->
            <div>
                <app-input-label labelFor="date" labelClass="label--bottom-margin"
                    [isRequired]="true">Date</app-input-label>

                <input placeholder="DD/MM/YYYY" formControlName="date"
                    class="block w-full rounded-lg border-0 pl-2 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6"
                    type="date">
            </div>
            <!-- Account Number -->
            <div>
                <app-input-label labelFor="account_number" labelClass="label--bottom-margin" [isRequired]="true">Account Number</app-input-label>

                <app-input placeholder="Enter Account Number" [isRequired]="true"  [inputClass]="'input--padding'"
                    formControlName="account_number"></app-input>
            </div>

            <!-- Account Name -->
            <div>
                <app-input-label labelFor="name" labelClass="label--bottom-margin" [isRequired]="true">Account Name</app-input-label>

                <app-input placeholder="Enter Account Name" [isRequired]="true" [readonly]="true" [inputClass]="'input--padding'"
                    formControlName="name"></app-input>
            </div>

            <!-- transaction type -->
            <div>
                <app-input-label labelFor="transaction type" labelClass="label--bottom-margin" [isRequired]="true">Transaction Type</app-input-label>
                <select name="transaction-type" required base-select formControlName="type">
                    <option value="" disabled selected>Select Transaction Type</option>
                    @for (type of transactionTypes; track $index) {
                    <option [value]="type.value">{{ type.label }}</option>
                    }
                </select>
            </div>
            <!-- Amount -->
            <div>
                <app-input-label labelFor="amount" labelClass="label--bottom-margin"
                    [isRequired]="true">Amount</app-input-label>
                <app-input [isRequired]="true" placeholder="Enter Amount" inputClass="input--padding" formControlName="amount" [readonly]="mutualFundsTransactionForm.get('type')?.value !== 'Subscription' && 
                  mutualFundsTransactionForm.get('type')?.value !== 'Redemption'"></app-input>
                <p class="text-xs">Amount: {{ (mutualFundsTransactionForm.get('amount')?.value?.toNumber() ?? 0) | number: '1.2-2' }}</p>
            </div>


            <div class="col-start-3 justify-self-end">
                <button (click)="addTransaction()" 
                    class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end" mango-button
                    >
                    <!-- <span *ngIf="isLoading" spin></span> -->
                    <!-- @if (!isLoading) {
                    {{ isEdit ? 'Update' : 'Save and Continue' }}
                    } --> Save and Continue
                </button>
            </div>

        </form>
    </div>
</app-slide-in-right-modal>

<app-confirm-dialog
  [confirmQuestion]="
    isInvestmentActionInProgress
      ? 
        ( mutualFundsTransactionForm.get('type')?.value) +
        ' ' +
        (totalAccural.toNumber() | number) +
        'in progress. Please do not close this page.'
      : 'You are about to ' +
        (mutualFundsTransactionForm.get('type')?.value) +
        ' of: ' +
        (totalAccural.toNumber() | number)
  "
  confirmText="Confirm"
  [isDialogLoading]="isInvestmentActionInProgress"
  [isOpen]="isInvestmentActionDialogOpen"
  dialogLoadingText="Adding Transaction..."
  [showQuestionGif]="false"
  (close)="
    isInvestmentActionDialogOpen = false;
   
  "
  (dialogAction)="confirmTransaction()"
  [showCancelButton]="true"
>
</app-confirm-dialog>