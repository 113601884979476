import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { ICorporateAccount } from '@core/models/admin/account/account.interface';
import { AccountManagementService } from '@core/services/account-management.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { ButtonDirective } from '@shared/directives/button.directive';
import { CorporateTabsComponent } from '@shared/ui/corporate-tabs/corporate-tabs.component';
import { DropDownModel, DropdownComponent } from '@shared/ui/dropdown/dropdown.component';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { LoadingService } from '@core/services/loading.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OrderService } from '@core/services/order.service';
import { PortfolioService } from '@core/services/portfolio.service';

@Component({
  selector: 'app-company-account',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    CommonModule,
    CorporateTabsComponent,
    DropdownComponent,
    ButtonDirective,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './company-account.component.html',
  styleUrl: './company-account.component.scss',
})
export class CompanyAccountComponent implements OnInit, OnDestroy {
  dropdownVisible = false;
  accountData!: ICorporateAccount | null;
  subs: Subscription[] = [];
  accountId!: string;
  totalSignatories = Array.from(Array(3).keys());
  editUrl!: string;
  nairaWalletBalance: number = 0;
  dollarWalletBalance: number = 0;

  tierLevelSubject$ = new BehaviorSubject<string>('');

  isFetchingTierLevel$!: Observable<boolean>;
  isFetchingAccountInfo$!: Observable<boolean>;
  tierLevel$ = this.tierLevelSubject$.asObservable();

  private notify = inject(NotificationFacade);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private breadCrumbService = inject(BreadCrumbService);
  private accountService = inject(AccountManagementService);
  private change = inject(ChangeDetectorRef);
  private clipboard = inject(Clipboard);
  private loadingService = inject(LoadingService);
  private orderService = inject(OrderService);
  private portfolioService = inject(PortfolioService);

  constructor() {
    this.isFetchingAccountInfo$ = this.loadingService.getLoadingObservable(
      'get-corporate-account-kyc'
    );
    this.isFetchingTierLevel$ = this.loadingService.getLoadingObservable(
      'check-kyc-tier-level'
    );
  }

  toggle() {
    this.dropdownVisible = !this.dropdownVisible;
    this.change.detectChanges();
  }

  ngOnInit(): void {
    this.getAccountInfo();
    this.getAccountKycTier();
    this.breadCrumbService.setPageBreadCrumb(this.route);
    this.getPortfolioBalance();
  }

  getAccountInfo(): void {
    const accountId = this.route.snapshot.params['accountId'];
    this.editUrl = `/admin/accounts/corporate/edit/${accountId}`;

    this.setTabAccountId(accountId);
    const subs = this.accountService
      .getCorporateAccountInfo(accountId)
      .subscribe({
        next: (response: any) => {
          this.accountData = response.data.account_kyc as ICorporateAccount;
          this.accountData.owner = response.data.user;

          this.change.detectChanges();
        },
        error: () => {},
      });
    this.subs.push(subs);
  }

  getAccountKycTier() {
    const accountId = this.route.snapshot.params['accountId'];
    const sub = this.accountService.getAccountKycTier(accountId).subscribe({
      next: (response) => {
        this.tierLevelSubject$.next(response.data.kyc_levels);
      },
      error: () => {
        this.notify.error('Failed to fetch tier level');
      },
    });

    this.subs.push(sub);
  }

  getPortfolioBalance() {
    const sub = forkJoin({
      nairaRequest: this.portfolioService.getPorfolioBalance({
        account_id: this.accountId,
        currency: 'NGN',
      }),
      dollarRequest: this.portfolioService.getPorfolioBalance({
        account_id: this.accountId,
        currency: 'USD',
      }),
    }).subscribe(({ nairaRequest, dollarRequest }) => {
      this.nairaWalletBalance = nairaRequest.data.balance;
      this.dollarWalletBalance = dollarRequest.data.balance;

      this.change.detectChanges();
    });

    this.subs.push(sub);
  }

  copy(accountNumber: string) {
    this.clipboard.copy(accountNumber);
    this.notify.success('Successfully copied to clipboard');
  }

  goBack() {
    history.back();
  }

  setTabAccountId(accountId: string) {
    this.accountId = accountId;
  }

  registerationDropdownActions(): DropDownModel[] {
    return [
      {
        title: 'Client Directory',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/client-directory-report/coperate/${this.accountId}`);
        },
        show: true,
      },
      {
        title: 'Account Statement',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/accocunt-statement-report/${this.accountId}`);
        },
        show: true,
      },
      {
        title: 'Nav',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/nav-report/${this.accountId}`);
        },
        show: true,
      },
      {
        title: 'Order',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/order-report/${this.accountId}`);
        },
        show: true,
      },
      // {
      //   title: 'Audit Log',
      //   action: () => {
      //     this.router.navigateByUrl('/admin/accounts/joint-profile-details');
      //   },
      //   show: true,
      // },
    ];
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
