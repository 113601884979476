import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { RouterLink } from '@angular/router';
import { RouterLinkActive } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy {
  private isWindowActive = false;
  // private worker: Worker;
  private authService = inject(AuthService);
  private router = inject(Router);
  private subs: Subscription[] = [];
  private inActiveTimer: number = 0;
  constructor() {
    // this.worker = new Worker('token-refresh-worker.js');
    // Listen for visibility changes
    this.trackRefreshToken();
    window.addEventListener('visibilitychange', () => {
      this.isWindowActive = document.visibilityState === 'visible';
      if (!this.isWindowActive) {
        this.trackLoginInactivity();
      } else {
        this.inActiveTimer = 0;
      }
    });
  }


  // ... other methods

  refreshToken(): void {
    // Check if the window is active
    if (document.visibilityState === 'visible') {

      const decoded = this.authService.getDecodedAccessToken();
      if (decoded?.exp) {
        const timer = decoded?.exp - (Date.now() / 1000);
        console.log('Token expires in:', timer);
        if (timer <= 45) {
          const sub = this.authService.getRefreshToken().subscribe(
            {
              error: (error) => {
                console.log('Error refreshing token:', error);
                this.authService.storeReturnUrl();
                this.router.navigateByUrl('/auth/login');
              }
            }
          );
          this.subs.push(sub);
        }
        if (timer <= 0) {
          console.log('Access token expired');
          this.authService.storeReturnUrl();
          this.router.navigateByUrl('/auth/login');
        }
      }

    }
  }
  trackLoginInactivity(): void {
    // Check if the user is on the auth
    if (this.router.url.indexOf('/auth/') > -1) {
      // Do nothing if the user is on the auth page
    }
    else {
      const sub = interval(60000) // 60 seconds
        .subscribe(() => {
          this.inActiveTimer += 60; //add 60 seconds
          //Logout after 5min of inactivity
          if (this.inActiveTimer >= (60 * 5) && this.router.url !== '/auth/login') {
            this.authService.storeReturnUrl();
            this.router.navigateByUrl('/auth/login');
          }
        });
      this.subs.push(sub);
    }
  }
  trackRefreshToken(): void {
    const sub = interval(10000) // 10 seconds
      .subscribe(() => {
        if (this.router.url.indexOf('/auth/') === -1) {
          console.log('Refreshing token...');
          this.refreshToken();
        }
      });
    this.subs.push(sub);
  }
  // ...

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}