import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { AllRolesDatum } from '@core/models/admin/roles-and-permissions';
import { AdminManagementService } from '@core/services/admin-management.service';
import { RolesAndPermissionsService } from '@core/services/roles-and-permissions.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';

import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { LoadingService } from '@core/services/loading.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { selectPopoverAnimation } from '@shared/animations/animations';
import {
  Adminroles,
  ViewAdminInfoResponse,
} from '@core/models/admin/admin-management';
import { ConfirmDialogComponent } from '../../shared/ui/confirm-dialog/confirm-dialog.component';
import { CustomValidators } from '@core/validators/custom-validator';

interface DisplayedRoles {
  id: string;
  name: string;
}

interface UpdatedRolesDatum extends AllRolesDatum {
  checked: boolean; // used to hide/show the roles in the input box
  show: boolean; // used to hide/show the roles in the dropdown
}

@Component({
  selector: 'app-adminreg',
  standalone: true,
  providers: [],
  imports: [
    RouterLink,
    RouterOutlet,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    SlideInRightModalComponent,
    ButtonDirective,
    SpinDirective,
    InputLabelComponent,
    InputComponent,
    BaseSelectDirective,
    NgxSkeletonLoaderModule,
    ConfirmDialogComponent,
  ],
  templateUrl: './admin-edit.component.html',
  styleUrl: './admin-edit.component.scss',
  animations: [selectPopoverAnimation],
})
export class AdminEditComponent implements OnDestroy, OnInit {
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('menu') menu!: ElementRef;

  route = inject(ActivatedRoute);
  router = inject(Router);
  _toast = inject(NotificationFacade);
  fb = inject(FormBuilder);
  adminService = inject(AdminManagementService);
  roleService = inject(RolesAndPermissionsService);
  change = inject(ChangeDetectorRef);
  private loadingService = inject(LoadingService);
  renderer = inject(Renderer2);

  allPossibleRolesListSubject$ = new BehaviorSubject<UpdatedRolesDatum[]>([]);
  selectedRolesListSubject$ = new BehaviorSubject<DisplayedRoles[]>([]);
  adminNameSubject$ = new BehaviorSubject<string | null>(null);
  existingAdminRolesSubject$ = new BehaviorSubject<Adminroles[] | null>(null);
  dialogLoadingTextSubject$ = new BehaviorSubject<string>('');

  allPossibleRolesList$ = this.allPossibleRolesListSubject$.asObservable();
  selectedRolesList$ = this.selectedRolesListSubject$.asObservable();
  adminName$ = this.adminNameSubject$.asObservable();
  existingAdminRoles$ = this.existingAdminRolesSubject$.asObservable();
  dialogLoadingText$ = this.dialogLoadingTextSubject$.asObservable();
  isUpdating$!: Observable<boolean>;
  isFetchingAdmin$!: Observable<boolean>;
  isFetchingRoles$!: Observable<boolean>;
  isAddingRole$!: Observable<boolean>;
  isRemovingRole$!: Observable<boolean>;

  isLoading: boolean = false;
  subs: Subscription[] = [];
  roles: AllRolesDatum[] = [];
  adminId!: string;
  adminName!: string;
  resetFormValue!: any;
  popoverState: 'visible' | 'hidden' = 'hidden';
  editform = this.fb.nonNullable.group({
    id: ['', [Validators.required]],
    first_name: ['', [Validators.required, Validators.minLength(2)]],
    last_name: ['', [Validators.required, Validators.minLength(2)]],
    middle_name: ['', [Validators.minLength(2)]],
    phone_number: ['', CustomValidators.phoneNumber()],
    email: ['', [Validators.required, Validators.email]],
  });

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      'update-admin-profile-data'
    );
    this.isFetchingAdmin$ =
      this.loadingService.getLoadingObservable('view-admin-info');
    this.isFetchingRoles$ =
      this.loadingService.getLoadingObservable('get-all-roles');
    this.isAddingRole$ =
      this.loadingService.getLoadingObservable('add-role-to-user');
    this.isRemovingRole$ = this.loadingService.getLoadingObservable(
      'remove-role-from-user'
    );
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.adminId = this.route.snapshot.params['userId'];
    if (this.adminId) {
      this.getAdminInfo();
    }
  }

  getAdminInfo() {
    const sub = this.adminService.viewAdminInfo(this.adminId).subscribe({
      next: (adminInfo) => {
        this.editform.patchValue({
          id: adminInfo.data[0].id,
          first_name: adminInfo.data?.[0].first_name || '',
          last_name: adminInfo?.data?.[0].last_name || '',
          middle_name: adminInfo?.data?.[0].middle_name || '',
          email: adminInfo?.data?.[0].email || '',
          phone_number: adminInfo?.data?.[0].phone_number || '',
        });
        this.adminName = `${adminInfo.data?.[0].first_name} ${adminInfo?.data?.[0].last_name}`;
        this.resetFormValue = this.editform.value;
        this.existingAdminRolesSubject$.next(adminInfo.data[0].admin_roles);
        this.fetchAllRoles();
        this.change.detectChanges();
      },
      error: (res) => {
        this._toast.error('Failed to retreive Admin Profile');
        this.change.detectChanges();
      },
    });
    this.subs.push(sub);
  }

  updateAdminInfo() {
    if (
      JSON.stringify(this.editform.value) ===
      JSON.stringify(this.resetFormValue)
    ) {
      this._toast.info('You have not made any new changes');
      return;
    }

    const sub = this.adminService
      .updateAdminInfo(this.editform.getRawValue())
      .subscribe({
        next: () => {
          this._toast.success('Successfully updated Admin Profile');
          this.router.navigate(['/admin/users']);
        },
        error: () => {
          this._toast.error('Failed to update Admin Profile');
        },
      });
    this.subs.push(sub);
  }

  fetchAllRoles() {
    const pagination = new Pagination();
    pagination.size = 100;

    const sub = this.roleService.getAllRoles(pagination).subscribe({
      next: (response) => {
        const existingRoles = this.existingAdminRolesSubject$.getValue();
        const existingRoleIds = new Set(
          existingRoles?.map((role) => role.role_id)
        );
        const allPossibleUpdatedRoles: UpdatedRolesDatum[] = response.data.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((role) => ({
            ...role,
            checked: existingRoleIds.has(role.id),
            show: !existingRoleIds.has(role.id),
          }));

        this.allPossibleRolesListSubject$.next(allPossibleUpdatedRoles);
      },
      error: () => {
        this._toast.error('Failed to fetch all roles');
      },
    });
    this.subs.push(sub);
  }

  toggleRoleCheckbox(event: Event, index: number) {
    const target = event.target as HTMLInputElement;
    const roleId = target.id;
    const isRoleChecked = target.checked;
    const roleName = target.name;

    // Update the `allPossibleRolesListSubject$` with the new checked/show state
    const currentRolesList = this.allPossibleRolesListSubject$.value;
    const updatedRolesList = currentRolesList.map((role, i) =>
      i === index
        ? { ...role, checked: isRoleChecked, show: !isRoleChecked }
        : role
    );
    this.allPossibleRolesListSubject$.next(updatedRolesList);

    isRoleChecked ? this.addRole(roleId, roleName) : null;
  }

  addRole(roleId: string, roleName: string) {
    this.dialogLoadingTextSubject$.next(`...Adding role '${roleName}' to user`);
    const sub = this.adminService
      .addRoleToUser({ admin_id: this.adminId, role_id: roleId })
      .subscribe({
        next: (response) => {
          const allExistingRoles = this.existingAdminRolesSubject$.getValue();
          allExistingRoles?.push(response.data as Adminroles);

          this.existingAdminRolesSubject$.next(allExistingRoles);
          this._toast.success(`Successfully added role: ${roleName}`);
        },
        error: () => {
          this._toast.error(`Failed to add role: ${roleName}`);
        },
      });
    this.subs.push(sub);
  }

  removeRole(payload: {
    adminRoledId: string;
    roleId?: string;
    roleName?: string;
  }) {
    this.dialogLoadingTextSubject$.next(
      `...Removing role '${payload.roleName}' from user`
    );
    const sub = this.adminService
      .removeRoleFromUser({ admin_id: this.adminId, id: payload.adminRoledId })
      .subscribe({
        next: () => {
          const existingRolesList = this.existingAdminRolesSubject$.value;
          const currentRolesList = this.allPossibleRolesListSubject$.value;

          if (existingRolesList?.length) {
            const updatedExistingRoles = existingRolesList?.filter(
              (role) => role.role.id !== payload.roleId
            );
            this.existingAdminRolesSubject$.next(updatedExistingRoles);
          }

          const updatedRolesLlist = currentRolesList.map((role) =>
            role.id === payload.roleId
              ? { ...role, checked: false, show: true }
              : role
          );

          this.allPossibleRolesListSubject$.next(updatedRolesLlist);
          this._toast.success(`Successfully removed role: ${payload.roleName}`);
        },
        error: () => {
          this._toast.error(`Failed to remove role: ${payload.roleName} `);
        },
      });
    this.subs.push(sub);
  }

  togglePopover() {
    this.popoverState = this.popoverState === 'hidden' ? 'visible' : 'hidden';
  }
  async popOverOpen(event: any) {
    if (event.fromState === 'hidden' && event.toState === 'visible') {
      // we check if the dropdown is visible now
      (await event.fromState) === 'hidden' && event.toState === 'visible';
      this.renderer.listen('window', 'click', (e: Event) => {
        /**
         * Only run when toggleButton is not clicked
         * If we don't check this, all clicks (even on the toggle button) gets into this
         * section which in the result we might never see the menu open!
         * And the menu itself is checked here, and it's where we check just outside of
         * the menu and button the condition abbove must close the menu
         */
        if (
          !this.toggleButton.nativeElement.contains(e.target) &&
          !this.menu.nativeElement.contains(e.target)
        ) {
          this.popoverState = 'hidden';
          this.change.detectChanges();
        }
      });
    }
  }

  goback() {
    history.back();
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
