<div class="flex flex-col gap-[0.94rem]">
  <div
    class="grid grid-cols-2 justify-between gap-4 md:grid md:justify-between"
  >
    <h2
      class="translate-y-[4px] text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
    >
      Profile
    </h2>

    <button
      type="button"
      mango-button
      routerLink="/admin/my-profile/change-password"
      class="w-fit justify-self-end"
    >
      Change Password
    </button>
  </div>

  <div class="flex flex-col gap-6 py-5">
    <div class="flex flex-col md:flex-row md:gap-1">
      <div
        class="left flex flex-col items-center text-nowrap bg-white px-8 py-[1.91rem]"
      >
        <div
          class="h-[4.375rem] w-[4.375rem] rounded-full bg-[url('/assets/images/avatar-placeholder.png')]"
        ></div>
        <h2 class="mb-1 mt-3 text-xl leading-[1.3125rem] text-mango-black17">
          {{ userData?.name }}
        </h2>
        <p class="text-sm text-mango-gray74">{{ userData?.email }}</p>
      </div>

      <div
        class="right flex w-full flex-col bg-white px-6 py-[1.91rem] md:gap-5"
      >
        <div class="right__top md:grid md:grid-cols-4">
          <ul class="flex flex-col">
            <li class="text-sm font-[350] text-mango-darkBlack">
              Phone number
            </li>
            <li class="text-base font-bold text-mango-darkBlack">
              {{
                userData?.phone_number === 'null' ? '—' : userData?.phone_number
              }}
            </li>
          </ul>
          <ul class="flex flex-col">
            <li class="text-sm font-[350] text-mango-darkBlack">Status</li>
            <li class="text-base font-bold text-mango-darkBlack">
              <span
                [ngClass]="[
                  userData?.status === 'activated'
                    ? 'bg-green-100 text-mango-pineGreen'
                    : 'bg-mango-shellRed text-mango-red707'
                ]"
                class="w-fit items-center rounded-full px-2 py-1 font-medium"
              >
                {{ userData?.status }}
              </span>
            </li>
          </ul>
        </div>
        <!-- Roles -->
        <div class="right__bottom">
          <ul class="flex flex-col">
            <li class="text-sm font-[350] text-mango-darkBlack">
              Roles ({{ userData?.roles?.length }})
            </li>
            <li
              class="flex flex-wrap gap-1 text-base font-bold text-mango-darkBlack"
            >
              @for (role of userData?.roles; track $index) {
                <span
                  class="w-fit items-center rounded-full bg-green-100 px-4 py-1 font-medium text-mango-pineGreen"
                >
                  {{ role.role.name }}
                </span>
              }
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
