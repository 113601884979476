import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { EditLocationDetailsComponent } from '@accounts/individual-account/edit-account/pages/edit-location-details/edit-location-details.component';
import { EditNextOfKinComponent } from '@accounts/individual-account/edit-account/pages/edit-next-of-kin/edit-next-of-kin.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { Nextofkin } from '@core/models/admin/account';
import { JointRegistrationService } from '@core/services/joint-registration.service';
import { JointNextofkin } from '@core/models/admin/account/joint';
import { ActivatedRoute } from '@angular/router';
import { State, States2, COUNTRIES } from '@core/data/countries-states';
import { NotificationFacade } from '@core/facades/notification.facade';
import { LoadingService } from '@core/services/loading.service';
import {
  NIGERIAN_TITLES,
  COUNTRY_ALPHA_CODE,
  KIN_RELATIONSHIPS,
  GENDER,
} from 'app/app.constants';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { URL_KEYS } from '@core/constants/url-keys.constants';

@Component({
  selector: 'app-edit-joint-kin',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    KycAccordionComponent,
    ButtonDirective,
    InputLabelComponent,
    InputComponent,
    BaseSelectDirective,
    InputDateDirective,
    SpinDirective,
  ],
  templateUrl: './edit-joint-kin.component.html',
  styleUrl: './edit-joint-kin.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointKinComponent implements OnInit, OnDestroy {
  @Input() nextOfKinDetails!: JointNextofkin;
  @Input() jointAccountId!: string; // for creating new next of kin

  nextOfKinForm!: FormGroup;
  states: (State | States2)[] = [];
  subs: Subscription[] = [];

  isUpdating$!: Observable<boolean>;
  isCreating$!: Observable<boolean>;
  hasNextOfKinSubject$ = new BehaviorSubject<boolean>(false);
  accountIdSubject$ = new BehaviorSubject<string | null>(null);
  nextOfKinIdSubject$ = new BehaviorSubject<string | null>(null);

  hasNextOfKin$ = this.hasNextOfKinSubject$.asObservable();

  countries = COUNTRIES.data;
  titles = Object.entries(NIGERIAN_TITLES).map(([key, value]) => ({
    key,
    value,
  }));
  countryAlphaCode = COUNTRY_ALPHA_CODE;
  kinRelationships = KIN_RELATIONSHIPS;
  genderList = GENDER;

  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private loadingService = inject(LoadingService);
  private toast = inject(NotificationFacade);

  private jointRegService = inject(JointRegistrationService);

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      URL_KEYS.JOINT.UPDATE_JOINT_ACCOUNT_NEXT_OF_KIN_KYC
    );
    this.isCreating$ = this.loadingService.getLoadingObservable(
      URL_KEYS.JOINT.ADD_JOINT_ACCOUNT_NEXT_OF_KIN_KYC
    );
  }

  ngOnInit(): void {
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(accountId);
    }

    this.createForm();
    if (this.nextOfKinForm) {
      this.setFormValues();
    }
  }

  submitForm(): void {
    this.hasNextOfKinSubject$.value
      ? this.updateNextOfKin()
      : this.createNextOfKin();
  }

  updateNextOfKin(): void {
    const sub = this.jointRegService
      .updateNextOfKin(this.nextOfKinForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated Next of Kin details');
        },
        error: () => {
          this.toast.error('Failed to update Next of Kin details');
        },
      });
    this.subs.push(sub);
  }

  createNextOfKin(): void {
    const sub = this.jointRegService
      .addNextOfKin(this.nextOfKinForm.getRawValue())
      .subscribe({
        next: (nextOfKinId) => {
          this.hasNextOfKinSubject$.next(true);
          this.nextOfKinIdSubject$.next(nextOfKinId);
          this.nextOfKinForm?.addControl('id', new FormControl(nextOfKinId));
          this.nextOfKinForm?.removeControl('account_id');
          this.nextOfKinForm?.removeControl('joint_account_id');
          this.toast.success('Successfully created Next of Kin details');
        },
        error: () => {
          this.toast.error('Failed to create Next of Kin details');
        },
      });

    this.subs.push(sub);
  }

  createForm(): void {
    if (!this.nextOfKinDetails) {
      this.nextOfKinForm = this.fb.nonNullable.group({
        title: [''],
        gender: [''],
        first_name: [''],
        last_name: [''],
        email: [''],
        relationship_with_nok: [''],
        dob: [new Date()],
        phone_number: [''],
        country: [''],
        state: [''],
        city: [''],
        address: [''],
        account_id: [''],
        joint_account_id: [''],
      });
    } else {
      this.nextOfKinForm = this.fb.nonNullable.group({
        id: [this.jointAccountId],
        title: [''],
        gender: [''],
        first_name: [''],
        last_name: [''],
        email: [''],
        relationship_with_nok: [''],
        dob: [new Date()],
        phone_number: [''],
        country: [''],
        state: [''],
        city: [''],
        address: [''],
      });
    }
  }

  setFormValues(): void {
    if (!this.nextOfKinDetails) {
      this.hasNextOfKinSubject$.next(false);
      this.nextOfKinForm.patchValue({
        account_id: this.accountIdSubject$.value,
        joint_account_id: this.jointAccountId,
      });
      return;
    }

    if (this.nextOfKinDetails) {
      if (this.nextOfKinDetails.country) {
        this.getCountryStates(this.nextOfKinDetails.country);
      }

      this.nextOfKinForm.patchValue({
        id: this.nextOfKinDetails.id,
        title: this.nextOfKinDetails.title,
        gender: this.nextOfKinDetails.gender,
        first_name: this.nextOfKinDetails.first_name,
        last_name: this.nextOfKinDetails.last_name,
        email: this.nextOfKinDetails.email,
        relationship_with_nok: this.nextOfKinDetails.relationship_with_nok,
        phone_number: this.nextOfKinDetails.phone_number,
        country: this.nextOfKinDetails.country,
        state: this.nextOfKinDetails.state,
        city: this.nextOfKinDetails.city,
        house_number: this.nextOfKinDetails.house_number,
      });
      this.hasNextOfKinSubject$.next(true);
      this.nextOfKinIdSubject$.next(this.nextOfKinDetails.id);

      // setting this separately because it is a date
      this.nextOfKinForm.controls['dob'].setValue(
        formatDate(this.nextOfKinDetails.dob, 'yyyy-MM-dd', 'en')
      );
    }
  }

  getCountryStates(countryName: string) {
    this.states = this.countries.filter(
      (country) => country.name === countryName
    )[0].states;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
