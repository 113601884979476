import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import {
  IndividualAccountKycData,
  IndividualAccountProfilesDatum,
} from '@core/models/admin/account';
import { AccountManagementService } from '@core/services/account-management.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { LoadingService } from '@core/services/loading.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-individual-accounts',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    ButtonDirective,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './individual-accounts.component.html',
  styleUrl: './individual-accounts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndividualAccountsComponent implements OnInit, OnDestroy {
  subs$: Subscription[] = [];
  accountId!: string;
  accountsUrl!: string;
  userDetailsUrl!: string;
  isLoadingInfo$!: Observable<boolean>;
  isLoadingProfile$!: Observable<boolean>;
  userAccounts$!: Observable<IndividualAccountProfilesDatum[]>;
  private accountDataSubject =
    new BehaviorSubject<IndividualAccountKycData | null>(null);
  user$ = this.accountDataSubject.asObservable();
  private accountProfilesSubject = new BehaviorSubject<
    IndividualAccountProfilesDatum[] | null
  >(null);
  profiles$ = this.accountProfilesSubject.asObservable();

  private route = inject(ActivatedRoute);
  private accountService = inject(AccountManagementService);
  private router = inject(Router);
  private breadCrumbService = inject(BreadCrumbService);
  private loadingService = inject(LoadingService);

  constructor() {
    this.isLoadingInfo$ = this.loadingService.getLoadingObservable(
      'get-individual-account-kyc'
    );
    this.isLoadingProfile$ = this.loadingService.getLoadingObservable(
      'get-profile-accounts'
    );
  }

  ngOnInit(): void {
    this.breadCrumbService.setPageBreadCrumb(this.route);
    this.accountId = this.route.snapshot.params['accountId'];
    if (this.accountId) {
      this.getProfileInfo();
      this.accountsUrl = `/admin/accounts/individual/accounts/${this.accountId}`;
      this.userDetailsUrl = `/admin/accounts/individual/${this.accountId}/details`;
    }
  }

  getProfileInfo() {
    const sub$ = this.accountService
      .getIndividualAccountInfo(this.accountId)
      .pipe(
        switchMap((accountInfo) => {
          this.accountDataSubject.next(accountInfo.data);

          return this.accountService.getAccountProfiles(
            accountInfo.data.user.id
          );
        })
      )
      .subscribe({
        next: (acctProfileResponse) => {
          this.accountProfilesSubject.next(acctProfileResponse.data);
          // this.loadingService.resolve('get-profile-accounts');
        },
      });

    this.subs$.push(sub$);
  }

  editAccount() {
    this.router.navigateByUrl(
      `/admin/accounts/individual/edit/${this.accountId}`
    );
  }

  goBack() {
    history.back();
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
  }
}
