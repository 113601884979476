import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import {
  AllRolesDatum,
  PermissionData,
} from '@core/models/admin/roles-and-permissions';
import { AdminManagementService } from '@core/services/admin-management.service';
import { RolesAndPermissionsService } from '@core/services/roles-and-permissions.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { LoadingService } from '@core/services/loading.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { selectPopoverAnimation } from '@shared/animations/animations';
import { CustomValidators } from '@core/validators/custom-validator';

interface DisplayedRoles {
  id: string;
  name: string;
}

interface UpdatedRolesDatum extends AllRolesDatum {
  checked: boolean;
}

@Component({
  selector: 'app-adminreg',
  standalone: true,
  providers: [],
  imports: [
    RouterLink,
    RouterOutlet,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    SlideInRightModalComponent,
    ButtonDirective,
    SpinDirective,
    InputLabelComponent,
    InputComponent,
    BaseSelectDirective,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './admin-registration.component.html',
  styleUrl: './admin-registration.component.scss',
  animations: [selectPopoverAnimation],
})
export class AdminRegistrationComponent implements OnInit, OnDestroy {
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('menu') menu!: ElementRef;

  isLoading: boolean = false;
  subs: Subscription[] = [];
  roles: AllRolesDatum[] = [];

  selectedRoles: DisplayedRoles[] = [];
  popoverState: 'visible' | 'hidden' = 'hidden';

  allRolesListSubject$ = new BehaviorSubject<UpdatedRolesDatum[]>([]);
  selectedRolesListSubject$ = new BehaviorSubject<DisplayedRoles[]>([]);
  // selectedRoleIdListSubject$ = new BehaviorSubject<string[]>([]);

  isInvitingAdmin$!: Observable<boolean>;
  isFetchingRoles$!: Observable<boolean>;
  allRolesList$ = this.allRolesListSubject$.asObservable();
  selectedRolesList$ = this.selectedRolesListSubject$.asObservable();

  route = inject(ActivatedRoute);
  router = inject(Router);
  _toast = inject(NotificationFacade);
  fb = inject(FormBuilder);
  private adminService = inject(AdminManagementService);
  change = inject(ChangeDetectorRef);
  roleService = inject(RolesAndPermissionsService);
  private loadingService = inject(LoadingService);
  renderer = inject(Renderer2);

  adminForm = this.fb.nonNullable.group({
    first_name: ['', [Validators.required, Validators.minLength(2)]],
    middle_name: ['', [Validators.minLength(2)]],
    last_name: ['', [Validators.required, Validators.minLength(2)]],
    email: ['', [Validators.required, Validators.email]],
    phone_number: ['', [Validators.required, CustomValidators.phoneNumber()]],
    role_ids: [[] as string[], Validators.required],
  });

  constructor() {
    this.isInvitingAdmin$ =
      this.loadingService.getLoadingObservable('invite-admin');
    this.isFetchingRoles$ =
      this.loadingService.getLoadingObservable('get-all-roles');
  }

  ngOnInit(): void {
    this.fetchAllRoles();

    this.setAdminFormRoleIds();
  }

  setAdminFormRoleIds() {
    this.selectedRolesListSubject$.subscribe(
      (selectedRole: DisplayedRoles[]) => {
        const roleIdFormArray = this.fb.array(
          selectedRole.map((role) => this.fb.control(role.id)),
          Validators.required
        );
        this.adminForm.setControl(
          'role_ids',
          roleIdFormArray as unknown as FormControl<string[]>
        );
      }
    );
  }

  inviteAdmin() {
    const sub = this.adminService
      .inviteAdmin(this.adminForm.getRawValue())
      .subscribe({
        next: (res) => {
          this.adminForm.reset();
          this._toast.success('Successfully invited admin');
        },
        error: (res) => {
          this._toast.error('Failed to invite admin');
        },
      });
    this.subs.push(sub);
  }

  fetchAllRoles() {
    const pagination = new Pagination();
    pagination.size = 100;
    const sub = this.roleService.getAllRoles(pagination).subscribe({
      next: (response) => {
        const sortedRoles = response.data.data.sort((a, b) =>
          a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
        );

        this.allRolesListSubject$.next(
          sortedRoles.map((role) => {
            return {
              ...role,
              checked: false,
            };
          })
        );
      },
      error: () => {
        this._toast.error('Failed to fetch roles');
      },
    });
    this.subs.push(sub);
  }

  toggleRoleCheckbox(event: Event, index: number) {
    const target = event.target as HTMLInputElement;

    // Update the `allRolesListSubject$` with the new checked state
    const currentRolesList = this.allRolesListSubject$.value;
    const updatedRolesList = currentRolesList.map((role, i) =>
      i === index ? { ...role, checked: target.checked } : role
    );
    this.allRolesListSubject$.next(updatedRolesList);

    this.updateSelectedRoles({
      isChecked: target.checked,
      name: target.name,
      id: target.id,
    });
  }

  updateSelectedRoles(roleCheckbox: {
    isChecked: boolean;
    name: string;
    id: string;
  }) {
    const currentSelectedRoles = this.selectedRolesListSubject$.value;
    if (roleCheckbox.isChecked) {
      const updatedSelectedRoles = [
        ...currentSelectedRoles,
        { id: roleCheckbox.id, name: roleCheckbox.name },
      ];
      this.selectedRolesListSubject$.next(updatedSelectedRoles);
    } else {
      const updatedSelectedRoles = currentSelectedRoles.filter(
        (role) => role.id !== roleCheckbox.id
      );
      this.selectedRolesListSubject$.next(updatedSelectedRoles);
    }
  }

  removeRole(roleId: string) {
    const currentSelectedRoles = this.selectedRolesListSubject$.value;

    // Remove role from selected roles
    const updatedSelectedRoles = currentSelectedRoles.filter(
      (role) => role.id !== roleId
    );
    this.selectedRolesListSubject$.next(updatedSelectedRoles);

    // Uncheck the checkbox
    const updatedRolesList = this.allRolesListSubject$.value.map((role) =>
      role.id === roleId ? { ...role, checked: false } : role
    );
    this.allRolesListSubject$.next(updatedRolesList);
  }

  goback() {
    history.back();
  }

  togglePopover() {
    this.popoverState = this.popoverState === 'hidden' ? 'visible' : 'hidden';
  }
  async popOverOpen(event: any) {
    if (event.fromState === 'hidden' && event.toState === 'visible') {
      // we check if the dropdown is visible now
      (await event.fromState) === 'hidden' && event.toState === 'visible';
      this.renderer.listen('window', 'click', (e: Event) => {
        /**
         * Only run when toggleButton is not clicked
         * If we don't check this, all clicks (even on the toggle button) gets into this
         * section which in the result we might never see the menu open!
         * And the menu itself is checked here, and it's where we check just outside of
         * the menu and button the condition abbove must close the menu
         */
        if (
          !this.toggleButton.nativeElement.contains(e.target) &&
          !this.menu.nativeElement.contains(e.target)
        ) {
          this.popoverState = 'hidden';
          this.change.detectChanges();
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
