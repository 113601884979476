import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-passforgot',
  standalone: true,
  imports: [ReactiveFormsModule, InputLabelComponent, InputComponent],
  templateUrl: './passforgot.component.html',
  styleUrl: './passforgot.component.scss',
})
export class PassforgotComponent {
  toast = inject(NotificationFacade);
  authService = inject(AuthService);
  email = new FormControl('', [Validators.required, Validators.email]);
  subs: Subscription[] = [];
  sendResetRequest() {
    const sub = this.authService
      .forgotPassword(this.email.value || '')
      .subscribe({
        next: (res) => {
          this.toast.success(res.message);
        },
        error: (res) => {
          this.toast.error(res?.error?.message || res.message);
        },
      });
    this.subs.push(sub);
  }
  goBack() {
    history.back();
  }
  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
