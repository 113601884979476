import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TransactionsService } from '@core/services/transactions.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { WalletTransactionData } from '@core/models/admin/account';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { LoadingService } from '@core/services/loading.service';
import {
  NgxSkeletonLoaderModule,
} from 'ngx-skeleton-loader';
import { StatusComponent } from "../../../../../shared/ui/status/status.component";
import { ButtonDirective } from '@shared/directives/button.directive';

@Component({
  selector: 'app-view-details',
  standalone: true,
  templateUrl: './view-details.component.html',
  styleUrl: './view-details.component.scss',
  imports: [
    SlideInRightModalComponent,
    CommonModule,
    ButtonDirective,
    NgxSkeletonLoaderModule,
    StatusComponent
],
})
export class ViewDetailsComponent implements OnInit, OnDestroy {
  transactionId: any = null;
  returnPath!: string;
  // transaction!: WalletTransactionData | TransactionsListDatum;
  transaction!: WalletTransactionData;
  user: any = null;
  isLoading: boolean = false;
  isReverseLoading: boolean = false;
  isFetchingDetails$!: Observable<boolean>;
  action!:string;
  route = inject(ActivatedRoute);
  router = inject(Router);
  subs: Subscription[] = [];
  change = inject(ChangeDetectorRef);
  transactionService = inject(TransactionsService);
  notify = inject(NotificationFacade);
  private loadingService = inject(LoadingService);

  constructor() {
    this.isFetchingDetails$ = this.loadingService.getLoadingObservable(
      'get-transaction-details'
    );
  }

  ngOnInit(): void {
   this.route.queryParams.subscribe((params) => {
      this.action = params['action'];
    });
    console.log("action",this.action);
    
    this.transactionId = this.route.snapshot.params['transactionId'];
    if (this.transactionId) this.getTransactionDetails();
  }

  goback() {
    history.back();
  }

  getTransactionDetails() {
    this.isLoading = true;
    const sub = this.transactionService
      .getTransactionDetails(this.transactionId)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.transaction = response.data;
          this.change.detectChanges();
        },
        error: (err) => {
          this.isLoading = false;

          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }
   reverseTransaction(){
    this.isReverseLoading = true;
      if (!this.transactionId) {
        this.isReverseLoading = false;
        this.notify.error('Invalid transaction or account details');
        return;
      }
      const sub = this.transactionService
        .reverseTransaction(this.transactionId)
        .subscribe({
          next: (response:any) => {
            if(response?.status === 'success'){
            this.isReverseLoading = false;
            this.change.detectChanges();
            history.back();
            this.notify.success(response?.message);
            }
          },
          error: (err) => {
            this.isReverseLoading = false;
            this.change.detectChanges();
             history.back();
          },
        });
  
      this.subs.push(sub);
    }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
