import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { CorporateAccountkyc } from '@core/models/admin/account';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { CorporateRegistrationService } from '@core/services/corporate-registration.service';
import { Observable, Subscription } from 'rxjs';
import { NotificationFacade } from '@core/facades/notification.facade';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@core/services/loading.service';
import { AccountManagementService } from '@core/services/account-management.service';

@Component({
  selector: 'app-edit-company-details',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    InputLabelComponent,
    InputComponent,
    ReactiveFormsModule,
    InputDateDirective,
    ButtonDirective,
    SpinDirective,
  ],
  templateUrl: './edit-company-details.component.html',
  styleUrl: './edit-company-details.component.scss',
})
export class EditCompanyDetailsComponent implements OnInit, OnDestroy {
  @Input() companyDetails!: CorporateAccountkyc;

  companyDetailsForm!: FormGroup;
  subs: Subscription[] = [];
  accountId!: string;
  isLoading$!: Observable<boolean>;

  fb = inject(FormBuilder);
  corporateService = inject(CorporateRegistrationService);
  cdRef = inject(ChangeDetectorRef);
  toast = inject(NotificationFacade);
  route = inject(ActivatedRoute);
  private loadingService = inject(LoadingService);
  private accountService = inject(AccountManagementService);

  constructor() {
    this.isLoading$ =
      this.loadingService.getLoadingObservable('add-corporate-kyc');
  }

  ngOnInit(): void {
    this.createForm();
    this.setAccountId();

    if (this.companyDetails) {
      this.setFormValues();
    }
  }

  setAccountId() {
    this.accountId = this.route.snapshot.params['accountId'];
    this.companyDetailsForm.patchValue({
      account_id: this.accountId,
    });
  }

  createForm() {
    this.companyDetailsForm = this.fb.nonNullable.group({
      name: [''],
      registration_number: [''],
      tin: [''],
      business_type: [''],
      business_address: [''],
      business_website: [''],
      registered_date: [new Date()],
      // DO NOT REMOVE
      // account_type: ['corporate'],
      account_id: [this.accountId],
    });
  }

  setFormValues() {
    if (this.companyDetails) {
      this.companyDetailsForm.patchValue({
        name: this.companyDetails.corporate_kyc?.name,
        registration_number:
          this.companyDetails.corporate_kyc?.registration_number || '',
        tin: this.companyDetails.corporate_kyc?.tin || '',
        business_type: this.companyDetails.corporate_kyc?.business_type || '',
        business_address:
          this.companyDetails.corporate_kyc?.business_address || '',
        business_website:
          this.companyDetails.corporate_kyc?.business_website || '',
      });

      // setting this separately because it is a date
      const registeredDate = this.companyDetails.corporate_kyc?.registered_date;
      this.companyDetailsForm.controls['registered_date'].setValue(
        registeredDate
          ? formatDate(
              this.companyDetails.corporate_kyc?.registered_date || '',
              'yyyy-MM-dd',
              'en'
            )
          : ''
      );
    }
  }

  updateCompanyDetails() {
    const sub = this.corporateService
      .updateCompanyDetails(this.companyDetailsForm.getRawValue())
      .subscribe({
        next: () => {
          this.accountService.clearCache(this.accountId);
          this.toast.success('Successfully updated company details');
        },
        error: () => {
          this.toast.error('Failed to update company details');
        },
      });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
