import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { AuthService } from '@core/services/auth.service';
import { ErrorDialogService } from '@core/services/error-dialog.service';
import { Store } from '@ngxs/store';
import { Observable, catchError, throwError } from 'rxjs';

export const ErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  let toast = inject(NotificationFacade);
  let router = inject(Router);
  let store = inject(Store);
  let authService = inject(AuthService);

  return next(req).pipe(
    // tap((res: HttpHeaderResponse)=>),
    catchError((err: HttpErrorResponse) => {
      // console.log(err);
      if (err) {
        switch (err.status) {
          case 400:
            toast.error(err?.error?.message || err?.message || err?.statusText);
            break;
          case 401:
            router.navigateByUrl(`/auth/login`);
            toast.info('Your session has expired, please log in again');
            break;
          case 403:
            if (authService.tokenIsExpired()) {
              authService.storeReturnUrl()
              router.navigateByUrl(`/auth/login`);
              toast.error(
                'Your session has expired.'
              );
            } else {
              toast.error(
                'You are not authorized to access this resource.'
              );
            }
            break;
          case 500:
            toast.error('Internal Server Error, please try again');
            break;
          case 502:
            toast.error('Internal Server Error, please try again');
            break;
          default:
            toast.error('Unexpected Error');
            err ? console.error('default: ', err.message) : null;
            break;
        }
      }
      return throwError(() => err);
    })

  );
};
