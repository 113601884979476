<app-kyc-accordion
  accordionTitle="Customer Information"
  accordionKey="customerInformation"
>
  <form class="" [formGroup]="customerInfoForm">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- Title -->
      <div>
        <app-input-label labelFor="title" labelClass="label--bottom-margin"
          >Title</app-input-label
        >
        <select name="title" required base-select formControlName="title">
          <option value="" disabled selected>Title</option>
          <option *ngFor="let title of titles" [value]="title.key">
            {{ title.value }}
          </option>
        </select>
      </div>

      <!-- First Name -->
      <div>
        <app-input-label labelFor="first-name" labelClass="label--bottom-margin"
          >First Name</app-input-label
        >

        <app-input
          placeholder="Enter First Name"
          [inputClass]="'input--padding'"
          formControlName="first_name"
        ></app-input>
      </div>

      <!-- Last Name -->
      <div>
        <app-input-label labelFor="last-name" labelClass="label--bottom-margin"
          >Last Name</app-input-label
        >

        <app-input
          placeholder="Enter Last Name"
          [inputClass]="'input--padding'"
          formControlName="last_name"
        ></app-input>
      </div>

      <!-- Middle Name -->
      <div>
        <app-input-label labelFor="last-name" labelClass="label--bottom-margin"
          >Middle Name</app-input-label
        >

        <app-input
          placeholder="Enter Middle Name"
          [inputClass]="'input--padding'"
          formControlName="middle_name"
        ></app-input>
      </div>

      <!-- Gender -->
      <div>
        <app-input-label labelFor="gender" labelClass="label--bottom-margin"
          >Gender</app-input-label
        >
        <select name="gender" required base-select formControlName="gender">
          <option value="" disabled selected>Select status</option>
          <option *ngFor="let gender of genderList" [value]="gender.value">
            {{ gender.label }}
          </option>
        </select>
      </div>

      <!-- FIXME: API doesn't allow editing Email -->
      <div>
        <app-input-label labelFor="email" labelClass="label--bottom-margin"
          >Email</app-input-label
        >
        <app-input
          placeholder="Enter email"
          inputClass="input--padding"
          formControlName="email"
        ></app-input>
      </div>

      <!-- Phone Number 1 -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Phone Number 1</app-input-label
        >
        <app-input
          placeholder="Enter Phone Number 1"
          [inputClass]="'input--padding'"
          formControlName="phone_number_1"
        >
        </app-input>
      </div>

      <!-- Phone Number 2 -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Phone Number 2</app-input-label
        >
        <app-input
          placeholder="Enter Phone Number 2"
          [inputClass]="'input--padding'"
          formControlName="phone_number_2"
        >
        </app-input>
      </div>

      <!-- Date of Birth -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Date of Birth</app-input-label
        >
        <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
        <input
          type="date"
          input-date
          class="input--padding"
          formControlName="dob"
        />
      </div>

      <!-- Marital Status -->
      <div>
        <app-input-label
          labelFor="marital status"
          labelClass="label--bottom-margin"
          >Marital Status</app-input-label
        >
        <select
          name="marital-status"
          required
          base-select
          formControlName="marital_status"
        >
          <option value="" disabled selected>Select status</option>
          <option *ngFor="let status of maritalStatus" [value]="status.value">
            {{ status.label }}
          </option>
        </select>
      </div>

      <!-- Nationality -->
      <div>
        <app-input-label
          labelFor="nationality"
          labelClass="label--bottom-margin"
          >Nationality</app-input-label
        >
        <select
          name="nationality"
          required
          base-select
          formControlName="nationality"
        >
          <option value="" disabled selected>Select country</option>
          @for (country of countries; track $index) {
            <option [value]="country.name">{{ country.name }}</option>
          }
        </select>
      </div>

      <!-- Annual Income -->
      <div class="">
        <app-input-label
          labelFor="annual income"
          labelClass="label--bottom-margin"
          >What is your Annual Income?</app-input-label
        >
        <select
          name="annual income"
          required
          base-select
          formControlName="annual_income"
        >
          <option value="" disabled selected>Select Annual Income</option>
          <option *ngFor="let range of annualIncomeRange" [value]="range.value">
            {{ range.label }}
          </option>
        </select>
      </div>

      <!-- State of Origin -->
      <div>
        <app-input-label labelFor="gender" labelClass="label--bottom-margin"
          >State of Origin</app-input-label
        >
        <select
          name="state-of-origin"
          required
          base-select
          formControlName="state_of_origin"
        >
          <option value="" disabled selected>Select State</option>
          @for (state of nigerianStates; track $index) {
            <option [value]="state.state_code">{{ state.name }}</option>
          }
        </select>
      </div>

      <!-- Place of birth -->
      <div>
        <app-input-label
          labelFor="place_of_birth"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Place of Birth</app-input-label
        >
        <app-input
          placeholder="Enter Place of Birth"
          inputClass="input--padding"
          formControlName="place_of_birth"
        ></app-input>
      </div>

      <fieldset>
        <legend class="text-sm font-semibold leading-6">
          Politically Exposed
        </legend>

        <div
          class="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0"
        >
          <div class="flex items-center">
            <input
              id="yes"
              name="political_exposure"
              type="radio"
              formControlName="political_exposure"
              value="true"
              class="h-4 w-4 border-gray-300 text-mango focus:ring-mango"
            />
            <label
              for="yes"
              class="ml-3 block text-sm font-medium leading-6 text-gray-900"
              >YES</label
            >
          </div>
          <div class="flex items-center">
            <input
              id="no"
              name="political_exposure"
              type="radio"
              formControlName="political_exposure"
              value="false"
              class="h-4 w-4 border-gray-300 text-mango focus:ring-mango"
            />
            <label
              for="no"
              class="ml-3 block text-sm font-medium leading-6 text-gray-900"
              >NO</label
            >
          </div>
        </div>
      </fieldset>

      <!-- BVN -->
      <div>
        <app-input-label labelFor="bvn" labelClass="label--bottom-margin"
          >BVN</app-input-label
        >

        <app-input
          placeholder="Enter BVN"
          [inputClass]="'input--padding'"
          formControlName="bvn"
        ></app-input>
      </div>
    </div>

    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': isLoading$ | async
        }"
      >
        <button
          type="button"
          mango-button
          [loading]="(isLoading$ | async)!!"
          [disabled]="(isLoading$ | async)!!"
          (click)="updateCustomerProfile()"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span
            *ngIf="isLoading$ | async"
            spin
            loadingText="Updating Customer Profile"
          ></span>
          @if (!(isLoading$ | async)) {
            Save Changes
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>
