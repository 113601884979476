import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import {
  CorporateRegistrationService,
  GetAccountSignatoriesData,
  GetAccountSignatoriesUser,
} from '@core/services/corporate-registration.service';
import { SignatoryService } from '@core/services/signatory.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import {
  DropdownComponent,
  DropDownModel,
} from '@shared/ui/dropdown/dropdown.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ButtonDirective } from '@shared/directives/button.directive';
import { LoadingService } from '@core/services/loading.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { URL_KEYS } from '@core/constants/url-keys.constants';
import { InputComponent } from '@shared/ui/input/input.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { SIGNATORY_CLASS } from 'app/app.constants';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { BreadCrumbService } from '@core/services/bread-crumb.service';

enum SignatoryStatusEnum {
  ACTIVATED = 'activated',
  DEACTIVATED = 'de_activated',
}

@Component({
  selector: 'app-signatory-list',
  standalone: true,
  imports: [
    DropdownComponent,
    ConfirmDialogComponent,
    RouterOutlet,
    CommonModule,
    ButtonDirective,
    RouterLink,
    NgxSkeletonLoaderModule,
    InputComponent,
    InputLabelComponent,
    ReactiveFormsModule,
    BaseSelectDirective,
  ],
  templateUrl: './signatory-list.component.html',
  styleUrl: './signatory-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatoryListComponent implements OnInit, OnDestroy {
  isDeleteDialogOpen: boolean = false;
  isDeleteInProgress: boolean = false;
  isDeactivateDialogOpen: boolean = false;
  isDeactivateInProgress: boolean = false;
  isInviteDialogOpen: boolean = false;
  isInviteInProgress: boolean = false;
  accountId!: string;
  subs: Subscription[] = [];
  signatoriesList: GetAccountSignatoriesData[] = [];
  isLoading: boolean = false;
  signatoryID!: string | null;
  SignatoryStatus = SignatoryStatusEnum;
  signatoryName!: string | null;
  signatoryClass = SIGNATORY_CLASS;

  private addSignatoryUrlSubject = new BehaviorSubject<string | null>(null);
  private addSignatureMandateUrlSubject = new BehaviorSubject<string | null>(
    null
  );
  private signatoryInfoSubject$ =
    new BehaviorSubject<GetAccountSignatoriesUser | null>(null);
  private isDialogOpenSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isDialogLoadingSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private hasMandateSubject$ = new BehaviorSubject<boolean>(false);
  openEditDialogSubject$ = new BehaviorSubject<boolean>(false);
  private isUpdatingSignatorySubject$ = new BehaviorSubject<boolean>(false);
  editSignatoryName$ = new BehaviorSubject<string>('');

  addSignatoryUrl$ = this.addSignatoryUrlSubject.asObservable();
  addSignatureMandateUrl$ = this.addSignatureMandateUrlSubject.asObservable();
  signatoryInfo$ = this.signatoryInfoSubject$.asObservable();
  isDialogOpen$ = this.isDialogOpenSubject$.asObservable();
  isDialogLoading$ = this.isDialogLoadingSubject$.asObservable();
  isFetchingList$!: Observable<boolean>;
  isFetchingMandate$!: Observable<boolean>;
  hasMandate$ = this.hasMandateSubject$.asObservable();
  openEditDialog$ = this.openEditDialogSubject$.asObservable();
  isUpdatingSignatory$ = this.isUpdatingSignatorySubject$.asObservable();

  private fb = inject(FormBuilder);
  private cdr = inject(ChangeDetectorRef);
  private toast = inject(NotificationFacade);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private corporateService = inject(CorporateRegistrationService);
  private signatoryService = inject(SignatoryService);
  private loadingService = inject(LoadingService);
  private breadCrumbService = inject(BreadCrumbService);

  editSignatoryForm = this.fb.nonNullable.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    signatory_id: [''],
    profile_id: ['', Validators.required], //accountID
    signatory_class: [''],
  });

  signatoryDropdownActions(
    signatory: GetAccountSignatoriesData
  ): DropDownModel[] {
    return [
      {
        title: 'Edit ',
        action: () => {
          this.editSignatory(signatory);
        },
        show: true,
      },
      {
        title: 'Deactivate user',
        action: () => {
          this.isDeactivateDialogOpen = true;
          this.signatoryID = signatory.user.id;
          this.signatoryName = `${signatory.user.first_name} ${signatory.user.last_name}`;
          this.cdr.detectChanges();
        },
        show: signatory.status === this.SignatoryStatus.ACTIVATED,
      },
      {
        title: 'Change role',
        action: () =>
          this.router.navigateByUrl(
            `/admin/accounts/corporate/${this.accountId}/signatories/change-role`
          ),
        show: true,
      },
      {
        title: 'Resend Invite',
        action: () => {
          this.isDialogOpenSubject$.next(true);
          this.signatoryInfoSubject$.next(signatory.user);
        },
        show: true,
      },

      {
        title: 'Delete user',
        action: () => {
          this.isDeleteDialogOpen = true;
          this.signatoryID = signatory.user.id;
          this.signatoryName = `${signatory.user.first_name} ${signatory.user.last_name}`;

          this.cdr.detectChanges();
        },
        show: true,
      },
    ];
  }

  constructor() {
    this.isFetchingList$ = this.loadingService.getLoadingObservable(
      URL_KEYS.CORPORATE_REGISTRATION.GET_ACCOUNT_SIGNATORIES
    );

    this.isFetchingMandate$ = this.loadingService.getLoadingObservable(
      URL_KEYS.CORPORATE_REGISTRATION.GET_ACCOUNT_SIGNATORY_MANDATE
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getAccountSignatories();
      }
    });
  }

  ngOnInit(): void {
    this.breadCrumbService.setPageBreadCrumb(this.route);
    this.getAccountIdInfo();
    if (this.accountId) {
      this.addSignatoryUrlSubject.next(
        `/admin/accounts/corporate/${this.accountId}/signatories/add`
      );
      this.addSignatureMandateUrlSubject.next(
        `/admin/accounts/corporate/${this.accountId}/signatories/add-mandate`
      );
      this.getAccountSignatories();
      this.getSignatoryMandate();
    }

    this.route.params.subscribe((params) => {
      const hasMandate = params['hasMandate'];
      if (hasMandate) {
        this.hasMandateSubject$.next(true);
      }
    });
  }

  getAccountIdInfo() {
    const accountId = this.route.snapshot.parent?.params['accountId'];
    if (accountId) {
      this.accountId = accountId;
      this.cdr.detectChanges();
    }
  }

  getAccountSignatories(): void {
    if (!this.accountId) {
      return;
    }

    const sub = this.corporateService
      .getAccountSignatories(this.accountId)
      .subscribe({
        next: (response) => {
          this.signatoriesList = response.data;
          this.cdr.detectChanges();
        },
        error: () => {
          this.toast.error('Failed to fetch signatories');
        },
      });

    this.subs.push(sub);
  }

  editSignatory(signatory: GetAccountSignatoriesData) {
    this.editSignatoryForm.patchValue({
      first_name: signatory.user.first_name,
      last_name: signatory.user.last_name,
      email: signatory.user.email,
      profile_id: signatory.user.id,
      signatory_id: signatory.signatory_id,
      signatory_class: signatory.signatory_class ?? '',
    });
    this.editSignatoryName$.next(
      `${signatory.user.first_name} ${signatory.user.last_name}`
    );
    this.openEditDialogSubject$.next(true);
  }

  viewSignatoryDetails() {}

  changeRole() {
    if (this.accountId) {
      this.router.navigateByUrl(
        `/admin/accounts/corporate/${this.accountId}/signatories/change-role`
      );
    }
  }

  assignToRM() {}

  deleteSignatory() {
    this.isDeleteInProgress = true;
    let signatoryID = '';
    if (this.signatoryID) {
      signatoryID = this.signatoryID;
    }

    const sub = this.signatoryService
      .removeUserFromAccount(signatoryID, this.accountId)
      .subscribe({
        next: () => {
          this.isDeleteInProgress = false;
          this.isDeleteDialogOpen = false;
          this.toast.success('Signatory deleted successfully');
          this.updateSignatoryList();
          this.cdr.detectChanges();
        },
        error: (response) => {
          this.isDeleteInProgress = false;
          this.isDeleteDialogOpen = false;
          this.toast.error(
            'Failed to delete signatory, please try again later'
          );
        },
      });

    this.subs.push(sub);
  }

  deactivateSignatory() {
    this.isDeactivateInProgress = true;
    this.cdr.detectChanges();

    let signatoryID = '';
    if (this.signatoryID) {
      signatoryID = this.signatoryID;
    }

    const sub = this.signatoryService
      .deactivateSignatory(signatoryID, this.accountId)
      .subscribe({
        next: () => {
          this.isDeactivateInProgress = false;
          this.isDeactivateDialogOpen = false;
          this.updateSignatoryStatus(signatoryID);
          this.cdr.detectChanges();
          this.toast.success('Signatory deactivated successfully');

          this.cdr.detectChanges();
        },
        error: (response) => {
          this.isDeactivateInProgress = false;
          this.isDeactivateDialogOpen = false;
          this.cdr.detectChanges();
          this.toast.error(
            'Failed to deactivate signatory, please try again later'
          );
        },
      });

    this.subs.push(sub);
  }

  updateSignatoryStatus(signatoryID: string) {
    const signatoryIndex = this.signatoriesList.findIndex(
      (signatory) => signatory.user.id === signatoryID
    );
    if (signatoryIndex === -1) {
      return;
    }
    const updatedSignatory = {
      ...this.signatoriesList[signatoryIndex],
      status: this.SignatoryStatus.DEACTIVATED,
    };
    const updatedSignatoryList = [
      ...this.signatoriesList.slice(0, signatoryIndex),
      updatedSignatory,
      ...this.signatoriesList.slice(signatoryIndex + 1),
    ];

    this.signatoriesList = updatedSignatoryList;
  }

  resendSignatoryInvite() {
    this.isDialogLoadingSubject$.next(true);
    const signatoryId = this.signatoryInfoSubject$.getValue()?.id;
    if (signatoryId) {
      const sub = this.corporateService
        .resendSignatoryInvite(signatoryId)
        .subscribe({
          next: () => {
            this.isDialogLoadingSubject$.next(false);
            this.isDialogOpenSubject$.next(false);
            this.toast.success('Successfully re-invited Signatory');
          },
          error: () => {
            this.isDialogLoadingSubject$.next(false);
            this.isDialogOpenSubject$.next(false);
            this.toast.error('Failed to invite signatory');
          },
        });

      this.subs.push(sub);
    } else {
      this.isDialogLoadingSubject$.next(false);
      this.toast.error('Signatory ID not found');
    }
  }

  updateSignatory() {
    this.isUpdatingSignatorySubject$.next(true);

    const formData = this.editSignatoryForm.getRawValue();

    const sub = this.corporateService.updateSignatory(formData).subscribe({
      next: (response) => {
        this.isUpdatingSignatorySubject$.next(false);
        this.openEditDialogSubject$.next(false);

        const signatoryIndex = this.signatoriesList.findIndex(
          (signatory) => signatory.user.id === formData.profile_id
        );

        if (signatoryIndex !== -1) {
          const updatedSignatory = {
            ...this.signatoriesList[signatoryIndex],
            user: {
              ...this.signatoriesList[signatoryIndex].user,
              id: response.user.id,
              first_name: formData.first_name,
              last_name: formData.last_name,
              email: formData.email,
            },
            signatory_class: formData.signatory_class,
            signatory_id: response.signatory_id,
          };

          this.signatoriesList[signatoryIndex] = updatedSignatory;
          this.toast.success('Successfully updated signatory');
        }
      },
      error: () => {
        this.isUpdatingSignatorySubject$.next(false);
        this.toast.error('Failed to update signatory');
      },
    });

    this.subs.push(sub);
  }

  updateSignatoryList() {
    this.signatoriesList = this.signatoriesList.filter(
      (signatory) => signatory.user.id !== this.signatoryID
    );
  }

  getSignatoryMandate() {
    if (!this.accountId) {
      return;
    }

    const sub = this.corporateService
      .getAccountSignatoryMandate(this.accountId)
      .subscribe({
        next: (response) => {
          response
            ? this.hasMandateSubject$.next(true)
            : this.hasMandateSubject$.next(false);
        },
        error: (err) => {
          this.toast.error('Failed to fetch signatory mandate');
        },
      });

    this.subs.push(sub);
  }

  closeDialog() {
    this.isDialogOpenSubject$.next(false);
    this.signatoryInfoSubject$.next(null);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
