<app-slide-in-right-modal
  modalTitle="Dividend History"
  iconImageSrc="assets/images/svgs/document--outline.svg"
  [slideOpen]="true"
  [showBackLink]="false"
  returnPath=""
>
  <div class="page__container flex flex-col pb-8">
    <!-- CARDS -->
    <div class="mt-[0.94rem] grid gap-5">
      <div
        class="rounded-lg border border-solid border-mango-gray200 bg-mango-paleBlue p-[38px]"
      >
        <!-- Total Principal -->
        <div>
          <p>Total Value - {{ accountNumber }}</p>
        </div>
        <app-tooltip-overlay
      [displayValue]="(totalDividend.toString()) | number: '1.2-2'"
      [fullValue]="(totalDividend.toString())"
    >
    </app-tooltip-overlay>
      </div>
    </div>

    <!-- table skeleton loader -->
    @if (isFetchingTransactionHistoryList$ | async) {
      <div class="mt-[0.94rem]">
        <app-table-skeleton-loader></app-table-skeleton-loader>
      </div>
    }

    <!-- Table -->
    <div
      *ngIf="!(isFetchingTransactionHistoryList$ | async)"
      class="mt-[0.94rem] rounded-lg border border-solid border-mango-gray200 bg-white"
    >
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
          >
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    class="flex border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold text-gray-600"
                  >
                    S/N
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99998 3.33301V12.6663M7.99998 12.6663L12.6666 7.99967M7.99998 12.6663L3.33331 7.99967"
                        stroke="#667085"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </th>
                  <th
                    class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
                  >
                    Date
                  </th>
                  <th
                    class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
                  >
                    Dividend
                  </th>
                </tr>
              </thead>

              <tbody>
                @if ((transactionHistoryList$ | async).length > 0) {
                  @for (
                    fund of transactionHistoryList$ | async;
                    track fund.id;
                    let i = $index
                  ) {
                    <ng-container
                      *ngTemplateOutlet="
                        mutualFundsRow;
                        context: { data: fund, i: i }
                      "
                    ></ng-container>
                  }
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <ng-template #mutualFundsRow let-mutualFund="data" let-i="i">
      <tr>
        <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
          <p>{{ i + 1 }}</p>
        </td>

        <!-- Date -->
        <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
          <p class="whitespace-no-wrap text-gray-900">
            {{ mutualFund.date | date: 'dd/MM/yyyy' }}
          </p>
        </td>
        <!-- Dividend -->
        <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
          <app-tooltip-overlay
          [displayValue]="mutualFund.dividend / 100 | number: '1.2-2'"
          [fullValue]="mutualFund.dividend / 100 | number"
          [mode]="'table'"
        >
        </app-tooltip-overlay>
        </td>
      </tr></ng-template
    >
  </div>
</app-slide-in-right-modal>
