import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { Pagination } from '@core/models/pagination.model';
import { LoadingService } from '@core/services/loading.service';
import { MutualFundService } from '@core/services/mutual-fund.service';
import { Store } from '@ngxs/store';
import {
  DropdownComponent,
  DropDownModel,
} from '@shared/ui/dropdown/dropdown.component';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { IFILTER } from 'app/app.constants';
import { AuthActions } from 'app/auth/store/auth.actions';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-dividend-mutual-funds',
  standalone: true,
  imports: [
    CommonModule,
    TableSkeletonLoaderComponent,
    DropdownComponent,
    TableNavigatorComponent,
  ],
  templateUrl: './dividend-mutual-funds.component.html',
  styleUrl: './dividend-mutual-funds.component.scss',
})
export class DividendMutualFundsComponent implements OnInit, OnDestroy {
  private mutualFundService = inject(MutualFundService);
  private change = inject(ChangeDetectorRef);
  private loadingService = inject(LoadingService);
  private router = inject(Router);
  private notify = inject(NotificationFacade);
  private store = inject(Store);
  private activatedRoute = inject(ActivatedRoute);

  totalCount: number = 0;
  subs: Subscription[] = [];
  isFetchingDividendList$!: Observable<boolean>;
  actionList: DropDownModel[] = [];
  start_date: Date | undefined;
  end_date: Date | undefined;

  private dividendListSubject = new BehaviorSubject<any>(null);
  dividendList$ = this.dividendListSubject.asObservable();

  filterStructure: IFILTER[] = [
    {
      filterKey: 'date',
      label: 'Date',
      type: 'date',
    },
  ];

  constructor() {
    this.isFetchingDividendList$ =
      this.loadingService.getLoadingObservable('get-dividend-list');
  }

  ngOnInit() {
    const sub = this.activatedRoute.queryParams.subscribe((params) => {
      this.start_date = params['start_date']
        ? new Date(params['start_date'])
        : undefined;
      this.end_date = params['end_date']
        ? new Date(params['end_date'])
        : undefined;

      if (this.start_date && this.end_date) {
        this.getDividendList(new Pagination(), this.start_date, this.end_date);
      } else {
        this.getDividendList(new Pagination());
      }
    });
    this.subs.push(sub);
  }

  getDividendList(page: Pagination, start_date?: Date, end_date?: Date) {
    const sub = this.mutualFundService
      .getDividendList(page, start_date, end_date)
      .subscribe({
        next: (res) => {
          this.totalCount = res.data.count;
          this.dividendListSubject.next(res.data.data);
          // this.change.detectChanges();
        },
        error: () => {},
      });
    this.subs.push(sub);
  }

  initDropdownList(param: any) {
    return (this.actionList = [
      {
        action: () => {
          this.router.navigate(
            ['admin/transactions/mutual-funds/tab/consolidated/dividend/Edit'],
            {
              state: { paramData: param },
            }
          );
        },
        title: 'Edit Dividend',
        show: true,
      },

      {
        action: () => {
          this.revaluateDivdend(param.dayDate);
        },
        title: 'Revaluate Dividend',
        show: true,
      },
    ]);
  }

  viewDistribution(date: string) {
    const formattedDate = date.split('T')[0];
    this.router.navigate([
      `/admin/transactions/mutual-funds/tab/consolidated/dividends-distribution/${formattedDate}`,
    ]);
  }

  revaluateDivdend(date: string) {
    console.log(date);
    this.store.dispatch(new AuthActions.SetLoadingStatus(true));

    const formattedDate = new Date(date).toISOString().split('T')[0];
    const payload = { date: formattedDate };
    const sub = this.mutualFundService.revaluateDivident(payload).subscribe({
      next: (res) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.notify.success('Weight revaluation successful');
        this.change.detectChanges();
      },
      error: () => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
      },
    });

    this.subs.push(sub);
  }

  navigate(currentPage: number) {
    const skip = currentPage * 10 - 10;
    this.getDividendList(
      new Pagination({ skip }),
      this.start_date,
      this.end_date
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
