import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { CorporateDoc } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

import { environment } from '@core/environments/environment';

interface IDocTitle {
  [key: string]: string;
}

const DocumentTitle: IDocTitle = {
  board_resolution: 'Board Resolution',
  incorporation_certificate:
    'Incorporation/Registration Certificate (True Copy)',
  memorandum: 'Memorandum and Article of  Association (True Copy)',
  form_co: 'Form CO2/CO7 (True Copy)',
  tax_id_cert: 'Tax Identification Certificate',
  scuml: 'SCUML (Optional)',
  reference_one: 'Reference - 1',
  reference_two: 'Reference - 2',
};
@Component({
  selector: 'app-view-documents',
  standalone: true,
  imports: [CommonModule, KycAccordionComponent],
  templateUrl: './view-documents.component.html',
  styleUrl: './view-documents.component.scss',
})
export class ViewDocumentsComponent {
  @Input() kycDocuments!: CorporateDoc[];
  isKycComplete: boolean = false;

  DocumentTitle = DocumentTitle;

  openDocument(link: string) {
    window.open(`${environment.FILE_BASE_URL}${link}`, '_blank');
  }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes['kycDocuments'] || changes['DocumentTitle']) {
        this.checkKycStatus();
      }
    }

  private checkKycStatus(): void {
    // Check if there are no KYC documents or if the KYC documents array is empty
    if (!this.kycDocuments || this.kycDocuments.length === 0) {
      this.isKycComplete = false;
      return;
    }
  
    // Check if there is at least one approved general KYC document
    const hasApprovedGeneralKyc = this.kycDocuments.some(
      doc => doc.document_type === 'general_kyc' && doc.status === 'approved'
    );
  
    // Determine if KYC is complete
    this.isKycComplete = hasApprovedGeneralKyc || (
      // Check if there are 8 or more documents and all are approved
      this.kycDocuments.length >= 8 &&
      this.kycDocuments.every(doc => doc.status === 'approved')
    );
  }
  

  
}
