@if (!(isFetchingAccountInfo$ | async)) {
  <div class="company-account__wrapper flex flex-col gap-[0.94rem]">
    <!-- AccountNumber and Button wrapper-->
    <div
      class="grid grid-cols-1 gap-y-[0.94rem] md:flex md:items-center md:justify-between"
    >
      <div
        class="left flex flex-col gap-2 md:flex md:shrink-0 md:flex-row md:gap-[0.31rem]"
      >
        <a
          (click)="goBack()"
          class="mb-2 flex translate-y-[2px] cursor-pointer items-center"
          ><img src="assets/images/svgs/chevron-left.svg" alt=""
        /></a>

        <!-- style="max-width: 70%" -->
        <h1
          class="flex max-w-[400px] translate-y-1 text-2xl font-normal leading-[1.875rem] text-mango-black17"
        >
          {{ accountData?.corporate_kyc?.name }}
        </h1>
        <!-- <span class="hidden items-center md:inline-flex">-</span> -->
        <span class="hidden items-center md:inline-flex">—</span>

        <!-- copy icon and account number -->
        <div class="flex items-center gap-[0.31rem]">
          <span class="translate-y-[2px] text-lg text-mango-grey">{{
            accountData?.mango_account_number
          }}</span>
          <button
            class="active:scale-y-95"
            (click)="copy(accountData?.mango_account_number!)"
          >
            <img src="assets/images/svgs/copy.svg" alt="" />
          </button>
          @if (accountData?.is_blocked ) {
            <app-status-badge [status]="accountData?.is_blocked ? 'blocked':''"></app-status-badge>
          }
        </div>
      </div>

      <div class="flex justify-between gap-2">
        <app-dropdown
        class="w-fit justify-self-end text-nowrap"
        [id]="'addCustomerDropdown'"
        [buttonTitle]="'Export Report'"
        [actionList]="registerationDropdownActions()"
  
       
      ></app-dropdown>
      <button [routerLink]="editUrl" mango-button class="w-fit shrink-0">
        + Edit Company Profile
      </button>
      </div>
    </div>

    <!-- Corporate Account Details Card -->
    <div
      class="grid grid-cols-2 gap-y-3 rounded-lg border border-solid border-mango-gray200 bg-white px-[2.06rem] py-[1.88rem] shadow-[0_1px_2px_0_rgba(16,24,40,0.06)] md:flex md:justify-between"
    >
      <!-- Account Number -->
      <ul class="flex flex-col md:gap-[0.62rem]">
        <li class="text-sm font-normal text-mango-gray74">Account No.</li>
        <li class="text-base font-bold text-mango-darkBlack">
          {{ accountData?.mango_account_number || '-' }}
        </li>
      </ul>

      <!-- Account Type -->
      <ul class="flex flex-col md:gap-[0.62rem]">
        <li class="text-sm font-normal text-mango-gray74">Account Type</li>
        <li class="text-base font-bold text-mango-darkBlack">
          {{ accountData?.account_type | titlecase }}
        </li>
      </ul>

      <!-- Wallet Amount -->
      <ul class="flex flex-col md:gap-[0.62rem]">
        <li class="text-sm font-normal text-mango-gray74">Wallet Amount</li>
        <li class="text-base font-bold text-mango-darkBlack">
          {{ accountData?.wallet?.[0]?.wallet_balance || 0 | currency: 'NGN' }}
        </li>
      </ul>

      <!-- Portfolio Balance -->
      <ul class="flex flex-col md:gap-[0.62rem]">
        <li class="text-sm font-normal text-mango-gray74">
          Portfolio Balance (NGN)
        </li>
        <li class="text-base font-bold text-mango-darkBlack">
          ₦ {{ nairaPortfolioBalance / 100 | number: '1.2-2' }}
        </li>
      </ul>

      <!-- Portfolio Balance -->
      <ul class="flex flex-col md:gap-[0.62rem]">
        <li class="text-sm font-normal text-mango-gray74">
          Portfolio Balance (USD)
        </li>
        <li class="text-base font-bold text-mango-darkBlack">
          $ {{ dollarPortfolioBalance / 100 | number: '1.2-2' }}
        </li>
      </ul>

      <!-- Date Created -->
      <ul class="flex flex-col md:gap-[0.62rem]">
        <li class="text-sm font-normal text-mango-gray74">Date Created</li>
        <li class="text-base font-bold text-mango-darkBlack">
          {{ (accountData?.created_at | date) || '-' }}
        </li>
      </ul>

      <!-- KYC Tier -->
      <ul class="flex flex-col md:gap-[0.62rem]">
        <li class="text-sm font-normal text-mango-gray74">KYC Tier</li>
        <li class="text-base font-bold text-mango-darkBlack">
          <img src="assets/images/tier-{{accountData?.tier?.toLowerCase()}}.png" alt="Tier {{accountData?.tier}} Logo" class="w-[48px]" />
        </li>
      </ul>
    </div>

    <!-- Tabs -->
    @if (accountId) {
      <app-corporate-tabs [accountId]="accountId"></app-corporate-tabs>
    }
    <div class="projected-content__wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
} @else {
  <div class="flex flex-col">
    <ngx-skeleton-loader
      *ngFor="let in of [0, 1, 2, 3, 4, 5, 6]; let i = index"
      [theme]="{ height: '5rem', width: '100%' }"
    ></ngx-skeleton-loader>
  </div>
}
