<div
  class="backoffice-login__container flex h-full bg-white"
  style="
    background: url('/assets/images/bg-illustration.png') no-repeat;
    background-position: left bottom;
    background-size: 330px;
  "
>
  <img
    src="assets/images/mango-logo--tinified.png"
    alt="image 2"
    class="fixed z-10 ml-[3.31rem] mt-[54px] h-[38px] w-[140px] sm:flex"
  />
  <!-- left side -->
  <div
    class="left-side hidden w-[550px] flex-col pt-[3.37rem] sm:hidden lg:flex"
  >
    <div class="align-center right-[100px] mx-[59px] mt-[244px] flex flex-col">
      <p class="mb-[22px]">
        <span class="span text-4xl font-bold">Welcome to </span>
        <span class="text-4xl font-bold text-mango"
          >Mango Asset Management
        </span>
        <span class="span text-4xl font-bold">Back Office</span>
      </p>
      <p class="">
        Discover a world of investment opportunities tailored to meet your
        needs.
      </p>
    </div>

    <div>
      <img
        class="futurism-background absolute left-[0] top-0 h-full w-[550px] opacity-10"
        src="assets/images/futurism-background.png"
      />
    </div>
  </div>

  <!-- right side -->

  <div
    class="right__side flex w-[70%] flex-1 flex-col items-center bg-[#fcfcfc] pt-[7.69rem]"
  >
    <router-outlet></router-outlet>
  </div>
</div>
