<app-kyc-accordion accordionTitle="Next Of Kin" accordionKey="nextOfKin">
  <form class="" [formGroup]="nextOfKinForm">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- Title -->
      <div>
        <app-input-label
          labelFor="title"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Title</app-input-label
        >
        <select name="title" required base-select formControlName="title">
          <option value="" disabled selected>Title</option>
          <option *ngFor="let title of titles" [value]="title.key">
            {{ title.value }}
          </option>
        </select>
      </div>

      <!-- First Name -->
      <div>
        <app-input-label
          labelFor="first-name"
          [isRequired]="true"
          labelClass="label--bottom-margin"
          >First Name</app-input-label
        >

        <app-input
          placeholder="Enter First Name"
          [inputClass]="'input--padding'"
          formControlName="firstName"
          formControlName="first_name"
        ></app-input>
      </div>

      <!-- Last Name -->
      <div>
        <app-input-label
          labelFor="last-name"
          [isRequired]="true"
          labelClass="label--bottom-margin"
          >Last Name</app-input-label
        >

        <app-input
          placeholder="Enter Last Name"
          [inputClass]="'input--padding'"
          formControlName="lastName"
          formControlName="last_name"
        ></app-input>
      </div>

      <!-- Email -->
      <div>
        <app-input-label
          labelFor="email"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Email</app-input-label
        >
        <app-input
          placeholder="Enter email"
          inputClass="input--padding"
          formControlName="email"
        ></app-input>
      </div>

      <!-- Phone Number -->
      <div>
        <app-input-label
          labelFor="phone-number"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Phone number
        </app-input-label>
        <app-input
          placeholder="Enter Phone Number"
          [inputClass]="'input--padding'"
          formControlName="phone_number"
        ></app-input>
      </div>

      <!-- Gender -->
      <div>
        <app-input-label
          labelFor="gender"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Gender</app-input-label
        >
        <select name="gender" required base-select formControlName="gender">
          <option value="" disabled selected>Select Gender</option>
          <option *ngFor="let gender of genderList" [value]="gender.value">
            {{ gender.label }}
          </option>
        </select>
      </div>

      <!-- Date of Birth -->
      <div>
        <app-input-label
          labelFor="date of birth"
          labelClass="label--bottom-margin"
          >Date of Birth</app-input-label
        >

        <input
          type="date"
          input-date
          class="input--padding"
          formControlName="dob"
        />
      </div>

      <!-- Relationship -->
      <div>
        <app-input-label
          labelFor="kinRelationship"
          labelClass="label--bottom-margin"
          [isRequired]="true"
          >Relationship</app-input-label
        >
        <select
          name="kinRelationship"
          required
          base-select
          formControlName="relationship_with_nok"
        >
          <option value="" disabled selected>Relationship</option>
          <option *ngFor="let kin of kinRelationships" [value]="kin.value">
            {{ kin.label }}
          </option>
        </select>
      </div>

      <!-- Country -->
      <div>
        <app-input-label labelFor="country" labelClass="label--bottom-margin"
          >Country</app-input-label
        >
        <select
          name="country"
          required
          base-select
          formControlName="country"
          #country
          (change)="getCountryStates(country.value)"
        >
          <option value="" disabled selected>Select country</option>

          @for (country of countries; track $index) {
            <option [value]="country.name">{{ country.name }}</option>
          }
        </select>
      </div>

      <!-- State -->
      <div>
        <app-input-label labelFor="state" labelClass="label--bottom-margin"
          >State</app-input-label
        >
        <div
          [ngClass]="{
            'cursor-not-allowed': states.length === 0
          }"
        >
          <select
            name="state"
            required
            base-select
            formControlName="state"
            [ngClass]="{
              'pointer-events-none bg-gray-50 text-gray-500 ring-gray-200':
                states.length === 0
            }"
          >
            <option value="" disabled selected>Select State</option>
            @for (state of states; track $index) {
              <option [value]="state.name">{{ state.name }}</option>
            }
          </select>
        </div>
      </div>

      <!-- City/Town -->
      <div>
        <app-input-label labelFor="city-town" labelClass="label--bottom-margin"
          >City/Town</app-input-label
        >

        <app-input
          placeholder="Enter city"
          [inputClass]="'input--padding'"
          formControlName="city"
        ></app-input>
      </div>

      <!-- Address & House Number -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Address & House Number</app-input-label
        >

        <div>
          <textarea
            formControlName="address"
            textarea-style
            placeholder="Enter Address & House Number"
          >
          </textarea>
        </div>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': (isUpdating$ | async) || (isCreating$ | async)
        }"
      >
        <button
          type="button"
          mango-button
          [loading]="(isUpdating$ | async)!! || (isCreating$ | async)!!"
          [disabled]="(isUpdating$ | async)!! || (isCreating$ | async)!!"
          (click)="submitForm()"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span
            *ngIf="(isUpdating$ | async) || (isCreating$ | async)"
            spin
            [loadingText]="
              (hasNextOfKin$ | async) === true
                ? 'Updating Next of Kin Info...'
                : 'Saving Next of Kin Info...'
            "
          ></span>
          @if (!(isUpdating$ | async) && !(isCreating$ | async)) {
            {{
              (hasNextOfKin$ | async) === true
                ? 'Update Next of Kin'
                : 'Add Next of Kin'
            }}
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>
