<app-kyc-accordion accordionKey="bankDetails" accordionTitle="Bank Details">
  <fieldset>
    <legend class="text-sm font-semibold leading-6 text-gray-900">
      Account Type
    </legend>
    <p class="mt-1 text-sm leading-6 text-gray-600">
      Select the type of account you want to create/update.
    </p>

    <div
      class="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0"
    >
      <div class="flex items-center">
        <input
          id="local"
          name="bank-account-type"
          type="radio"
          (change)="toggleBankRegion($event, 'local')"
          [checked]="(bankRegion$ | async) === 'local'"
          class="h-4 w-4 border-gray-300 text-mango focus:ring-mango"
        />
        <label
          for="local"
          class="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >Local Account</label
        >
      </div>
      <div class="flex items-center">
        <input
          id="foreign"
          name="bank-account-type"
          type="radio"
          (change)="toggleBankRegion($event, 'foreign')"
          [checked]="(bankRegion$ | async) === 'foreign'"
          class="h-4 w-4 border-gray-300 text-mango focus:ring-mango"
        />
        <label
          for="foreign"
          class="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >Foreign Account</label
        >
      </div>
    </div>
  </fieldset>

  <hr class="my-10" />

  <form class="" [formGroup]="bankDetailsForm">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- Currency -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Currency</app-input-label
        >
        <select
          name="bank-branch"
          required
          base-select
          formControlName="currency"
        >
          <option value="" disabled selected>Currency</option>
          <option
            *ngFor="let currency of currencyList"
            [value]="currency.value"
          >
            {{ currency.label }}
          </option>
        </select>
      </div>
      <!-- Bank Name - Foreign -->
      <div [ngClass]="{ hidden: (bankRegion$ | async) === 'local' }">
        <app-input-label labelClass="label--bottom-margin"
          >Bank Name - Foreign</app-input-label
        >
        <app-input
          placeholder="Enter Foreign Bank Name"
          inputClass="input--padding"
          formControlName="bank_name"
        ></app-input>
      </div>

      <!-- Bank Name - Local -->
      <div [ngClass]="{ hidden: (bankRegion$ | async) === 'foreign' }">
        <app-input-label labelClass="label--bottom-margin"
          >Bank Name - Local</app-input-label
        >

        <!-- (change)="onBankSelectionChange($event)" -->
        <select
          name="bank-name"
          required
          base-select
          formControlName="bank_code"
          #bankName
        >
          <option value="" disabled selected>
            {{
              (isFetchingBankList$ | async) === true
                ? '...Fetching the list of banks'
                : banks.length === 0
                  ? 'No banks available'
                  : 'Select Bank'
            }}
          </option>
          @for (bank of banks; track $index) {
            <option value="{{ bank.bankCode }}">{{ bank.bankName }}</option>
          }
        </select>
      </div>

      <!-- Account number -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Account number</app-input-label
        >
        <!-- (ngModelChange)="onAccountNumberChange()" -->
        <app-input
          #bankAccountNo
          [placeholder]="
            !bankName.value ? 'Choose bank first' : 'Enter Account number'
          "
          inputClass="input--padding"
          formControlName="bank_account_number"
          (ngModelChange)="checkAccountNoInfo()"
          [isDisabled]="
            (!bankName.value && (bankRegion$ | async) === 'local') ||
            ((isFetchingAcctNo$ | async) === true &&
              (bankRegion$ | async) === 'local')
          "
        ></app-input>
      </div>

      <!-- Account name -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Account name</app-input-label
        >

        <app-input
          placeholder="Enter Account number to view account name"
          [placeholder]="
            (isFetchingAcctNo$ | async)
              ? '...Fetching Account Name'
              : 'Enter Account Number'
          "
          inputClass="input--padding"
          formControlName="bank_account_name"
          [isDisabled]="(bankRegion$ | async) === 'local'"
        ></app-input>
      </div>

      <!-- Bank Branch -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Bank Branch</app-input-label
        >
        <app-input
          placeholder="Enter Bank Branch"
          [inputClass]="'input--padding'"
          formControlName="bank_branch"
        >
        </app-input>
      </div>

      <!-- Bank Sort Code -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Bank Sort Code</app-input-label
        >
        <app-input
          placeholder="Enter Bank Sort Code"
          [inputClass]="'input--padding'"
          formControlName="bank_sort_code"
        >
        </app-input>
      </div>
    </div>

    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': (isUpdatingBankDetails$ | async)
        }"
      >
        <button
          type="button"
          mango-button
          [loading]="
            (isUpdatingBankDetails$ | async)!! ||
            (isAddingNewBankInfo$ | async)!!
          "
          [disabled]="
            (isUpdatingBankDetails$ | async)!! ||
            (isAddingNewBankInfo$ | async)!!
          "
          (click)="submitBankDetails()"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span
            *ngIf="
              (isUpdatingBankDetails$ | async) || (isAddingNewBankInfo$ | async)
            "
            spin
            [loadingText]="
              (isBankCreated$ | async) === true
                ? 'Updating Bank Info...'
                : 'Saving Bank Info...'
            "
          ></span>
          @if (
            !(isUpdatingBankDetails$ | async) && !(isAddingNewBankInfo$ | async)
          ) {
            {{
              (isBankCreated$ | async) === true
                ? 'Update Bank Info'
                : 'Add Bank Info'
            }}
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>
