import { Injectable, NgZone, inject } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { AuthActions } from './auth.actions';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { TOKEN } from 'app/app.constants';
import { NotificationFacade } from '@core/facades/notification.facade';

export interface AuthStateModel {
  token: string | null;
  loading: boolean | null;
  isSessionValid: boolean | null;
  tokenExpiredUrl: string | null;
  hasApiError: boolean | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    loading: false,
    isSessionValid: null,
    tokenExpiredUrl: null,
    hasApiError: null,
  },
})
@Injectable()
export class AuthState {
  authService = inject(AuthService);
  store = inject(Store);
  ngZone = inject(NgZone);
  router = inject(Router);
  toast = inject(NotificationFacade);

  @Action(AuthActions.Login)
  login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login) {
    this.store.dispatch(new AuthActions.SetLoadingStatus(true));

    const { email, password } = action.payload;
    this.authService.login({ email, password }).subscribe({
      next: (response) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.store.dispatch(new AuthActions.SetTokenValue(response.token));
        this.store.dispatch(new AuthActions.SetSessionStatus(true));
        ctx.patchState({
          token: response.token,
          loading: false,
          isSessionValid: true,
          hasApiError: false,
        });

        this.toast.success('Login successful');
        localStorage.setItem(TOKEN, response.token);

        this.ngZone.run(async () =>
          this.router.navigateByUrl('/admin/dashboard')
        );
      },
      error: (resp) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        ctx.patchState({
          token: null,
          loading: false,
          isSessionValid: false,
          tokenExpiredUrl: null,
          hasApiError: null,
        });
      },
    });
  }

  @Action(AuthActions.SetLoadingStatus)
  setLoadingStatus(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetLoadingStatus
  ) {
    ctx.patchState({
      loading: action.loading,
    });
  }

  @Action(AuthActions.SetSessionStatus)
  setSessionStatus(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetSessionStatus
  ) {
    ctx.patchState({
      token: null,
      isSessionValid: action.payload,
    });
  }

  @Action(AuthActions.SetTokenExpiredUrl)
  setTokenExpiredUrl(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetTokenExpiredUrl
  ) {
    ctx.patchState({
      tokenExpiredUrl: action.payload,
    });
  }

  @Action(AuthActions.ResetState)
  resetAuthState(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      token: null,
      loading: false,
      isSessionValid: false,
      tokenExpiredUrl: null,
      hasApiError: null,
    });
  }

  @Action(AuthActions.SetTokenValue)
  setTokenValue(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetTokenValue
  ) {
    ctx.patchState({
      token: action.token,
    });
  }
}
