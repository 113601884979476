import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '@core/services/report.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [
    SlideInRightModalComponent,
    InputComponent,
    InputLabelComponent,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ButtonDirective
  ],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent {
  reportService = inject(ReportService);
  router = inject(Router);
  subs: Subscription[] = [];
  fb = inject(FormBuilder);
  private activatedRoute = inject(ActivatedRoute);
  private change = inject(ChangeDetectorRef);

  isLoading: boolean = false;
  returnPathUrl: string = '';
  reportUrl!: string;
  reportTitle: string = "Wallet Report"


  dateRangeForm = this.fb.nonNullable.group({
    end_date: [''],
    start_date: [''],


  });

  ngOnInit(): void { }


  getWalletReport() {
    this.isLoading = true;
    const sub = this.reportService
      .getWalletReport(this.dateRangeForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.reportUrl = response.data.statement;
          this.change.detectChanges();


        },
        error: (resp) => {
        },
      });
    this.subs.push(sub);
  }

  openDocument(link: string) {
    window.open(link, '_blank');
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
