<div>
  @if (accountId) {
    <!-- Tabs for mobile -->
    <div class="scroll-wrapper w-full overflow-hidden">
      <div
        class="scroll-container box-border w-full overflow-x-auto whitespace-nowrap p-[10px] sm:hidden"
      >
        <div class="scroll-content inline-block">
          @for (tab of tabs; track $index) {
            <a
              [routerLink]="tab.route"
              [ngClass]="{
                'bg-mango-lightOrange text-mango': router.url.includes(tab.path)
              }"
              class="badge mr-[10px] inline-block cursor-pointer whitespace-nowrap text-wrap rounded-[100px] border border-solid border-gray-400 px-[21px] py-[9px] text-gray-500"
            >
              {{ tab.label | titlecase }}
            </a>
          }
        </div>
      </div>
    </div>
  }

  @if (accountId) {
    <!-- Tabs for desktop -->
    <div class="hidden w-fit rounded-lg bg-white sm:block">
      <nav class="flex w-fit space-x-4 p-[0.31rem]" aria-label="Tabs">
        @for (tab of tabs; track $index) {
          <a
            [routerLink]="tab.route"
            [ngClass]="{
              'bg-mango-lightOrange text-mango': router.url.includes(tab.path)
            }"
            class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
            ><span>{{ tab.label | titlecase }}</span></a
          >
        }
      </nav>
    </div>
  }
</div>
