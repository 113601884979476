import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { ExpiredSessionService } from '@core/services/expired-session.service';
import { Select, Store } from '@ngxs/store';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { AuthActions } from 'app/auth/store/auth.actions';
import { AuthSelectors } from 'app/auth/store/auth.selectors';
import { Observable, Subscription, find } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '@core/services/loading.service';
export interface UserInterface {
  email: string;
  token: string;
  username: string;
}
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    SpinDirective,
    ButtonDirective,
    InputLabelComponent,
    InputComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  isPasswordVisible: boolean = false;
  isLoading: boolean = false;
  tokenExpiredUrl!: string;
  subs: Subscription[] = [];

  expiredTokenUrl$!: Observable<string>;
  isLoading$!: Observable<boolean>;

  authService = inject(AuthService);
  toast = inject(ToastrService);
  router = inject(Router);
  cdr = inject(ChangeDetectorRef);
  route = inject(ActivatedRoute);
  expiredSessionService = inject(ExpiredSessionService);
  private store = inject(Store);
  private loadingService = inject(LoadingService);

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable('login');
  }

  ngOnInit(): void {}

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  login(): void {
    const email = this.form.controls['email'].value as string;
    const password = this.form.controls['password'].value as string;
    const sub = this.authService.login({ email, password }).subscribe({
      next: () => {
        this.toast.success('Successfully logged in');
        this.authService.startSession();
      },
      error: (err) => {
        this.toast.error('Failed to login');
      },
    });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
