<app-slide-in-right-modal
  [slideOpen]="true"
  returnPath="/admin/transactions/wallet"
  modalTitle="Approve Withdrawal Request"
>
  @if (!(isFetchingBeneficiary$ | async) && !(isFetchingBankName$ | async)) {
    <div class="page__container flex flex-col gap-5 pb-8">
      <p class="cta__text text-base text-mango-gray74">
        Are you sure you want to approve this Debit transaction? This action
        cannot be undone.
      </p>

      <h3>Recipient Details</h3>

      <div
        class="flex flex-col gap-3 rounded-2xl border border-solid border-[#f2e6d4] p-3"
      >
        <div class="flex justify-between">
          <div class="text-sm text-gray-500">Bank Name</div>

          @if ((isFetchingBankName$ | async) === true) {
            <div>
              <ngx-skeleton-loader
                [theme]="{ height: '2rem', width: '100%' }"
              ></ngx-skeleton-loader>
            </div>
          }

          <div class="text-base font-bold">
            {{ bankName }}
          </div>
        </div>
        <div class="flex justify-between">
          <div class="text-sm text-gray-500">Account Name</div>
          <div class="text-base font-bold">
            {{
              ((beneficiaryDetails$ | async)?.bank_account_name | titlecase) ||
                'No Account Name Provided'
            }}
          </div>
        </div>
        <div class="flex justify-between">
          <div class="text-sm text-gray-500">Account Number</div>
          <div class="text-base font-bold">
            {{ (beneficiaryDetails$ | async)?.bank_account_number }}
          </div>
        </div>
        <div class="flex justify-between">
          <div class="text-sm text-gray-500">Amount</div>
          <div class="text-base font-bold">
            {{
              (beneficiaryDetails$ | async)?.currency ||
                'Currency Not Available'
            }}
            {{
              ((transactionDetails$ | async)?.transaction_amount || 0) / 100
                | number
            }}
          </div>
        </div>
        <div class="flex justify-between">
          <div class="text-sm text-gray-500">Remark</div>
          <div class="text-base font-bold">
            {{ (transactionDetails$ | async)?.tran_remarks }}
          </div>
        </div>
      </div>

      <div class="flex justify-end space-x-6">
        <button
          type="button"
          mango-button
          (click)="isDialogOpenSubject$.next(true)"
          class="justify-self-end xl:w-fit"
        >
          Yes, Approve Transaction
        </button>
      </div>
    </div>
  } @else {
    <div class="flex flex-col">
      <div class="flex flex-col gap-3">
        <ngx-skeleton-loader
          [theme]="{ height: '2rem', width: '100%' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          [theme]="{ height: '7rem', width: '100%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="flex justify-end">
        <ngx-skeleton-loader
          [theme]="{ height: '2rem', width: '100px' }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  }
</app-slide-in-right-modal>

<!-- [isDialogLoading]="(isDialogOpen$ | async) === true" -->
<app-confirm-dialog
  confirmQuestion="Are you sure you want to approve this transaction? This action cannot be undone"
  confirmText="Yes, Approve Transaction"
  [isOpen]="(isDialogOpen$ | async)!!"
  dialogLoadingText="...Aproving Transaction"
  (close)="closeDialog()"
  (dialogAction)="approveTransaction()"
  [disableActionButton]="disableApproveAction"
  [isDialogLoading]="!!(isDialogLoading$ | async)"
>
</app-confirm-dialog>
