import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { Pagination } from '@core/models/pagination.model';
import { AccountManagementService } from '@core/services/account-management.service';
import { LoadingService } from '@core/services/loading.service';
import { MutualFundService } from '@core/services/mutual-fund.service';
import {
  DropdownComponent,
  DropDownModel,
} from '@shared/ui/dropdown/dropdown.component';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { IFILTER } from 'app/app.constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-consolidated-mutual-funds',
  standalone: true,
  imports: [
    DropdownComponent,
    CommonModule,
    TableSkeletonLoaderComponent,
    FiltersComponent,
    RouterOutlet,
    TableNavigatorComponent,
  ],
  templateUrl: './consolidated-mutual-funds.component.html',
  styleUrl: './consolidated-mutual-funds.component.scss',
})
export class ConsolidatedMutualFundsComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private mutualFundService = inject(MutualFundService);
  private change = inject(ChangeDetectorRef);
  private loadingService = inject(LoadingService);
  private notify = inject(NotificationFacade);
  private accountService = inject(AccountManagementService);

  totalCount: number = 0;
  subs: Subscription[] = [];
  isFetchingMutualFundsList$!: Observable<boolean>;
  actionList: DropDownModel[] = [];
  start_date: Date | undefined;
  end_date: Date | undefined;
  searchText: string = '';

  private mutualFundsListSubject = new BehaviorSubject<any[]>([]);
  mutualFundsList$ = this.mutualFundsListSubject.asObservable();

  filterStructure: IFILTER[] = [
    {
      filterKey: 'date',
      label: 'Date',
      type: 'date',
    },
  ];

  constructor() {
    this.isFetchingMutualFundsList$ = this.loadingService.getLoadingObservable(
      'get-mutual-funds-list'
    );
  }

  ngOnInit() {
    const sub = this.activatedRoute.queryParams.subscribe((params) => {
      this.searchText = params['search'] || '';
      this.start_date = params['start_date']
        ? new Date(params['start_date'])
        : undefined;
      this.end_date = params['end_date']
        ? new Date(params['end_date'])
        : undefined;

      if (this.start_date && this.end_date) {
        this.getMutualFundsList(
          new Pagination(),
          this.searchText,
          this.start_date,
          this.end_date
        );
      } else {
        this.getMutualFundsList(new Pagination(), this.searchText);
      }
    });
    this.subs.push(sub);
  }

  initDropdownList(param: any) {
    return (this.actionList = [
      {
        action: () => {
          this.transactionHistory(param.account_number);
        },
        title: 'Transaction History',
        show: true,
      },

      {
        action: () => {
          this.dividentHistory(param.account_number);
        },
        title: 'Dividends History',
        show: true,
      },
    ]);
  }

  transactionHistory(account_number: string) {
    this.router.navigate([
      `admin/transactions/mutual-funds/tab/consolidated/mutual-funds-history/${account_number}`,
    ]);
  }
  dividentHistory(account_number: string) {
    this.router.navigate([
      `admin/transactions/mutual-funds/tab/consolidated/dividends-history/${account_number}`,
    ]);
  }

  getMutualFundsList(
    page: Pagination,
    search_text: string = '',
    start_date?: Date,
    end_date?: Date
  ) {
    const sub = this.mutualFundService
      .getMutualFundsList(page, search_text, start_date, end_date)
      .subscribe({
        next: (res) => {
          this.totalCount = res.data.count;
          this.mutualFundsListSubject.next(res.data.data);
          // this.change.detectChanges();
        },
        error: () => {},
      });
    this.subs.push(sub);
  }

  viewAccountDetails(account_number: string) {
    const sub = this.accountService
      .getAccountDetails(account_number)
      .subscribe({
        next: (res) => {
          this.router.navigate([
            `/admin/accounts/${res?.data[0]?.account_type}/${res?.data[0]?.id}/portfolio`,
          ]);
        },
        error: () => {},
      });
    this.subs.push(sub);
  }

  navigate(currentPage: number) {
    const skip = currentPage * 10 - 10;
    this.getMutualFundsList(new Pagination({ skip }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
