import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { CorporateDoc } from '@core/models/admin/account';
import { InputFileComponent } from '@shared/ui/input-file/input-file.component';

import { HttpEvent, HttpEventType } from '@angular/common/http';
import { CentralDocumentUploadService } from '@core/services/central-document-upload.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { Subscription } from 'rxjs';
import { environment } from '@core/environments/environment';
import { GeneralFileUploadService } from '@core/services/general-file-upload.service';

interface IDocTitle {
  [key: string]: string;
}

const DocumentTitle: IDocTitle = {
  board_resolution: 'Board Resolution',
  incorporation_certificate:
    'Incorporation/Registration Certificate (True Copy)',
  memorandum: 'Memorandum and Article of  Association (True Copy)',
  form_co: 'Form CO2/CO7 (True Copy)',
  tax_id_cert: 'Tax Identification Certificate',
  scuml: 'SCUML (Optional)',
  reference_one: 'Reference - 1',
  reference_two: 'Reference - 2',
  general_kyc: 'General KYC',
};

interface DocumentDetails {
  id: string;
  inputLabel: string;
  isUploaded: boolean;
  key: string;
  fileLink: string | null;
  documentFile: File | null;
  docIdentifier: string;
  isApproved: boolean;
  maxFileSize: number;
  acceptedFileTypes: string[];
}
interface DocList {
  [key: string]: DocumentDetails;
}

@Component({
  selector: 'app-edit-documents',
  standalone: true,
  imports: [CommonModule, KycAccordionComponent, InputFileComponent],
  templateUrl: './edit-documents.component.html',
  styleUrl: './edit-documents.component.scss',
})
export class EditDocumentsComponent implements OnInit, OnDestroy {
  @Input() kycDocuments!: CorporateDoc[];

  documentService = inject(CentralDocumentUploadService);
  cdRef = inject(ChangeDetectorRef);
  route = inject(ActivatedRoute);
  toast = inject(NotificationFacade);
  generalFileUploadService = inject(GeneralFileUploadService);

  public readonly originalOrder = (): number => 0;
  DocumentTitle = DocumentTitle;
  uploadInProgress: boolean = false;
  accountId!: string;
  subs: Subscription[] = [];
  acceptedFileTypes: string[] = ['image/png', 'image/jpeg', 'application/pdf'];
  maxFileSize: number = 2000;

  documentList: DocList = {
    boardResolution: {
      id: 'boardResolutionId',
      inputLabel: 'Board Resolution',
      isUploaded: false,
      key: 'boardResolution',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'board_resolution',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    incorporationCertificate: {
      id: 'incorporationCertificateId',
      inputLabel: 'Incorporation/Registration Certificate (True Copy)',
      isUploaded: false,
      key: 'incorporationCertificate',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'incorporation_certificate',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    memorandum: {
      id: 'memorandumId',
      inputLabel: 'Memorandum and Article of  Association (True Copy)',
      isUploaded: false,
      key: 'memorandum',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'memorandum',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    formCo: {
      id: 'formCoId',
      inputLabel: 'Form CO2/CO7 (True Copy)',
      isUploaded: false,
      key: 'formCo',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'form_co',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    taxIdCert: {
      id: 'taxIdCertId',
      inputLabel: 'Tax Identification Certificate',
      isUploaded: false,
      key: 'taxIdCert',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'tax_id_cert',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    scuml: {
      id: 'scumlId',
      inputLabel: 'SCUML (Optional)',
      isUploaded: false,
      key: 'scuml',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'scuml',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    referenceOne: {
      id: 'referenceOneId',
      inputLabel: 'Reference - 1',
      isUploaded: false,
      key: 'referenceOne',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'reference_one',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    referenceTwo: {
      id: 'referenceTwoId',
      inputLabel: 'Reference - 2',
      isUploaded: false,
      key: 'referenceTwo',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'reference_two',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    generalKyc: {
      id: 'generalKycId',
      inputLabel: `General KYC - 25MB (PDF)`,
      isUploaded: false,
      key: 'generalKyc',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'general_kyc',
      isApproved: false,
      maxFileSize: 25000,
      acceptedFileTypes: ['application/pdf'],
    },
  };

  ngOnInit(): void {
    this.setAccountId();

    this.checkIsDocumentApproved();
  }

  setAccountId() {
    this.accountId = this.route.snapshot.params['accountId'];
  }

  checkIsDocumentApproved() {
    if (this.kycDocuments) {
      this.kycDocuments.forEach((doc, index) => {
        if (this.documentList[this.titleCase(doc.document_type)]) {
          this.documentList[this.titleCase(doc.document_type)].isApproved =
            doc.status === 'approved' ? true : false;
        }
      });
    }
  }

  titleCase(str: string) {
    return str
      .replace(/^[-_]*(.)/, (_, c) => c.toLowerCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => '' + c.toUpperCase()); // First char after each -/_
  }

  openDocument(link: string | null) {
    window.open(`${environment.FILE_BASE_URL}${link}`, '_blank');
  }

  getUploadedFile(fileList: FileList | null, documentType: string): void {
    if (fileList) {
      this.documentList[documentType].isUploaded = true;
      this.documentList[documentType].documentFile = fileList[0];
      // this.uploadDocument(documentType);
      documentType === 'generalKyc'
        ? this.uploadGeneralFile()
        : this.uploadDocument(documentType);
    } else {
      this.documentList[documentType].isUploaded = false;
      this.documentList[documentType].documentFile = null;
    }
  }

  uploadDocument(docType: string) {
    const formData = new FormData();
    const documentFile = this.documentList[docType].documentFile;
    if (documentFile) {
      formData.append('file', documentFile);
      formData.append(
        'document_type',
        this.documentList[docType].docIdentifier
      );
      formData.append('account_id', this.accountId);
    }

    const sub = this.documentService.uploadKycDocument(formData).subscribe({
      next: (response: HttpEvent<any>) => {
        this.uploadInProgress = false;
        this.cdRef.detectChanges();

        if (response.type === HttpEventType.Response) {
          this.toast.success(
            `Successfully uploaded '${this.documentList[docType].inputLabel}' Document`
          );
        }
      },
      error: (response) => {
        this.toast.error('Failed to upload Document');
      },
    });

    this.subs.push(sub);
  }

  async uploadGeneralFile(): Promise<void> {
    const generalKey = 'generalKyc';

    if (!this.documentList[generalKey].documentFile) {
      this.toast.error('No file selected');
      return;
    }

    try {
      // Get the presigned URL from the service
      const presignedUrl = await this.generalFileUploadService.getPresignedUrl(
        this.accountId
      );

      const formData = new FormData();
      const documentFile = this.documentList[generalKey].documentFile;
      if (documentFile) {
        formData.append('file', documentFile);
        formData.append(
          'document_type',
          this.documentList[generalKey].docIdentifier
        );
        formData.append('account_id', this.accountId);
      }

      // Use the presigned URL to upload the file
      await this.generalFileUploadService.uploadFile(presignedUrl, formData);
      window.location.reload();
      this.toast.success(`Successfully uploaded General KYC Document`);
    } catch (err) {
      this.toast.error(
        'Failed to upload the General KYC Document. Please try again.'
      );
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
