import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { EditLocationDetailsComponent } from '@accounts/individual-account/edit-account/pages/edit-location-details/edit-location-details.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { JointLocationkyc } from '@core/models/admin/account/joint';
import { JointRegistrationService } from '@core/services/joint-registration.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';
import { COUNTRIES, State, States2 } from '@core/data/countries-states';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@core/services/loading.service';
import { NotificationFacade } from '@core/facades/notification.facade';

@Component({
  selector: 'app-edit-joint-location',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    InputDateDirective,
    BaseSelectDirective,
    SpinDirective,
  ],
  templateUrl: './edit-joint-location.component.html',
  styleUrl: './edit-joint-location.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointLocationComponent implements OnDestroy {
  @Input() locationDetails!: JointLocationkyc;
  @Input() jointAccountId!: string; // for new location

  locationForm!: FormGroup;
  states: (State | States2)[] = [];
  countries = COUNTRIES.data;
  isUpdating$!: Observable<boolean>;
  isCreating$!: Observable<boolean>;
  subs: Subscription[] = [];

  isLocationCreatedSubject$ = new BehaviorSubject<boolean>(false);
  accountIdSubject$ = new BehaviorSubject<string | null>(null);
  locationIdSubject$ = new BehaviorSubject<string | null>(null);

  isLocationCreated$ = this.isLocationCreatedSubject$.asObservable();

  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private loadingService = inject(LoadingService);
  private toast = inject(NotificationFacade);
  private jointRegService = inject(JointRegistrationService);

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      URL_KEYS.JOINT.CREATE_KYC_LOCATION
    );
    this.isCreating$ = this.loadingService.getLoadingObservable(
      URL_KEYS.JOINT.UPDATE_KYC_LOCATION
    );
  }

  ngOnInit(): void {
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(this.route.snapshot.params['accountId']);
    }

    this.createForm();
    if (this.locationForm) {
      this.setFormValues();
    }
  }

  saveLocation() {
    this.isLocationCreatedSubject$.value
      ? this.updateLocation()
      : this.createNewLocation();
  }

  updateLocation() {
    this.locationForm.removeControl('joint_account_id');
    this.locationForm.removeControl('account_id');

    const sub = this.jointRegService
      .updateLocationDetails(this.locationForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated Location details');
        },
        error: () => {
          this.toast.error('Failed to update Location details');
        },
      });

    this.subs.push(sub);
  }

  createNewLocation() {
    const sub = this.jointRegService
      .addCustomerLocation(this.locationForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.isLocationCreatedSubject$.next(true);
          this.locationIdSubject$.next(response.id);
          this.locationForm?.addControl(
            'id',
            new FormControl(this.locationIdSubject$.value)
          );

          this.toast.success('Successfully added Location details');
        },
        error: () => {
          this.toast.error('Failed to add Location details');
        },
      });

    this.subs.push(sub);
  }

  createForm() {
    if (!this.locationDetails) {
      this.locationForm = this.fb.nonNullable.group({
        country: [''],
        state: [''],
        city: [''],
        // house_number: [''],
        address: [''],
        citizenship_status: [''],
        second_country: [''],
        residency_status: [''],
        residence_permit: [''],
        permit_issue_date: [new Date()],
        permit_expiry_date: [new Date()],
        joint_account_id: [''],
        account_id: [''],
      });
    } else {
      this.locationForm = this.fb.nonNullable.group({
        id: [''],
        country: [''],
        state: [''],
        city: [''],
        // house_number: [''],
        address: [''],
        citizenship_status: [''],
        second_country: [''],
        residency_status: [''],
        residence_permit: [''],
        permit_issue_date: [new Date()],
        permit_expiry_date: [new Date()],
      });
    }
  }

  setFormValues() {
    if (!this.locationDetails) {
      this.locationForm.patchValue({
        account_id: this.accountIdSubject$.value,
        joint_account_id: this.jointAccountId,
      });

      return;
    }

    this.isLocationCreatedSubject$.next(true);
    this.locationIdSubject$.next(this.locationDetails.id);

    if (this.locationDetails.country) {
      this.getCountryStates(this.locationDetails.country);
    }

    this.locationForm.patchValue({
      id: this.locationIdSubject$.value,
      country: this.locationDetails.country || '',
      state: this.locationDetails.state || '',
      city: this.locationDetails.city || '',
      address: this.locationDetails.address || '',
      citizenship_status: this.locationDetails.citizenship_status || '',
      second_country: this.locationDetails.second_country || '',
      residency_status: this.locationDetails.residency_status || '',
      residence_permit: this.locationDetails.residence_permit || '',
    });

    this.locationForm.controls['permit_issue_date'].setValue(
      formatDate(this.locationDetails.permit_issue_date, 'yyyy-MM-dd', 'en')
    );
    this.locationForm.controls['permit_expiry_date'].setValue(
      formatDate(this.locationDetails.permit_expiry_date, 'yyyy-MM-dd', 'en')
    );
  }

  getCountryStates(countryName: string) {
    this.states = this.countries.filter(
      (country) => country.name === countryName
    )[0].states;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
