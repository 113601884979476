<app-kyc-accordion
  accordionKey="documentDetails"
  accordionTitle="Document Details"
>
  <p *ngIf="kycDocuments.length === 0" class="mb-10">
    Upload your KYC Documents:
  </p>
  <div
    *ngIf="kycDocuments.length > 0"
    class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5"
  >
    @for (doc of kycDocuments; track $index) {
      <button
        (click)="openDocument(doc.link)"
        [title]="DocumentTitle[doc.document_type]"
        class="flex cursor-pointer items-center gap-3 rounded-lg bg-mango-lightOrange px-[0.88rem] py-3 shadow-sm"
      >
        <img src="assets/images/svgs/file-text.svg" alt="" class="h-6 w-6" />
        <span
          class="flex flex-col overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium"
        >
          <span
            [ngClass]="{
              'bg-green-100 text-mango-pineGreen': doc.status === 'approved',
              'bg-red-100 text-mango-sharpRed':
                doc.status === 'rejected' || doc.status === 'pending'
            }"
            class="w-fit items-center rounded-full px-2 py-1 text-xs font-medium"
          >
            {{ doc.status }}
          </span>
          @if (DocumentTitle[doc.document_type]) {
            <span class="overflow-hidden text-ellipsis">
              {{ DocumentTitle[doc.document_type] }}</span
            >
          } @else {
            <span class="overflow-hidden text-ellipsis">
              {{ doc.document_type }}</span
            >
          }
        </span>

        <div class="ml-auto inline-flex gap-2">
          <span>View</span>
          <!-- Heroicons: arrow-top-right-on-square -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-5 w-5 shrink-0"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </div>
      </button>
    }
  </div>

  <hr *ngIf="kycDocuments.length > 0" class="my-10" />

  <div
    class="mb-3 inline-flex items-center gap-[0.9rem] rounded-xl bg-mango-floralWhite px-[0.6rem] py-[0.9rem]"
  >
    <img
      src="assets/images/svgs/info-circle--yellow.svg"
      alt=""
      class="h-[1.5625rem] w-[1.5625rem] flex-1"
    />
    <p class="text-sm leading-[1.3125rem] text-mango-grey">
      Document should not be larger than 2MB. File types accepted include PDF,
      JPEG and PNG
    </p>
  </div>

  <div
    *ngIf="kycDocuments"
    class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5"
  >
    @for (
      document of documentList | keyvalue: originalOrder;
      track document.key;
      let i = $index
    ) {
      <app-input-file
        *ngIf="document.value.isApproved === false"
        [inputId]="document.value.id"
        [inputLabel]="document.value.inputLabel"
        (uploadedFileEvent)="getUploadedFile($event, document.value.key)"
        [maxFileSize]="document.value.maxFileSize"
        [acceptedFileTypes]="document.value.acceptedFileTypes"
      >
      </app-input-file>
    }
  </div>
</app-kyc-accordion>
