import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tooltip-overlay',
  standalone: true,
  imports: [
    CommonModule,
    OverlayModule
  ],
  templateUrl: './tooltip-overlay.component.html',
  styleUrl: './tooltip-overlay.component.scss'
})
export class TooltipOverlayComponent {
  @Input() displayValue!: string | null; // Rounded value
  @Input() fullValue!: string | null; // Full unrounded value
  @Input() mode: 'default' | 'table' = 'default'; 

  showOverlay = false; // Controls tooltip visibility

  @ViewChild('overlayOrigin') overlayOrigin!: ElementRef;
}
