<app-kyc-accordion
  accordionTitle="Employment Details"
  accordionKey="employmentDetails"
  [isKycComplete]="isKycComplete"
>
  <div *ngIf="!employmentDetails">
    <p>No employment details</p>
  </div>
  <div
    *ngIf="employmentDetails"
    class="grid grid-cols-1 gap-y-5 md:grid-cols-4"
  >
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Employment Status</li>
      <li class="text-sm font-light">
        {{ (employmentDetails.status | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Employer's Name</li>
      <li class="text-sm font-light">
        {{ (employmentDetails.name | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Nature of Business</li>
      <li class="text-sm font-light">
        {{ (employmentDetails.nature_of_business | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Address</li>
      <li class="text-sm font-light">
        {{ employmentDetails.address || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Country</li>
      <li class="text-sm font-light">
        {{ (employmentDetails.country | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">State</li>
      <li class="text-sm font-light">
        {{ (employmentDetails.state | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">City/ Town</li>
      <li class="text-sm font-light">
        {{ (employmentDetails.city | titlecase) || '—' }}
      </li>
    </ul>

    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Office Phone Number</li>
      <li class="text-sm font-light">
        {{ employmentDetails.phone_number || '—' }}
      </li>
    </ul>
  </div>
</app-kyc-accordion>
