<div class="flex grid-cols-2 flex-col gap-4 md:grid md:justify-between">
  <h2
    class="text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
  >
    Approvals
  </h2>
</div>

<div class="mt-[0.94rem] grid gap-5 lg:grid-cols-3">
  <div class="rounded-md bg-gray-200 p-[38px] shadow-sm">
    <!-- Your chart component goes here -->
    <div>
      <p class="text-">Total Requests</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">{{ totalReq || 0 }}</h1>
    </div>
  </div>
  <div class="rounded-md bg-[#fff6e8] p-[38px] shadow-sm">
    <!-- Your chart component goes here -->
    <div>
      <p>Approved Documents</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">
        {{ totalApproved }}
      </h1>
    </div>
  </div>
  <div class="flex items-center rounded-md bg-gray-200 p-[38px] shadow-sm">
    <!-- Your chart component goes here -->

    <div>
      <div>
        <p>Pending Requests</p>
      </div>
      <div>
        <h1 class="number-stat-font text-4xl">{{ totalPending }}</h1>
      </div>
    </div>
  </div>
</div>

<!-- Search Input and Filters -->
<app-filters
[filters]="filterStructure">
</app-filters>

<!-- Client Table -->

<div
  style="
    border-radius: 0.5rem;
    border: 1px solid var(--Gray-200, #eaecf0);
    background: var(--Base-White, #fff);
    margin-top: 0.94rem;
  "
>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="flex border-b-2 border-gray-200 bg-gray-100 px-5 py-4 text-left text-xs font-semibold text-gray-600"
              >
                S/N
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99998 3.33301V12.6663M7.99998 12.6663L12.6666 7.99967M7.99998 12.6663L3.33331 7.99967"
                    stroke="#667085"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-2 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Date Created
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-2 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                User / Organisation
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-2 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Document Type
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-2 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Status
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-2 text-left text-xs font-semibold tracking-wider text-gray-600"
              ></th>
            </tr>
          </thead>
          <tbody>
            @if (isLoading) {
              <tr>
                <td colspan="6" class="text-center py-4">
                  <div class="flex justify-center items-center">
                    <span class ="loading-spinner"></span>
                    <span class="ml-2">Loading...</span>
                  </div>
                </td>
              </tr>
            } @else if (documentList.length < 1) {
              <tr>
                <td colspan="6" class="text-center py-4">No document found!</td>
              </tr>
            } @else {
              @for (
                doc of documentList;
                track doc.id;
                let i = $index
              ) {
                <tr>
                  <td
                    class="border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <p>{{ i + 1 }}</p>
                  </td>
                  <td
                    class="border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ doc.created_at | date: 'medium' }}
                    </p>
                  </td>
                  <td
                    class="border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <p class="whitespace-no-wrap text-gray-900">
                      {{
                        doc.account?.corporate_kyc
                          ? doc.account.corporate_kyc.name
                          : (doc.account.user_profile.first_name +
                              ' ' +
                              doc.account.user_profile.last_name | titlecase)
                      }}
                    </p>
                  </td>
                  <td
                    class="flex gap-2 border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <img
                      src="assets/images/svgs/file-attachment.svg"
                      alt="File Attachment Icon"
                      class="h-[20px] flex-none"
                    />
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ doc.document_type.replace('_', ' ') | titlecase }}
                    </p>
                  </td>

                  <td
                    class="border-b border-gray-200 bg-white px-2 py-2 text-xs sm:px-3 sm:py-3 sm:text-sm md:px-4 md:py-4 lg:px-5"
                  >
                    <span
                      class="relative inline-block px-2 py-1 font-semibold leading-tight text-green-900 sm:px-2"
                    >
                      <span
                        aria-hidden
                        class="absolute inset-0 {{
                          doc.status === 'approved'
                            ? 'bg-green-200'
                            : 'bg-red-300'
                        }} opacity-50 rounded-full"
                      ></span>
                      <span
                        class="relative flex  {{
                          doc.status === 'approved'
                            ? 'text-[#027A48]'
                            : 'text-red-700'
                        }} sm:text-[11px]"
                      >
                        {{ doc.status | uppercase }}</span
                      >
                    </span>
                  </td>

                  <td
                    class="relative whitespace-nowrap border-b py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <div
                      class="relative inline-block w-fit cursor-pointer border-gray-200 bg-white text-left"
                    >
                      <app-dropdown
                        [id]="doc.id"
                        [actionList]="initDropdownList(doc)"
                      >
                      </app-dropdown>
                    </div>
                  </td>
                </tr>
              }
            }
          </tbody>
        </table>
        <app-table-navigator
          [total]="totalCount"
          (onNavigation)="navigate($event)"
        ></app-table-navigator>
      </div>
    </div>
  </div>
</div>

<div class="fixed right-0 top-0"></div>
