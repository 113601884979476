import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExpiredSessionService {
  expiredSessionUrl = signal<string | null>(null);

  setExpiredSessionUrl(url: string | null) {
    this.expiredSessionUrl.set(url);
  }
}
