import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { MutualFundService } from '@core/services/mutual-fund.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { BehaviorSubject, Subscribable, Subscription } from 'rxjs';

@Component({
  selector: 'app-mutual-funds-dividend',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    InputLabelComponent,
    InputComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonDirective,
  ],
  templateUrl: './mutual-funds-dividend.component.html',
  styleUrl: './mutual-funds-dividend.component.scss',
})
export class MutualFundsDividendComponent implements OnInit, OnDestroy {
  fb = inject(FormBuilder);
  mutualFundService = inject(MutualFundService);
  router = inject(Router);
  notify = inject(NotificationFacade);
  changes = inject(ChangeDetectorRef);
  activatedRoute = inject(ActivatedRoute);

  subs: Subscription[] = [];
  formType = this.activatedRoute.snapshot.params['type'];
  private dailyDividendSubject = new BehaviorSubject<any[]>([]);
  dailyDividend$ = this.dailyDividendSubject.asObservable();

  mutualFundsDividendForm = this.fb.nonNullable.group({
    date: ['', Validators.required],
    amount: [0, Validators.required],
    yield: [0, Validators.required],
  });

  selectedDate = this.mutualFundsDividendForm.get('date')?.value;

  ngOnInit(): void {
    let paramData: any = null;

    // Access state from history
    const state = history.state;
    paramData = state.paramData;

    if (this.formType === 'Edit') {
      this.mutualFundsDividendForm.patchValue({
        amount: paramData ? paramData.dividendAmount / 100 : 0,
        date: paramData
          ? paramData.dayDate.split('T')[0]
          : new Date().toISOString().split('T')[0],
        yield: paramData ? paramData.yield : 0,
      });
    } else {
      this.mutualFundsDividendForm.patchValue({
        date: new Date().toISOString().split('T')[0],
      });
    }
  }

  addDividend() {
    const apiCall =
      this.formType === 'Create'
        ? this.mutualFundService.addDividend
        : this.mutualFundService.editDividend;

    const formData = this.mutualFundsDividendForm.getRawValue();

    // Multiply amount by 100
    formData.amount = parseInt(`${formData.amount * 100}`);

    const sub = apiCall.call(this.mutualFundService, formData).subscribe({
      next: () => {
        this.notify.success(
          `Dividend ${this.formType.toLowerCase()}d successfully`
        );
        this.changes.detectChanges();
        this.router.navigateByUrl(
          'admin/transactions/mutual-funds/tab/consolidated'
        );
      },
      error: () =>
        this.notify.error(`Failed to ${this.formType.toLowerCase()} dividend`),
    });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
