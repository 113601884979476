@if (
  !(isFetchingCompanyDetails$ | async) && !(isFetchingSignatories$ | async)
) {
  <div *ngIf="companyDetails" class="edit-account__wrapper flex flex-col">
    <div class="inline-flex gap-[0.62rem]">
      <a (click)="goBack()" class="mb-2 translate-y-[2px] cursor-pointer"
        ><img src="assets/images/svgs/chevron-left.svg" alt=""
      /></a>

      <h1
        class="translate-y-1 text-2xl font-normal leading-[1.3125rem] text-mango-black17"
      >
        {{ companyDetails.corporate_kyc?.name }}
      </h1>
    </div>

    <div class="tab__wrapper mb-6 mt-[0.94rem]">
      <!-- Tabs for desktop -->
      <div class="hidden w-fit rounded-lg bg-white sm:block">
        <nav class="flex w-fit space-x-4 p-[0.31rem]" aria-label="Tabs">
          <a
            [routerLink]="userDetailsUrl"
            routerLinkActive="bg-mango-lightOrange text-mango"
            class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
            ><span>Company Details</span></a
          >
          <a
            [routerLink]="accountUrl"
            routerLinkActive="bg-mango-lightOrange text-mango"
            class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
            ><span>Accounts</span></a
          >
        </nav>
      </div>
    </div>

    <div class="edit-form__wrapper grid grid-cols-1 divide-y bg-white">
      <div class="hidden"></div>
      <app-edit-company-details
        [companyDetails]="companyDetails"
      ></app-edit-company-details>
      <app-edit-bank-details
        [bankDetails]="bankDetails"
      ></app-edit-bank-details>
      <app-edit-director-details
        [directorDetails]="directorDetails"
      ></app-edit-director-details>
      <app-edit-signatory-details
        [signatoriesList]="signatoriesList"
      ></app-edit-signatory-details>
      <app-edit-documents [kycDocuments]="documents"></app-edit-documents>
      <div></div>
    </div>
  </div>
} @else {
  <div class="flex flex-col justify-start">
    <ngx-skeleton-loader
      [theme]="{ height: '2rem', width: '200px' }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{ height: '2rem', width: '200px' }"
    ></ngx-skeleton-loader>
  </div>
  <div class="flex flex-col">
    <ngx-skeleton-loader
      *ngFor="let in of [0, 1, 2, 3, 4, 5, 6]; let i = index"
      [theme]="{ height: '5rem', width: '100%' }"
    ></ngx-skeleton-loader>
  </div>
}
