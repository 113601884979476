import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@core/services/loading.service';
import { MutualFundService } from '@core/services/mutual-fund.service';
import { FilterDropdownItemComponent } from '@shared/ui/filter-dropdown-item/filter-dropdown-item.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-mutual-funds-history',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    FilterDropdownItemComponent,
    TableSkeletonLoaderComponent,
  ],
  templateUrl: './mutual-funds-history.component.html',
  styleUrl: './mutual-funds-history.component.scss',
})
export class MutualFundsHistoryComponent implements OnInit, OnDestroy {
  mutualFundService = inject(MutualFundService);
  change = inject(ChangeDetectorRef);
  activatedRoute = inject(ActivatedRoute);
  loadingService = inject(LoadingService);

  isFetchingTransactionHistoryList$!: Observable<boolean>;
  private transactionHistoryListSubject = new BehaviorSubject<any>(null);
  transactionHistoryList$ = this.transactionHistoryListSubject.asObservable();
  accountNumber = this.activatedRoute.snapshot.params['account_number'];

  subs: Subscription[] = [];

  constructor() {
    this.isFetchingTransactionHistoryList$ =
      this.loadingService.getLoadingObservable(
        'get-mutual-funds-transactions-list'
      );
  }

  ngOnInit() {
    this.getMutualFundsList();
  }

  getMutualFundsList() {
    const sub = this.mutualFundService
      .getIndividualMutualFundsTransactionsList(this.accountNumber)
      .subscribe({
        next: (res) => {
          this.transactionHistoryListSubject.next(res.data.data);
          this.change.detectChanges();
        },
        error: () => {},
      });
    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
