@if (!(isLoading$ | async)) {
  <div *ngIf="accountData" class="edit-account__wrapper flex flex-col">
    <div class="inline-flex gap-[0.62rem]">
      <a (click)="goBack()" class="mb-2 translate-y-[2px] cursor-pointer"
        ><img src="assets/images/svgs/chevron-left.svg" alt=""
      /></a>

      <h1
        class="translate-y-1 text-2xl font-normal leading-[1.3125rem] text-mango-black17"
      >
        {{ accountData.user.first_name | titlecase }}
        {{ accountData.user.last_name | titlecase }}
      </h1>
    </div>

    <div class="tab__wrapper mb-6 mt-[0.94rem]">
      <!-- Tabs for desktop -->
      <div class="hidden w-fit rounded-lg bg-white sm:block">
        <nav class="flex w-fit space-x-4 p-[0.31rem]" aria-label="Tabs">
          <a
            [routerLink]="userDetailsUrl"
            routerLinkActive="bg-mango-lightOrange text-mango"
            class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
            ><span>User Details</span></a
          >
          <a
            [routerLink]="accountsUrl"
            routerLinkActive="bg-mango-lightOrange text-mango"
            class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
            ><span>Accounts</span></a
          >
        </nav>
      </div>
    </div>

    <div class="edit-form__wrapper grid grid-cols-1 divide-y bg-white">
      @if (accountData) {
        <div class="hidden"></div>
        <app-edit-customer-information
          [customerInformation]="customerDetails"
        ></app-edit-customer-information>
        <app-edit-location-details
          [locationDetails]="locationDetails"
        ></app-edit-location-details>
        <app-edit-means-of-id
          [meansOfId]="meansOfIdDetails"
        ></app-edit-means-of-id>
        <app-edit-employment-details
          [employmentDetails]="employmentDetails"
        ></app-edit-employment-details>
        <app-edit-next-of-kin
          [nextOfKinDetails]="nextOfKinDetails"
        ></app-edit-next-of-kin>
        <app-edit-bank-details
          [bankDetails]="bankDetails"
        ></app-edit-bank-details>
        <app-edit-documents
          [kycDocuments]="documentDetails"
        ></app-edit-documents>
        <div></div>
      }
    </div>
  </div>
} @else {
  <div class="flex flex-col justify-start">
    <ngx-skeleton-loader
      [theme]="{ height: '2rem', width: '200px' }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{ height: '2rem', width: '200px' }"
    ></ngx-skeleton-loader>
  </div>
  <div class="flex flex-col">
    <ngx-skeleton-loader
      *ngFor="let in of [0, 1, 2, 3, 4, 5, 6]; let i = index"
      [theme]="{ height: '5rem', width: '100%' }"
    ></ngx-skeleton-loader>
  </div>
}
