<app-kyc-accordion
  accordionTitle="Customer Information"
  accordionKey="customerInformation"
>
  <app-tabs [tabs]="profileTabs" [initialSelectedTab]="0"> </app-tabs>

  <ng-template #profileOneTemplate>
    <form class="" [formGroup]="formAccountOne">
      <div
        class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5"
      >
        <!-- Title -->
        <div>
          <app-input-label labelFor="title" labelClass="label--bottom-margin"
            >Title</app-input-label
          >
          <select name="title" required base-select formControlName="title">
            <option value="" disabled selected>Title</option>
            <option *ngFor="let title of titles" [value]="title.key">
              {{ title.value }}
            </option>
          </select>
        </div>

        <!-- First Name -->
        <div>
          <app-input-label
            labelFor="first-name"
            labelClass="label--bottom-margin"
            >First Name</app-input-label
          >

          <app-input
            placeholder="Enter First Name"
            [inputClass]="'input--padding'"
            formControlName="first_name"
          ></app-input>
        </div>

        <!-- Last Name -->
        <div>
          <app-input-label
            labelFor="last-name"
            labelClass="label--bottom-margin"
            >Last Name</app-input-label
          >

          <app-input
            placeholder="Enter Last Name"
            [inputClass]="'input--padding'"
            formControlName="last_name"
          ></app-input>
        </div>

        <!-- Middle Name -->
        <div>
          <app-input-label
            labelFor="last-name"
            labelClass="label--bottom-margin"
            >Middle Name</app-input-label
          >

          <app-input
            placeholder="Enter Middle Name"
            [inputClass]="'input--padding'"
            formControlName="middle_name"
          ></app-input>
        </div>

        <!-- Gender -->
        <div>
          <app-input-label labelFor="gender" labelClass="label--bottom-margin"
            >Gender</app-input-label
          >
          <select name="gender" required base-select formControlName="gender">
            <option value="" disabled selected>Select status</option>
            <option *ngFor="let gender of genderList" [value]="gender.value">
              {{ gender.label }}
            </option>
          </select>
        </div>

        <div>
          <app-input-label labelFor="email" labelClass="label--bottom-margin"
            >Email</app-input-label
          >
          <app-input
            placeholder="Enter email"
            inputClass="input--padding"
            formControlName="email"
          ></app-input>
        </div>

        <!-- Phone Number 1 -->
        <div>
          <app-input-label labelClass="label--bottom-margin"
            >Phone Number 1</app-input-label
          >
          <app-input
            placeholder="Enter Phone Number 1"
            [inputClass]="'input--padding'"
            formControlName="phone_number_1"
          >
          </app-input>
        </div>

        <!-- Phone Number 2 -->
        <div>
          <app-input-label labelClass="label--bottom-margin"
            >Phone Number 2</app-input-label
          >
          <app-input
            placeholder="Enter Phone Number 2"
            [inputClass]="'input--padding'"
            formControlName="phone_number_2"
          >
          </app-input>
        </div>

        <!-- Date of Birth -->
        <div>
          <app-input-label labelClass="label--bottom-margin"
            >Date of Birth</app-input-label
          >
          <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
          <input
            type="date"
            input-date
            class="input--padding"
            formControlName="dob"
          />
        </div>

        <!-- Marital Status -->
        <div>
          <app-input-label
            labelFor="marital status"
            labelClass="label--bottom-margin"
            >Marital Status</app-input-label
          >
          <select
            name="marital-status"
            required
            base-select
            formControlName="marital_status"
          >
            <option value="" disabled selected>Select status</option>
            <option *ngFor="let status of maritalStatus" [value]="status.value">
              {{ status.label }}
            </option>
          </select>
        </div>

        <!-- Nationality -->
        <div>
          <app-input-label
            labelFor="nationality"
            labelClass="label--bottom-margin"
            >Nationality</app-input-label
          >
          <select
            name="nationality"
            required
            base-select
            formControlName="nationality"
          >
            <option value="" disabled selected>Select country</option>
            @for (country of countries; track $index) {
              <option [value]="country.name">{{ country.name }}</option>
            }
          </select>
        </div>

        <!-- Annual Income -->
        <div class="">
          <app-input-label
            labelFor="annual income"
            labelClass="label--bottom-margin"
            >What is your Annual Income?</app-input-label
          >
          <select
            name="annual income"
            required
            base-select
            formControlName="annual_income"
          >
            <option value="" disabled selected>Select Annual Income</option>
            <option
              *ngFor="let range of annualIncomeRange"
              [value]="range.value"
            >
              {{ range.label }}
            </option>
          </select>
        </div>

        <!-- State of Origin -->
        <div>
          <app-input-label labelFor="gender" labelClass="label--bottom-margin"
            >State of Origin</app-input-label
          >
          <select
            name="state-of-origin"
            required
            base-select
            formControlName="state_of_origin"
          >
            <option value="" disabled selected>Select State</option>
            @for (state of nigerianStates; track $index) {
              <option [value]="state.state_code">{{ state.name }}</option>
            }
          </select>
        </div>

        <!-- BVN -->
        <div>
          <app-input-label labelFor="bvn" labelClass="label--bottom-margin"
            >BVN</app-input-label
          >

          <app-input
            placeholder="Enter BVN"
            [inputClass]="'input--padding'"
            formControlName="bvn"
          ></app-input>
        </div>
      </div>

      <div class="mt-5 flex justify-end">
        <div
          [ngClass]="{
            'cursor-not-allowed': isUpdatingProfileOne$ | async
          }"
        >
          <button
            type="button"
            mango-button
            [loading]="(isUpdatingProfileOne$ | async)!!"
            [disabled]="(isUpdatingProfileOne$ | async)!!"
            (click)="updateProfile('accountOne')"
            class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
          >
            <span
              *ngIf="isUpdatingProfileOne$ | async"
              spin
              loadingText="Updating Customer Profile"
            ></span>
            @if (!(isUpdatingProfileOne$ | async)) {
              Update Profile
            }
          </button>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #profileTwoTemplate>
    <form class="" [formGroup]="formAccountTwo">
      <div
        class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5"
      >
        <!-- Title -->
        <div>
          <app-input-label labelFor="title" labelClass="label--bottom-margin"
            >Title</app-input-label
          >
          <select name="title" required base-select formControlName="title">
            <option value="" disabled selected>Title</option>
            <option *ngFor="let title of titles" [value]="title.key">
              {{ title.value }}
            </option>
          </select>
        </div>

        <!-- First Name -->
        <div>
          <app-input-label
            labelFor="first-name"
            labelClass="label--bottom-margin"
            >First Name</app-input-label
          >

          <app-input
            placeholder="Enter First Name"
            [inputClass]="'input--padding'"
            formControlName="first_name"
          ></app-input>
        </div>

        <!-- Last Name -->
        <div>
          <app-input-label
            labelFor="last-name"
            labelClass="label--bottom-margin"
            >Last Name</app-input-label
          >

          <app-input
            placeholder="Enter Last Name"
            [inputClass]="'input--padding'"
            formControlName="last_name"
          ></app-input>
        </div>

        <!-- Middle Name -->
        <div>
          <app-input-label
            labelFor="last-name"
            labelClass="label--bottom-margin"
            >Middle Name</app-input-label
          >

          <app-input
            placeholder="Enter Middle Name"
            [inputClass]="'input--padding'"
            formControlName="middle_name"
          ></app-input>
        </div>

        <!-- Gender -->
        <div>
          <app-input-label labelFor="gender" labelClass="label--bottom-margin"
            >Gender</app-input-label
          >
          <select name="gender" required base-select formControlName="gender">
            <option value="" disabled selected>Select status</option>
            <option *ngFor="let gender of genderList" [value]="gender.value">
              {{ gender.label }}
            </option>
          </select>
        </div>

        <div>
          <app-input-label labelFor="email" labelClass="label--bottom-margin"
            >Email</app-input-label
          >
          <app-input
            placeholder="Enter email"
            inputClass="input--padding"
            formControlName="email"
          ></app-input>
        </div>

        <!-- Phone Number 1 -->
        <div>
          <app-input-label labelClass="label--bottom-margin"
            >Phone Number 1</app-input-label
          >
          <app-input
            placeholder="Enter Phone Number 1"
            [inputClass]="'input--padding'"
            formControlName="phone_number_1"
          >
          </app-input>
        </div>

        <!-- Phone Number 2 -->
        <div>
          <app-input-label labelClass="label--bottom-margin"
            >Phone Number 2</app-input-label
          >
          <app-input
            placeholder="Enter Phone Number 2"
            [inputClass]="'input--padding'"
            formControlName="phone_number_2"
          >
          </app-input>
        </div>

        <!-- Date of Birth -->
        <div>
          <app-input-label labelClass="label--bottom-margin"
            >Date of Birth</app-input-label
          >
          <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
          <input
            type="date"
            input-date
            class="input--padding"
            formControlName="dob"
          />
        </div>

        <!-- Marital Status -->
        <div>
          <app-input-label
            labelFor="marital status"
            labelClass="label--bottom-margin"
            >Marital Status</app-input-label
          >
          <select
            name="marital-status"
            required
            base-select
            formControlName="marital_status"
          >
            <option value="" disabled selected>Select status</option>
            <option *ngFor="let status of maritalStatus" [value]="status.value">
              {{ status.label }}
            </option>
          </select>
        </div>

        <!-- Nationality -->
        <div>
          <app-input-label
            labelFor="nationality"
            labelClass="label--bottom-margin"
            >Nationality</app-input-label
          >
          <select
            name="nationality"
            required
            base-select
            formControlName="nationality"
          >
            <option value="" disabled selected>Select country</option>
            @for (country of countries; track $index) {
              <option [value]="country.name">{{ country.name }}</option>
            }
          </select>
        </div>

        <!-- Annual Income -->
        <div class="">
          <app-input-label
            labelFor="annual income"
            labelClass="label--bottom-margin"
            >What is your Annual Income?</app-input-label
          >
          <select
            name="annual income"
            required
            base-select
            formControlName="annual_income"
          >
            <option value="" disabled selected>Select Annual Income</option>
            <option
              *ngFor="let range of annualIncomeRange"
              [value]="range.value"
            >
              {{ range.label }}
            </option>
          </select>
        </div>

        <!-- State of Origin -->
        <div>
          <app-input-label labelFor="gender" labelClass="label--bottom-margin"
            >State of Origin</app-input-label
          >
          <select
            name="state-of-origin"
            required
            base-select
            formControlName="state_of_origin"
          >
            <option value="" disabled selected>Select State</option>
            @for (state of nigerianStates; track $index) {
              <option [value]="state.state_code">{{ state.name }}</option>
            }
          </select>
        </div>

        <!-- BVN -->
        <div>
          <app-input-label labelFor="bvn" labelClass="label--bottom-margin"
            >BVN</app-input-label
          >

          <app-input
            placeholder="Enter BVN"
            [inputClass]="'input--padding'"
            formControlName="bvn"
          ></app-input>
        </div>
      </div>

      <div class="mt-5 flex justify-end">
        <div
          [ngClass]="{
            'cursor-not-allowed': isUpdatingProfileTwo$ | async
          }"
        >
          <button
            type="button"
            mango-button
            [loading]="
              (isUpdatingProfileTwo$ | async) === true ||
              (isCreatingProfileTwo$ | async) === true
            "
            [disabled]="
              (isUpdatingProfileTwo$ | async) === true ||
              (isCreatingProfileTwo$ | async) === true
            "
            (click)="submitAcctTwoForm()"
            class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
          >
            <span
              *ngIf="
                (isUpdatingProfileTwo$ | async) === true ||
                (isCreatingProfileTwo$ | async) === true
              "
              spin
              [loadingText]="
                (hasAccountTwo$ | async) === true
                  ? 'Updating Customer Profile...'
                  : 'Creating Customer Profile...'
              "
            ></span>
            @if (
              !(isUpdatingProfileTwo$ | async) &&
              !(isCreatingProfileTwo$ | async)
            ) {
              {{
                (hasAccountTwo$ | async) === true
                  ? 'Update Profile'
                  : 'Add Profile'
              }}
            }
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</app-kyc-accordion>
