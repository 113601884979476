import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { AdminManagementService } from '@core/services/admin-management.service';
import { ViewAdminInfoData } from '@core/models/admin/admin-management';
import { ButtonDirective } from '@shared/directives/button.directive';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { NotificationFacade } from '@core/facades/notification.facade';
import { RolesAndPermissionsService } from '@core/services/roles-and-permissions.service';
import { AllRolesDatum } from '@core/models/admin/roles-and-permissions/all-roles.model';

@Component({
  selector: 'app-admin-profile',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    ButtonDirective,
    ConfirmDialogComponent,
  ],
  templateUrl: './admin-profile.component.html',
  styleUrl: './admin-profile.component.scss',
})
export class AdminProfileComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  subs: Subscription[] = [];
  // admin!: ViewAdminListDatum | ViewAdminInfoData;
  admin!: ViewAdminInfoData;
  adminId!: string;
  adminName!: string;
  confirmQuestion: string = `Are you sure you want to delete this admin? This action cannot be undone.`;
  isDialogOpen: boolean = false;
  isDeleteInProgress: boolean = false;
  roles: AllRolesDatum[] = [];
  roleName!: string;

  route = inject(ActivatedRoute);
  router = inject(Router);
  adminService = inject(AdminManagementService);
  change = inject(ChangeDetectorRef);
  notify = inject(NotificationFacade);
  roleService = inject(RolesAndPermissionsService);

  ngOnInit(): void {
    if (history.state.user) {
      this.admin = history.state.user;
      this.adminId = this.admin.id;
      this.adminName = `${this.admin.first_name} ${this.admin.last_name}`;
    } else {
      this.adminId = this.route.snapshot.params['userId'];
      if (this.adminId) {
        this.getAdminProfile();
      }
    }
  }

  editAdmin() {
    this.router.navigate(['/admin/users/edit/' + this.admin.id], {
      state: { user: this.admin },
    });
  }

  deleteAdmin() {
    this.isDialogOpen = true;
    this.isDeleteInProgress = true;
    const sub = this.adminService.deleteAdmin(this.adminId).subscribe({
      next: () => {
        this.isDialogOpen = false;
        this.isDeleteInProgress = false;
        this.notify.success('Admin deleted successfully');
        this.router.navigate(['/admin/users']).then(() => {
          window.location.reload();
        });
      },
      error: (err) => {
        this.isDeleteInProgress = false;
        this.change.detectChanges();
      },
    });

    this.subs.push(sub);
  }

  getAdminProfile() {
    this.isLoading = true;
    const sub = this.adminService.viewAdminInfo(this.adminId).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.admin = response.data[0];
        this.adminName = `${this.admin.first_name} ${this.admin.last_name}`;
        this.confirmQuestion = `Are you sure you want to delete this admin: ${this.adminName}? This action cannot be undone.`;

        this.change.detectChanges();
      },
      error: (err) => {
        this.isLoading = false;
        this.change.detectChanges();
      },
    });
    this.subs.push(sub);
  }

  goback() {
    history.back();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
