import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  inject,
  effect,
  ChangeDetectorRef,
  Renderer2,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '@shared/directives/button.directive';
import {
  closeButtonAnimation,
  dropdownMenuAnimation,
  offCanvasBackdropAnimation,
  offCanvasMenuAnimation,
} from './animations';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { CURRENT_USER, TOKEN } from 'app/app.constants';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { AuthService } from '@core/services/auth.service';
import { LoginResponse, LoginResponseRole2 } from '@core/models/client/auth';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { ExpiredSessionService } from '@core/services/expired-session.service';
import { Select, Store } from '@ngxs/store';
import { AuthSelectors } from 'app/auth/store/auth.selectors';
import { Observable, Subscription, find } from 'rxjs';
import { AuthActions } from 'app/auth/store/auth.actions';
import { ErrorDialogService } from '@core/services/error-dialog.service';

@Component({
  selector: 'app-dashboard-layout',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    ConfirmDialogComponent,
  ],
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss',
  animations: [
    closeButtonAnimation,
    offCanvasBackdropAnimation,
    offCanvasMenuAnimation,
    dropdownMenuAnimation,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardLayoutComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('menu') menu!: ElementRef;

  mainBreadCrumb!: string;
  nextBreadCrumb!: string;
  primaryBreadCrumb!: string;
  secondaryBreadCrumb!: string;
  currentUser!: LoginResponse | null;
  // showDialog: boolean = false;
  tokenExpiredUrl!: string;
  userName: string | null = null;
  userRoles!: LoginResponseRole2[];

  activatedRoute = inject(ActivatedRoute);
  route = inject(Router);
  breadCrumbService = inject(BreadCrumbService);
  authService = inject(AuthService);
  cdr = inject(ChangeDetectorRef);
  expiredSessionService = inject(ExpiredSessionService);
  private store = inject(Store);
  private errorDialogService = inject(ErrorDialogService);
  renderer = inject(Renderer2);

  buttonState: 'visible' | 'hidden' = 'visible';
  menuState: 'visible' | 'hidden' = 'visible';
  navigationMenuState: 'visible' | 'hidden' = 'hidden';
  userMenuState: 'visible' | 'hidden' = 'hidden';
  accountMenuState: 'visible' | 'hidden' = 'hidden';
  productMenuState: 'visible' | 'hidden' = 'hidden';
  transactionsMenuState: 'visible' | 'hidden' = 'hidden';

  showDialog$!: Observable<boolean>;
  private dialogSubscription!: Subscription;

  constructor() {
    // this will watch for any changes to the navigation service and update the
    // bread crumb accordingly.
    effect(() => {
      this.mainBreadCrumb = this.breadCrumbService.currentPage()
        ?.main as string;
      this.nextBreadCrumb = this.breadCrumbService.currentPage()
        ?.next as string;
      this.primaryBreadCrumb = this.breadCrumbService.currentPage()
        ?.primary as string;
      this.secondaryBreadCrumb = this.breadCrumbService.currentPage()
        ?.secondary as string;

      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.setDefaultAnimationFlags();
    this.userName = this.authService.getUserName();
    this.userRoles = this.authService.getUserRoles() ?? [];

    this.showDialog$ = this.errorDialogService.showDialog$;
  }

  setDefaultAnimationFlags() {
    this.buttonState = 'hidden';
    this.menuState = 'hidden';
    this.userMenuState = 'hidden';
    this.accountMenuState = 'hidden';
    this.transactionsMenuState = 'hidden';
  }

  toggleSidebarMenu() {
    this.buttonState = this.buttonState === 'hidden' ? 'visible' : 'hidden';
    this.menuState = this.menuState === 'hidden' ? 'visible' : 'hidden';
  }

  logout() {
    this.authService.logout();
    this.route.navigateByUrl('/');
  }

  login() {
    // this.authService.logout();
    this.errorDialogService.closeDialog();
    this.route.navigateByUrl(`/auth/login`);
  }

  toggleNavigationMenu() {
    this.navigationMenuState =
      this.navigationMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  toggleUsersDropdown(): void {
    this.userMenuState = this.userMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  toggleAccountsDropdown(): void {
    this.accountMenuState =
      this.accountMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  toggleProductsDropdown(): void {
    this.productMenuState =
      this.productMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  toggleTransactionsDropdown(): void {
    this.transactionsMenuState =
      this.transactionsMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  async popOverOpen(event: any) {
    if (event.fromState === 'hidden' && event.toState === 'visible') {
      // we check if the dropdown is visible now
      (await event.fromState) === 'hidden' && event.toState === 'visible';
      this.renderer.listen('window', 'click', (e: Event) => {
        /**
         * Only run when toggleButton is not clicked
         * If we don't check this, all clicks (even on the toggle button) gets into this
         * section which in the result we might never see the menu open!
         * And the menu itself is checked here, and it's where we check just outside of
         * the menu and button the condition abbove must close the menu
         */
        if (
          !this.toggleButton.nativeElement.contains(e.target) &&
          !this.menu.nativeElement.contains(e.target)
        ) {
          this.navigationMenuState = 'hidden';
          this.cdr.detectChanges();
        }
      });
    }
  }
}
