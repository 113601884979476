import { CommonModule } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@core/environments/environment';
import { NotificationFacade } from '@core/facades/notification.facade';
import { DocKyc } from '@core/models/admin/account';
import { UploadKycDocResponse } from '@core/models/admin/kyc';
import { CentralDocumentUploadService } from '@core/services/central-document-upload.service';
import { GeneralFileUploadService } from '@core/services/general-file-upload.service';
import { LoadingService } from '@core/services/loading.service';
import { InputFileComponent } from '@shared/ui/input-file/input-file.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

import { BehaviorSubject, elementAt, Observable, Subscription } from 'rxjs';
interface IDocTitle {
  [key: string]: string;
}

const DocumentTitle: IDocTitle = {
  signature: 'Signature',
  passport_photograph: 'Passport Photograph',
  means_of_id: 'Means of Identification',
  utility_bill: 'Utility Bill',
  general_kyc: 'General KYC',
};

interface DocumentDetails {
  id: string;
  inputLabel: string;
  isUploaded: boolean;
  key: string;
  fileLink: string | null;
  documentFile: File | null;
  docIdentifier: string;
  isApproved: boolean;
  maxFileSize: number;
  acceptedFileTypes: string[];
}
interface DocList {
  [key: string]: DocumentDetails;
}

@Component({
  selector: 'app-edit-documents',
  standalone: true,
  imports: [KycAccordionComponent, CommonModule, InputFileComponent],
  templateUrl: './edit-documents.component.html',
  styleUrl: './edit-documents.component.scss',
})
export class EditDocumentsComponent implements OnInit, OnDestroy {
  @Input() kycDocuments!: DocKyc[];

  private documentService = inject(CentralDocumentUploadService);
  private loadingService = inject(LoadingService);
  private toast = inject(NotificationFacade);
  route = inject(ActivatedRoute);
  change = inject(ChangeDetectorRef);
  generalFileUploadService = inject(GeneralFileUploadService);

  DocumentTitle = DocumentTitle;

  isUploading$!: Observable<boolean>;
  accountId!: string;
  subs: Subscription[] = [];
  public readonly originalOrder = (): number => 0;
  private unapprovedDocsSubject = new BehaviorSubject<number>(0);
  unapprovedCounter$ = this.unapprovedDocsSubject.asObservable();
  acceptedFileTypes: string[] = ['image/png', 'image/jpeg', 'application/pdf'];
  maxFileSize: number = 2000;
  documentList: DocList = {
    signature: {
      id: 'signatureId',
      inputLabel: 'Signature',
      isUploaded: false,
      key: 'signature',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'signature',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    passportPhotograph: {
      id: 'passportId',
      inputLabel: 'Passport Photograph',
      isUploaded: false,
      key: 'passportPhotograph',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'passport_photograph',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    meansOfIdentification: {
      id: 'meansOfIdID',
      inputLabel: 'Means of Identification',
      isUploaded: false,
      key: 'meansOfIdentification',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'means_of_id',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    utilityBill: {
      id: 'utilityBillId',
      inputLabel: 'Utility Bill',
      isUploaded: false,
      key: 'utilityBill',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'utility_bill',
      isApproved: false,
      maxFileSize: this.maxFileSize,
      acceptedFileTypes: this.acceptedFileTypes,
    },
    generalKyc: {
      id: 'generalKycId',
      inputLabel: `General KYC - 25MB (PDF)`,
      isUploaded: false,
      key: 'generalKyc',
      fileLink: null,
      documentFile: null,
      docIdentifier: 'general_kyc',
      isApproved: false,
      maxFileSize: 25000,
      acceptedFileTypes: ['application/pdf'],
    },
  };

  constructor() {
    this.isUploading$ =
      this.loadingService.getLoadingObservable('add-kyc-document');
  }

  ngOnInit(): void {
    this.setAccountId();
    this.checkIsDocumentApproved();
  }

  setAccountId() {
    this.accountId = this.route.snapshot.params['accountId'];
  }

  checkIsDocumentApproved() {
    if (this.kycDocuments) {
      let unapprovedCounter = 0;

      for (const doc of this.kycDocuments) {
        const documentType = this.titleCase(doc.document_type);
        if (this.documentList[documentType]) {
          this.documentList[documentType].isApproved =
            doc.status === 'approved';
          if (doc.status !== 'approved') {
            unapprovedCounter++;
          }
        }
      }
      this.unapprovedDocsSubject.next(unapprovedCounter);
    }
  }

  titleCase(str: string) {
    return str
      .replace(/^[-_]*(.)/, (_, c) => c.toLowerCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => '' + c.toUpperCase()); // First char after each -/_
  }

  getUploadedFile(fileList: FileList | null, documentType: string): void {
    if (fileList) {
      this.documentList[documentType].isUploaded = true;
      this.documentList[documentType].documentFile = fileList[0];
      documentType === 'generalKyc'
        ? this.uploadGeneralFile()
        : this.uploadDocument(documentType);

    } else {
      this.documentList[documentType].isUploaded = false;
      this.documentList[documentType].documentFile = null;
    }
  }

  openDocument(link: string | null) {
    window.open(`${environment.FILE_BASE_URL}${link}`, '_blank');
  }

  uploadDocument(docType: string) {
    const formData = new FormData();
    const documentFile = this.documentList[docType].documentFile;
    if (documentFile) {
      formData.append('file', documentFile);
      formData.append(
        'document_type',
        this.documentList[docType].docIdentifier
      );
      formData.append('account_id', this.accountId);
    }

    const sub = this.documentService.uploadKycDocument(formData).subscribe({
      next: (response: HttpEvent<any>) => {
        if (response.type === HttpEventType.Response) {
          // const res = response.body as UploadKycDocResponse;

          // const isDocExist = (doc: DocKyc) => doc.document_type === docType;
          // const index = this.kycDocuments.findIndex(isDocExist);

          // index !== 1
          //   ? this.kycDocuments.splice(index, 1, res.data)
          //   : this.kycDocuments.push(res.data);

          // // console.log(this.kycDocuments);
          // this.change.detectChanges();

          window.location.reload();
          this.toast.success(`Successfully uploaded Document`);
        }
      },
      error: () => {
        this.toast.error('Failed to upload Document');
      },
    });

    this.subs.push(sub);
  }

  async uploadGeneralFile(): Promise<void> {
    const generalKey = 'generalKyc';

    if (!this.documentList[generalKey].documentFile) {
      this.toast.error('No file selected');
      return;
    }

    try {
      // Get the presigned URL from the service
      const presignedUrl = await this.generalFileUploadService.getPresignedUrl(
        this.accountId
      );

      const formData = new FormData();
      const documentFile = this.documentList[generalKey].documentFile;
      if (documentFile) {
        formData.append('file', documentFile);
        formData.append(
          'document_type',
          this.documentList[generalKey].docIdentifier
        );
        formData.append('account_id', this.accountId);
      }

      // Use the presigned URL to upload the file
      await this.generalFileUploadService.uploadFile(presignedUrl, formData);
      window.location.reload();
      this.toast.success(`Successfully uploaded General KYC Document`);
    } catch (err) {
      this.toast.error(
        'Failed to upload the General KYC Document. Please try again.'
      );
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
