import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { URL_KEYS } from '@core/constants/url-keys.constants';
import { NotificationFacade } from '@core/facades/notification.facade';
import {
  BeneficiaryDetailsData,
  WalletTransactionData,
} from '@core/models/admin/account';
import { CommonService } from '@core/services/common.service';
import { LoadingService } from '@core/services/loading.service';
import { TransactionsService } from '@core/services/transactions.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-approve-transaction',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    ButtonDirective,
    InputComponent,
    ReactiveFormsModule,
    ConfirmDialogComponent,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './approve-transaction.component.html',
  styleUrl: './approve-transaction.component.scss',
})
export class ApproveTransactionComponent implements OnInit, OnDestroy {
  private transactionService = inject(TransactionsService);
  private route = inject(ActivatedRoute);
  private toast = inject(NotificationFacade);
  private loadingService = inject(LoadingService);
  private commonService = inject(CommonService);
  private cdr = inject(ChangeDetectorRef);

  subs: Subscription[] = [];
  beneficiaryDetails!: BeneficiaryDetailsData | null;
  // transactionDetails!: WalletTransactionData;

  isDialogOpenSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private isDialogLoadingSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private transactionDetailsSubject$ =
    new BehaviorSubject<WalletTransactionData | null>(null);
  private beneficiaryDetailsSubject$ =
    new BehaviorSubject<BeneficiaryDetailsData | null>(null);

  isDialogOpen$ = this.isDialogOpenSubject$.asObservable();
  isDialogLoading$ = this.isDialogLoadingSubject$.asObservable();
  transactionDetails$ = this.transactionDetailsSubject$.asObservable();
  beneficiaryDetails$ = this.beneficiaryDetailsSubject$.asObservable();

  isFetchingBeneficiary$!: Observable<boolean>;
  isFetchingBankName$!: Observable<boolean>;

  transactionAmount!: string;
  bankAmount!: string;
  bankAccountName!: string;
  bankName?: string;
  accountId!: string;
  transactionId!: string;
  disableApproveAction!: boolean;

  constructor() {
    this.isFetchingBeneficiary$ = this.loadingService.getLoadingObservable(
      URL_KEYS.TRANSACTIONS.GET_BENEFICIARY_DETAILS
    );
    this.isFetchingBankName$ = this.loadingService.getLoadingObservable(
      URL_KEYS.COMMON.GET_BANK_NAME
    );
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.queryParams['account_id'];
    this.transactionId = this.route.snapshot.queryParams['tran_id'];

    if (this.accountId && this.transactionId) {
      this.getBeneficiaryDetails(this.accountId);
      this.getTransactionDetails(this.transactionId);
    }
  }

  getBeneficiaryDetails(accountId: string) {
    const sub = this.transactionService
      .getBeneficiaryDetails(accountId)
      .subscribe({
        next: (response) => {
          this.beneficiaryDetailsSubject$.next(response.data);
          this.fetchBankName(response.data.bank_code);
        },
        error: () => {
          this.toast.error('Failed to verify Beneficiary Details');
        },
      });

    this.subs.push(sub);
  }

  getTransactionDetails(tranId: string) {
    const sub = this.transactionService
      .getTransactionDetails(tranId)
      .subscribe({
        next: (response) => {
          let data = response.data;
          data.transaction_amount = Math.abs(data.transaction_amount);
          this.transactionDetailsSubject$.next(data);
        },
        error: () => {
          this.toast.error('Failed to fetch transaction details');
        },
      });

    this.subs.push(sub);
  }

  approveTransaction() {
    this.isDialogLoadingSubject$.next(true);
    const sub = this.transactionService
      .processDebitTransaction({
        status: 'completed',
        transactionId: this.transactionId,
        accountId: this.accountId,
      })
      .subscribe({
        next: () => {
          this.isDialogLoadingSubject$.next(false);
          this.isDialogOpenSubject$.next(false);
          history.back();

          setTimeout(() => {
            window.location.reload();
          }, 300);

          this.toast.success('Successfully approved transaction');
        },
        error: (err) => {
          this.isDialogLoadingSubject$.next(false);
          this.toast.error('Failed to approve transaction');
        },
      });

    this.subs.push(sub);
  }

  async fetchBankName(bankCode: string) {
    this.bankName = await this.commonService.getBankName(bankCode);
  }

  closeDialog() {
    this.isDialogOpenSubject$.next(false);
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
