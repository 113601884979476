<div class="flex grid-cols-2 flex-col gap-4 md:grid md:justify-between">
  <h2
    class="text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
  >
    Transactions - Wallet
  </h2>
</div>

<!-- Transaction cards -->
<div
  class="portfolio__wrapper mt-[0.94rem] flex flex-col gap-[0.94rem] md:flex-row"
>
  <!-- Providus -->
  <div
    class="flex min-w-[17.8125rem] flex-col rounded-[0.9375rem] bg-gradient-to-r from-mango-grey to-[#786440] px-5 py-[1.19rem] text-white"
  >
    <div class="flex items-center justify-between">
      <p class="text-xs font-normal leading-[1.125rem]">Providus Balance</p>
      <div class="rounded-lg bg-[#9c907a] px-3 py-1.5 text-xs">Fund</div>
    </div>
    <p class="py-[-2rem] text-3xl font-extrabold leading-[3.75rem]">
      ₦ {{ (providusBalance$ | async ) ?? 0| number }}
    </p>
  </div>

  <!-- Optimus  -->
  <div
    class="flex min-w-[17.8125rem] flex-col rounded-[0.9375rem] bg-gradient-to-r from-mango-grey to-[#786440] px-5 py-[1.19rem] text-white"
  >
    <div class="flex items-center justify-between">
      <p class="text-xs font-normal leading-[1.125rem]">Optimus Balance</p>
      <div class="rounded-lg bg-[#9c907a] px-3 py-1.5 text-xs">Fund</div>
    </div>
    <p class="text-3xl font-extrabold leading-[3.75rem]">
      ₦ {{optimusBalance$ | async | number}}
    </p>
  </div>
</div>

<div class="flex items-center justify-between gap-4">
  <!-- Search Input and Filters -->
  <app-filters class="flex-1" [filters]="filterStructure"></app-filters>
  <button mango-button (click)="exportReport()">Export Report</button>
</div>
<!-- table skeleton loader -->
<div *ngIf="isFetchingTransactions$ | async" class="mt-[0.94rem]">
  <app-table-skeleton-loader></app-table-skeleton-loader>
</div>

<!-- Table -->
<div
  *ngIf="!(isFetchingTransactions$ | async)"
  class="mt-[0.94rem] rounded-lg border border-solid bg-white"
>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="flex py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                S/N
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99998 3.33301V12.6663M7.99998 12.6663L12.6666 7.99967M7.99998 12.6663L3.33331 7.99967"
                    stroke="#111849"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </th>
              <th
                scope="col"
                class="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Date Created
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                User/Organization
              </th>
              <th
                scope="col"
                class="px-1.5 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Purpose
              </th>
              <th
                scope="col"
                class="whitespace-nowrap px-1.5 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Current Balance
              </th>
              <th
                scope="col"
                class="whitespace-nowrap px-1.5 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Transaction Channel
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Transaction Type
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Amount
              </th>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-2 text-left text-sm font-semibold text-gray-900"
              >
                Status
              </th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- @if (transactionsList.length < 1) {
              <tr>
                <td span="6">No Transactions found!</td>
              </tr>
            } @else {
              @for (
                transaction of transactionsList | search: searchText;
                track transaction.transaction_id;
                let i = $index
              ) {
                
              } @empty {
                Empty list of transaction
              }
            } -->

            <ng-container
              *ngIf="
                transactionsList && transactionsList.length;
                else noTransactions
              "
            >
              <tr
                *ngFor="let transaction of transactionsList; let i = index"
                class="border-b border-gray-200 bg-white"
              >
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p>{{ i + 1 }}</p>
                </td>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">
                    {{ transaction.created_at | date: 'medium' }}
                  </p>
                </td>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  @if (transaction.account.account_type === 'corporate') {
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ transaction.account.corporate_kyc || '-' }}
                    </p>
                  } @else {
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ transaction.account.user_profile.first_name || '-' }}
                      {{ transaction.account.user_profile.last_name }}
                    </p>
                  }
                </td>
                <td class="border-b border-gray-200 bg-white px-3 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">
                    {{ transaction.purpose }}
                  </p>
                </td>

                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">
                    ₦{{ transaction.current_balance / 100 | number: '1.2-2' }}
                  </p>
                </td>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">
                    {{ transaction.transaction_channel }}
                  </p>
                </td>

                <td
                  class="sm:pmd:py-4 border-b border-gray-200 bg-white px-2 py-2 text-xs sm:px-2 sm:text-sm md:px-3 lg:px-4"
                >
                  <span
                    class="relative inline-block px-2 py-1 font-semibold leading-tight sm:px-2"
                  >
                    <span
                      aria-hidden
                      class="absolute inset-0 {{
                        transaction.transaction_type === 'credit'
                          ? 'bg-green-200'
                          : 'bg-blue-300'
                      }} opacity-50 rounded-full"
                    ></span>
                    <span
                      class="relative flex  {{
                        transaction.transaction_type === 'credit'
                          ? 'text-[#027A48]'
                          : 'text-blue-700'
                      }} sm:text-[11px]"
                    >
                      {{ transaction.transaction_type | uppercase }}</span
                    >
                  </span>
                </td>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">
                    ₦{{
                      transaction.transaction_amount / 100 | number: '1.2-2'
                    }}
                  </p>
                </td>
                <td
                  class="sm:pmd:py-4 border-b border-gray-200 bg-white px-2 py-2 text-xs sm:px-3 sm:text-sm md:px-4 lg:px-5"
                >
                  <app-status-badge
                    [status]="transaction.status"
                  ></app-status-badge>
                </td>
                <td
                  class="relative whitespace-nowrap border-b py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                >
                  <div
                    class="relative inline-block w-fit cursor-pointer border-gray-200 bg-white text-left"
                  >
                    <app-dropdown
                      [id]="transaction.id"
                      [actionList]="initDropdownList(transaction)"
                    >
                    </app-dropdown>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #noTransactions>
              <tr>
                <td colspan="6">No Transactions found!</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
        <app-table-navigator
          *ngIf="totalCount"
          [total]="totalCount"
          (onNavigation)="navigate($event)"
        >
        </app-table-navigator>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<app-confirm-dialog
  confirmQuestion="Are you sure you want to reject this transaction? This action cannot be undone"
  confirmText="Yes, Reject Transaction"
  [isOpen]="isRejectDialogOpen"
  bgConfirmButton="danger"
  [isDialogLoading]="isRejectInProgress"
  dialogLoadingText="Rejecting Transaction, please wait..."
  (close)="isRejectDialogOpen = false; transactionId = null; accountId = null"
  (dialogAction)="rejectTransaction()"
>
</app-confirm-dialog>

<app-confirm-dialog
  [confirmQuestion]="approveDialogQuestion"
  confirmText="Yes, Approve Transaction"
  [isOpen]="isApproveDialogOpen"
  [isDialogLoading]="isApproveInProgress"
  [dialogLoadingText]="isApproveLoadingText"
  (close)="
    isApproveDialogOpen = false;
    transactionId = null;
    accountId = null;
    approveDialogQuestion = null;
    beneficiaryDetails = null
  "
  (dialogAction)="approveTransaction()"
  [disableActionButton]="disableApproveAction"
  [isHTMLQuestion]="true"
>
</app-confirm-dialog>
