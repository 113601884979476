import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { Accountbankinfo } from '@core/models/admin/account';
import { JointAccountbankinfo } from '@core/models/admin/account/joint';
import { CommonService } from '@core/services/common.service';
import { IndividualRegistrationService } from '@core/services/individual-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { TabsComponent } from '@shared/ui/tabs/tabs.component';
import { BankRegion, CURRENCY } from 'app/app.constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-bank-details',
  standalone: true,
  imports: [
    KycAccordionComponent,
    CommonModule,
    ReactiveFormsModule,
    InputComponent,
    InputLabelComponent,
    ButtonDirective,
    BaseSelectDirective,
    SpinDirective,
    TabsComponent,
  ],
  templateUrl: './edit-bank-details.component.html',
  styleUrl: './edit-bank-details.component.scss',
})
export class EditBankDetailsComponent implements OnInit, OnDestroy {
  @Input() bankDetails!: Accountbankinfo[] | JointAccountbankinfo[];

  bankForm!: FormGroup;
  bankAccountNo!: string;
  // isFetchingAccountNo: boolean = false;
  banks: { bankName: string; bankCode: string }[] = [];
  isUpdating$!: Observable<boolean>;
  isAddingNewBankInfo$!: Observable<boolean>;
  isBankListLoading$!: Observable<boolean>;
  isBankValidationLoading$!: Observable<boolean>;
  subs: Subscription[] = [];
  // accountId!: string;
  currencyList = CURRENCY;

  isBankCreatedSubject$ = new BehaviorSubject<boolean>(false);
  accountIdSubject$ = new BehaviorSubject<string | null>(null);
  hasBankNameSubject$ = new BehaviorSubject<boolean>(false);
  bankIdSubject$ = new BehaviorSubject<string | null>(null);
  private bankRegionSubject$ = new BehaviorSubject<BankRegion>('local');

  hasBankName$ = this.hasBankNameSubject$.asObservable();
  bankRegion$ = this.bankRegionSubject$.asObservable();
  isBankCreated$ = this.isBankCreatedSubject$.asObservable();

  fb = inject(FormBuilder);
  commonService = inject(CommonService);
  cdr = inject(ChangeDetectorRef);
  toast = inject(NotificationFacade);
  private route = inject(ActivatedRoute);
  private loadingService = inject(LoadingService);
  private individualService = inject(IndividualRegistrationService);

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      'update-banking-details'
    );
    this.isAddingNewBankInfo$ = this.loadingService.getLoadingObservable(
      'add-banking-details-to-account'
    );

    this.isBankListLoading$ =
      this.loadingService.getLoadingObservable('get-bank-list');
    this.isBankValidationLoading$ = this.loadingService.getLoadingObservable(
      'validate-bank-details'
    );
  }

  ngOnInit(): void {
    // this.accountId = this.route.snapshot.params['accountId'];
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(accountId);
      this.createForm();
      this.getListOfBanks();
      this.setFormValues();
    }
  }

  submitBankDetails() {
    this.isBankCreatedSubject$.value
      ? this.updateBankDetails()
      : this.createNewBankDetails();
  }

  updateBankDetails() {
    if (this.bankRegionSubject$.value === 'foreign') {
      this.bankForm.removeControl('bank_code');
    }
    this.bankForm?.addControl(
      'id',
      new FormControl(this.accountIdSubject$.value)
    );

    const sub = this.individualService
      .updateBankDetails(this.bankForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated bank details');
        },
        error: () => {
          this.toast.error('Failed to update bank details');
        },
      });

    this.subs.push(sub);
  }

  createNewBankDetails() {
    const sub = this.individualService
      .addBankDetails(this.bankForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.isBankCreatedSubject$.next(true);
          this.bankIdSubject$.next(response.data[0].id);
          this.toast.success('Successfully added new bank details');
        },
        error: () => {
          this.toast.error('Failed to add new bank details');
        },
      });
    this.subs.push(sub);
  }

  createForm() {
    if (!this.bankDetails.length) {
      // new bank details form
      this.bankForm = this.fb.nonNullable.group({
        bank_account_name: [''],
        bank_account_number: [''],
        bank_code: [''],
        bank_branch: [''],
        bank_sort_code: [''],
        currency: [''],
        account_id: [''],
        bank_name: [''], // for FOREIGN accounts ONLY
        region: [this.bankRegionSubject$.value],
      });
      return;
    }

    // existing bank details form
    this.bankForm = this.fb.nonNullable.group({
      id: [''],
      bank_account_name: [''],
      bank_account_number: [''],
      bank_code: [''],
      bank_branch: [''],
      bank_sort_code: [''],
      currency: [''],
      account_id: [''],
      bank_name: [''], // for FOREIGN accounts ONLY
      region: [this.bankRegionSubject$.value],
    });
  }

  setFormValues() {
    this.bankForm.patchValue({
      account_id: this.accountIdSubject$.value,
    });

    if (!this.bankDetails.length) {
      return;
    }

    const bankDetails = this.bankDetails[0];
    this.setBankRegion(bankDetails.region as BankRegion);

    // values for an existing bank form
    this.bankForm.patchValue({
      id: bankDetails.id,
      bank_account_name: bankDetails.bank_account_name || '',
      bank_account_number: bankDetails.bank_account_number,
      bank_code: bankDetails.bank_code,
      bank_branch: bankDetails.bank_branch || '',
      currency: bankDetails.currency || '',
      bank_sort_code: bankDetails.bank_sort_code || '',
      region: bankDetails.region || 'local',
      bank_name: bankDetails.bank_name || '',
    });

    this.isBankCreatedSubject$.next(true);
    this.bankIdSubject$.next(bankDetails.id);
  }

  setBankRegion(region: BankRegion) {
    this.bankRegionSubject$.next(region);
  }

  validateBankAccountNo(): void {
    this.bankAccountNo = this.bankForm.get('bank_account_number')
      ?.value as string;

    const bankCode = this.bankForm.get('bank_code')?.value as string;

    if (
      this.bankAccountNo?.length === 10 &&
      this.bankForm.get('bank_code')?.dirty
    ) {
      const sub = this.commonService
        .validateBankAccountNo(this.bankAccountNo, bankCode)
        .subscribe({
          next: (response) => {
            this.bankForm
              .get('bank_account_name')
              ?.setValue(response.data.accountName);

            this.cdr.detectChanges();
          },
          error: () => {
            this.toast.error('Failed to validate bank account number');
          },
        });
      this.subs.push(sub);
    }
  }

  getListOfBanks() {
    const sub = this.commonService.getBankList().subscribe({
      next: (res: any) => {
        this.banks = res.data;
      },
      error: () => {
        this.toast.info('Unable to fetch bank list.\nKindly refresh');
      },
    });
    this.subs.push(sub);
  }

  toggleBankRegion(event: Event, bankType: BankRegion) {
    event.preventDefault();
    this.bankRegionSubject$.next(bankType);

    if (bankType === 'local') {
      this.bankForm?.removeControl('bank_name');
      this.bankForm?.addControl('bank_code', new FormControl(''));

      this.bankForm.patchValue({
        region: 'local' as BankRegion,
      });
    } else {
      this.bankForm?.addControl('bank_name', new FormControl(''));
      this.bankForm?.removeControl('bank_code');
      this.bankForm.patchValue({
        region: 'foreign' as BankRegion,
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
