import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { EditEmploymentDetailsComponent } from '@accounts/individual-account/edit-account/pages/edit-employment-details/edit-employment-details.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { JointEmployerkyc } from '@core/models/admin/account/joint';
import { URL_KEYS } from '@core/constants/url-keys.constants';
import { JointRegistrationService } from '@core/services/joint-registration.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { COUNTRIES, State, States2 } from '@core/data/countries-states';
import { EMPLOYMENT_STATUS } from 'app/app.constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@core/services/loading.service';

@Component({
  selector: 'app-edit-joint-employment',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    BaseSelectDirective,
    InputDateDirective,
    SpinDirective,
  ],
  templateUrl: './edit-joint-employment.component.html',
  styleUrl: './edit-joint-employment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointEmploymentComponent implements OnDestroy {
  @Input() employmentDetails!: JointEmployerkyc;
  @Input() jointAccountId!: string; // for creating new employment

  employerForm!: FormGroup;
  isCreating$!: Observable<boolean>;
  isUpdating$!: Observable<boolean>;
  // cities: string[] | null = [];
  countries = COUNTRIES.data;
  states: (State | States2)[] = [];
  subs: Subscription[] = [];
  employmentStatus = EMPLOYMENT_STATUS;

  accountIdSubject$ = new BehaviorSubject<string | null>(null);
  hasEmploymentSubject$ = new BehaviorSubject<boolean>(false);
  employmentIdSubject$ = new BehaviorSubject<string | null>(null);

  hasEmployment$ = this.hasEmploymentSubject$.asObservable();

  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private loadingService = inject(LoadingService);
  private toast = inject(NotificationFacade);
  private jointRegService = inject(JointRegistrationService);

  constructor() {
    this.isUpdating$ = this.loadingService.getLoadingObservable(
      URL_KEYS.JOINT.UPDATE_JOINT_ACCOUNT_EMPLOYMENT_DETAILS
    );
    this.isCreating$ = this.loadingService.getLoadingObservable(
      URL_KEYS.JOINT.ADD_JOINT_ACCOUNT_EMPLOYMENT_DETAILS
    );
  }

  ngOnInit(): void {
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(accountId);
    }

    this.createForm();
    if (this.employerForm) {
      this.setFormValues();
    }
  }

  submitForm(): void {
    this.hasEmploymentSubject$.value
      ? this.updateEmploymentDetails()
      : this.addEmploymentDetails();
  }

  updateEmploymentDetails(): void {
    this.jointRegService
      .updateEmploymentDetails(this.employerForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated Employment details');
        },
        error: () => {
          this.toast.error('Failed to update Employment details');
        },
      });
  }

  addEmploymentDetails(): void {
    this.jointRegService
      .addEmploymentDetails(this.employerForm.getRawValue())
      .subscribe({
        next: (employmentId) => {
          this.hasEmploymentSubject$.next(true);
          this.employmentIdSubject$.next(employmentId);
          this.employerForm?.addControl('id', new FormControl(employmentId));
          this.employerForm?.removeControl('account_id');
          this.employerForm?.removeControl('joint_account_id');

          this.toast.success('Successfully added Employment details');
        },
        error: () => {
          this.toast.error('Failed to add Employment details');
        },
      });
  }

  createForm(): void {
    if (!this.employmentDetails) {
      this.employerForm = this.fb.nonNullable.group({
        status: [''],
        name: [''],
        nature_of_business: [''],
        country: [''],
        state: [''],
        city: [''],
        address: [''],
        phone_number: [''],
        joint_account_id: [''],
        account_id: [''],
      });
    } else {
      this.employerForm = this.fb.nonNullable.group({
        id: [''],
        status: [''],
        name: [''],
        nature_of_business: [''],
        country: [''],
        state: [''],
        city: [''],
        address: [''],
        phone_number: [''],
      });
    }
  }

  setFormValues(): void {
    if (!this.employmentDetails) {
      this.employerForm.patchValue({
        account_id: this.accountIdSubject$.value,
        joint_account_id: this.jointAccountId,
      });
      return;
    }

    this.hasEmploymentSubject$.next(true);
    this.employmentIdSubject$.next(this.employmentDetails.id);
    if (this.employmentDetails.country) {
      this.getCountryStates(this.employmentDetails.country);
    }
    this.employerForm.patchValue({
      id: this.employmentDetails.id,
      status: this.employmentDetails.status,
      name: this.employmentDetails.name,
      nature_of_business: this.employmentDetails.nature_of_business,
      country: this.employmentDetails.country,
      state: this.employmentDetails.state,
      city: this.employmentDetails.city,
      address: this.employmentDetails.address,
      phone_number: this.employmentDetails.phone_number,
    });
  }

  getCountryStates(countryName: string) {
    this.states = this.countries.filter(
      (country) => country.name === countryName
    )[0].states;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
