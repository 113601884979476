<app-slide-in-right-modal
  modalTitle="Transaction Details"
  [showBackLink]="true"
  [returnPath]="'/admin/transactions/wallet'"
>
  @if (!(isFetchingDetails$ | async)) {
    <dl class="space-y-4 text-left">
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Date Created:</dt>
        <dd class="ml-4">{{ transaction.created_at | date: 'medium' }}</dd>
      </div>

      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Transaction Type:</dt>
        <span
          class="relative inline-block px-2 py-1 text-xs font-semibold leading-tight"
        >
          <span
            aria-hidden
            class="absolute inset-0 rounded-full opacity-50"
            [class.bg-green-200]="transaction.transaction_type === 'credit'"
            [class.bg-blue-300]="transaction.transaction_type !== 'credit'"
          ></span>
          <span
            class="relative"
            [class.text-[#027A48]="transaction.transaction_type === 'credit'"
            [class.text-blue-700]="transaction.transaction_type !== 'credit'"
          >
            {{ transaction.transaction_type | titlecase }}
          </span>
        </span>
      </div>
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Transaction Channel:</dt>
        <dd class="ml-4">
          {{ transaction.transaction_channel }}
        </dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Transaction Amount:</dt>
        <dd class="ml-4">
          ₦{{ transaction.transaction_amount / 100 | number }}
        </dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Current Balance:</dt>
        <dd class="ml-4">₦{{ transaction.current_balance / 100 | number }}</dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Status:</dt>
        <span
          class="relative inline-block px-2 py-1 text-xs font-semibold leading-tight"
        >
          <span
            aria-hidden
            class="absolute inset-0 rounded-full opacity-50"
            [ngClass]="[
              transaction.status === 'denied' ? 'bg-mango-shellRed ' : '',
              transaction.status === 'completed' ? 'bg-green-100 ' : '',
              transaction.status === 'pending' ||
              transaction.status === 'withheld'
                ? 'bg-mango-paleBlue '
                : ''
            ]"
          ></span>
          <span
            class="relative"
            [ngClass]="[
              transaction.status === 'denied' ? ' text-mango-red707' : '',
              transaction.status === 'completed' ? ' text-mango-pineGreen' : '',
              transaction.status === 'pending' ||
              transaction.status === 'withheld'
                ? ' text-indigo-700'
                : ''
            ]"
          >
            {{ transaction.status | uppercase }}
          </span>
        </span>
      </div>
    </dl>
  } @else {
    <div class="flex flex-col gap-4">
      <ngx-skeleton-loader
        *ngFor="let in of [0, 1, 2, 3, 4, 5, 6]; let i = index"
        [theme]="{ height: '1.5rem', width: '100%' }"
      ></ngx-skeleton-loader>
    </div>
  }
</app-slide-in-right-modal>
