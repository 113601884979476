<app-kyc-accordion
  accordionKey="directorDetails"
  accordionTitle="Director Details"
  [isKycComplete]="isKycComplete"
>
  <div *ngIf="!directorDetails?.length">
    <p>No director details</p>
  </div>
  <div
    *ngIf="directorDetails?.length"
    class="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-4"
  >
    @for (director of directorDetails; track director) {
      <div
        class="director__wrapper inline-flex items-center justify-between rounded-[0.625rem] bg-mango-orangeFF px-[0.88rem] py-[0.62rem] xl:px-[1.76rem]"
      >
        <div class="flex flex-col">
          <span class="text-base font-bold text-mango-black17">{{
            director.first_name + ' ' + director.last_name
          }}</span>
          <span class="text-sm leading-6 text-mango-gray74">
            {{ director.email || '—' }}
          </span>
          <span class="text-sm leading-6 text-mango-gray74">
            {{ director.phone_number || '—' }}
          </span>
          <span class="text-sm leading-6 text-mango-gray74">
            {{ director.bvn || '—' }}
          </span>
        </div>
      </div>
    }
  </div>
</app-kyc-accordion>
