import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { CorporateAccountkyc } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-view-company-details',
  standalone: true,
  imports: [CommonModule, KycAccordionComponent],
  templateUrl: './view-company-details.component.html',
  styleUrl: './view-company-details.component.scss',
})
export class ViewCompanyDetailsComponent {
  @Input() companyDetails!: CorporateAccountkyc;
  accordionTitle: string = 'Company Details';
  accordionKey: string = 'companyDetails';

  isKycComplete: boolean = false;
  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyDetails']) {
      this.checkKycStatus();
    }
  }
 
  private checkKycStatus(): void {

  if (!this.companyDetails || !this.companyDetails.corporate_kyc) {
    this.isKycComplete = false;
    return;
  }
  this.isKycComplete = !!(
    this.companyDetails.corporate_kyc.name &&
    this.companyDetails.corporate_kyc.registration_number &&
    this.companyDetails.corporate_kyc.registered_date &&
    this.companyDetails.corporate_kyc.business_address &&
    this.companyDetails.corporate_kyc.business_type
  );
}}
