import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { EditJointMeansOfIdComponent } from './pages/edit-joint-means-of-id/edit-joint-means-of-id.component';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { EditJointCustomerInformationComponent } from './pages/edit-joint-customer-information/edit-joint-customer-information.component';
import { EditJointKinComponent } from './pages/edit-joint-kin/edit-joint-kin.component';
import { EditJointEmploymentComponent } from './pages/edit-joint-employment/edit-joint-employment.component';
import { EditJointBankComponent } from './pages/edit-joint-bank/edit-joint-bank.component';
import { EditJointLocationComponent } from './pages/edit-joint-location/edit-joint-location.component';
import { JointAccountKycDocComponent } from '../../../customers/create-customer-account/joint/joint-account-kyc-doc/joint-account-kyc-doc.component';
import { EditJointDocsComponent } from './pages/edit-joint-docs/edit-joint-docs.component';
import { EditJointSignatoryComponent } from './pages/edit-joint-signatory/edit-joint-signatory.component';
import { EditSignatoryDetailsComponent } from '../../company-account/edit-account/edit-signatory-details/edit-signatory-details.component';
import { JointRegistrationService } from '@core/services/joint-registration.service';
import {
  GetJointAccountData,
  JointAccountbankinfo,
  JointEmployerkyc,
  JointIdkyc,
  Jointkyc,
  JointLocationkyc,
  JointNextofkin,
  JointPersonaldetails,
} from '@core/models/admin/account/joint';
import { LoadingService } from '@core/services/loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';
import { DocKyc } from '@core/models/admin/account';
import {
  CorporateRegistrationService,
  GetAccountSignatoriesData,
} from '@core/services/corporate-registration.service';
import { NotificationFacade } from '@core/facades/notification.facade';

@Component({
  selector: 'app-edit-joint-account',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgxSkeletonLoaderModule,
    RouterLinkActive,
    EditJointMeansOfIdComponent,
    EditJointCustomerInformationComponent,
    EditJointKinComponent,
    EditJointEmploymentComponent,
    EditJointBankComponent,
    EditJointLocationComponent,
    JointAccountKycDocComponent,
    EditJointDocsComponent,
    EditJointSignatoryComponent,
    EditSignatoryDetailsComponent,
  ],
  templateUrl: './edit-joint-account.component.html',
  styleUrl: './edit-joint-account.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointAccountComponent implements OnInit, OnDestroy {
  userDetailsUrl!: string;
  accountsUrl!: any; //TODO: Fix this
  accountId!: string;
  isLoading$!: Observable<boolean>;
  subs: Subscription[] = [];

  jointAccountDataSubject$ = new BehaviorSubject<GetJointAccountData | null>(
    null
  );
  jointAccountData$ = this.jointAccountDataSubject$.asObservable();
  jointAccountId$ = this.jointAccountDataSubject$
    .asObservable()
    .pipe(map((data) => data?.joint_kyc[0].id));
  jointAccountName$ = this.jointAccountDataSubject$.asObservable().pipe(
    map((data) => {
      const firstAccountName = `${data?.joint_kyc[0].personal_details.first_name} ${data?.joint_kyc[0].personal_details.last_name}`;
      const secondAccount = data?.joint_kyc[1];
      const secondAccountName = secondAccount
        ? ` & ${secondAccount.personal_details.first_name} ${secondAccount.personal_details.last_name}`
        : ' & Profile 2';

      return `${firstAccountName}${secondAccountName}`;
    })
  );

  idKycDetails$ = this.jointAccountDataSubject$.asObservable().pipe(
    map((data) => {
      const idKycDetails: JointIdkyc[] = [];
      console.log(data?.joint_kyc);

      if (data?.joint_kyc) {
        for (const profile of data.joint_kyc) {
          idKycDetails.push(profile.id_kyc);
        }
      }
      console.log(idKycDetails);

      return idKycDetails;
    })
  );

  idKycDetails!: JointIdkyc[]; // PAIR for two users
  employmentDetails!: JointEmployerkyc;
  nextOfKinDetails!: JointNextofkin;
  locationDetails!: JointLocationkyc;
  personalDetails!: JointPersonaldetails[]; // PAIR for two users
  bankDetails!: JointAccountbankinfo[];
  documentDetails!: DocKyc[];
  signatoriesList: GetAccountSignatoriesData[] = [];
  jointDataDetails!: Jointkyc[];

  private breadCrumbService = inject(BreadCrumbService);
  private route = inject(ActivatedRoute);
  private jointAccountService = inject(JointRegistrationService);
  private loadingService = inject(LoadingService);
  private corporateService = inject(CorporateRegistrationService);
  private toast = inject(NotificationFacade);

  constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      URL_KEYS.JOINT.GET_JOINT_ACCOUNT_KYC
    );
  }

  goBack() {
    history.back();
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.params['accountId'];
    this.breadCrumbService.setPageBreadCrumb(this.route);
    if (this.accountId) {
      console.log(this.accountId);

      this.userDetailsUrl = `/admin/accounts/joint/edit/${this.accountId}`;

      this.getJointKycInfo();
      this.getAccountSignatories();
    }
  }

  getJointKycInfo() {
    const sub = this.jointAccountService
      .getJointKycInfo(this.accountId)
      .subscribe({
        next: (response) => {
          if (response.joint_kyc) {
            this.jointAccountDataSubject$.next(response);
            const jointAcctOne = response.joint_kyc[0];
            this.idKycDetails = [jointAcctOne.id_kyc];
            this.employmentDetails = jointAcctOne.employer_kyc;
            this.nextOfKinDetails = jointAcctOne.next_of_kin;
            this.locationDetails = jointAcctOne.location_kyc;
            this.personalDetails = [jointAcctOne.personal_details];
            this.bankDetails = response.account_bank_info ?? [];
            this.documentDetails = response.doc;
            this.jointDataDetails = response.joint_kyc;

            const jointAcctTwo = response.joint_kyc[1];
            if (jointAcctTwo) {
              this.idKycDetails.push(jointAcctTwo.id_kyc);
              this.personalDetails.push(jointAcctTwo.personal_details);
            }
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    this.subs.push(sub);
  }

  getAccountSignatories(): void {
    const sub = this.corporateService
      .getAccountSignatories(this.accountId)
      .subscribe({
        next: (response) => {
          this.signatoriesList = response.data;
        },
        error: () => {
          this.toast.error('Failed to fetch signatories');
        },
      });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
