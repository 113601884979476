import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@core/environments/environment';
import { LoadingService } from './loading.service';
import { finalize, Observable } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import Decimal from 'decimal.js';

@Injectable({
  providedIn: 'root',
})
export class MutualFundService {
  http = inject(HttpClient);
  BASE_URL = `${environment.ADMIN_BASE_URL}/v1/mutual-funds`;

  constructor() {}

  private loadingService = inject(LoadingService);

  getMutualFundsList(
    data: Pagination = new Pagination(),
    search_text: string = '',
    start_date?: Date,
    end_date?: Date
  ): Observable<MutualFundsListResponse> {
    const uniqueStateUrl = 'get-mutual-funds-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    let params = new HttpParams({
      fromObject: {
        take: data.size.toString(),
        skip: data.skip.toString(),
        ...(start_date
          ? { start_date: start_date.toISOString().split('T')[0] }
          : {}),
        ...(end_date ? { end_date: end_date.toISOString().split('T')[0] } : {}),
        ...(search_text
          ? /^\d+$/.test(search_text)
            ? { account_number: search_text }
            : { name: search_text }
          : {}),
      },
    });

    return this.http
      .get<MutualFundsListResponse>(
        `${this.BASE_URL}/list?${params.toString()}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getMutualFundsTransactionsList(
    data: Pagination = new Pagination(),
    search_text: string = '',
    start_date?: Date,
    end_date?: Date
  ): Observable<MutualFundsListResponse> {
    const uniqueStateUrl = 'get-mutual-funds-transactions-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    let params = new HttpParams({
      fromObject: {
        take: data.size.toString(),
        skip: data.skip.toString(),
        ...(start_date
          ? { start_date: start_date.toISOString().split('T')[0] }
          : {}),
        ...(end_date ? { end_date: end_date.toISOString().split('T')[0] } : {}),
        ...(search_text
          ? /^\d+$/.test(search_text)
            ? { account_number: search_text }
            : { name: search_text }
          : {}),
      },
    });

    return this.http
      .get<MutualFundsListResponse>(
        `${this.BASE_URL}/get-funds-transaction?${params.toString()}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getDividendList(
    data: Pagination = new Pagination(),
    start_date?: Date,
    end_date?: Date
  ): Observable<MutualFundsListResponse> {
    const uniqueStateUrl = 'get-dividend-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    let params = new HttpParams({
      fromObject: {
        take: data.size.toString(),
        skip: data.skip.toString(),
        ...(start_date
          ? { start_date: start_date.toISOString().split('T')[0] }
          : {}),
        ...(end_date ? { end_date: end_date.toISOString().split('T')[0] } : {}),
      },
    });
    return this.http
      .get<MutualFundsListResponse>(
        `${this.BASE_URL}/get-dividends?${params.toString()}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getIndividualMutualFundsTransactionsList(
    accountNumber: string
  ): Observable<MutualFundsListResponse> {
    const uniqueStateUrl = 'get-mutual-funds-transactions-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<MutualFundsListResponse>(`${this.BASE_URL}/get-funds-transaction`, {
        params: new HttpParams().set('account_number', accountNumber),
      })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getDividendHistoryListByAccount(
    accountNumber: string
  ): Observable<DividendListResponse> {
    const uniqueStateUrl = 'get-dividend-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<DividendListResponse>(
        `${this.BASE_URL}/get-dividend-history-by-account`,
        {
          params: new HttpParams().set('account_number', accountNumber),
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getDividendByAccountNumber(
    accountNumber: string
  ): Observable<DividendByAccountResponse> {
    const uniqueStateUrl = 'get-dividend-by-account';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<DividendByAccountResponse>(
        `${this.BASE_URL}/get-dividends-by-account`,
        {
          params: new HttpParams().set('account_number', accountNumber),
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getTotalWeight(date: string): Observable<GlobalWeightResponse> {
    const uniqueStateUrl = 'get-global-weight';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<GlobalWeightResponse>(`${this.BASE_URL}/get-global-weight`, {
        params: new HttpParams().set('date', date),
      })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getDividendDistribution(
    date: string
  ): Observable<DividendDistributionResponse> {
    const uniqueStateUrl = 'get-dividend-distribution';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<DividendDistributionResponse>(
        `${this.BASE_URL}/get-dividend-distributions`,
        {
          params: new HttpParams().set('date', date),
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getDailyDividend(date: string): Observable<DailyDividendResponse> {
    const uniqueStateUrl = 'get-daily-dividend';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<DailyDividendResponse>(
        `${this.BASE_URL}/get-dividend-distribution-sum-by-date`,
        {
          params: new HttpParams().set('date', date),
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getTotalPrincipal(date: string): Observable<TotalPrincipalResponse> {
    const uniqueStateUrl = 'get-global-principal';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<TotalPrincipalResponse>(`${this.BASE_URL}/get-global-principal`, {
        params: new HttpParams().set('date', date),
      })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getTotalAccured(
    account_number: string,
    date: string
  ): Observable<TotalAccuredResponse> {
    const uniqueStateUrl = 'get-total-accured';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<TotalAccuredResponse>(`${this.BASE_URL}/get-accrual-by-account`, {
        params: new HttpParams()
          .set('account_number', account_number)
          .set('date', date),
      })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  revaluateWeight(): Observable<RevaluateWeightResponse> {
    const uniqueStateUrl = 'revaluate-weight';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<RevaluateWeightResponse>(`${this.BASE_URL}/update-weights`, {})
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  revaluateDivident(payload: any): Observable<RevaluateWeightResponse> {
    const uniqueStateUrl = 'revaluate-weight';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<RevaluateWeightResponse>(
        `${this.BASE_URL}/evaluate-dividends`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addRedemption(data: IAddTransactionPayload): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/add-redemption`, data);
  }
  addSubscription(data: IAddTransactionPayload): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/add-subscription`, data);
  }
  investmentAction(data: IInvestementActionPayload): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/investment-action`, data);
  }
  addDividend(data: IDividendPayload): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/create-dividend`, data);
  }
  editDividend(data: IDividendPayload): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/update-dividend`, data);
  }
}

interface MutualFundsListResponse {
  status: string;
  message: string;
  data: MutualFundsListData;
}

interface MutualFundsListData {
  count: number;
  data: MutualFundsListData[];
}

interface MutualFundsListData {
  name: string;
  account_number: string;
  amount: number;
  type: string;
  weight: number;
  created_at: string;
  updated_at: string;
}

interface DividendListResponse {
  status: string;
  message: string;
  data: DividendListData[];
}

interface DividendListData {
  date: string;
  dividend: number;
}

interface DividendByAccountResponse {
  status: string;
  message: string;
  data: DividendByAccountData;
}

interface DividendByAccountData {
  principal_till_date: number;
  total_dividend: number;
  total_value: number;
  account_number: number;
}
interface SumData {
  dividend: number;
}
interface RevaluateWeightResponse {
  status: string;
  message: string;
}

interface IAddTransactionPayload {
  name: string;
  account_number: string;
  amount: Decimal;
  type: string;
}
export interface IInvestementActionPayload {
  name: string;
  account_number: string;
  amount: string;
  action_type: string;
  date: string;
}

interface TotalAccuredResponse {
  status: string;
  message: string;
  data: TotalAccuredData;
}
interface TotalAccuredData {
  _sum: {
    dividend: number;
  };
}
interface TotalPrincipalResponse {
  status: string;
  message: string;
  data: TotalPrincipalData;
}

interface TotalPrincipalData {
  amount: number;
}

interface GlobalWeightResponse {
  status: string;
  message: string;
  data: number;
}

interface IDividendPayload {
  amount: number;
  date: string;
}

interface DailyDividendResponse {
  status: string;
  message: string;
  data: {
    _sum: Dividend;
  };
}
interface Dividend {
  dividend: number;
}

interface DividendDistributionResponse {
  status: string;
  message: string;
  data: DailyDividendData;
}

interface DailyDividendData {
  dividend_amount: DividendAmount;
  distributed_dividend: DistributedDividend[];
}

interface DividendAmount {
  _sum: { dividend: number };
}

interface DistributedDividend {
  dividend: number;
  account_number: string;
  date: string;
  weight: number;
}
