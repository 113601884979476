import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import { Pagination } from '@core/models/pagination.model';
import { finalize, map, Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';
import { ViewProfileList, ViewProfileListData } from '@core/models/admin/users';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  http = inject(HttpClient);
  BASE_URL = `${environment.ADMIN_BASE_URL}/v1`;

  private loadingService = inject(LoadingService);

  constructor() {}

  filterProfileList(queryParams: string, data: Pagination = new Pagination()): Observable<ViewProfileListData> {
    return this.http.get<ViewProfileList>(
      `${this.BASE_URL}/admin/filter-profile-list?${queryParams}&take=${data.size}&skip=${data.skip}`
    ).pipe(
      map((res: ViewProfileList) => res.data)
    );
  }

  viewProfileList(
    data: Pagination = new Pagination()
  ): Observable<ViewProfileListData> {
    const uniqueStateUrl = URL_KEYS.USER.VIEW_PROFILE_LIST;
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<ViewProfileList>(
        `${this.BASE_URL}/admin/view-profile-list?take=${data.size}&order=${data.order}&skip=${data.skip}`
      )
      .pipe(
        map((res: ViewProfileList) => res.data),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}
