import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ViewCompanyDetailsComponent } from './pages/view-company-details/view-company-details.component';
import { ViewBankDetailsComponent } from './pages/view-bank-details/view-bank-details.component';
import { ViewDirectorDetailsComponent } from './pages/view-director-details/view-director-details.component';
import { ViewDocumentsComponent } from './pages/view-documents/view-documents.component';
import { ActivatedRoute } from '@angular/router';
import { AccountManagementService } from '@core/services/account-management.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { Observable, Subscription } from 'rxjs';
import {
  CorporateAccountbankInfo,
  CorporateAccountkyc,
  CorporateData,
  CorporateDirector,
  CorporateDoc,
} from '@core/models/admin/account';
import { LoadingService } from '@core/services/loading.service';
import { CommonService } from '@core/services/common.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

interface FormPageDetails {
  id: string;
  key: string;
  isAccordionOpen: boolean;
}
interface FormPage {
  [key: string]: FormPageDetails;
}
@Component({
  selector: 'app-corporate-details',
  standalone: true,
  imports: [
    CommonModule,
    ViewCompanyDetailsComponent,
    ViewBankDetailsComponent,
    ViewDirectorDetailsComponent,
    ViewDocumentsComponent,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './corporate-details.component.html',
  styleUrl: './corporate-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorporateDetailsComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  accountId!: string;
  corporateAccountData!: CorporateData;
  companyDetails!: CorporateAccountkyc;
  bankDetails!: CorporateAccountbankInfo[];
  directorDetails!: CorporateDirector[];
  documents!: CorporateDoc[];
  bankName!: string;

  isFetchingBankList$!: Observable<boolean>;
  isFetchingAcctInfo$!: Observable<boolean>;

  isLoading!: boolean;

  private route = inject(ActivatedRoute);
  private cdr = inject(ChangeDetectorRef);
  private toast = inject(NotificationFacade);
  private accountService = inject(AccountManagementService);
  private loadingService = inject(LoadingService);
  private commonService = inject(CommonService);

  constructor() {
    this.isFetchingBankList$ =
      this.loadingService.getLoadingObservable('get-bank-list');
    this.isFetchingAcctInfo$ = this.loadingService.getLoadingObservable(
      'get-corporate-account-kyc'
    );
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.parent?.params['accountId'];

    if (this.accountId) {
      this.getCompanyDetails();
    }
  }

  getCompanyDetails() {
    this.isLoading = true;
    const subs = this.accountService
      .getCorporateAccountInfo(this.accountId)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.corporateAccountData = response.data;
          this.cdr.detectChanges();

          this.companyDetails = this.corporateAccountData.account_kyc;
          this.bankDetails =
            this.corporateAccountData.account_kyc.account_bank_info;
          // if (this.bankDetails.length > 0) {
          //   const bankCode =
          //     this.corporateAccountData.account_kyc.account_bank_info[0]
          //       .bank_code;
          //   this.commonService
          //     .getBankName(bankCode)
          //     .then((bankName) => {
          //       if (bankName) {
          //         console.log(bankName);

          //         this.bankName = bankName;
          //       } else {
          //         this.toast.error('Failed to fetch bank name');
          //       }
          //     })
          //     .catch((error) => {
          //       this.toast.error('Failed to fetch bank name');
          //     });
          // }
          if (this.corporateAccountData.director) {
            this.directorDetails = this.corporateAccountData.director;
          }
          this.documents = this.corporateAccountData.account_kyc.doc;
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.isLoading = false;
        },
      });
    this.subs.push(subs);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
