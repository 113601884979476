import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { EditCompanyDetailsComponent } from './edit-company-details/edit-company-details.component';
import { EditBankDetailsComponent } from './edit-bank-details/edit-bank-details.component';
import { EditDirectorDetailsComponent } from './edit-director-details/edit-director-details.component';
import { EditSignatoryDetailsComponent } from './edit-signatory-details/edit-signatory-details.component';
import { EditDocumentsComponent } from './edit-documents/edit-documents.component';
import { AccountManagementService } from '@core/services/account-management.service';
import { Observable, Subscription } from 'rxjs';
import {
  CorporateAccountbankInfo,
  CorporateAccountkyc,
  CorporateData,
  CorporateDirector,
  CorporateDoc,
} from '@core/models/admin/account';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { CommonModule } from '@angular/common';
import {
  CorporateRegistrationService,
  GetAccountSignatoriesData,
} from '@core/services/corporate-registration.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoadingService } from '@core/services/loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';

@Component({
  selector: 'app-edit-account',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    EditCompanyDetailsComponent,
    EditBankDetailsComponent,
    EditDirectorDetailsComponent,
    EditSignatoryDetailsComponent,
    EditSignatoryDetailsComponent,
    EditDocumentsComponent,
    CommonModule,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './edit-account.component.html',
  styleUrl: './edit-account.component.scss',
})
export class EditAccountComponent implements OnInit, OnDestroy {
  userDetailsUrl!: string;
  accountId!: string;
  accountUrl!: string;
  isLoading!: boolean;
  subs: Subscription[] = [];
  corporateAccountData!: CorporateData;
  companyDetails!: CorporateAccountkyc;
  bankDetails!: CorporateAccountbankInfo[];
  directorDetails!: CorporateDirector[] | null;
  signatoriesList: GetAccountSignatoriesData[] = [];
  documents!: CorporateDoc[];

  isFetchingSignatories$!: Observable<boolean>;
  isFetchingCompanyDetails$!: Observable<boolean>;

  route = inject(ActivatedRoute);
  private accountService = inject(AccountManagementService);
  cdr = inject(ChangeDetectorRef);
  toast = inject(NotificationFacade);
  corporateService = inject(CorporateRegistrationService);
  breadCrumbService = inject(BreadCrumbService);
  private loadingService = inject(LoadingService);

  constructor() {
    this.isFetchingSignatories$ = this.loadingService.getLoadingObservable(
      URL_KEYS.CORPORATE_REGISTRATION.GET_ACCOUNT_SIGNATORIES
    );
    this.isFetchingCompanyDetails$ = this.loadingService.getLoadingObservable(
      'get-corporate-account-kyc'
    );
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.params['accountId'];
    this.userDetailsUrl = `/admin/accounts/corporate/edit/${this.accountId}`;
    this.breadCrumbService.setPageBreadCrumb(this.route);

    if (this.accountId) {
      this.getCompanyDetails();
      this.getAccountSignatories();
    }
  }

  getCompanyDetails() {
    const subs = this.accountService
      .getCorporateAccountInfo(this.accountId)
      .subscribe({
        next: (response) => {
          this.corporateAccountData = response.data;
          this.companyDetails = this.corporateAccountData.account_kyc;
          this.bankDetails =
            this.corporateAccountData.account_kyc.account_bank_info;
          this.directorDetails = this.corporateAccountData.director;
          this.documents = this.corporateAccountData.account_kyc.doc;
          this.cdr.detectChanges();
        },
        error: (err) => {},
      });
    this.subs.push(subs);
  }

  getAccountSignatories(): void {
    const sub = this.corporateService
      .getAccountSignatories(this.accountId)
      .subscribe({
        next: (response) => {
          this.signatoriesList = response.data;
          this.cdr.detectChanges();
        },
        error: (response) => {
          this.toast.error('Failed to fetch signatories');
        },
      });

    this.subs.push(sub);
  }

  goBack() {
    history.back();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
