<div class="bg-whites flex min-h-screen items-center justify-center">
  <div class="mb-4 flex flex-col items-center justify-center lg:mb-8">
    <img
      src="assets/images/not-found.gif"
      alt="404 Image"
      class="w-49 h-49 lg:w-100 lg:h-100 -mt-36"
    />
    <div class="mb-4">
      <h3
        class="-mt-32 text-center text-2xl font-semibold text-gray-800 sm:text-2xl md:-mt-36 md:text-3xl lg:text-4xl"
      >
        Something's Missing
      </h3>

      <p class="mt-3 text-center text-base text-gray-600 sm:text-sm md:text-sm">
        Looks like the page you're trying to visit doesn't exist. Check the URL
        and<br />try again
      </p>
    </div>
    <button mango-button (click)="goBackToDashboard()" cla>
      Back to Dashboard
    </button>
  </div>
</div>
