<!-- table skeleton loader -->
@if (isFetchingDividendList$ | async) {
  <div class="mt-[0.94rem]">
    <app-table-skeleton-loader></app-table-skeleton-loader>
  </div>
}

<!-- Table -->
<div
  *ngIf="!(isFetchingDividendList$ | async)"
  class="mt-[0.94rem] rounded-lg border border-solid border-mango-gray200 bg-white"
>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="flex border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold text-gray-600"
              >
                S/N
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99998 3.33301V12.6663M7.99998 12.6663L12.6666 7.99967M7.99998 12.6663L3.33331 7.99967"
                    stroke="#667085"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Date
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Amount
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600"
              >
                Yield
              </th>
              <th
                scope="col"
                class="relative border-b-2 border-gray-200 bg-gray-100 py-3.5 pl-3 pr-4 sm:pr-6"
              ></th>
            </tr>
          </thead>

          <tbody>
            @if ((dividendList$ | async).length > 0) {
              @for (
                dividend of dividendList$ | async;
                track dividend.id;
                let i = $index
              ) {
                <ng-container
                  *ngTemplateOutlet="
                    dividendRow;
                    context: { data: dividend, i: i }
                  "
                ></ng-container>
              }
            }
          </tbody>
        </table>

        <app-table-navigator
          [total]="totalCount"
          (onNavigation)="navigate($event)"
        ></app-table-navigator>
      </div>
    </div>
  </div>
</div>

<ng-template #dividendRow let-dividend="data" let-i="i">
  <tr>
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p>{{ i + 1 }}</p>
    </td>

    <!-- Dividend Date -->
    <td
      class="cursor-pointer border-b border-gray-200 bg-white px-5 py-5 text-sm"
      (click)="viewDistribution(dividend.dayDate)"
    >
      <p class="whitespace-no-wrap text-gray-900">
        {{ dividend.dayDate | date: 'shortDate' }}
      </p>
    </td>

    <!-- Amount -->
    <td
      class="cursor-pointer border-b border-gray-200 bg-white px-5 py-5 text-sm"
      (click)="viewDistribution(dividend.dayDate)"
    >
      <p class="whitespace-no-wrap text-gray-900">
        {{ dividend.dividendAmount / 100 | number: '1.2-2' }}
      </p>
    </td>

    <!-- Yield -->
    <td
      class="cursor-pointer border-b border-gray-200 bg-white px-5 py-5 text-sm"
      (click)="viewDistribution(dividend.dayDate)"
    >
      <p class="whitespace-no-wrap text-gray-900">
        {{ dividend.yield | number: '1.2-2' }}%
      </p>
    </td>

    <td
      class="relative whitespace-nowrap border-b py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
    >
      <div
        class="relative inline-block w-fit cursor-pointer border-gray-200 bg-white text-left"
      >
        <app-dropdown
          [id]="dividend.id"
          [actionList]="initDropdownList(dividend)"
        >
        </app-dropdown>
      </div>
    </td>
  </tr>
</ng-template>
