<app-kyc-accordion accordionKey="documentDetails" accordionTitle="Documents" [isKycComplete]="isKycComplete">
  <div *ngIf="!kycDocuments?.length">
    <p>No document(s) uploaded</p>
  </div>
  <div
    *ngIf="kycDocuments?.length"
    class="flex flex-col gap-5 md:grid md:grid-cols-4 md:gap-x-4 md:gap-y-5"
  >
    @for (doc of kycDocuments; track doc) {
      <button
        (click)="openDocument(doc.link)"
        [title]="DocumentTitle[doc.document_type]"
        class="flex cursor-pointer items-center gap-3 rounded-lg bg-mango-lightOrange px-[0.88rem] py-3 shadow-sm"
      >
        <img src="assets/images/svgs/file-text.svg" alt="" class="h-6 w-6" />
        <span
          class="flex flex-col overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium"
        >
          <span
            [ngClass]="{
              'bg-green-100 text-mango-pineGreen': doc.status === 'approved',
              'bg-red-100 text-mango-sharpRed':
                doc.status === 'rejected' || doc.status === 'pending'
            }"
            class="w-fit items-center rounded-full px-2 py-1 text-xs font-medium"
          >
            {{ doc.status }}
          </span>

          @if (DocumentTitle[doc.document_type]) {
            <span class="overflow-hidden text-ellipsis">
              {{ DocumentTitle[doc.document_type] }}</span
            >
          } @else {
            <span class="overflow-hidden text-ellipsis">
              {{ doc.document_type }}</span
            >
          }
        </span>

        <div class="ml-auto inline-flex gap-2">
          <span>View</span>
          <!-- Heroicons: arrow-top-right-on-square -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-5 w-5 shrink-0"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </div>
      </button>
    }
  </div>
</app-kyc-accordion>
