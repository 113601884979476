import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import {
  IndividualAccountKycData,
  IndividualAccountProfilesDatum,
} from '@core/models/admin/account';
import { AccountManagementService } from '@core/services/account-management.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { LoadingService } from '@core/services/loading.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-profile-accounts',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    ButtonDirective,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './profile-accounts.component.html',
  styleUrl: './profile-accounts.component.scss',
})
export class ProfileAccountsComponent {
  @Input() type!: string;

  accountId!: string;
  subs$: Subscription[] = [];
  accountsUrl!: string;
  userDetailsUrl!: string;
  isLoadingInfo$!: Observable<boolean>;
  isLoadingProfile$!: Observable<boolean>;
  userAccounts$!: Observable<IndividualAccountProfilesDatum[]>;
  private accountDataSubject =
    new BehaviorSubject<IndividualAccountKycData | null>(null);
  user$ = this.accountDataSubject.asObservable();
  private accountProfilesSubject = new BehaviorSubject<
    IndividualAccountProfilesDatum[] | null
  >(null);
  profiles$ = this.accountProfilesSubject.asObservable();

  private route = inject(ActivatedRoute);
  private accountService = inject(AccountManagementService);
  private router = inject(Router);
  private breadCrumbService = inject(BreadCrumbService);
  private loadingService = inject(LoadingService);

  constructor() {
    this.isLoadingInfo$ = this.loadingService.getLoadingObservable(
      'get-individual-account-kyc'
    );
    this.isLoadingProfile$ = this.loadingService.getLoadingObservable(
      'get-profile-accounts'
    );
  }

  ngOnInit(): void {
    this.breadCrumbService.setPageBreadCrumb(this.route);
    this.accountId = this.route.snapshot.params['accountId'];
    if (this.accountId) {
      this.getProfileInfo();
      this.accountsUrl = `/admin/accounts/${this.type}/accounts/${this.accountId}`;
      this.userDetailsUrl = `/admin/accounts/${this.type}/${this.accountId}/details`;
    }
  }

  getProfileInfo() {
    let accountInfo$;
    if (this.type === 'joint') {
      accountInfo$ = this.accountService.getJointAccountInfo(this.accountId);
    } else {
      accountInfo$ = this.accountService.getIndividualAccountInfo(this.accountId);
    }

    const sub$ = accountInfo$
      .pipe(
        switchMap((accountInfo) => {
          this.accountDataSubject.next(accountInfo.data);
          return this.accountService.getAccountProfiles(accountInfo.data.user.id);
        })
      )
      .subscribe({
        next: (acctProfileResponse) => {
          this.accountProfilesSubject.next(acctProfileResponse.data);
        },
      });

    this.subs$.push(sub$);
  }


  editAccount() {
    this.router.navigateByUrl(`/admin/accounts/${this.type}/edit/${this.accountId}`);
  }

  goBack() {
    history.back();
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
  }
}
