import { Routes } from '@angular/router';
import { LoginComponent } from './auth/pages/login/login.component';
import { PassforgotComponent } from './auth/pages/passforgot/passforgot.component';
import { NewpasswordComponent } from './auth/pages/newpassword/newpassword.component';
import { AdminProfileComponent } from './admin-operations/admin-profile/admin-profile.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AdminUsersComponent } from './admin-operations/admin-users/admin-users.component';
import { CustomersComponent } from './customers/user-customers.component';
import { AuthLayoutComponent } from './auth/auth-layout/auth-layout.component';
import { WalletTransactionsComponent } from './transactions/components/wallet-transactions/wallet-transactions.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { AccountsComponent } from './accounts/accounts.component';
import { CompanyAccountComponent } from './accounts/company-account/company-account.component';
import { WalletComponent } from './accounts/company-account/pages/wallet/wallet.component';
import { AuditLogComponent } from './accounts/company-account/pages/audit-log/audit-log.component';
import { PortfolioComponent } from './accounts/company-account/pages/portfolio/portfolio.component';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { SettingsComponent } from './settings/settings.component';
import { IndividualAccountComponent } from './accounts/individual-account/individual-account.component';
import { RolesComponent } from './roles/roles.component';
import { CorporateDetailsComponent } from './accounts/company-account/pages/corporate-details/corporate-details.component';
import { DetailsComponent } from './accounts/individual-account/pages/details/details.component';
import { SignatoryListComponent } from './accounts/company-account/pages/signatory-list/signatory-list.component';
import { ViewDetailsComponent } from './transactions/components/wallet-transactions/pages/view-details/view-details.component';
import { EditAccountComponent } from './accounts/company-account/edit-account/edit-account.component';
import { AdminRegistrationComponent } from './admin-operations/admin-registration/admin-registration.component';
import { AdminEditComponent } from './admin-operations/admin-edit/admin-edit.component';
import { HomeComponent } from './home/home.component';
import { EditIndividualAccountComponent } from './accounts/individual-account/edit-account/edit-individual-account.component';
import { IndividualAccountsComponent } from './accounts/individual-account/pages/individual-accounts/individual-accounts.component';
import { ProfileComponent } from './profile/profile.component';
import { AssignRmFormComponent } from '@shared/components/assign-rm-form/assign-rm-form.component';
import { JOINT_ACCOUNT_PATHS } from './app.constants';
import { ApproveTransactionComponent } from './transactions/components/wallet-transactions/pages/approve-transaction/approve-transaction.component';
import { OrdersByAccountComponent } from '@accounts/common-pages/orders/orders-by-account.component';
import { EditJointAccountComponent } from '@accounts/joint-account/edit-account/edit-joint-account.component';
import { ReportComponent } from './transactions/components/wallet-transactions/pages/report/report.component';

export const routes: Routes = [
  {
    path: 'admin',
    component: DashboardLayoutComponent,
    children: [
      { path: '', title: 'Mango Home', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        title: 'Mango Home',
        component: HomeComponent,
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Home',
        },
      },
      {
        path: 'dashboard',
        title: 'Mango Home',
        component: HomeComponent,
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Home',
        },
      },
      {
        path: 'users',
        title: 'Admin Users',
        component: AdminUsersComponent,
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'System Users',
        },

        children: [
          { path: '', redirectTo: 'users', pathMatch: 'full' },
          {
            path: 'register',
            title: 'Register Admin',
            component: AdminRegistrationComponent,
          },
          {
            path: 'edit/:userId',
            title: 'Edit Admin',
            component: AdminEditComponent,
          },
          { path: 'profile/:userId', component: AdminProfileComponent },
        ],
      },
      {
        path: 'roles',
        title: 'Roles',
        component: RolesComponent,
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Roles',
        },
        children: [
          { path: '', redirectTo: 'roles', pathMatch: 'full' },
          {
            path: 'add-role',
            title: 'Add New Role',
            loadComponent: () =>
              import('./roles/pages/add-role/add-role.component').then(
                (component) => component.AddRoleComponent
              ),
          },
          {
            path: 'edit-role/:roleId',
            title: 'Edit Role',
            loadComponent: () =>
              import('./roles/pages/edit-role/edit-role.component').then(
                (component) => component.EditRoleComponent
              ),
          },
          {
            path: 'view-role/:roleId',
            title: 'View Role',
            loadComponent: () =>
              import('./roles/pages/view-role/view-role.component').then(
                (component) => component.ViewRoleComponent
              ),
          },
        ],
      },
      {
        path: 'customers',
        title: 'Mobile App Users',
        component: CustomersComponent,
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Mobile App Users',
        },
        children: [
          { path: '', redirectTo: 'customers', pathMatch: 'full' },

          // Corporate Routes
          {
            path: 'corp-basic-details',
            title: 'Basic Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/corporate/pages/basic-details/basic-details.component'
              ).then((component) => component.BasicDetailsComponent),
          },
          {
            path: 'corp-company-details',
            title: 'Company Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/corporate/pages/company-details/company-details.component'
              ).then((component) => component.CompanyDetailsComponent),
          },
          {
            path: 'corp-bank-details',
            title: 'Bank Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/corporate/pages/bank-details/bank-details.component'
              ).then((component) => component.BankDetailsComponent),
          },
          {
            path: 'corp-director-details',
            title: 'Director Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/corporate/pages/director-details/director-details.component'
              ).then((component) => component.DirectorDetailsComponent),
          },
          {
            path: 'corp-confirm-director',
            title: 'Confirm Director',
            loadComponent: () =>
              import(
                './customers/create-customer-account/corporate/pages/confirm-director/confirm-director.component'
              ).then((component) => component.ConfirmDirectorComponent),
          },
          {
            path: 'corp-add-signatory',
            title: 'Add Signatory',
            loadComponent: () =>
              import(
                './customers/create-customer-account/corporate/pages/add-signatory/add-signatory.component'
              ).then((component) => component.AddSignatoryComponent),
          },
          {
            path: 'corp-confirm-signatory',
            title: 'Confirm Signatory',
            loadComponent: () =>
              import(
                './customers/create-customer-account/corporate/pages/confirm-signatory/confirm-signatory.component'
              ).then((component) => component.ConfirmSignatoryComponent),
          },
          {
            path: 'corp-documents',
            title: 'Documents',
            loadComponent: () =>
              import(
                './customers/create-customer-account/corporate/pages/documents/documents.component'
              ).then((component) => component.DocumentsComponent),
          },
          // Individual Routes
          {
            path: 'ind-personal-details',
            title: 'Personal Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/individual/pages/personal-details/personal-details.component'
              ).then((component) => component.PersonalDetailsComponent),
          },
          {
            path: 'ind-location-details',
            title: 'Location Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/individual/pages/location-details/location-details.component'
              ).then((component) => component.LocationDetailsComponent),
          },
          {
            path: 'ind-identification',
            title: 'Means of ID',
            loadComponent: () =>
              import(
                './customers/create-customer-account/individual/pages/means-of-id/means-of-id.component'
              ).then((component) => component.MeansOfIdComponent),
          },
          {
            path: 'ind-employment',
            title: 'Employment Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/individual/pages/employment-details/employment-details.component'
              ).then((component) => component.EmploymentDetailsComponent),
          },
          {
            path: 'ind-kin',
            title: 'Next of Kin',
            loadComponent: () =>
              import(
                './customers/create-customer-account/individual/pages/next-of-kin/next-of-kin.component'
              ).then((component) => component.NextOfKinComponent),
          },
          {
            path: 'ind-bank-details',
            title: 'Bank Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/individual/pages/bank-details/bank-details.component'
              ).then((component) => component.BankDetailsComponent),
          },
          {
            path: 'ind-documents',
            title: 'Documents',
            loadComponent: () =>
              import(
                './customers/create-customer-account/individual/pages/documents/documents.component'
              ).then((component) => component.DocumentsComponent),
          },
          // Joint Account
          {
            path: 'joint-profile-details',
            title: 'Joint Account Details',
            loadComponent: () =>
              import(
                './customers/create-customer-account/joint/joint.component'
              ).then((component) => component.JointComponent),
          },
        ],
      },

      {
        path: 'orders',
        title: 'Orders',
        loadComponent: () =>
          import('./orders/orders.component').then(
            (component) => component.OrdersComponent
          ),
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Orders',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'orders',
          },
          {
            path: 'details/:orderId',
            title: 'Order Details',
            loadComponent: () =>
              import(
                './orders/pages/view-order-details/view-order-details.component'
              ).then((component) => component.ViewOrderDetailsComponent),
          },
        ],
      },

      {
        path: 'transactions/wallet',
        title: 'Transactions - Wallet',
        component: WalletTransactionsComponent,
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Transactions - Wallet',
        },
        children: [
          {
            path: 'details/:transactionId',
            component: ViewDetailsComponent,
            data: {
              breadcrumbMain: 'Transactions',
              breadcrumbNext: 'Details',
            },
          },
          {
            path: 'export-report',
            component: ReportComponent,
            data: {
              breadcrumbMain: 'Transactions',
              breadcrumbNext: 'Trnasactions Report',
            },
          },
          {
            path: 'approve',
            component: ApproveTransactionComponent,
          },
        ],
      },
      {
        path: 'transactions/cash',
        title: 'Transactions - Cash',
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Transactions - Cash',
        },
        loadComponent: () =>
          import(
            './transactions/components/cash-transactions/cash-transactions.component'
          ).then((component) => component.CashTransactionsComponent),
        children: [
          {
            path: ':transactionId',
            title: 'Cash Transaction Details',
            loadComponent: () =>
              import(
                './transactions/components/cash-transactions/pages/view-cash-details/view-cash-details.component'
              ).then((component) => component.ViewCashDetailsComponent),
          },
        ],
      },
      { path: 'settings', component: SettingsComponent, title: 'Settings' },
      {
        path: 'pms',
        title: 'PMS Products',
        loadComponent: () =>
          import('./products-pms/products.component').then(
            (component) => component.PmsProductsComponent
          ),
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'PMS Products',
        },
        children: [
          {
            path: '',
            redirectTo: 'products-fixed-deposit',
            pathMatch: 'full',
          },
          {
            path: 'add',
            loadComponent: () =>
              import(
                './products-pms/pages/create-new-product/create-new-product.component'
              ).then((component) => component.CreateNewProductComponent),
          },

          {
            path: 'edit-product/:productName/:productId',
            loadComponent: () =>
              import(
                './products-pms/pages/edit-product/edit-product.component'
              ).then((component) => component.EditProductComponent),
          },
          {
            path: 'modify-pms-composition/:productName/:productId',
            loadComponent: () =>
              import(
                './products-pms/pages/pms-composition/pms-composition.component'
              ).then((component) => component.PmsCompositionComponent),
          },
        ],
      },
      {
        path: 'lcm',
        loadComponent: () =>
          import('./products-lcm/products-lcm.component').then(
            (component) => component.ProductsLcmComponent
          ),
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'LCM Products',
        },
        children: [
          {
            path: '',
            redirectTo: 'product-fixed-deposit',
            pathMatch: 'full',
          },
          {
            path: 'product-fixed-deposit',
            title: 'Products - Fixed Deposit',
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Products - Fixed Deposit',
            },
            loadComponent: () =>
              import(
                './products-lcm/pages/products-fixed-deposit/products-fixed-deposit.component'
              ).then((component) => component.ProductsFixedDepositComponent),
            children: [
              {
                path: 'add',
                title: 'Add Fixed Deposit Product',
                data: {
                  breadcrumbMain: 'Products - Fixed Deposit',
                  breadcrumbNext: 'Add Fixed Deposit Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-fixed-deposit/pages/add-fixed-deposit/add-fixed-deposit.component'
                  ).then((component) => component.AddFixedDepositComponent),
              },
              {
                path: 'edit/:productId',
                title: 'Edit Fixed Deposit Product',
                data: {
                  breadcrumbMain: 'Products - Fixed Deposit',
                  breadcrumbNext: 'Edit Fixed Deposit Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-fixed-deposit/pages/edit-fixed-deposit/edit-fixed-deposit.component'
                  ).then((component) => component.EditFixedDepositComponent),
              },
              {
                path: 'add-rate/:productId',
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-fixed-deposit/pages/add-product-rate/add-product-rate.component'
                  ).then((component) => component.AddProductRateComponent),
              },
            ],
          },
          {
            path: 'product-treasury-bills',
            title: 'Products - Treasury Bills',
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Products - Treasury Bills',
            },
            loadComponent: () =>
              import(
                './products-lcm/pages/products-treasury-bills/products-treasury-bills.component'
              ).then((component) => component.ProductsTreasuryBillsComponent),
            children: [
              {
                path: 'add',
                title: 'Add Treasury Bill Product',
                data: {
                  breadcrumbMain: 'Products - Treasury Bills',
                  breadcrumbNext: 'Add Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-treasury-bills/pages/add-treasury-bill/add-treasury-bill.component'
                  ).then((component) => component.AddTreasuryBillComponent),
              },
              {
                path: 'edit/:productId',
                title: 'Edit Treasury Bill Product',
                data: {
                  breadcrumbMain: 'Products - Treasury Bills',
                  breadcrumbNext: 'Edit Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-treasury-bills/pages/edit-treasury-bill/edit-treasury-bill.component'
                  ).then((component) => component.EditTreasuryBillComponent),
              },
              {
                path: 'view',
                title: 'View Treasury Bill Product',
                data: {
                  breadcrumbMain: 'Products - Treasury Bills',
                  breadcrumbNext: 'View Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-treasury-bills/pages/view-treasury-bill/view-treasury-bill.component'
                  ).then((component) => component.ViewTreasuryBillComponent),
              },
            ],
          },
          {
            path: 'product-bonds',
            title: 'Products - Bonds',
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Products - Bonds',
            },
            loadComponent: () =>
              import(
                './products-lcm/pages/products-bonds/products-bonds.component'
              ).then((component) => component.ProductsBondsComponent),
            children: [
              {
                path: 'add',
                title: 'Add Bond Product',
                data: {
                  breadcrumbMain: 'Products - Bond',
                  breadcrumbNext: 'Add Bond Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-bonds/pages/add-bond/add-bond.component'
                  ).then((component) => component.AddBondComponent),
              },
              {
                path: 'edit/:productId',
                title: 'Edit Bond Product',
                data: {
                  breadcrumbMain: 'Products - Bond',
                  breadcrumbNext: 'Edit Bond Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-bonds/pages/edit-bond/edit-bond.component'
                  ).then((component) => component.EditBondComponent),
              },
            ],
          },
          {
            path: 'product-commercial-papers',
            title: 'Products - Commercial Papers',
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Products - Commercial Papers',
            },
            loadComponent: () =>
              import(
                './products-lcm/pages/products-commercial-papers/products-commercial-papers.component'
              ).then(
                (component) => component.ProductsCommercialPapersComponent
              ),
            children: [
              {
                path: 'add',
                title: 'Add Commercial Papers Product',
                data: {
                  breadcrumbMain: 'Products - Commercial Papers',
                  breadcrumbNext: 'Add Commercial Papers Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-commercial-papers/pages/add-commercial-paper/add-commercial-paper.component'
                  ).then((component) => component.AddCommercialPaperComponent),
              },
              {
                path: 'edit/:productId',
                title: 'Edit Commercial Papers Product',
                data: {
                  breadcrumbMain: 'Products - Commercial Papers',
                  breadcrumbNext: 'Edit Commercial Papers Product',
                },
                loadComponent: () =>
                  import(
                    './products-lcm/pages/products-commercial-papers/pages/edit-commercial-paper/edit-commercial-paper.component'
                  ).then((component) => component.EditCommercialPaperComponent),
              },
            ],
          },
        ],
      },

      {
        path: 'lcm/product-fixed-deposit/view-rates/:productId',
        loadComponent: () =>
          import(
            './products-lcm/pages/products-fixed-deposit/pages/view-product-rates/view-product-rates.component'
          ).then((component) => component.ViewProductRatesComponent),
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Products',
          primaryBreadCrumb: 'View Product Rate',
        },
      },
      {
        path: 'lcm/product-fixed-deposit/edit-rate/:productId/:rateId',
        loadComponent: () =>
          import(
            './products-lcm/pages/products-fixed-deposit/pages/edit-product-rates/edit-product-rates.component'
          ).then((component) => component.EditProductRatesComponent),
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Products',
          primaryBreadCrumb: 'Edit Product Rate',
        },
      },
      {
        path: 'approvals',
        title: 'Approvals',
        component: ApprovalsComponent,
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Approvals',
        },
      },
      {
        path: 'reports',
        loadComponent: () =>
          import('./reports/reports.component').then(
            (component) => component.ReportsComponent
          ),
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Reports',
        },
        children: [
          {
            path: '',
            redirectTo: 'funded-clients-report',
            pathMatch: 'full',
          },
          {
            path: 'active-clients-report',
            title: 'Report - Active Clients',
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Report - Active Clients',
            },
            loadComponent: () =>
              import(
                './reports/pages/active-clients/active-clients.component'
              ).then((component) => component.ActiveClientsComponent),
            children: [
              {
                path: 'export-report/:export_format',
                title: 'Export Report',
                loadComponent: () =>
                  import(
                    './reports/pages/export-reports/export-reports.component'
                  ).then((component) => component.ExportReportsComponent),
              },
            ],
          },

          {
            path: 'funded-clients-report',
            title: 'Report - Funded Clients',
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Report - Funded Clients',
            },
            loadComponent: () =>
              import(
                './reports/pages/funded-clients/funded-clients.component'
              ).then((component) => component.FundedClientsComponent),
            children: [
              {
                path: 'export-report/:export_format',
                title: 'Export Report',
                loadComponent: () =>
                  import(
                    './reports/pages/export-reports/export-reports.component'
                  ).then((component) => component.ExportReportsComponent),
              },
            ],
          },
          {
            path: 'inactive-clients-report',
            title: 'Report - Inactive Clients',
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Report - Inactive Clients',
            },
            loadComponent: () =>
              import(
                './reports/pages/inactive-clients/inactive-clients.component'
              ).then((component) => component.InactiveClientsComponent),
            children: [
              {
                path: 'export-report/:export_format',
                title: 'Export Report',
                loadComponent: () =>
                  import(
                    './reports/pages/export-reports/export-reports.component'
                  ).then((component) => component.ExportReportsComponent),
              },
            ],
          },
          {
            path: 'portfolio-balance-report',
            title: 'Report - Portlio Balance',
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Report - Portlio Balance',
            },
            loadComponent: () =>
              import('./reports/pages/portfolio/portfolio.component').then(
                (component) => component.PortfolioComponent
              ),
            children: [
              {
                path: 'export-report/:export_format',
                title: 'Export Report',
                loadComponent: () =>
                  import(
                    './reports/pages/export-reports/export-reports.component'
                  ).then((component) => component.ExportReportsComponent),
              },
            ],
          },
        ],
      },
      {
        path: 'accounts',
        title: 'Accounts',
        children: [
          { path: '', redirectTo: 'accounts', pathMatch: 'full' },
          {
            path: '',
            component: AccountsComponent,
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Accounts',
            },
            children: [
              {
                path: ':accountId/assign-to-rm',
                component: AssignRmFormComponent,
              },
              // Account registration routes START
              // children: [
              { path: '', redirectTo: 'customers', pathMatch: 'full' },

              // reports
              {
                path: 'export-report',
                loadComponent: () =>
                  import(
                    './accounts/report/report.component'
                  ).then((component) => component.ReportComponent),
              },
              // Corporate Routes
              {
                path: 'corp-basic-details',
                title: 'Basic Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/corporate/pages/basic-details/basic-details.component'
                  ).then((component) => component.BasicDetailsComponent),
              },
              {
                path: 'corp-company-details',
                title: 'Company Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/corporate/pages/company-details/company-details.component'
                  ).then((component) => component.CompanyDetailsComponent),
              },
              {
                path: 'corp-bank-details',
                title: 'Bank Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/corporate/pages/bank-details/bank-details.component'
                  ).then((component) => component.BankDetailsComponent),
              },
              {
                path: 'corp-director-details',
                title: 'Director Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/corporate/pages/director-details/director-details.component'
                  ).then((component) => component.DirectorDetailsComponent),
              },
              {
                path: 'corp-confirm-director',
                title: 'Confirm Director',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/corporate/pages/confirm-director/confirm-director.component'
                  ).then((component) => component.ConfirmDirectorComponent),
              },
              {
                path: 'corp-add-signatory',
                title: 'Add Signatory',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/corporate/pages/add-signatory/add-signatory.component'
                  ).then((component) => component.AddSignatoryComponent),
              },
              {
                path: 'corp-confirm-signatory',
                title: 'Confirm Signatory',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/corporate/pages/confirm-signatory/confirm-signatory.component'
                  ).then((component) => component.ConfirmSignatoryComponent),
              },
              {
                path: 'corp-documents',
                title: 'Documents',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/corporate/pages/documents/documents.component'
                  ).then((component) => component.DocumentsComponent),
              },
              // Individual Routes
              {
                path: 'ind-personal-details',
                title: 'Personal Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/individual/pages/personal-details/personal-details.component'
                  ).then((component) => component.PersonalDetailsComponent),
              },
              {
                path: 'ind-location-details',
                title: 'Location Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/individual/pages/location-details/location-details.component'
                  ).then((component) => component.LocationDetailsComponent),
              },
              {
                path: 'ind-identification',
                title: 'Means of ID',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/individual/pages/means-of-id/means-of-id.component'
                  ).then((component) => component.MeansOfIdComponent),
              },
              {
                path: 'ind-employment',
                title: 'Employment Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/individual/pages/employment-details/employment-details.component'
                  ).then((component) => component.EmploymentDetailsComponent),
              },
              {
                path: 'ind-kin',
                title: 'Next of Kin',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/individual/pages/next-of-kin/next-of-kin.component'
                  ).then((component) => component.NextOfKinComponent),
              },
              {
                path: 'ind-bank-details',
                title: 'Bank Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/individual/pages/bank-details/bank-details.component'
                  ).then((component) => component.BankDetailsComponent),
              },
              {
                path: 'ind-documents',
                title: 'Documents',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/individual/pages/documents/documents.component'
                  ).then((component) => component.DocumentsComponent),
              },
              {
                path: 'order-report/:accountId',
                title: 'Orders Report',
                loadComponent: () =>
                  import(
                    './accounts/individual-account/reports/orders-report/orders-report.component'
                  ).then((component) => component.OrdersReportComponent),
              },
              {
                path: 'nav-report/:accountId',
                title: 'Nav Report',
                loadComponent: () =>
                  import(
                    './accounts/individual-account/reports/nav-report/nav-report.component'
                  ).then((component) => component.NavReportComponent),
              },
              {
                path: 'accocunt-statement-report/:accountId',
                title: 'Account Statement Report',
                loadComponent: () =>
                  import(
                    './accounts/individual-account/reports/account-statement-report/account-statement-report.component'
                  ).then(
                    (component) => component.AccountStatementReportComponent
                  ),
              },
              {
                path: 'client-directory-report/:account-type/:accountId',
                title: 'Cliend Directory Report',
                loadComponent: () =>
                  import(
                    './accounts/individual-account/reports/client-directory-report/client-directory-report.component'
                  ).then(
                    (component) => component.ClientDirectoryReportComponent
                  ),
              },
              //Joint account registration routes
              // Joint Account
              {
                path: JOINT_ACCOUNT_PATHS.main,
                title: 'Joint Account Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint.component'
                  ).then((component) => component.JointComponent),
              },
              {
                path: JOINT_ACCOUNT_PATHS.joint,
                title: 'Joint Account Additional User',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint.component'
                  ).then((component) => component.JointComponent),
              },
              {
                path: JOINT_ACCOUNT_PATHS.meansOfId,
                title: 'Joint Account Means of ID',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint-account-means-of-id/joint-account-means-of-id.component'
                  ).then(
                    (component) => component.JointAccountMeansOfIdComponent
                  ),
              },
              {
                path: JOINT_ACCOUNT_PATHS.location,
                title: 'Joint Account Location Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint-account-location/joint-account-location.component'
                  ).then(
                    (component) => component.JointAccountLocationComponent
                  ),
              },
              {
                path: JOINT_ACCOUNT_PATHS.employment,
                title: 'Joint Account Employment Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint-account-employment/joint-account-employment.component'
                  ).then(
                    (component) => component.JointAccountEmploymentComponent
                  ),
              },
              {
                path: JOINT_ACCOUNT_PATHS.kin,
                title: 'Joint Account Next of Kin',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint-account-kin/joint-account-kin.component'
                  ).then((component) => component.JointAccountKinComponent),
              },
              {
                path: JOINT_ACCOUNT_PATHS.bank,
                title: 'Joint Account Bank Details',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint-account-bank/joint-account-bank.component'
                  ).then((component) => component.JointAccountBankComponent),
              },
              {
                path: JOINT_ACCOUNT_PATHS.signatory,
                title: 'Joint Account Signatory',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint-account-signatory/joint-account-signatory.component'
                  ).then(
                    (component) => component.JointAccountSignatoryComponent
                  ),
              },
              {
                path: JOINT_ACCOUNT_PATHS.confirmSignatory,
                title: 'Joint Account - Confirm Signatories',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint-account-confirm-signatory/joint-account-confirm-signatory.component'
                  ).then(
                    (component) =>
                      component.JointAccountConfirmSignatoryComponent
                  ),
              },
              {
                path: JOINT_ACCOUNT_PATHS.doc,
                title: 'Joint Account KYC Documents',
                loadComponent: () =>
                  import(
                    './customers/create-customer-account/joint/joint-account-kyc-doc/joint-account-kyc-doc.component'
                  ).then((component) => component.JointAccountKycDocComponent),
              },

              //End of registration routes
              // ],
            ],
          },
          {
            path: 'individual/accounts/:accountId',
            component: IndividualAccountsComponent,
            data: {
              breadcrumbMain: 'Dashboard',
              breadcrumbNext: 'Individual Accounts',
            },
          },
          {
            path: 'individual/:accountId',
            component: IndividualAccountComponent,
            data: {
              breadcrumbMain: 'Accounts',
              breadcrumbNext: 'Individual Account',
              mainRoute: 'admin/accounts',
            },
            children: [
              { path: '', redirectTo: 'details', pathMatch: 'full' },
              {
                path: 'details',
                component: DetailsComponent,
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Details',
                  mainRoute: 'admin/accounts',
                },
              },
              {
                path: 'wallet',
                component: WalletComponent,
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Individual Accounts',
                  primaryBreadCrumb: 'Wallet',
                  mainRoute: 'admin/accounts',
                },
                children: [
                  {
                    path: ':transactionId',
                    title: 'View Wallet Transaction',
                    loadComponent: () =>
                      import(
                        './accounts/company-account/pages/wallet/pages/view-wallet/view-wallet.component'
                      ).then((component) => component.ViewWalletComponent),
                  },
                ],
              },
              {
                path: 'portfolio',
                component: PortfolioComponent,
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Portfolio',
                },
              },
              {
                path: 'orders',
                component: OrdersByAccountComponent,
                data: {
                  breadcrumbMain: 'Order',
                  breadcrumbNext: 'Orders',
                },
              },
              {
                path: 'audit-log',
                component: AuditLogComponent,
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Audit Log',
                },
              },
              {
                path: 'rm-list',
                title: 'Individual Account - Relationship Managers',
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Individual',
                  primaryBreadCrumb: 'Relationship Managers',
                },
                loadComponent: () =>
                  import(
                    './accounts/individual-account/pages/relationship-managers/relationship-managers.component'
                  ).then(
                    (component) => component.RelationshipManagersComponent
                  ),
                children: [
                  {
                    path: 'assign-rm',
                    title: 'Assign RM to Account',
                    loadComponent: () =>
                      import(
                        './shared/components/assign-rm-form/assign-rm-form.component'
                      ).then((component) => component.AssignRmFormComponent),
                  },
                ],
              },
            ],
          },
          {
            path: 'individual/edit/:accountId',
            component: EditIndividualAccountComponent,
            data: {
              breadcrumbMain: 'Accounts',
              breadcrumbNext: 'Edit Individual Account',
              mainRoute: 'admin/accounts',
            },
          },

          {
            path: 'corporate/:accountId',
            component: CompanyAccountComponent,
            data: {
              breadcrumbMain: 'Accounts',
              breadcrumbNext: 'Corporate Account',
              mainRoute: '/admin/accounts',
            },
            children: [
              { path: '', redirectTo: 'details', pathMatch: 'full' },
              {
                path: 'orders',
                component: OrdersByAccountComponent,
                data: {
                  breadcrumbMain: 'Order',
                  breadcrumbNext: 'Orders',
                },
              },
              {
                path: 'signatories',
                component: SignatoryListComponent,
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Corporate Account',
                },
                children: [
                  {
                    path: 'change-role',
                    title: 'Change Signatory Role',
                    loadComponent: () =>
                      import(
                        './accounts/company-account/pages/signatory-list/pages/change-role/change-role.component'
                      ).then((component) => component.ChangeRoleComponent),
                  },

                  {
                    path: 'add',
                    title: 'Add Signatory',
                    loadComponent: () =>
                      import(
                        './accounts/company-account/pages/signatory-list/pages/add-signatory/add-signatory.component'
                      ).then((component) => component.AddSignatoryComponent),
                  },

                  {
                    path: 'add-mandate',
                    title: 'Add Signature Mandate',
                    loadComponent: () =>
                      import(
                        './accounts/company-account/pages/signatory-list/pages/add-signature-mandate/add-edit-signature-mandate.component'
                      ).then(
                        (component) => component.AddSignatureMandateComponent
                      ),
                  },
                  {
                    path: 'edit',
                    title: 'Edit Signatory',
                    loadComponent: () =>
                      import(
                        './accounts/company-account/pages/signatory-list/pages/edit-signatory/edit-signatory.component'
                      ).then((component) => component.EditSignatoryComponent),
                  },
                ],
              },

              {
                path: 'wallet',
                component: WalletComponent,
                data: {
                  breadcrumbMain: 'Dashboard',
                  breadcrumbNext: 'Corporate Accounts',
                },
                children: [
                  {
                    path: ':transactionId',
                    title: 'View Wallet Transaction',
                    loadComponent: () =>
                      import(
                        './accounts/company-account/pages/wallet/pages/view-wallet/view-wallet.component'
                      ).then((component) => component.ViewWalletComponent),
                  },
                ],
              },
              {
                path: 'portfolio',
                component: PortfolioComponent,
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Portfolio',
                },
              },
              {
                path: 'audit-log',
                component: AuditLogComponent,
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Audit Log',
                },
              },
              {
                path: 'details',
                component: CorporateDetailsComponent,
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Details',
                },
              },
              {
                path: 'rm-list',
                title: 'Corporate Account - Relationship Managers',
                loadComponent: () =>
                  import(
                    './accounts/individual-account/pages/relationship-managers/relationship-managers.component'
                  ).then(
                    (component) => component.RelationshipManagersComponent
                  ),
                data: {
                  breadcrumbMain: 'Accounts',
                  breadcrumbNext: 'Corporate',
                  primaryBreadCrumb: 'Relationship Managers',
                },
                children: [
                  {
                    path: 'assign-rm',
                    title: 'Assign Relationship Manager',
                    loadComponent: () =>
                      import(
                        './shared/components/assign-rm-form/assign-rm-form.component'
                      ).then((component) => component.AssignRmFormComponent),
                  },
                ],
              },
            ],
          },
          {
            path: 'corporate/edit/:accountId',
            component: EditAccountComponent,
            data: {
              breadcrumbMain: 'Accounts',
              breadcrumbNext: 'Corporate Account',
            },
          },
          {
            path: 'joint/:accountId',
            loadComponent: () =>
              import('./accounts/joint-account/joint-account.component').then(
                (component) => component.JointAccountComponent
              ),
            data: {
              breadcrumbMain: 'Accounts',
              breadcrumbNext: 'Joint Account',
              mainRoute: 'admin/accounts',
            },
            children: [
              { path: '', redirectTo: 'details', pathMatch: 'full' },
              {
                path: 'details',
                title: 'Joint Account Details',
                loadComponent: () =>
                  import(
                    './accounts/joint-account/pages/details/details.component'
                  ).then((component) => component.DetailsComponent),
                data: {
                  breadcrumbMain: 'Dashboard',
                  breadcrumbNext: 'Joint Account',
                  primaryBreadCrumb: 'Details',
                },
              },
              {
                path: 'signatories',
                title: 'Joint Account Signatories',
                loadComponent: () =>
                  import(
                    './accounts/joint-account/pages/signatories/signatories.component'
                  ).then((component) => component.SignatoriesComponent),
                data: {
                  breadcrumbMain: 'Dashboard',
                  breadcrumbNext: 'Joint Account',
                  primaryBreadCrumb: 'Signatories',
                },
                children: [
                  {
                    path: 'change-role',
                    title: 'Change Signatory Role',
                    loadComponent: () =>
                      import(
                        './accounts/joint-account/pages/signatories/pages/change-role/change-role.component'
                      ).then((component) => component.ChangeRoleComponent),
                  },

                  {
                    path: 'add',
                    title: 'Add Signatory',
                    loadComponent: () =>
                      import(
                        './accounts/joint-account/pages/signatories/pages/add-signatory/add-signatory.component'
                      ).then((component) => component.AddSignatoryComponent),
                  },

                  {
                    path: 'add-mandate',
                    title: 'Add Signature Mandate',
                    loadComponent: () =>
                      import(
                        './accounts/joint-account/pages/signatories/pages/add-signature-mandate/add-edit-signature-mandate.component'
                      ).then(
                        (component) => component.AddSignatureMandateComponent
                      ),
                  },
                  {
                    path: 'edit',
                    title: 'Edit Signatory',
                    loadComponent: () =>
                      import(
                        './accounts/joint-account/pages/signatories/pages/edit-signatory/edit-signatory.component'
                      ).then((component) => component.EditSignatoryComponent),
                  },
                ],
              },
              {
                path: 'wallet',
                title: 'Joint Account Wallet',
                component: WalletComponent,
                data: {
                  breadcrumbMain: 'Dashboard',
                  breadcrumbNext: 'Joint Account',
                  primaryBreadCrumb: 'Wallet',
                },
                children: [
                  {
                    path: ':transactionId',
                    title: 'View Wallet Transaction',
                    loadComponent: () =>
                      import(
                        './accounts/company-account/pages/wallet/pages/view-wallet/view-wallet.component'
                      ).then((component) => component.ViewWalletComponent),
                  },
                ],
              },
              {
                path: 'portfolio',
                title: 'Joint Account Portfolio',
                component: PortfolioComponent,
                data: {
                  breadcrumbMain: 'Dashboard',
                  breadcrumbNext: 'Joint Account',
                  primaryBreadCrumb: 'Portfolio',
                },
              },
              {
                path: 'orders',
                title: 'Joint Account Orders',
                component: OrdersByAccountComponent,
                data: {
                  breadcrumbMain: 'Dashboard',
                  breadcrumbNext: 'Joint Account',
                  primaryBreadCrumb: 'Orders',
                },
              },
              {
                path: 'audit-log',
                title: 'Joint Account Audit Logs',
                component: AuditLogComponent,
                data: {
                  breadcrumbMain: 'Dashboard',
                  breadcrumbNext: 'Joint Account',
                  primaryBreadCrumb: 'Audit Log',
                },
              },
              {
                path: 'rm-list',
                title: 'Joint Account Relationship Managers',
                loadComponent: () =>
                  import(
                    './accounts/individual-account/pages/relationship-managers/relationship-managers.component'
                  ).then(
                    (component) => component.RelationshipManagersComponent
                  ),
                data: {
                  breadcrumbMain: 'Dashboard',
                  breadcrumbNext: 'Joint Account',
                  primaryBreadCrumb: 'Relationship Managers',
                },
                children: [
                  {
                    path: 'assign-rm',
                    title: 'Assign RM to Account',
                    loadComponent: () =>
                      import(
                        './shared/components/assign-rm-form/assign-rm-form.component'
                      ).then((component) => component.AssignRmFormComponent),
                  },
                ],
              },
            ],
          },
          {
            path: 'joint/edit/:accountId',
            component: EditJointAccountComponent,
            data: {
              breadcrumbMain: 'Accounts',
              breadcrumbNext: 'Edit Joint Account',
            },
          },
        ],
      },

      {
        path: 'my-profile',
        title: 'Profile',
        component: ProfileComponent,
        data: {
          breadcrumbMain: 'Dashboard',
          breadcrumbNext: 'Profile',
        },
        children: [
          {
            path: 'change-password',
            title: 'Change Password',
            loadComponent: () =>
              import(
                './profile/pages/change-password/change-password.component'
              ).then((component) => component.ChangePasswordComponent),
          },
        ],
      },
    ],
  },

  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent, title: 'Login' },
      {
        path: 'reset-password',
        title: 'Password Page',
        component: NewpasswordComponent,
      },
      { path: 'forgot-password', component: PassforgotComponent },
      {
        path: 'create-new-password',
        pathMatch: 'full',
        component: NewpasswordComponent,
      },
    ],
  },

  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  { path: 'dashboard', component: DashboardLayoutComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];
