<app-kyc-accordion accordionTitle="Bank Details" accordionKey="bankDetails" [isKycComplete]="isKycComplete">
  <div *ngIf="!bankDetails?.length">
    <p>No bank details</p>
  </div>
  <div *ngIf="bankDetails" class="grid grid-cols-1 gap-y-5 md:grid-cols-4">
    @for (bank of bankDetails; track $index) {
      <ul class="text-mango-darkBlack">
        <li class="text-base font-medium">Account Name</li>
        <li class="text-sm font-light">
          {{ (bankDetails[$index].bank_account_name | titlecase) || '—' }}
        </li>
      </ul>
      <ul class="text-mango-darkBlack">
        <li class="text-base font-medium">Account Number</li>
        <li class="text-sm font-light">
          {{ bankDetails[$index].bank_account_number || '—' }}
        </li>
      </ul>
      <ul class="text-mango-darkBlack">
        <li class="text-base font-medium">Bank Name</li>
        <li class="text-sm font-light">
          {{ bankName || '—' }}
        </li>
      </ul>
      <ul class="text-mango-darkBlack">
        <li class="text-base font-medium">Bank Branch</li>
        <li class="text-sm font-light">
          {{ (bankDetails[$index].bank_branch | titlecase) || '—' }}
        </li>
      </ul>
      <ul class="text-mango-darkBlack">
        <li class="text-base font-medium">Currency</li>
        <li class="text-sm font-light">
          {{ (bankDetails[$index].currency | uppercase) || '—' }}
        </li>
      </ul>
    }
  </div>
</app-kyc-accordion>
