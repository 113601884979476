import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination } from '@core/models/pagination.model';
import { ApprovalService } from '@core/services/approval.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import { Subscription } from 'rxjs';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { environment } from '@core/environments/environment';
import { IFILTER } from 'app/app.constants';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { Store } from '@ngxs/store';
import { AuthActions } from 'app/auth/store/auth.actions';
import { AuthState } from 'app/auth/store/auth.state';
import { ApprovalStateModel } from 'app/auth/store/auth.state.model';
@Component({
  selector: 'app-approvals',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './approvals.component.html',
  styleUrl: './approvals.component.scss',
  animations: [dropdownMenuAnimation],
  imports: [
    CommonModule,
    DropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    TableNavigatorComponent,
    FiltersComponent
],
})
export class ApprovalsComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  approvals$;

  isDropdownVisible = true;
  showDropdown = false;
  subs: Subscription[] = [];
  documentList: any[] = [];
  actionList: DropDownModel[] = [];
  totalPending = 0;
  totalApproved = 0;
  totalReq = 0;
  totalCount!: number;
  date = new FormControl('', [Validators.required]);

  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  notify = inject(NotificationFacade);
  approval = inject(ApprovalService);
  change = inject(ChangeDetectorRef);
  breadCrumbService = inject(BreadCrumbService);
  $index: any;

  constructor(private store: Store) {
    this.approvals$ = this.store.select(AuthState.approval);
  }

  isFilterActive: boolean = false;


  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
    {
      filterKey: 'status',
      label: 'Status',
      type: 'dropdown',
      dropdown: [
        { key: 'pending', value: 'Pending' },
        { key: 'approved', value: 'Approved' },
        { key: 'rejected', value: 'Rejected' },
      ],
    },
  ];

  toggleFilterMenu() {
    this.filterMenuState =
      this.filterMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  

  initDropdownList(param: any) {

    return (this.actionList = [
      {
        action: () => {
          this.openDocument(param);
        },
        title: 'Open Document',
        show: true,
      },
      {
        action: () => {
          this.approve(param);
        },
        title: 'Approve',
        show: param.status !== 'approved',
      },
      {
        action: () => {
          this.reject(param);
        },
        title: 'Reject',
        show: param.status !== 'rejected',
      },
      {
        action: () => this.viewAccountDetails(param),
        title: 'View Account',
      },
    ]);
  }

  approve(data: any) {
    const sub = this.approval.approveDocument(data.id).subscribe({
      next: async (resp: any) => {
        this.notify.success(resp.message);

        this.navigate();
      },
    });
    this.subs.push(sub);
  }

  reject(data: any) {
    const sub = this.approval.rejectDocument(data.id).subscribe({
      next: async (resp: any) => {
        this.notify.success(resp.message);

        this.navigate();
      },
    });
    this.subs.push(sub);
  }

  openDocument(data: any) {
    window.open(`${environment.FILE_BASE_URL}${data.link}`, '_blank');
  }

  toggle() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  approvalsFilterEventListener() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.documentList = [];
      let query = "";
      const currentPage = params['page'] ? parseInt(params['page']) : 1;
      const skip = (currentPage - 1) * 10;
      const size = 10;

      const queryParams = [];

      if (params[this.filterStructure[0].filterKey]) {
        queryParams.push  (`start_date=${params['start_date']}`)
      }
      if (params[this.filterStructure[1].filterKey]) {
        queryParams.push (`end_date=${params['end_date']}`);
      }
      if (params[this.filterStructure[2].filterKey]) {
        queryParams.push (`status=${params['status']}`);
      }
      if (params['search']) {
        queryParams.push (`search_text=${params['search']}`);
      }

      query = queryParams.join('&');
      if (params[this.filterStructure[0].filterKey] ||
         params[this.filterStructure[1].filterKey] ||
        params[this.filterStructure[2].filterKey] ||
        params['search']) {
          this.isFilterActive = true;
          this.isLoading = true;
          const sub = this.approval.filterDocumentsList(query).subscribe({
            next: (res: any) => {
              this.documentList = res.data.data;
              this.totalCount = res.data.total_count || 10; 
              this.isLoading = false;
              this.change.detectChanges();
            },
            error: (err) => {
              this.isLoading = false;
              this.change.detectChanges();
            },
          });
          this.subs.push(sub);
        
      } else {
        this.isFilterActive = false;
        this.getApprovalList(new Pagination({ skip, size }));
      }
    });
  }



 

  
getApprovalList(params: Pagination) {
  this.store.dispatch(new AuthActions.LoadApprovals({ params }));

   this.documentList = [];
   this.isLoading = true;
     const sub = this.approval.getDocumentsList(params).subscribe({
       next: (resp: any) => {
         this.totalCount = resp.data.total_count;
       this.documentList = resp.data.data;
         this.isLoading = false;
         this.change.detectChanges();
      },
       error: (err) => {
        this.isLoading = false;
      },
     });
     this.subs.push(sub);
   }

  getDocumentsMetaData() {
    const sub = this.approval.getDocumentsMetaData().subscribe({
      next: (resp: any) => {
        this.totalReq = resp.data.total_docs
        this.totalPending = resp.data.pendind_doc_count
        this.totalApproved = resp.data.approved_doc_count

        this.change.detectChanges();
      },
    });
    this.subs.push(sub);
  }

  viewAccountDetails(txn: any) {
    this.router.navigateByUrl(
      `/admin/accounts/${txn.account.account_type}/${txn.account_id}`
    );
  }

  navigate(currentPage: number = 1) {
    this.isLoading = true;
    this.router.navigate([], {
      queryParams: { page: currentPage },
      queryParamsHandling: 'merge',
    });
  }
  //   this.isLoading = true;
  //  const skip = (currentPage - 1) * 10;
  //  const size = 10;

   
  //   if (this.isFilterActive) {
  //     // Append page param only
  //     this.router.navigate([], {
  //       queryParams: { page: currentPage },
  //       queryParamsHandling: 'merge',
  //     }).then(() => {
  //       this.approvalsFilterEventListener();
  //     });
      
  //   } else {
  //     // Normal pagination when no filter is active
      
  //     this.getDocumentsMetaData();
  //   this.getApprovalList(new Pagination({ skip, size}));
  //   }
  
  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);
    this.getDocumentsMetaData();
   
    this.approvalsFilterEventListener();
    this.store.dispatch(new AuthActions.LoadApprovals({ params: new Pagination() }));

    this.subs.push(
      this.approvals$.subscribe((approvalState) => {
        this.documentList = approvalState.approvals;
        this.isLoading = approvalState.loading;
        this.change.detectChanges();
      })
    );
  }


  


  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}