<app-slide-in-right-modal
  modalTitle="Transaction Details"
  [showBackLink]="true"
  [returnPath]="'/admin/transactions/wallet'"
>
  @if (!(isFetchingDetails$ | async)) {
    <dl class="space-y-4 text-left">
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Account name:</dt>
        @switch (transaction.account.account_type) {
          @case ('individual') {
            <dd class="ml-4">
              {{ transaction.account.user_profile?.last_name | uppercase }}
              {{ transaction.account.user_profile?.first_name | titlecase }}
              {{ transaction.account.user_profile?.middle_name | titlecase }}
            </dd>
          }
          @case ('corporate') {
            <dd class="ml-4">
              {{ transaction.account.corporate_kyc?.name | uppercase }}
            </dd>
          }
          @case ('joint') {
            @for (
              acc of transaction.account.joint_kyc;
              track $index
            ) {
              <dd class="ml-4">
                {{ acc.personal_details.last_name | uppercase }}
                {{ acc.personal_details.first_name | titlecase }}
                {{ acc.personal_details.middle_name | titlecase }}

                @if (($index + 1) < (transaction.account.joint_kyc?.length || 1)) {
                  <span> & </span>
                }
              </dd>
            }
          }
          @default {
            'unknown'
          }
        }
      </div>

      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Account Number:</dt>
        <dd class="ml-4">{{ transaction.account.mango_account_number }}</dd>
      </div>
      
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Date Created:</dt>
        <dd class="ml-4">{{ transaction.created_at | date: 'medium' }}</dd>
      </div>

      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Transaction Type:</dt>
        <span
          class="relative inline-block px-2 py-1 text-xs font-semibold leading-tight"
        >
          <span
            aria-hidden
            class="absolute inset-0 rounded-full opacity-50"
            [class.bg-green-200]="transaction.transaction_type === 'credit'"
            [class.bg-blue-300]="transaction.transaction_type !== 'credit'"
          ></span>
          <span
            class="relative"
            [class.text-[#027A48]="transaction.transaction_type === 'credit'"
            [class.text-blue-700]="transaction.transaction_type !== 'credit'"
          >
            {{ transaction.transaction_type | titlecase }}
          </span>
        </span>
      </div>
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Transaction Channel:</dt>
        <dd class="ml-4">
          {{ transaction.transaction_channel }}
        </dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Transaction Amount:</dt>
        <dd class="ml-4">
          ₦{{ transaction.transaction_amount / 100 | number }}
        </dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Current Balance:</dt>
        <dd class="ml-4">₦{{ transaction.current_balance / 100 | number }}</dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="flex-1 font-semibold">Status:</dt>
        <span
          class="relative inline-block px-2 py-1 text-xs font-semibold leading-tight"
        >
          <span
            aria-hidden
            class="absolute inset-0 rounded-full opacity-50"
            [ngClass]="[
              transaction.status === 'denied' ? 'bg-mango-shellRed ' : '',
              transaction.status === 'completed' ? 'bg-green-100 ' : '',
              transaction.status === 'pending' ||
              transaction.status === 'withheld'
                ? 'bg-mango-paleBlue '
                : ''
            ]"
          ></span>
          <span
            class="relative"
            [ngClass]="[
              transaction.status === 'denied' ? ' text-mango-red707' : '',
              transaction.status === 'completed' ? ' text-mango-pineGreen' : '',
              transaction.status === 'pending' ||
              transaction.status === 'withheld'
                ? ' text-indigo-700'
                : ''
            ]"
          >
            {{ transaction.status | uppercase }}
          </span>
        </span>
      </div>
    </dl>
    @if (transaction.timeline_logs.length) {
      <hr class="my-4" />
      @for (log of transaction.timeline_logs; track $index) {

        <div class="card flex items-center justify-between p-2 border-l-4 mb-2">
          <div>
              <h6>{{log?.message}}</h6>
              <small><code style="font-family:  ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;">{{log?.last_time_checked | date :'medium'}}</code></small>
          </div>
          <app-status-badge [status]="log?.timeline || 'unknown'"></app-status-badge>
      </div>
      }

    }
  } @else {
    <div class="flex flex-col gap-4">
      <ngx-skeleton-loader
        *ngFor="let in of [0, 1, 2, 3, 4, 5, 6]; let i = index"
        [theme]="{ height: '1.5rem', width: '100%' }"
      ></ngx-skeleton-loader>
    </div>
  }
</app-slide-in-right-modal>
