import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import {
  Accountbankinfo,
  DocKyc,
  Employerkyc,
  IndividualAccountKycData,
  Locationkyc,
  MeansOfIdkyc,
  Nextofkin,
  UserKyc,
} from '@core/models/admin/account';
import { AccountKYC } from '@core/models/admin/account/account.interface';
import { AccountManagementService } from '@core/services/account-management.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { Observable, Subscription } from 'rxjs';
import { EditCustomerInformationComponent } from './pages/edit-customer-information/edit-customer-information.component';
import { EditLocationDetailsComponent } from './pages/edit-location-details/edit-location-details.component';
import { EditMeansOfIdComponent } from './pages/edit-means-of-id/edit-means-of-id.component';
import { EditEmploymentDetailsComponent } from './pages/edit-employment-details/edit-employment-details.component';
import { EditNextOfKinComponent } from './pages/edit-next-of-kin/edit-next-of-kin.component';
import { EditBankDetailsComponent } from './pages/edit-bank-details/edit-bank-details.component';
import { EditDocumentsComponent } from './pages/edit-documents/edit-documents.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoadingService } from '@core/services/loading.service';
import { NotificationFacade } from '@core/facades/notification.facade';

@Component({
  selector: 'app-edit-individual-account',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    EditCustomerInformationComponent,
    EditLocationDetailsComponent,
    EditMeansOfIdComponent,
    EditEmploymentDetailsComponent,
    EditNextOfKinComponent,
    EditBankDetailsComponent,
    EditDocumentsComponent,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './edit-individual-account.component.html',
  styleUrl: './edit-individual-account.component.scss',
})
export class EditIndividualAccountComponent implements OnInit, OnDestroy {
  accountData!: IndividualAccountKycData;
  userDetailsUrl!: string;
  accountsUrl!: string;
  accountId!: string;
  subs: Subscription[] = [];
  locationDetails!: Locationkyc;
  meansOfIdDetails!: MeansOfIdkyc;
  employmentDetails!: Employerkyc;
  nextOfKinDetails!: Nextofkin;
  bankDetails!: Accountbankinfo[];
  documentDetails!: DocKyc[];
  customerDetails!: UserKyc;
  isLoading$!: Observable<boolean>;

  private breadCrumbService = inject(BreadCrumbService);
  private accountService = inject(AccountManagementService);
  private route = inject(ActivatedRoute);
  private cdr = inject(ChangeDetectorRef);
  private loadingService = inject(LoadingService);
  private toast = inject(NotificationFacade);

  constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      'get-individual-account-kyc'
    );
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.params['accountId'];
    this.breadCrumbService.setPageBreadCrumb(this.route);
    if (this.accountId) {
      this.userDetailsUrl = `/admin/accounts/individual/edit/${this.accountId}`;
      this.accountsUrl = `/admin/accounts/individual/accounts/${this.accountId}`;
      this.getAccountInfo();
    }
  }

  getAccountInfo(): void {
    const sub = this.accountService
      .getIndividualAccountInfo(this.accountId)
      .subscribe({
        next: (response) => {
          this.accountData = response.data;
          this.accountService.setAccountKYCData(
            this.accountData as unknown as AccountKYC
          );
          this.locationDetails = response.data.location_kyc as Locationkyc;
          this.meansOfIdDetails = response.data.id_kyc as MeansOfIdkyc;
          this.employmentDetails = response.data.employer_kyc as Employerkyc;
          this.nextOfKinDetails = response.data.next_of_kin as Nextofkin;
          this.bankDetails = response.data
            .account_bank_info as Accountbankinfo[];
          this.documentDetails = response.data.doc as DocKyc[];
          this.customerDetails = response.data.user as UserKyc;
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.toast.error('Failed to fetch account information');
        },
      });

    this.subs.push(sub);
  }

  goBack() {
    history.back();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
