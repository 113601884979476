import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditDocumentsComponent } from '@accounts/individual-account/edit-account/pages/edit-documents/edit-documents.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { InputFileComponent } from '@shared/ui/input-file/input-file.component';
import { DocKyc } from '@core/models/admin/account';

@Component({
  selector: 'app-edit-joint-docs',
  standalone: true,
  imports: [CommonModule, KycAccordionComponent, InputFileComponent],
  templateUrl: './edit-joint-docs.component.html',
  styleUrl: './edit-joint-docs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointDocsComponent extends EditDocumentsComponent {
  constructor() {
    super();
  }
}
