<app-slide-in-right-modal modalTitle="{{formType}} Dividend" iconImageSrc="assets/images/svgs/document--outline.svg"
    [slideOpen]="true" [showBackLink]="false" returnPath="">
    <div class="page__container flex flex-col pb-8">
        <p class="cta__text text-sm text-mango-gray74">
            Kindly input dividend details
        </p>

        <form class="flex flex-col gap-5 px-0 pt-5" [formGroup]="mutualFundsDividendForm">

            <!-- Date -->
            <div>
                <app-input-label labelFor="date" labelClass="label--bottom-margin"
                    [isRequired]="true">Date</app-input-label>

                <input placeholder="DD/MM/YYYY" formControlName="date"
                    class="block w-full rounded-lg border-0 pl-2 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6"
                    type="date">
            </div>

            <div>
                <app-input-label labelFor="yield" labelClass="label--bottom-margin" [isRequired]="true">
                    Yield %
                </app-input-label>
                
                <app-input placeholder="Enter Yield Percentage" [isRequired]="true"
                    [inputClass]="'input--padding'" formControlName="yield"></app-input>
                 <p class="text-xs mt-1">  {{mutualFundsDividendForm.get('yield')?.value || 0 | number: '1.2-2'}}</p>
            </div>

            <!-- Accured interest -->
            <div>
                <app-input-label labelFor="name" labelClass="label--bottom-margin" [isRequired]="true">Accured
                    Amount</app-input-label>

                <app-input placeholder="Enter Accured Amount" [isRequired]="true"
                    [inputClass]="'input--padding'" formControlName="amount"></app-input>
                    <p class="text-xs mt-1">Amount: {{mutualFundsDividendForm.get('amount')?.value || 0 | number: '1.2-2'}}</p>
            </div>




            <div class="col-start-3 justify-self-end">
                <button (click)="addDividend()"
                    class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end" mango-button>
                    <!-- <span *ngIf="isLoading" spin></span> -->
                    <!-- @if (!isLoading) {
                    {{ isEdit ? 'Update' : 'Save and Continue' }}
                    } --> Save and Continue
                </button>
            </div>

        </form>
    </div>
</app-slide-in-right-modal>