<div class="flex flex-col gap-5">
  <div
    *ngIf="signatoriesList.length && (isFetchingList$ | async) === false"
    class="corporate-details__wrapper flex flex-col gap-5"
  >
    @for (signatory of signatoriesList; track $index) {
      <div
        class="flex items-center justify-between rounded-lg border border-solid border-mango-gray200 bg-white p-[0.94rem] shadow-[0_1px_2px_0_rgba(16,24,40,0.06)] hover:border-gray-400"
      >
        <div class="avatar-details__wrapper inline-flex items-center gap-4">
          <div
            class="h-[4.375rem] w-[4.375rem] rounded-full bg-[url('/assets/images/avatar-placeholder.png')]"
          ></div>

          <ul class="text-mango-gray900">
            <li class="text-lg font-normal">
              {{ signatory.user.first_name }} {{ signatory.user.last_name }}
            </li>
            <li class="text-base font-light">{{ signatory.user.email }}</li>
            <li class="text-base font-light">
              {{ signatory.user.phone_number_1 }}
            </li>
            <li>
              <span
                class="w-fit items-center rounded-full px-2 py-1 text-xs font-medium"
                [ngClass]="[
                  signatory.status === SignatoryStatus.ACTIVATED
                    ? 'bg-green-100 text-mango-pineGreen'
                    : '',
                  signatory.status === SignatoryStatus.DEACTIVATED
                    ? 'bg-red-100 text-mango-sharpRed'
                    : ''
                ]"
              >
                {{
                  signatory.status === 'activated'
                    ? 'ACTIVATED'
                    : 'DE-ACTIVATED'
                }}
              </span>
            </li>
          </ul>
        </div>

        <div class="relative">
          <app-dropdown
            [id]="signatory.user.id"
            [actionList]="signatoryDropdownActions(signatory)"
          >
          </app-dropdown>
        </div>
      </div>
    }
  </div>

  <div
    *ngIf="signatoriesList.length === 0 && (isFetchingList$ | async) === false"
  >
    <div
      [ngClass]="{
        'mb-3': !(isFetchingList$ | async) && signatoriesList.length > 0
      }"
      class="mb-3 inline-flex w-full items-center gap-[0.9rem] rounded-xl bg-mango-floralWhite px-[0.6rem] py-[0.9rem]"
    >
      <img
        src="assets/images/svgs/info-circle--yellow.svg"
        alt=""
        class="h-[1.5625rem] w-[1.5625rem]"
      />
      <p class="text-sm leading-[1.3125rem] text-mango-grey">
        No Signatories Found
      </p>
    </div>
  </div>

  <div *ngIf="isFetchingList$ | async" class="flex flex-col">
    <div *ngFor="let in of [0, 1, 2, 3]; let i = index">
      <ngx-skeleton-loader
        [theme]="{ height: '5rem', width: '100%' }"
      ></ngx-skeleton-loader>
    </div>
    <div class="flex justify-end">
      <ngx-skeleton-loader
        [theme]="{ height: '2.5rem', width: '150px' }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <div *ngIf="!(isFetchingList$ | async)" class="flex justify-end gap-1">
    <button mango-button [routerLink]="addSignatoryUrl$ | async">
      + Add New Signatory
    </button>

    <button mango-button [routerLink]="addSignatureMandateUrl$ | async">
      @if ((isFetchingMandate$ | async) === false) {
        {{
          (hasMandate$ | async) === true
            ? 'Edit Signature Mandate'
            : '+ Add Signature Mandate'
        }}
      }
    </button>
  </div>
</div>

<router-outlet></router-outlet>

<app-confirm-dialog
  [isOpen]="isDeleteDialogOpen"
  (close)="
    signatoryID = null; this.isDeleteDialogOpen = false; signatoryName = null
  "
  (dialogAction)="deleteSignatory()"
  [confirmQuestion]="
    'Are you sure you want to delete this User: ' + signatoryName + '?'
  "
  confirmText="Yes, Delete"
  bgConfirmButton="danger"
  [isDialogLoading]="isDeleteInProgress"
  dialogLoadingText="Deleting Signatory, please wait..."
>
</app-confirm-dialog>

<app-confirm-dialog
  [isOpen]="isDeactivateDialogOpen"
  (close)="
    signatoryID = null; isDeactivateDialogOpen = false; signatoryName = null
  "
  (dialogAction)="deactivateSignatory()"
  [confirmQuestion]="
    'Are you sure you want to deactivate this User: ' + signatoryName + '?'
  "
  confirmText="Yes, Deactivate"
  bgConfirmButton="danger"
  [isDialogLoading]="isDeactivateInProgress"
  dialogLoadingText="Deactivating Signatory, please wait..."
>
</app-confirm-dialog>

<ng-container *ngIf="signatoryInfo$ | async as signatoryInfo">
  <app-confirm-dialog
    [isOpen]="(isDialogOpen$ | async)!!"
    [confirmQuestion]="
      'Are you sure you want to resend invitation to this User: \'' +
      signatoryInfo.first_name +
      signatoryInfo.last_name +
      '\'?'
    "
    confirmText="Yes, Re-send invite"
    (dialogAction)="resendSignatoryInvite()"
    [isDialogLoading]="(isDialogLoading$ | async)!!"
    dialogLoadingText="Resending Invite, please wait..."
    (close)="closeDialog()"
  >
  </app-confirm-dialog>
</ng-container>

<app-confirm-dialog
  confirmText="Update Signatory"
  [isOpen]="(openEditDialog$ | async) === true"
  [showQuestionGif]="false"
  (close)="this.openEditDialogSubject$.next(false)"
  (dialogAction)="updateSignatory()"
  [isDialogLoading]="(isUpdatingSignatory$ | async) === true"
  dialogLoadingText="Updating Signatory, please wait..."
>
  <h2 class="mb-4 font-bold">
    Edit Signatory: {{ editSignatoryName$.getValue() }}
  </h2>
  <form [formGroup]="editSignatoryForm" class="flex flex-col gap-5">
    <!-- First Name -->
    <div>
      <app-input-label labelClass="label--bottom-margin"
        >First Name</app-input-label
      >
      <app-input
        placeholder="Enter First Name"
        [inputClass]="'input--padding'"
        formControlName="first_name"
      >
      </app-input>
    </div>

    <!-- Last Name -->
    <div>
      <app-input-label labelClass="label--bottom-margin"
        >Last Name</app-input-label
      >
      <app-input
        placeholder="Enter Last Name"
        [inputClass]="'input--padding'"
        formControlName="last_name"
      >
      </app-input>
    </div>

    <!-- Email -->
    <div>
      <app-input-label labelClass="label--bottom-margin">Email</app-input-label>
      <app-input
        placeholder="Enter Email"
        [inputClass]="'input--padding'"
        formControlName="email"
      >
      </app-input>
    </div>

    <!-- Class of signatory -->
    <div>
      <app-input-label
        labelFor="classOfSignatory"
        labelClass="label--bottom-margin"
        >Class of signatory</app-input-label
      >
      <select
        name="classOfSignatory"
        required
        base-select
        formControlName="signatory_class"
      >
        <option value="" disabled selected>Select Signatory Class</option>
        <option
          *ngFor="let sigClass of signatoryClass"
          [value]="sigClass.value"
        >
          {{ sigClass.label }}
        </option>
      </select>
    </div>
  </form>
</app-confirm-dialog>
