import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { Pagination } from '@core/models/pagination.model';
import { AdminManagementService } from '@core/services/admin-management.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { Subscription } from 'rxjs';
import { FILTER, IFILTER } from 'app/app.constants';
import { AccountManagementService } from '@core/services/account-management.service';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { ViewAdminListDatum } from '@core/models/admin/admin-management';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { RolesAndPermissionsService } from '@core/services/roles-and-permissions.service';
import { AllRolesDatum } from '@core/models/admin/roles-and-permissions/all-roles.model';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { CdkPortal } from '@angular/cdk/portal';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
interface ViewAdminListRole extends ViewAdminListDatum {
  role_name?: string;
}
@Component({
  selector: 'app-admin-users',
  standalone: true,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './admin-users.component.html',
  styleUrl: './admin-users.component.scss',
  animations: [dropdownMenuAnimation],
  imports: [
    RouterOutlet,
    CommonModule,
    DropdownComponent,
    DropdownComponent,
    RouterLink,
    InputLabelComponent,
    BaseSelectDirective,
    InputDateDirective,
    ButtonDirective,
    ReactiveFormsModule,
    FormsModule,
    SearchPipe,
    FiltersComponent,
    TableNavigatorComponent,
    ConfirmDialogComponent,
    OverlayModule,
  ],
})
export class AdminUsersComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  showDropdown = false;
  subs: Subscription[] = [];
  usersList: ViewAdminListRole[] = [];
  totalCount: number = 0;
  actionList: DropDownModel[] = [];
  adminName!: string | null;
  adminId!: string | null;
  confirmQuestion: string = `Are you sure you want to deactivate this admin? This action cannot be undone.`;
  isDeleteDialogOpen: boolean = false;
  isDeleteInProgress: boolean = false;
  resendQuestion: string =
    'Are you sure you want to resend this admin an invitation?';
  isResendDialogOpen: boolean = false;
  isResendInProgress: boolean = false;
  isLoading: boolean = false;
  $index: any;
  searchText: any;


  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  notify = inject(NotificationFacade);
  breadCrumbService = inject(BreadCrumbService);
  adminService = inject(AdminManagementService);
  change = inject(ChangeDetectorRef);
  roleService = inject(RolesAndPermissionsService);
  searchService = inject(AccountManagementService);
  overlay= inject(Overlay);

  isOpen = false;


  roles: AllRolesDatum[] = [];
  filterStructure: IFILTER[] = [
    {
      filterKey: 'role',
      label: 'Role',
      type: 'dropdown',
      dropdown: []
    },
    {
      filterKey: 'status',
      label: 'Status',
      type: 'dropdown',
      dropdown: [
        { key: 'activated', value: 'Activated' },
        { key: 'de_activated', value: 'Deactivated' },
        { key: 'pending', value: 'Pending' },
      ],
    },
  ];

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);

    this.getRoles();
    this.listFiltersEventListern();
  }


  listFiltersEventListern() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let query = "";
      if (params[this.filterStructure[0].filterKey]) {
        query = `role_id=${params['role']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `status=${params['status']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}`;
      }
      if (query) {
        const sub = this.adminService.fitlerAdminList(query).subscribe((res) => {
          this.usersList = res.data.data;
          this.totalCount = res.data.total_count;
          this.change.detectChanges();
        });
        this.subs.push(sub);
      } else {
        this.getAdminList();
      }
    });
  }

  getRoles() {
    this.roleService.getAllRoles().subscribe((res) => {
      this.roles = res.data.data;
      this.filterStructure[0].dropdown = this.roles.map((role) => ({
        key: role.id,
        value: role.name,
      }));
    })
  }

  toggle() {
    this.change.detectChanges();
  }

  fullyRegister(): number {
    const count =
      this.usersList.filter((u) => u.status === 'activated').length || 0;
    return count;
  }

  initDropdownList(adminUser: ViewAdminListDatum) {
    return (this.actionList = [
      {
        action: () => {
          this.viewUserDetails(adminUser);
        },
        title: 'View Details',
        show: true,
      },
      {
        action: () => {
          this.editUserDetails(adminUser);
        },
        title: 'Edit',
        show: true,
      },
      {
        action: () => {
          this.isDeleteDialogOpen = true;
          this.adminId = adminUser.id;
          this.adminName = `${adminUser.first_name} ${adminUser.last_name}`;
          this.confirmQuestion = `Are you sure you want to delete this admin: ${this.adminName}? This action cannot be undone.`;
        },
        title: 'Delete',
        show: true,
      },
      {
        action: () => {
          this.isResendDialogOpen = true;
          this.adminId = adminUser.id;
          this.adminName = `${adminUser.first_name} ${adminUser.last_name}`;
          this.resendQuestion = `Are you sure you want to resend the activation email to this admin: ${this.adminName}?`;
        },
        title: 'Resend Invite',
        show: adminUser.status === 'pending',
      },
    ]);
  }

  viewUserDetails(user: ViewAdminListDatum) {
    this.router.navigate([`profile`, `${user.id}`], {
      relativeTo: this.activatedRoute,
      state: { user },
    });
  }

  editUserDetails(user: ViewAdminListDatum) {
    this.router.navigate(['edit', user.id], {
      relativeTo: this.activatedRoute,
      state: { user },
    });
  }

  deleteAdmin() {
    this.isDeleteInProgress = true;
    if (!this.adminId) return;
    const sub = this.adminService.deleteAdmin(this.adminId).subscribe({
      next: () => {
        this.isDeleteDialogOpen = false;
        this.isDeleteInProgress = false;
        // this.getAdminList();
        this.usersList = this.usersList.filter(
          (adminUser) => adminUser.id !== this.adminId
        );
        this.change.detectChanges();

        this.notify.success('Admin deactivated successfully');
      },
      error: (err) => {
        this.isDeleteInProgress = false;
        this.change.detectChanges();
      },
    });

    this.subs.push(sub);
  }

  resendInvite() {
    this.isResendInProgress = true;
    if (!this.adminId) return;
    const sub = this.adminService.resendInvite(this.adminId).subscribe({
      next: () => {
        this.isResendDialogOpen = false;
        this.isResendInProgress = false;
        this.change.detectChanges();
        this.notify.success('Admin invite resent successfully');
      },
      error: (err) => {
        this.isResendInProgress = false;
        this.change.detectChanges();
      },
    });

    this.subs.push(sub);
  }

  getAdminList(page: Pagination = new Pagination()) {
    const sub = this.adminService.viewAdminList(page).subscribe((res) => {
      this.usersList = res.data.data;
      this.totalCount = res.data.total_count;

      this.change.detectChanges();
    });
    this.subs.push(sub);
  }


  navigate(currentPage: number = 1) {
    const skip = currentPage * 10 - 10;
    this.getAdminList(new Pagination({ skip }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
