import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { Pagination } from '@core/models/pagination.model';
import { TransactionsService } from '@core/services/transactions.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import {
  BeneficiaryDetailsData,
  TransactionsListDatum,
} from '@core/models/admin/account';
import { NotificationFacade } from '@core/facades/notification.facade';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { LoadingService } from '@core/services/loading.service';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { StatusComponent } from '@shared/ui/status/status.component';
import { IFILTER } from 'app/app.constants';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';

@Component({
  selector: 'app-admin-transactions',
  standalone: true,
  providers: [DecimalPipe, TitleCasePipe],
  templateUrl: './wallet-transactions.component.html',
  styleUrl: './wallet-transactions.component.scss',
  animations: [dropdownMenuAnimation],
  imports: [
    CommonModule,
    ButtonDirective,
    DropdownComponent,
    ReactiveFormsModule,
    FormsModule,
    ConfirmDialogComponent,
    RouterOutlet,
    TableSkeletonLoaderComponent,
    StatusComponent,
    FiltersComponent,
    TableNavigatorComponent,
  ],
})
export class WalletTransactionsComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  showAction = false;

  providusBalanceSubject$ = new BehaviorSubject<number>(0);
  providusBalance$ = this.providusBalanceSubject$.asObservable();

  optimusBalanceSubject$ = new BehaviorSubject<number>(0);
  optimusBalance$ = this.optimusBalanceSubject$.asObservable();

  // approve
  isApproveDialogOpen: boolean = false;
  isApproveInProgress: boolean = false;
  approveDialogQuestion!: any;
  disableApproveAction: boolean = true;
  // reject
  isRejectDialogOpen: boolean = false;
  isRejectInProgress: boolean = false;

  isFetchingTransactions$!: Observable<boolean>;

  isApproveLoadingText!: string;
  subs: Subscription[] = [];
  // transactionsList: any[] = [];
  transactionsList: TransactionsListDatum[] = [];
  filteredList: any[] = [];
  totalCount: number = 0;
  currentPage: number = 1;
  transaction: any;
  searchText = '';
  isLoading: boolean = false;
  filterService: any;
  totalCredit = 0;
  totalDebit = 0;
  isFiltered: boolean = false;
  $index: any;
  actionList: DropDownModel[] = [];
  transactionId!: string | null;
  accountId!: string | null;
  transactionDetails!: TransactionsListDatum | null;
  beneficiaryDetails!: BeneficiaryDetailsData | null;
  isReverseDialogOpen: boolean = false;
  isReverseInProgress: boolean = false;
  private breadCrumbService = inject(BreadCrumbService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private tranService = inject(TransactionsService);
  private change = inject(ChangeDetectorRef);
  private transactionService = inject(TransactionsService);
  private notify = inject(NotificationFacade);
  //filterService = inject(FilterService);
  private decimalPipe = inject(DecimalPipe);
  private titleCasePipe = inject(TitleCasePipe);
  private loadingService = inject(LoadingService);

  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
    {
      filterKey: 'type',
      label: 'Transaction Type',
      type: 'dropdown',
      dropdown: [
        { key: 'credit', value: 'Credit' },
        { key: 'debit', value: 'Debit' },
      ],
    },
    {
      filterKey: 'status',
      label: 'Status',
      type: 'dropdown',
      dropdown: [
        { key: 'withdrawn', value: 'Withdrawn' },
        { key: 'withheld', value: 'Withheld' },
        { key: 'denied', value: 'Denied' },
        { key: 'completed', value: 'Completed' },
      ],
    },
  ];

  constructor() {
    this.isFetchingTransactions$ = this.loadingService.getLoadingObservable(
      'get-wallet-transactions'
    );
  }

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);

    this.getTransactionsList(new Pagination())
    this.getProvidusSettlementAcctBalance();
    this.getOptimusSettlementAcctBalance()
    this.WalletFiltersEventListener();

  }

  WalletFiltersEventListener() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let query = "";
      if (params[this.filterStructure[0].filterKey]) {
        query = `start_date=${params['start_date']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `end_date=${params['end_date']}&`;
      }
      if (params[this.filterStructure[2].filterKey]) {
        query += `type=${params['type']}&`;
      }
      if (params[this.filterStructure[3].filterKey]) {
        query += `status=${params['status']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}`;
      }
      if (query) {
        const sub = this.tranService.filterWalletTransactionsList(query).subscribe({
          next: (res) => {
            this.transactionsList = res.data.data;
            this.totalCount = 10; // 10 to remove pagination


            this.change.detectChanges();
          },
          error: (err) => { },
        });
        this.subs.push(sub);
      } else {
        this.getTransactionsList(new Pagination());
      }
    });
  }



  initDropdownList(transaction: TransactionsListDatum) {
    return (this.actionList = [
      {
        title: 'Approve',
        action: () => {
          const transactionId = transaction.id;
          const accountId = transaction.account_id;

          this.router.navigateByUrl(
            `/admin/transactions/wallet/approve?tran_id=${transactionId}&account_id=${accountId}`
          );
        },
        show:
          transaction.status === 'pending' || transaction.status === 'withheld',
      },
      {
        title: 'Reject',
        action: () => {
          this.isRejectDialogOpen = true;
          this.transactionId = transaction.id;
          this.accountId = transaction.account.id;
        },
        show:
          transaction.status === 'pending' || transaction.status === 'withheld',
      },
      {
        action: () => {
          this.viewDetails(transaction);
        },
        title: 'View Details',
        show: true,
      },      {
        action: () => {
          this.viewAccountDetails(transaction);
        },
        title: 'View Account',
        show: true,
      },
      {
        action: () => {
         this.viewDetailsToReverse(transaction)
        },
        title: 'Reverse Fund',
        show: !transaction?.reversed_request_id,
      },
    ]);
  }

  viewDetailsToReverse(transaction: TransactionsListDatum) {
    this.router.navigate(
      [`/admin/transactions/wallet/details`, `${transaction.id}`],
      {
        relativeTo: this.activatedRoute,
        state: { transaction },
        queryParams: { action: 'reverseTransaction' },
      }
    );
  }

 


  viewAccountDetails(txn: any) {
    this.router.navigateByUrl(
      `/admin/accounts/${txn.account.account_type}/${txn.account_id}`
    );
  }
  viewDetails(transaction: TransactionsListDatum) {
    this.router.navigate(
      [`/admin/transactions/wallet/details`, `${transaction.id}`],
      {
        relativeTo: this.activatedRoute,
        state: { transaction },
      }
    );
  }

  rejectTransaction() {
    this.isRejectInProgress = true;

    if (!(this.transactionId && this.accountId)) {
      this.isRejectInProgress = false;
      this.notify.error('Invalid transaction or account details');
      return;
    }
    const sub = this.transactionService
      .processDebitTransaction({
        status: 'denied',
        transactionId: this.transactionId,
        accountId: this.accountId,
      })
      .subscribe({
        next: (response) => {
          this.isRejectInProgress = false;
          this.isRejectDialogOpen = false;

          this.updateTransactionStatus({
            status: 'denied',
            transactionId: this.transactionId as string,
          });

          this.notify.success('Transaction rejected successfully');
          this.change.detectChanges();
        },
        error: (err) => {
          this.isRejectInProgress = false;
          this.isRejectDialogOpen = false;

          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }

  approveTransaction() {
    this.isApproveInProgress = true;
    this.isApproveLoadingText = 'Approving Transaction...';

    if (!(this.transactionId && this.accountId)) {
      this.notify.error('Invalid transaction or account details');
      this.isApproveInProgress = false;
      this.isApproveLoadingText = 'Yes, Approve Transaction';
      return;
    }

    if (!this.beneficiaryDetails?.bank_account_number) {
      this.notify.error('No beneficiary account number provided');
      this.isApproveInProgress = false;
      this.isApproveLoadingText = 'Yes, Approve Transaction';
      return;
    }

    const sub = this.transactionService
      .processDebitTransaction({
        status: 'completed',
        transactionId: this.transactionId,
        accountId: this.accountId,
      })
      .subscribe({
        next: (response) => {
          this.isApproveInProgress = false;
          this.isApproveDialogOpen = false;

          this.updateTransactionStatus({
            status: 'completed',
            transactionId: this.transactionId as string,
          });

          this.change.detectChanges();
          this.notify.success('Transaction approved successfully');
        },
        error: (err) => {
          this.isApproveInProgress = false;
          this.isApproveDialogOpen = true;

          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }

  updateTransactionStatus(updatePaylod: {
    status: 'denied' | 'completed';
    transactionId: string;
  }) {
    let transactionIndex = 0;
    this.transactionsList.forEach((transaction, index) => {
      if (transaction.id === this.transactionId) {
        transactionIndex = index;
        return;
      }
    });

    if (transactionIndex === -1) {
      return;
    }

    let newStatus =
      updatePaylod.status === 'completed' ? 'completed' : 'denied';

    const updatedTransactions = {
      ...this.transactionsList[transactionIndex],
      status: newStatus,
    };

    const updatedTransactionList = [
      ...this.transactionsList.slice(0, transactionIndex),
      updatedTransactions,
      ...this.transactionsList.slice(transactionIndex + 1),
    ];

    this.transactionsList = updatedTransactionList;
  }

  getBeneficiaryDetails(accountId: string) {
    this.isApproveInProgress = true;
    this.isApproveLoadingText = 'Fetching Transaction Details, please wait...';

    const sub = this.transactionService
      .getBeneficiaryDetails(accountId)
      .subscribe({
        next: (response) => {
          this.beneficiaryDetails = response.data;
          if (response.data.bank_account_number === null) {
            this.disableApproveAction = true;
          }
          this.isApproveInProgress = false;

          let amount = this.transactionDetails?.transaction_amount
            ? this.decimalPipe.transform(
              (this.transactionDetails?.transaction_amount as number) / 100,
              '1.2-2'
            )
            : 'not provided';
          let bankAccountName = response.data.bank_account_name
            ? this.titleCasePipe.transform(response.data.bank_account_name)
            : 'not provided';

          this.approveDialogQuestion = `
          <p>Are you sure you want to approve this Debit transaction? This action cannot be undone. </p>

          <div class="flex flex-col text-sm gap-1 mt-1">
            <h5>Recipient Details</h5>
            <div class="flex justify-between">
              <div class="text-gray-500"> Account Name: </div>
              <div class="font-bold">${bankAccountName}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Account Number: </div>
              <div class="font-bold">${response.data.bank_account_number ? response.data.bank_account_number : 'not provided'}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Amount: </div>
              <div class="font-bold"> ${amount}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Remark: </div>
              <div class="font-bold">${this.transactionDetails?.tran_remarks ? this.transactionDetails?.tran_remarks : 'not provided'}</div>
            </div>
          </div>
          `;

          this.disableApproveAction = false;

          this.change.detectChanges();
        },
        error: (err) => {
          // console.log(err);
          this.isApproveInProgress = false;
          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }

  getCountByTypeAndStatus(type: string, status: string) {
    const sub = this.tranService
      .getCountByTypeAndStatus(type, status)
      .subscribe({
        next: (resp: any) => {
          if (type === 'credit') {
            this.totalCredit = resp.data.total;
          } else if (type === 'debit') {
            this.totalDebit = resp.data.total;
          }
        },
        error: (err) => { },
      });
    this.subs.push(sub);
  }

  toggleFilterMenu() {
    this.filterMenuState =
      this.filterMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  getTransactionsList(page: Pagination = new Pagination()) {
    const sub = this.tranService.viewWalletTransactionsList(page).subscribe({
      next: (res) => {
        this.transactionsList = res.data.data;
        this.totalCount = res.data.total_count;
      },
      error: (err) => { },
    });
    this.subs.push(sub);
  }

  navigate(page: number) {
    this.getTransactionsList(new Pagination({ skip: (page * 10) - 10 }));
  }


  getProvidusSettlementAcctBalance() {
    const sub = this.transactionService
      .getProvidusSettlementAccountBalance()
      .subscribe({
        next: (res) => {
          this.providusBalanceSubject$.next(Number(res.data?.availableBalance ?? '0'));
        },
        error: (err) => {
          this.notify.error(
            'Failed to fetch Providus Settlement Account Balance'
          );
        },
      });
    this.subs.push(sub);
  }

  getOptimusSettlementAcctBalance() {
    const sub = this.transactionService
      .getOptimusSettlementAccountBalance()
      .subscribe({
        next: (res) => {
          this.optimusBalanceSubject$.next(Number(res.data?.availableBalance));
        },
        error: (err) => {
          this.notify.error(
            'Failed to fetch Optimus Settlement Account Balance'
          );
        },
      });
    this.subs.push(sub);
  }

  get totalPage() {
    const remain = this.totalCount % 10; //.4
    const round = Number.parseInt((this.totalCount / 10).toFixed(0));
    return remain > 0 ? round + 1 : round;
  }


  exportReport() {
    this.router.navigateByUrl(`${this.router.url}/export-report`);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
