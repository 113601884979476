import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  GetPortfolioChart,
  PortfolioBalanceResponse,
  PortfolioChartData,
  PortfolioListResponse,
} from '@core/models/admin/portfolio';
import { finalize, map, Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  http = inject(HttpClient);
  BASE_URL = environment.ADMIN_BASE_URL + '/v1/portfolio';
  private loadingService = inject(LoadingService);

  constructor() {}

  filterPortfolioList(queryParams: string, accountId: string): Observable<PortfolioListResponse> {
    return this.http.get<PortfolioListResponse>(`${this.BASE_URL}/filter-account-portfolios?account_id=${accountId}&${queryParams}`);
  }

  getPortfolioList(accountId: string): Observable<PortfolioListResponse> {
    const uniqueStateUrl = 'get-portfolio-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<PortfolioListResponse>(
        `${this.BASE_URL}/get-portfolio-list?account_id=${accountId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getPorfolioBalance(payload: {
    account_id: string;
    currency: 'NGN' | 'USD';
  }): Observable<PortfolioBalanceResponse> {
    const uniqueStateUrl = 'get-portfolio-balance';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<PortfolioBalanceResponse>(
        `${this.BASE_URL}/get-portfolio-balance`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getPortfolioChart(payload: {
    account_id: string;
    currency: 'NGN' | 'USD';
  }): Observable<{
    pms_sum: number;
    lcm_sum: number;
    cashOnHand: number;
  } | null> {
    const uniqueStateUrl = URL_KEYS.PORTFOLIO.GET_PORTFOLIO_CHART;
    this.loadingService.setLoading(uniqueStateUrl, true);

    const params = new HttpParams()
      .set('account_id', payload.account_id)
      .set('currency', payload.currency);

    return this.http
      .get<GetPortfolioChart>(`${this.BASE_URL}/get-portfolio-chart`, {
        params,
      })
      .pipe(
        map((res: GetPortfolioChart) => {
          const { pms_sum, lcm_sum, cashOnHand } = res.data;
          const total = pms_sum + lcm_sum + cashOnHand;

          if (total === 0) return null;

          const pmsPercentage = Math.round((pms_sum / total) * 100);
          const lcmPercentage = Math.round((lcm_sum / total) * 100);
          const cashOnHandPercentage = Math.round((cashOnHand / total) * 100);

          return {
            pms_sum: pmsPercentage,
            lcm_sum: lcmPercentage,
            cashOnHand: cashOnHandPercentage,
          };
        }),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}
