<app-kyc-accordion accordionTitle="Means Of Id" accordionKey="meansOfId">
  <app-tabs [tabs]="profileTabs" [initialSelectedTab]="0"> </app-tabs>

  <ng-template #profileOneTemplate>
    <form class="" [formGroup]="meansOfIdFormOne">
      <div
        class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5"
      >
        <!-- ID Type -->
        <div>
          <app-input-label labelFor="idType" labelClass="label--bottom-margin"
            >ID Type</app-input-label
          >
          <select name="idType" required base-select formControlName="type">
            <option value="" disabled selected>Select ID type</option>
            <option
              *ngFor="let idType of meansOfIdTypes"
              [value]="idType.value"
            >
              {{ idType.label }}
            </option>
          </select>
        </div>

        <!-- ID Number -->
        <div>
          <app-input-label
            labelFor="id_number"
            labelClass="label--bottom-margin"
            [isRequired]="true"
            >ID Number</app-input-label
          >

          <app-input
            placeholder="Enter ID Number"
            [inputClass]="'input--padding'"
            formControlName="number"
          ></app-input>
        </div>

        <!-- Issue Date -->
        <div>
          <app-input-label
            labelClass="label--bottom-margin"
            [isRequired]="requiresExpiry"
            >Issue Date</app-input-label
          >
          <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
          <input
            type="date"
            input-date
            class="input--padding"
            formControlName="issue_date"
          />
        </div>

        <!-- Exipry Date -->
        <div>
          <app-input-label
            labelClass="label--bottom-margin"
            [isRequired]="requiresExpiry"
            >Exipry Date</app-input-label
          >
          <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
          <input
            type="date"
            input-date
            class="input--padding"
            formControlName="expiry_date"
          />
        </div>
      </div>
      <div class="mt-5 flex justify-end">
        <div
          [ngClass]="{
            'cursor-not-allowed': (isLoadingProfileOne$ | async)
          }"
        >
          <button
            type="button"
            mango-button
            [loading]="(isLoadingProfileOne$ | async)!!"
            [disabled]="(isLoadingProfileOne$ | async)!!"
            (click)="submitForm('one')"
            class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
          >
            <span
              *ngIf="isLoadingProfileOne$ | async"
              spin
              [loadingText]="
                (profileOneHasIdSubject$ | async) === true
                  ? 'Updating Means of ID...'
                  : 'Saving Means of ID...'
              "
            ></span>
            @if (!(isLoadingProfileOne$ | async)) {
              {{
                (profileOneHasIdSubject$ | async) === true
                  ? 'Update Means of ID'
                  : 'Add Means of ID'
              }}
            }
          </button>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #profileTwoTemplate>
    @if (jointDataDetails.length === 1) {
      <div
        class="mb-3 inline-flex items-center gap-[0.9rem] rounded-xl bg-mango-floralWhite px-[0.6rem] py-[0.9rem]"
      >
        <img
          src="assets/images/svgs/info-circle--yellow.svg"
          alt=""
          class="h-[1.5625rem] w-[1.5625rem] flex-1"
        />
        <p class="text-sm leading-[1.3125rem] text-mango-grey">
          Create the Customer Information Profile for '{{
            profileTabs[1].label
          }}' to continue.
        </p>
      </div>
    }

    <form
      *ngIf="jointDataDetails.length > 1"
      class=""
      [formGroup]="meansOfIdFormTwo"
    >
      <div
        class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5"
      >
        <!-- ID Type -->
        <div>
          <app-input-label labelFor="idType" labelClass="label--bottom-margin"
            >ID Type</app-input-label
          >
          <select name="idType" required base-select formControlName="type">
            <option value="" disabled selected>Select ID type</option>
            <option
              *ngFor="let idType of meansOfIdTypes"
              [value]="idType.value"
            >
              {{ idType.label }}
            </option>
          </select>
        </div>

        <!-- ID Number -->
        <div>
          <app-input-label
            labelFor="id_number"
            labelClass="label--bottom-margin"
            [isRequired]="true"
            >ID Number</app-input-label
          >

          <app-input
            placeholder="Enter ID Number"
            [inputClass]="'input--padding'"
            formControlName="number"
          ></app-input>
        </div>

        <!-- Issue Date -->
        <div>
          <app-input-label
            labelClass="label--bottom-margin"
            [isRequired]="requiresExpiry"
            >Issue Date</app-input-label
          >
          <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
          <input
            type="date"
            input-date
            class="input--padding"
            formControlName="issue_date"
          />
        </div>

        <!-- Exipry Date -->
        <div>
          <app-input-label
            labelClass="label--bottom-margin"
            [isRequired]="requiresExpiry"
            >Exipry Date</app-input-label
          >
          <!-- [value]="companyDetails.corporate_kyc.registered_date | date" -->
          <input
            type="date"
            input-date
            class="input--padding"
            formControlName="expiry_date"
          />
        </div>
      </div>
      <div class="mt-5 flex justify-end">
        <div
          [ngClass]="{
            'cursor-not-allowed': (isLoadingProfileTwo$ | async)
          }"
        >
          <button
            type="button"
            mango-button
            [loading]="(isLoadingProfileTwo$ | async)!!"
            [disabled]="(isLoadingProfileTwo$ | async)!!"
            (click)="submitForm('two')"
            class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
          >
            <span
              *ngIf="isLoadingProfileTwo$ | async"
              spin
              [loadingText]="
                (profileTwoHasIdSubject$ | async) === true
                  ? 'Updating Means of ID...'
                  : 'Saving Means of ID...'
              "
            ></span>
            @if (!(isLoadingProfileTwo$ | async)) {
              {{
                (profileTwoHasIdSubject$ | async) === true
                  ? 'Update Means of ID'
                  : 'Add Means of ID'
              }}
            }
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</app-kyc-accordion>
