<app-kyc-accordion accordionTitle="Next of Kin" accordionKey="nextOfKin"   [isKycComplete]="isKycComplete">
  <div *ngIf="!nextOfKin">
    <p>No next of kin details</p>
  </div>
  <div *ngIf="nextOfKin" class="grid grid-cols-1 gap-y-5 md:grid-cols-4">
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">First Name</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.first_name | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Last Name</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.last_name | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Title</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.title | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Email</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.email | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Phone Number</li>
      <li class="text-sm font-light">
        {{ nextOfKin.phone_number || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Gender</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.gender | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Date of Birth</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.dob | date) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Relationship</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.relationship_with_nok | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Country</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.country | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">State</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.state | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">City/ Town</li>
      <li class="text-sm font-light">
        {{ (nextOfKin.city | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Address & House Number</li>
      <li class="text-sm font-light">
        {{ nextOfKin.house_number || '—' }}
      </li>
    </ul>
  </div>
</app-kyc-accordion>
