import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Employerkyc } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-ind-employment-details',
  standalone: true,
  imports: [KycAccordionComponent, CommonModule],
  templateUrl: './ind-employment-details.component.html',
  styleUrl: './ind-employment-details.component.scss',
})
export class IndEmploymentDetailsComponent {
  @Input() employmentDetails!: Employerkyc;
  isKycComplete: boolean = false;

  ngOnInit(): void {
    this.checkKycStatus();
  }

  private checkKycStatus(): void {
    if (!this.employmentDetails) {
      this.isKycComplete = false;
      return;
    }

    this.isKycComplete = !! this.employmentDetails.name;
      
    
  }
}
