import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { ProfileAccountsComponent } from '@shared/ui/profile-accounts/profile-accounts.component';

@Component({
  selector: 'app-joint-accounts',
  standalone: true,
  imports: [ProfileAccountsComponent],
  templateUrl: './joint-accounts.component.html',
  styleUrl: './joint-accounts.component.scss',
})
export class JointAccountsComponent implements OnInit {
  accountId!: string;

  private activatedRoute = inject(ActivatedRoute);

  ngOnInit(): void {
    this.accountId = this.activatedRoute.snapshot.params['accountId'];
  }
}
