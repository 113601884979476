import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CorporateDirector } from '@core/models/admin/account';
import { CorporateRegistrationService } from '@core/services/corporate-registration.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { Observable, Subscription } from 'rxjs';
import { LoadingService } from '@core/services/loading.service';
import { CustomValidators } from '@core/validators/custom-validator';

@Component({
  selector: 'app-edit-director-details',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    SpinDirective,
  ],
  templateUrl: './edit-director-details.component.html',
  styleUrl: './edit-director-details.component.scss',
})
export class EditDirectorDetailsComponent implements OnInit, OnDestroy {
  @Input() directorDetails!: CorporateDirector[] | null;

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  corporateService = inject(CorporateRegistrationService);
  cdRef = inject(ChangeDetectorRef);
  toast = inject(NotificationFacade);
  router = inject(Router);
  private loadingService = inject(LoadingService);

  // isLoading: boolean = false;
  totalDirectors!: number;
  accountId!: string;
  subs: Subscription[] = [];
  isLoading$!: Observable<boolean>;
  directorDetailsForm = this.fb.nonNullable.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone_number: ['', [Validators.required, CustomValidators.phoneNumber()]],
    bvn: ['', [Validators.required, CustomValidators.bvn()]],
    account_id: [this.accountId],
  });

  constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      'add-corporate-director-kyc'
    );
  }

  ngOnInit(): void {
    this.setAccountId();
  }

  setAccountId() {
    this.accountId = this.route.snapshot.params['accountId'];
    this.directorDetailsForm.patchValue({
      account_id: this.accountId,
    });
  }

  addNewDirector(): void {
    const sub = this.corporateService
      .directorDetails(this.directorDetailsForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.directorDetails = response.data;
          this.cdRef.detectChanges();
          this.toast.success('Successfully added director');
        },
        error: (resp) => {
          this.toast.error('Failed to add director details');
        },
      });

    this.subs.push(sub);
  }

  resetForm() {
    // this.directorDetailsForm.reset();
    this.directorDetailsForm.patchValue({
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      bvn: '',
      account_id: this.accountId,
    });

    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
