import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { AccountManagementService } from '@core/services/account-management.service';
import { CommonService } from '@core/services/common.service';
import { LoadingService } from '@core/services/loading.service';
import {
  IInvestementActionPayload,
  MutualFundService,
} from '@core/services/mutual-fund.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import Decimal from 'decimal.js';
import { combineLatest, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-add-mutual-funds-transaction',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    InputLabelComponent,
    SlideInRightModalComponent,
    FormsModule,
    ReactiveFormsModule,
    BaseSelectDirective,
    ButtonDirective,
    ConfirmDialogComponent,
  ],
  templateUrl: './add-mutual-funds-transaction.component.html',
  styleUrl: './add-mutual-funds-transaction.component.scss',
})
export class AddMutualFundsTransactionComponent implements OnInit, OnDestroy {
  fb = inject(FormBuilder);
  change = inject(ChangeDetectorRef);
  commonService = inject(CommonService);
  notify = inject(NotificationFacade);
  loadingService = inject(LoadingService);
  mutualFundService = inject(MutualFundService);
  accountService = inject(AccountManagementService);
  router = inject(Router);
  activateRoute = inject(ActivatedRoute);

  totalAccural: Decimal = new Decimal(0);
  displayAccural: Decimal = new Decimal(0);
  subs: Subscription[] = [];
  isLoading$!: Observable<boolean>;
  isInvestmentActionInProgress: boolean = false;
  isInvestmentActionDialogOpen: boolean = false;
  pendingTransactionData: IInvestementActionPayload | null = null;

  transactionTypes = [
    { label: 'Subscription', value: 'Subscription' },
    { label: 'Redemption', value: 'Redemption' },
    { label: 'Total Acrrued Redemption', value: 'invest_principal_only' },
    { label: 'Dividend Reinvestment', value: 'invest_total_value' },
    { label: 'Total Redemption', value: 'redem_total_value' },
  ];

  mutualFundsTransactionForm = this.fb.nonNullable.group({
    account_number: ['', Validators.required],
    name: ['', Validators.required],
    amount: [new Decimal(0), Validators.required],
    type: ['', Validators.required],
    date: ['', Validators.required],
  });

  constructor() {
    this.isLoading$ =
      this.loadingService.getLoadingObservable('add-transaction');
  }

  ngOnInit(): void {
    this.formChangesWatcher();
    this.watchAccountNumber();
  }

  watchAccountNumber() {
    const accountSub = this.activateRoute.queryParams.subscribe((params) => {
      const accountNumber = params['account_number'];
      if (accountNumber) {
        this.mutualFundsTransactionForm
          .get('account_number')
          ?.setValue(accountNumber);
        this.getAccountName();
      }
    });
    if (accountSub) this.subs.push(accountSub);
  }

  formChangesWatcher() {
    // Watch account_number changes
    const accountSub = this.mutualFundsTransactionForm
      .get('account_number')
      ?.valueChanges.subscribe(() => {
        this.getAccountName();
      });
    if (accountSub) this.subs.push(accountSub);

    // Combined watcher for type and date changes
    const transactionWatcherSub = combineLatest([
      this.mutualFundsTransactionForm.get('type')!.valueChanges,
      this.mutualFundsTransactionForm.get('date')!.valueChanges,
      this.mutualFundsTransactionForm.get('account_number')!.valueChanges,
    ]).subscribe(([type, date, accountNumber]) => {
      if (
        accountNumber &&
        date &&
        type &&
        type !== 'Subscription' &&
        type !== 'Redemption'
      ) {
        this.getAccountTotalAccural(accountNumber, date);
      }

      if (transactionWatcherSub) this.subs.push(transactionWatcherSub);
    });
  }

  getAccountName() {
    const account =
      this.mutualFundsTransactionForm.get('account_number')?.value || '';
    if (account?.length >= 10) {
      const sub = this.accountService
        .filterAccountList(`search_text=${account}`)
        .subscribe({
          next: (res: any) => {
            if (res.data.data.length === 0) {
              this.notify.error('Account not found');
              return;
            }
            this.mutualFundsTransactionForm
              .get('name')
              ?.setValue(this.commonService.getAccountName(res.data.data[0]));
            this.change.detectChanges();
          },
          error: () => {
            this.notify.error('Unable to validate account number. Try again');
            this.change.detectChanges();
          },
        });
      this.subs.push(sub);
    }
  }

  getAccountTotalAccural(account_number: string, date: string) {
    const sub = this.mutualFundService
      .getTotalAccured(account_number, date)
      .subscribe({
        next: (res) => {
          const dividend = new Decimal(res.data._sum.dividend || 0);
          this.displayAccural = dividend.div(100);
          this.totalAccural = dividend;
          this.mutualFundsTransactionForm
            .get('amount')
            ?.setValue(this.displayAccural);
          this.mutualFundsTransactionForm.get('amount')?.disable();
          this.change.detectChanges();
        },
        error: (err) => {
          console.error('Error fetching total accrual:', err);
        },
      });
    this.subs.push(sub);
  }

  addTransaction() {
    if (this.mutualFundsTransactionForm.valid) {
      const formData = this.mutualFundsTransactionForm.getRawValue();

      // Multiply amount by 100
      formData.amount = new Decimal(formData.amount).times(100);

      let transactionRequest: Observable<any>;

      if (formData.type === 'Subscription') {
        transactionRequest = this.mutualFundService.addSubscription(formData);
      } else if (formData.type === 'Redemption') {
        transactionRequest = this.mutualFundService.addRedemption(formData);
      } else {
        // Change "type" to "action_type" for other transactions
        const newFormData = {
          ...formData,
          action_type: formData.type, // Rename "type" to "action_type"
          amount: String(formData.amount), // Convert amount to string
          type: undefined, // Remove original "type" field
        };

        if (this.totalAccural <= Decimal(0)) {
          this.notify.error('Total Accrual is 0. Cannot proceed');
          return;
        }
        // Store transaction data and open modal
        this.pendingTransactionData = newFormData;
        this.isInvestmentActionDialogOpen = true;
        return;
      }
      this.processTransaction(transactionRequest);
    }
  }

  confirmTransaction() {
    if (!this.pendingTransactionData) return;

    this.isInvestmentActionInProgress = true;

    if (this.totalAccural > Decimal(0)) {
      const transactionData = {
        ...this.pendingTransactionData,
        amount: String(this.totalAccural), // Convert to string
      };

      const transactionRequest =
        this.mutualFundService.investmentAction(transactionData);

      // const transactionRequest = this.mutualFundService.investmentAction(this.pendingTransactionData);

      const sub = transactionRequest.subscribe({
        next: () => {
          this.notify.success('Transaction added successfully');
          this.mutualFundsTransactionForm.reset();
          this.router.navigateByUrl(
            '/admin/transactions/mutual-funds/tab/subred'
          );
          this.isInvestmentActionDialogOpen = false;
          this.isInvestmentActionInProgress = false;
          this.pendingTransactionData = null;
          this.change.detectChanges();
        },
        error: () => {
          this.notify.error('Transaction failed');
          this.isInvestmentActionInProgress = false;
          this.change.detectChanges();
        },
      });

      this.subs.push(sub);
    } else {
      this.notify.error('Insufficient balance');
      this.isInvestmentActionDialogOpen = false;
      this.pendingTransactionData = null;
    }
  }

  processTransaction(transactionRequest: Observable<any>) {
    const sub = transactionRequest.subscribe({
      next: () => {
        this.notify.success('Transaction added successfully');
        this.mutualFundsTransactionForm.reset();
        this.router.navigateByUrl(
          '/admin/transactions/mutual-funds/tab/subred'
        );
        this.change.detectChanges();
      },
      error: () => {
        this.notify.error('Transaction failed');
        this.change.detectChanges();
      },
    });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
