<app-slide-in-right-modal
  modalTitle="
    Add Admin User
  "
  [slideOpen]="true"
  iconImageSrc="assets/images/svgs/user--outline.svg"
  returnPath="/admin/users"
>
  @if (!(isFetchingRoles$ | async)) {
    <div class="page__container flex flex-col pb-8">
      <p class="mb-4 text-sm text-mango-gray74">
        Kindly input Admin User details to send an invite link
      </p>

      <form class="flex flex-col gap-5 px-0 pt-5" [formGroup]="adminForm">
        <!-- First name and Last name -->
        <!-- <div
          class="form-row flex flex-col gap-5 md:grid md:grid-cols-2 md:gap-x-5"
        > -->
        <!-- First Name -->
        <div>
          <app-input-label
            labelFor="first-name"
            labelClass="label--bottom-margin"
            [isRequired]="true"
            >First Name</app-input-label
          >

          <app-input
            placeholder="Enter First Name"
            [inputClass]="'input--padding'"
            formControlName="first_name"
          ></app-input>
        </div>

        <!-- Middle Name -->
        <div>
          <app-input-label
            labelFor="middle-name"
            labelClass="label--bottom-margin"
            >Middle Name</app-input-label
          >

          <app-input
            placeholder="Enter Middle Name"
            [inputClass]="'input--padding'"
            formControlName="middle_name"
          ></app-input>
        </div>

        <!-- Last Name -->
        <div>
          <app-input-label
            labelFor="last-name"
            labelClass="label--bottom-margin"
            [isRequired]="true"
            >Last Name</app-input-label
          >

          <app-input
            placeholder="Enter Last Name"
            [inputClass]="'input--padding'"
            formControlName="last_name"
          ></app-input>
        </div>
        <!-- </div> -->

        <!-- Email -->
        <div>
          <app-input-label
            labelFor="email"
            labelClass="label--bottom-margin"
            [isRequired]="true"
            >Email</app-input-label
          >

          <app-input
            placeholder="Enter Email"
            [inputClass]="'input--padding'"
            formControlName="email"
          ></app-input>
        </div>

        <div>
          <app-input-label
            labelFor="phone-number"
            labelClass="label--bottom-margin"
            [isRequired]="true"
            >Phone Number
          </app-input-label>
          <app-input
            placeholder="Enter Phone Number"
            [inputClass]="'input--padding'"
            formControlName="phone_number"
          ></app-input>
        </div>

        <div>
          <app-input-label
            labelFor="idType"
            labelClass="label--bottom-margin"
            [isRequired]="true"
            >Role</app-input-label
          >

          <!-- Role dropdown -->
          <div class="relative">
            <div
              class="relative w-full cursor-default rounded-md bg-white py-[0.62rem] pl-[0.88rem] pr-6 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-mango sm:text-sm sm:leading-6"
              aria-haspopup="listbox"
              aria-expanded="true"
              tabindex="0"
            >
              <span
                *ngIf="(selectedRolesList$ | async)?.length"
                class="flex flex-wrap gap-1"
              >
                @for (role of selectedRolesList$ | async; track $index) {
                  <span
                    class="inline-flex w-fit items-center gap-1 rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-[#0A756F]"
                  >
                    {{ role.name }}
                    <img
                      (click)="removeRole(role.id)"
                      class="cursor-pointer"
                      src="assets/images/svgs/x-close--green.svg"
                      alt=""
                    />
                  </span>
                }
              </span>
              <span *ngIf="(selectedRolesList$ | async)?.length === 0">
                No role selected
              </span>
              <span
                class="absolute inset-y-0 right-0 flex items-center px-[0.88rem] py-[0.62rem]"
              >
                <img
                  #toggleButton
                  (click)="togglePopover()"
                  src="assets/images/chevron-down--gray.png"
                  alt=""
                  [ngClass]="{ 'rotate-180': popoverState === 'visible' }"
                  class="cursor-pointer"
                />
              </span>
            </div>

            <ul
              #menu
              [@selectPopoverAnimation]="popoverState"
              (@selectPopoverAnimation.start)="popOverOpen($event)"
              [ngClass]="[popoverState === 'hidden' ? 'hidden' : '']"
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              @for (role of allRolesList$ | async; track $index) {
                <li
                  class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                  id="listbox-option-0"
                  role="option"
                >
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <div
                    [ngClass]="[role.checked ? 'font-semibold' : 'font-normal']"
                    class="relative flex items-start"
                  >
                    <div class="flex h-6 items-center">
                      <input
                        [id]="role.id"
                        aria-describedby="comments-description"
                        [name]="role.name"
                        type="checkbox"
                        [value]="role.id"
                        [checked]="role.checked"
                        (click)="toggleRoleCheckbox($event, $index)"
                        class="h-4 w-4 rounded border-gray-300 text-mango focus:ring-mango"
                      />
                    </div>
                    <div class="ml-3 text-sm leading-6">
                      <label
                        [htmlFor]="role.id"
                        class="font-medium text-gray-900"
                        >{{ role.name }}
                      </label>
                    </div>
                  </div>
                </li>
              }
            </ul>
          </div>
        </div>

        <div class="xl:grid xl:grid-cols-2">
          <div
            [ngClass]="{
              'cursor-not-allowed':
                (isInvitingAdmin$ | async) || adminForm.pristine
            }"
            class="col-start-3 justify-self-end"
          >
            <button
              type="button"
              mango-button
              (click)="inviteAdmin()"
              [disabled]="(isInvitingAdmin$ | async)!! || adminForm.pristine"
              [loading]="(isInvitingAdmin$ | async)!! || adminForm.pristine"
              class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
            >
              <span
                *ngIf="isInvitingAdmin$ | async"
                spin
                loadingText="Inviting Admin"
              ></span>
              @if (!(isInvitingAdmin$ | async)) {
                Save
              }
            </button>
          </div>
        </div>
      </form>
    </div>
  } @else {
    <div class="flex flex-col">
      <div *ngFor="let in of [0, 1, 2, 3]; let i = index">
        <ngx-skeleton-loader
          [theme]="{ height: '1rem', width: '100px' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          [theme]="{ height: '2rem', width: '100%' }"
        ></ngx-skeleton-loader>
      </div>
      <div class="flex justify-end">
        <ngx-skeleton-loader
          [theme]="{ height: '2rem', width: '100px' }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  }
</app-slide-in-right-modal>
