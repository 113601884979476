<div class="flex grid-cols-2 flex-col gap-4 md:grid md:justify-between">
  <h2
    class="text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
  >
    Settings
  </h2>

  <!-- remove if no button action for this page -->
  <!-- <button
      type="button"
      class="w-full bg-mango text-white py-3 px-5 rounded-lg cursor-pointer md:w-fit md:justify-self-end"
    >
      + Add New Settings
    </button> -->
</div>

<div class="mt-[0.94rem] grid gap-6 lg:grid-cols-3">
  <!-- page content here -->
</div>
