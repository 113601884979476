import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import {
  BeneficiaryDetailsData,
  WalletTransactionData,
} from '@core/models/admin/account';
import { Pagination } from '@core/models/pagination.model';
import { AccountManagementService } from '@core/services/account-management.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { TransactionsService } from '@core/services/transactions.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { CorporateTabsComponent } from '@shared/ui/corporate-tabs/corporate-tabs.component';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { Subscription } from 'rxjs';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { IFILTER } from 'app/app.constants';
import { FiltersComponent } from '@shared/ui/filters/filters.component';

@Component({
  selector: 'app-wallet',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    CorporateTabsComponent,
    InputLabelComponent,
    BaseSelectDirective,
    ButtonDirective,
    InputDateDirective,
    DropdownComponent,
    TableNavigatorComponent,
    RouterOutlet,
    ConfirmDialogComponent,
    FiltersComponent,
  ],
  providers: [DecimalPipe, TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './wallet.component.html',
  styleUrl: './wallet.component.scss',
  animations: [dropdownMenuAnimation],
})
export class WalletComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  currentPage: number = 1;
  totalCount: number = 0;
  subs: Subscription[] = [];
  walletList: any[] = [];
  accountId!: string;
  walletTransactionList: WalletTransactionData[] = [];
  isLoading: boolean = false;
  returnPath!: string;
  transactionId!: string | null;
  nairaWalletBalance: number = 0;
  dollarWalletBalance: number = 0;
  beneficiaryDetails!: BeneficiaryDetailsData | null;
  transactionDetails!: WalletTransactionData | null;

  // reject dialog
  isRejectDialogOpen: boolean = false;
  isRejectDialogLoading: boolean = false;

  // approve dialog
  isApproveDialogOpen: boolean = false;
  isApproveInProgress: boolean = false;
  approveDialogQuestion!: any;
  isApproveLoadingText!: string;
  disableApproveAction: boolean = true;

  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  accountService = inject(AccountManagementService);
  change = inject(ChangeDetectorRef);
  transactionService = inject(TransactionsService);
  notify = inject(NotificationFacade);
  breadCrumbService = inject(BreadCrumbService);
  decimalPipe = inject(DecimalPipe);
  titleCasePipe = inject(TitleCasePipe);


  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
    {
      filterKey: 'type',
      label: 'Transaction Type',
      type: 'dropdown',
      dropdown: [
        { key: 'credit', value: 'Credit' },
        { key: 'debit', value: 'Debit' },
      ],
    },
    {
      filterKey: 'transaction_channel',
      label: 'Transaction Channel',
      type: 'dropdown',
      dropdown: [
        { key: 'transfer', value: 'Transfer' },
        { key: 'card', value: 'Card' },
        { key: 'portfolio', value: 'Portfolio' },
      ],
    },
    {
      filterKey: 'status',
      label: 'Status',
      type: 'dropdown',
      dropdown: [
        { key: 'withdrawn', value: 'Withdrawn' },
        { key: 'withheld', value: 'Withheld' },
        { key: 'denied', value: 'Denied' },
        { key: 'completed', value: 'Completed' },
      ],
    },
  ];

  ngOnInit() {
    this.accountId = this.activatedRoute.snapshot.parent?.params['accountId'];
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);

    this.getCurrentPageTransactions();
    if (this.accountId) {
      this.getWalletBalance();
    }
    this.WalletFiltersEventListener();
  }

  WalletFiltersEventListener() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let query = "";
      if (params[this.filterStructure[0].filterKey]) {
        query = `start_date=${params['start_date']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `end_date=${params['end_date']}&`;
      }
      if (params[this.filterStructure[2].filterKey]) {
        query += `type=${params['type']}&`;
      }
      if (params[this.filterStructure[3].filterKey]) {
        query += `transaction_channel=${params['transaction_channel']}&`;
      }
      if (params[this.filterStructure[4].filterKey]) {
        query += `status=${params['status']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}`;
      }
      if (query) {
        const sub = this.transactionService.filterAccountTransactions(query, this.accountId).subscribe({
          next: (res) => {
            this.walletTransactionList = res.data.data;
            this.totalCount = 10; // 10 to remove pagination
  
            this.change.detectChanges();
          },
          error: (err) => {},
        });
        this.subs.push(sub);
      } else {
        this.getWalletTransactions();
      }
    });
  }

  getCurrentPageTransactions() {
    const sub = this.activatedRoute.queryParams.subscribe({
      next: (param) => {
        this.currentPage = Number.parseInt(param['page'] || '1');
        const skip = this.currentPage === 1 ? 0 : this.currentPage * 10 - 10;
        this.getWalletTransactions(new Pagination({ skip }));
      },
    });
    this.subs.push(sub);
  }

  walletDropdownActions(transaction: WalletTransactionData): DropDownModel[] {
    return [
      {
        title: 'Approve',
        action: () => {
          // console.log(transaction);

          this.isApproveDialogOpen = true;
          this.transactionId = transaction.id;
          this.transactionDetails = transaction;
          if (this.accountId) {
            this.getBeneficiaryDetails();
          }
        },
        show:
          transaction.status === 'pending' || transaction.status === 'withheld',
      },
      {
        title: 'Reject',
        action: () => {
          this.isRejectDialogOpen = true;
          this.transactionId = transaction.id;
        },
        show:
          transaction.status === 'pending' || transaction.status === 'withheld',
      },
      {
        title: 'View Details',
        action: () => {
          let viewTransactionUrl = '';
          let accountType = '';
          accountType = this.router.url.includes('individual')
            ? 'individual'
            : 'corporate';

          this.returnPath = `/admin/accounts/${accountType}/${this.accountId}/wallet`;
          viewTransactionUrl = `/admin/accounts/${accountType}/${this.accountId}/wallet/${transaction.id}`;

          this.router.navigate([viewTransactionUrl], {
            state: { transaction, returnPath: this.returnPath },
          });
        },
        show: true,
      },
    ];
  }

  toggleFilterMenu() {
    this.filterMenuState =
      this.filterMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  getWalletTransactions(page: Pagination = new Pagination()) {
    this.isLoading = true;
    const sub = this.transactionService
      .viewAccountTransactions(this.accountId, page)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.walletTransactionList = response.data.data;
          this.totalCount = response.data.total_count;
          // console.log(this.walletTransactionList);

          this.change.detectChanges();
        },
        error: (err) => {
          this.isLoading = false;

          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }

  getWalletBalance() {
    this.isLoading = true;
    const subs = this.accountService
      .getCorporateAccountInfo(this.accountId)
      .subscribe({
        next: (response) => {
          this.isLoading = false;

          response.data.account_kyc.wallet.forEach((wallet) => {
            if (wallet.type === 'USD') {
              this.dollarWalletBalance = wallet.wallet_balance;
            } else if (wallet.type === 'NGN') {
              this.nairaWalletBalance = wallet.wallet_balance;
            }
          });

          this.change.detectChanges();
        },
        error: (err) => {
          this.isLoading = false;
        },
      });
    this.subs.push(subs);
  }

  approveTransaction() {
    this.isApproveInProgress = true;
    this.isApproveLoadingText = 'Approving Transaction...';

    if (!this.beneficiaryDetails?.bank_account_number) {
      this.notify.error('No beneficiary account number provided');
      this.isApproveInProgress = false;
      this.isApproveLoadingText = 'Yes, Approve Transaction';
      this.change.detectChanges();
      return;
    }

    const sub = this.transactionService
      .processDebitTransaction({
        status: 'completed',
        transactionId: this.transactionId as string,
        accountId: this.accountId,
      })
      .subscribe({
        next: () => {
          this.isApproveInProgress = false;
          this.isApproveDialogOpen = false;

          this.updateTransactionStatus({
            status: 'completed',
            transactionId: this.transactionId as string,
          });
          this.change.detectChanges();
          this.notify.success('Transaction approved successfully');
        },
        error: (err) => {
          this.isApproveInProgress = false;
          this.isApproveDialogOpen = false;

          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }

  rejectTransaction() {
    this.isRejectDialogLoading = true;

    const sub = this.transactionService
      .processDebitTransaction({
        status: 'denied',
        transactionId: this.transactionId as string,
        accountId: this.accountId,
      })
      .subscribe({
        next: (response) => {
          this.isRejectDialogLoading = false;
          this.isRejectDialogOpen = false;

          this.updateTransactionStatus({
            status: 'denied',
            transactionId: this.transactionId as string,
          });

          this.change.detectChanges();
          this.notify.success('Transaction rejected successfully');
        },
        error: (err) => {
          this.isRejectDialogLoading = false;
          this.isRejectDialogOpen = false;

          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }

  getBeneficiaryDetails() {
    this.isApproveInProgress = true;
    this.isApproveLoadingText = 'Fetching Transaction Details, please wait...';

    const sub = this.transactionService
      .getBeneficiaryDetails(this.accountId)
      .subscribe({
        next: (response) => {
          this.beneficiaryDetails = response.data;
          if (response.data.bank_account_number === null) {
            this.disableApproveAction = true;
          }
          this.isApproveInProgress = false;

          let amount = this.decimalPipe.transform(
            (this.transactionDetails?.transaction_amount as number) / 100,
            '1.2-2'
          );
          let bankAccountName = response.data.bank_account_name
            ? this.titleCasePipe.transform(response.data.bank_account_name)
            : 'not provided';

          this.approveDialogQuestion = `
          <p>Are you sure you want to approve this Debit transaction? This action cannot be undone. </p>

          <div class="flex flex-col text-sm gap-1 mt-1">
            <h5>Recipient Details</h5>
            <div class="flex justify-between">
              <div class="text-gray-500"> Account Name: </div>
              <div class="font-bold">${bankAccountName}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Account Number: </div>
              <div class="font-bold">${response.data.bank_account_number ? response.data.bank_account_number : 'not provided'}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Amount: </div>
              <div class="font-bold"> ₦${amount ? amount : 'not provided'}</div>
            </div>
            <div class="flex justify-between">
              <div class="text-gray-500"> Remark: </div>
              <div class="font-bold">${this.transactionDetails?.tran_remarks ? this.transactionDetails?.tran_remarks : 'not provided'}</div>
            </div>
          </div>
          `;

          this.disableApproveAction = false;

          this.change.detectChanges();
        },
        error: (err) => {
          // console.log(err);
          this.isApproveInProgress = false;
          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }

  updateTransactionStatus(updatePaylod: {
    status: 'denied' | 'completed';
    transactionId: string;
  }) {
    let transactionIndex = 0;
    this.walletTransactionList.forEach((transaction, index) => {
      if (transaction.id === this.transactionId) {
        transactionIndex = index;
        return;
      }
    });

    if (transactionIndex === -1) {
      return;
    }

    let newStatus =
      updatePaylod.status === 'completed' ? 'completed' : 'denied';

    const updatedWalletTransaction = {
      ...this.walletTransactionList[transactionIndex],
      status: newStatus,
    };

    const updatedWalletTransactionList = [
      ...this.walletTransactionList.slice(0, transactionIndex),
      updatedWalletTransaction,
      ...this.walletTransactionList.slice(transactionIndex + 1),
    ];

    this.walletTransactionList = updatedWalletTransactionList;

    this.change.detectChanges();
  }

  navigate(currentPage: number) {
    this.getWalletTransactions(new Pagination({ skip: currentPage * 10 - 10 }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
