import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { TabsComponent } from '@shared/ui/tabs/tabs.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MEANS_OF_ID } from 'app/app.constants';
import { ActivatedRoute } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { Jointkyc } from '@core/models/admin/account/joint';
import { JointRegistrationService } from '@core/services/joint-registration.service';

@Component({
  selector: 'app-edit-joint-means-of-id',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    BaseSelectDirective,
    InputDateDirective,
    TabsComponent,
    SpinDirective,
  ],
  templateUrl: './edit-joint-means-of-id.component.html',
  styleUrl: './edit-joint-means-of-id.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJointMeansOfIdComponent implements OnInit {
  @Input() jointDataDetails!: Jointkyc[];
  // @Input() meansOfId!: JointIdkyc[];

  @ViewChild('profileOneTemplate', { static: true })
  profileOneTemplate!: TemplateRef<any>;
  @ViewChild('profileTwoTemplate', { static: true })
  profileTwoTemplate!: TemplateRef<any>;
  profileTabs: { label: string; contentTemplate: TemplateRef<any> }[] = [];

  meansOfIdFormOne!: FormGroup;
  meansOfIdFormTwo!: FormGroup;
  accountId!: string;
  subs: Subscription[] = [];
  meansOfIdFile: File | null = null;
  meansOfIdTypes = MEANS_OF_ID;
  requiresExpiry = true;

  // hasMeansOfIdSubject$ = new BehaviorSubject<boolean>(false);
  accountIdSubject$ = new BehaviorSubject<string | null>(null);
  isLoadingProfileOneSubject$ = new BehaviorSubject<boolean>(false);
  isLoadingProfileTwoSubject$ = new BehaviorSubject<boolean>(false);
  profileOneHasIdSubject$ = new BehaviorSubject<boolean>(false);
  profileTwoHasIdSubject$ = new BehaviorSubject<boolean>(false);

  isLoadingProfileOne$ = this.isLoadingProfileOneSubject$.asObservable();
  isLoadingProfileTwo$ = this.isLoadingProfileTwoSubject$.asObservable();
  isUploadingDoc$!: Observable<boolean>;
  profileOneHasId$ = this.profileOneHasIdSubject$.asObservable();
  profileTwoHasId$ = this.profileTwoHasIdSubject$.asObservable();

  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private toast = inject(NotificationFacade);
  private jointRegService = inject(JointRegistrationService);

  ngOnInit(): void {
    this.profileTabs = [
      { label: 'Profile 1', contentTemplate: this.profileOneTemplate },
      { label: 'Profile 2', contentTemplate: this.profileTwoTemplate },
    ];

    this.createForm();
    this.isNIN();
    const accountId = this.route.snapshot.params['accountId'];
    if (accountId) {
      this.accountIdSubject$.next(accountId);
    }

    if (this.meansOfIdFormOne) {
      this.setFormValues();
    }
  }

  submitForm(formType: 'one' | 'two'): void {
    if (formType === 'one') {
      this.profileOneHasIdSubject$.value
        ? this.updateMeansOfId('one')
        : this.addMeansOfId('one');
    } else {
      this.profileTwoHasIdSubject$.value
        ? this.updateMeansOfId('two')
        : this.addMeansOfId('two');
    }
  }

  isNIN(): void {
    let sub = this.meansOfIdFormOne.valueChanges.subscribe((val) => {
      this.requiresExpiry = val.type === 'nin' ? false : true;
    });

    sub = this.meansOfIdFormTwo.valueChanges.subscribe((val) => {
      this.requiresExpiry = val.type === 'nin' ? false : true;
    });
    this.subs.push(sub);
  }

  updateMeansOfId(formType: 'one' | 'two'): void {
    if (formType === 'one') {
      this.isLoadingProfileOneSubject$.next(true);
      this.meansOfIdFormOne.removeControl('account_id');
      this.meansOfIdFormOne.removeControl('joint_account_id');
    }

    if (formType === 'two') {
      this.isLoadingProfileTwoSubject$.next(true);
      this.meansOfIdFormTwo.removeControl('account_id');
      this.meansOfIdFormTwo.removeControl('joint_account_id');
    }

    const formValue =
      formType === 'one'
        ? this.meansOfIdFormOne.getRawValue()
        : this.meansOfIdFormTwo.getRawValue();

    const sub = this.jointRegService.updateMeansOfID(formValue).subscribe({
      next: () => {
        formType === 'one'
          ? this.isLoadingProfileOneSubject$.next(false)
          : this.isLoadingProfileTwoSubject$.next(false);

        this.toast.success('Successfully updated Means of Identification');
      },
      error: () => {
        formType === 'one'
          ? this.isLoadingProfileOneSubject$.next(false)
          : this.isLoadingProfileTwoSubject$.next(false);
        this.toast.error('Failed to update Means of Identification');
      },
    });
    this.subs.push(sub);
  }

  addMeansOfId(formType: 'one' | 'two'): void {
    const formValue =
      formType === 'one'
        ? this.meansOfIdFormOne.getRawValue()
        : this.meansOfIdFormTwo.getRawValue();
    formType === 'one'
      ? this.isLoadingProfileOneSubject$.next(true)
      : this.isLoadingProfileTwoSubject$.next(true);

    const sub = this.jointRegService.addMeansOfId(formValue).subscribe({
      next: (response: any) => {
        formType === 'one'
          ? this.profileOneHasIdSubject$.next(true)
          : this.profileTwoHasIdSubject$.next(true);
        formType === 'one'
          ? this.isLoadingProfileOneSubject$.next(false)
          : this.isLoadingProfileTwoSubject$.next(false);

        formType === 'one'
          ? this.meansOfIdFormOne?.addControl(
              'id',
              new FormControl(response.data.id)
            )
          : this.meansOfIdFormTwo?.addControl(
              'id',
              new FormControl(response.data.id)
            );

        this.toast.success('Successfully added Means of Identification');
      },
      error: (resp) => {
        formType === 'one'
          ? this.isLoadingProfileOneSubject$.next(false)
          : this.isLoadingProfileTwoSubject$.next(false);
        this.toast.error(
          resp?.error?.message ||
            'Failed to add means of identification details'
        );
      },
    });
    this.subs.push(sub);
  }

  createForm() {
    this.meansOfIdFormOne = this.fb.nonNullable.group({
      type: [''],
      number: [''],
      issue_date: [new Date()],
      expiry_date: [new Date()],
      account_id: [''],
      joint_account_id: [''],
    });
    this.meansOfIdFormTwo = this.fb.nonNullable.group({
      type: [''],
      number: [''],
      issue_date: [new Date()],
      expiry_date: [new Date()],
      account_id: [''],
      joint_account_id: [''],
    });
  }

  setFormValues() {
    if (this.jointDataDetails.length) {
      this.setAccountFormValues(
        this.jointDataDetails[0],
        this.meansOfIdFormOne,
        this.profileOneHasIdSubject$,
        0
      );

      if (this.jointDataDetails.length > 1) {
        this.setAccountFormValues(
          this.jointDataDetails[1],
          this.meansOfIdFormTwo,
          this.profileTwoHasIdSubject$,
          1
        );
      }
    }
  }

  private setAccountFormValues(
    accountData: Jointkyc,
    form: FormGroup,
    profileHasIdSubject$: BehaviorSubject<boolean>,
    tabIndex: number
  ) {
    const { personal_details, id_kyc, id } = accountData;

    const label = personal_details
      ? `${personal_details.first_name} ${personal_details.last_name}`
      : `Profile ${tabIndex + 1}`;
    this.profileTabs[tabIndex].label = label;

    // No means of ID
    if (!id_kyc) {
      form.patchValue({
        account_id: this.accountIdSubject$.value,
        joint_account_id: id,
      });
      return;
    }

    // Has means of ID
    profileHasIdSubject$.next(true);

    form.addControl('id', new FormControl(id_kyc.id));
    form.removeControl('account_id');
    form.removeControl('joint_account_id');

    form.patchValue({
      type: id_kyc.type,
      number: id_kyc.number,
    });

    if (id_kyc.issue_date) {
      form.controls['issue_date'].setValue(
        formatDate(id_kyc.issue_date, 'yyyy-MM-dd', 'en')
      );
    }

    if (id_kyc.expiry_date) {
      form.controls['expiry_date'].setValue(
        formatDate(id_kyc.expiry_date, 'yyyy-MM-dd', 'en')
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
