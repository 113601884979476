@if (!(isFetchingAccountInfo$ | async)) {
  <div class="flex justify-between">
    <div
      class="flex flex-col items-center gap-2 md:flex md:shrink-0 md:flex-row md:gap-[0.31rem]"
    >
      <a (click)="goBack()" class="translate-y-[3px] cursor-pointer"
        ><img src="assets/images/svgs/chevron-left.svg" alt=""
      /></a>

      <h1
        class="translate-y-1 text-2xl font-normal leading-[1.3125rem] text-mango-black17"
      >
        {{ accountData?.user?.first_name | titlecase }}
        {{ accountData?.user?.last_name | titlecase }}
      </h1>
    </div>
    <div class="flex justify-between gap-2">
      <app-dropdown
      class="w-fit justify-self-end text-nowrap"
      [id]="'addCustomerDropdown'"
      [buttonTitle]="'Export Report'"
      [actionList]="registerationDropdownActions()"
     
    ></app-dropdown>
      <button
        *ngIf="accountId"
        mango-button
        class="w-fit shrink-0 items-center"
        (click)="editAccount()"
      >
        Edit Profile
      </button>
    </div>
  </div>

  <div *ngIf="accountId" class="tab__wrapper mt-[0.94rem]">
    <div class="hidden w-fit rounded-lg bg-white sm:block">
      <nav class="flex w-fit space-x-4 p-[0.31rem]" aria-label="Tabs">
        <a
          [routerLink]="userDetailsUrl"
          routerLinkActive="bg-mango-lightOrange text-mango text-2xl"
          class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
          ><span>User Details</span></a
        >
        <a
          [routerLink]="accountsUrl"
          routerLinkActive="bg-mango-lightOrange text-mango"
          class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
          ><span>Accounts</span></a
        >
      </nav>
    </div>
  </div>

  <!-- details card -->
  <div class="flex flex-col gap-6 py-5">
    <div class="flex flex-col md:flex-row md:gap-1">
      <div
        class="left flex flex-col items-center text-nowrap bg-white px-8 py-[1.91rem]"
      >
        <div
          class="h-[4.375rem] w-[4.375rem] rounded-full bg-[url('/assets/images/avatar-placeholder.png')]"
        ></div>
        <h2 class="mb-1 mt-3 text-xl leading-[1.3125rem] text-mango-black17">
          {{ accountData?.user?.first_name | titlecase }}
          {{ accountData?.user?.last_name | titlecase }}
        </h2>
        <p class="text-sm text-mango-gray74">
          {{ accountData?.user?.email || '—' }}
        </p>
        @if (accountData?.is_blocked) {
          <app-status-badge status="{{'blocked'}}"></app-status-badge>
        }
        
      </div>
      <div
        class="right flex w-full flex-col gap-x-4 gap-y-9 bg-white px-6 py-[1.91rem] md:grid md:grid-cols-4"
      >
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">Title</li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ accountData?.user?.title || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">
            Phone number 1
          </li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ accountData?.user?.phone_number_1 || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">
            Phone number 2
          </li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ accountData?.user?.phone_number_2 || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">Date of Birth</li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ (accountData?.user?.dob | date) || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">
            Marital Status
          </li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ accountData?.user?.marital_status || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">Nationality</li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ accountData?.user?.nationality || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">Annual Income</li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ accountData?.user?.annual_income || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">
            State of Origin
          </li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ accountData?.user?.state_of_origin || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">
            Place of Birth
          </li>
          <li class="text-base font-bold text-mango-darkBlack">
            {{ accountData?.user?.place_of_birth || '—' }}
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">
            Politically Exposed
          </li>
          <li class="text-base font-bold text-mango-darkBlack">
            @if (accountData?.user?.political_exposure) {
              <app-status-badge [status]="accountData?.user?.political_exposure === true ? 'YES':'NO'"></app-status-badge>
            } 
            @else {
              <span>__</span>
            }
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">Tier Level</li>
          <li class="text-base font-bold text-mango-darkBlack">
            <img src="assets/images/tier-{{accountData?.tier?.toLowerCase()}}.png" alt="Tier {{accountData?.tier}}}} Logo" class="w-[48px]" />
          </li>
        </ul>
        <ul class="flex flex-col">
          <li class="text-sm font-[350] text-mango-darkBlack">
            Account Number
          </li>
          <li class="text-base font-bold text-mango-darkBlack">
            <div class="flex items-center gap-[0.31rem] ">
              <span class="translate-y-[2px] text-lg text-mango-grey">{{
                accountData?.mango_account_number
              }}</span>
              <button
                class="active:scale-y-95"
                (click)="copy(accountData?.mango_account_number!)"
              >
                <img src="assets/images/svgs/copy.svg" alt="" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="mb-6">
    <app-individual-tabs></app-individual-tabs>
  </div>

  <router-outlet></router-outlet>
} @else {
  <div class="flex flex-col">
    <ngx-skeleton-loader
      *ngFor="let in of [0, 1, 2, 3, 4, 5, 6]; let i = index"
      [theme]="{ height: '5rem', width: '100%' }"
    ></ngx-skeleton-loader>
  </div>
}
