<!-- Tabs for desktop -->
<div class="hidden w-fit rounded-lg bg-white sm:block">
  @if (setRouteUrls.length) {
    <nav class="flex w-fit space-x-4 p-[0.31rem]" aria-label="Tabs">
      @for (item of setRouteUrls; track $index) {
        <a
          [routerLink]="getRoutePath(item)"
          routerLinkActive="bg-mango-lightOrange text-mango"
          class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
          ><span>{{ pathMap.get(item) }}</span></a
        >
      }
    </nav>
  }
</div>

<!-- Mobile -->
<div class="scroll-wrapper w-full overflow-hidden">
  <div
    class="scroll-container box-border w-full overflow-x-auto whitespace-nowrap p-[10px] sm:hidden"
  >
    <div class="scroll-content inline-block">
      @for (item of setRouteUrls; track $index) {
        <a
          [routerLink]="getRoutePath(item)"
          routerLinkActive="bg-mango-lightOrange text-mango"
          class="badge mr-[10px] inline-block cursor-pointer whitespace-nowrap text-wrap rounded-[100px] border border-solid border-gray-400 px-[21px] py-[9px] text-gray-500"
        >
          {{ pathMap.get(item) }}</a
        >
      }
    </div>
  </div>
</div>
