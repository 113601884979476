import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterOutlet,
} from '@angular/router';
import { Pagination } from '@core/models/pagination.model';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { UserService } from '@core/services/user.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { FiltersComponent } from '../shared/ui/filters/filters.component';
import { IFILTER } from 'app/app.constants';
import {
  ViewProfileListData,
  ViewProfileListDatum,
} from '@core/models/admin/users';

@Component({
  selector: 'app-user-customers',
  standalone: true,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss',
  animations: [dropdownMenuAnimation],
  imports: [
    RouterOutlet,
    CommonModule,
    DropdownComponent,
    InputLabelComponent,
    BaseSelectDirective,
    InputDateDirective,
    ButtonDirective,
    SearchPipe,
    ReactiveFormsModule,
    FormsModule,
    TableNavigatorComponent,
    FiltersComponent,
  ],
})
export class CustomersComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  DropdownVisible = false;
  subs: Subscription[] = [];
  usersList: ViewProfileListDatum[] = [];
  totalCount = 0;

  adminService = inject(UserService);
  change = inject(ChangeDetectorRef);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  breadCrumbService = inject(BreadCrumbService);

  isFetchingListSubject$ = new BehaviorSubject<boolean>(false);
  isFetchingList$ = this.isFetchingListSubject$.asObservable();

  filterStructure: IFILTER[] = [
    {
      label: 'Status',
      filterKey: 'status',
      type: 'dropdown',
      dropdown: [
        { key: 'activated', value: 'Activated' },
        { key: 'pending', value: 'Pending' },
      ],
    },
  ];

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);
    this.getAdminList(new Pagination());
    this.customerFilterEventListener(new Pagination());
  }



  

  customerFilterEventListener(page: Pagination = new Pagination()) {
    this.isFetchingListSubject$.next(true);

    this.activatedRoute.queryParams.subscribe((params) => {
      let query = "";
      if (params[this.filterStructure[0].filterKey]) {
        query = `status=${params['status']}&`;
      }
      if (params['search']) {
        query += `search_text=${params['search']}`;
      }
      if (query) {
        console.log(query);
        const sub = this.adminService.filterProfileList(query).subscribe((res) => {

          this.isFetchingListSubject$.next(false);
          this.usersList = res.data;
          this.totalCount = 10;
          this.change.detectChanges();
        });
        this.subs.push(sub);
      } else {
        this.getAdminList();
      }
    });
  }
  registerationDropdownActions(): DropDownModel[] {
    return [
      {
        title: 'Individual',
        action: () => this.goToIndividualReg(),
        show: true,
      },
      {
        title: 'Corporate',
        action: () => this.goToCorporateReg(),
        show: true,
      },
    ];
  }
  goToIndividualReg() {
    this.router.navigateByUrl('/admin/accounts/ind-personal-details');
  }
  goToCorporateReg() {
    this.router.navigateByUrl('/admin/accounts/corp-company-details');
  }

  navigate(currentPage: number = 1) {
    const skip = currentPage * 10 - 10;
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params) {
        this.customerFilterEventListener(new Pagination({ skip }));
      }
    });

    this.getAdminList(new Pagination({ skip }));
  }

  getAdminList(page: Pagination = new Pagination()) {
    this.isFetchingListSubject$.next(true);

    const sub = this.adminService.viewProfileList(page).subscribe({
      next: (res) => {
        this.isFetchingListSubject$.next(false);

        this.usersList = res.data;
        this.totalCount = res.total_count;
        this.change.detectChanges();
      },
      error: () => {
        this.isFetchingListSubject$.next(false);
      },
    });
    this.subs.push(sub);
  }

  
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
