import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { LoginResponseUser } from '@core/models/client/auth';
import { AuthService } from '@core/services/auth.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ButtonDirective, CommonModule, RouterOutlet, RouterLink],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit, OnDestroy {
  userData!: LoginResponseUser | null;
  subs: Subscription[] = [];

  private authService = inject(AuthService);
  breadCrumbService = inject(BreadCrumbService);
  route = inject(ActivatedRoute);

  constructor() {}

  ngOnInit(): void {
    this.breadCrumbService.setPageBreadCrumb(this.route);

    this.userData = this.authService.getCurrentUserData();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
