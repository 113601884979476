import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CorporateAccountbankInfo } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-view-bank-details',
  standalone: true,
  imports: [CommonModule, KycAccordionComponent],
  templateUrl: './view-bank-details.component.html',
  styleUrl: './view-bank-details.component.scss',
})
export class ViewBankDetailsComponent {
  @Input() bankDetails!: CorporateAccountbankInfo[];
  @Input() bankName!: string;
  isKycComplete: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['bankDetails'] || changes['bankName']) {
      this.checkKycStatus();
    }
  }

  private checkKycStatus(): void {
    if (!this.bankDetails || this.bankDetails.length === 0) {
      this.isKycComplete = false;
      return;
    }
    this.isKycComplete = true;
    
  }

}
