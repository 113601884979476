import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import {
  CorporateRegistrationService,
  GetAccountSignatoriesData,
} from '@core/services/corporate-registration.service';
import { SignatoryService } from '@core/services/signatory.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { SIGNATORY_CLASS } from 'app/app.constants';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { LoadingService } from '@core/services/loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';

@Component({
  selector: 'app-edit-signatory-details',
  standalone: true,
  imports: [
    KycAccordionComponent,
    CommonModule,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    SpinDirective,
    ConfirmDialogComponent,
    BaseSelectDirective,
    InputDateDirective,
    RouterOutlet,
  ],
  templateUrl: './edit-signatory-details.component.html',
  styleUrl: './edit-signatory-details.component.scss',
})
export class EditSignatoryDetailsComponent implements OnInit, OnDestroy {
  @Input() signatoriesList: GetAccountSignatoriesData[] = [];

  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private corporateService = inject(CorporateRegistrationService);
  private cdr = inject(ChangeDetectorRef);
  private toast = inject(NotificationFacade);
  private signatoryService = inject(SignatoryService);
  private loadingService = inject(LoadingService);

  private hasMandateSubject$ = new BehaviorSubject<boolean>(false);
  openEditDialogSubject$ = new BehaviorSubject<boolean>(false);
  editSignatoryName$ = new BehaviorSubject<string>('');
  private isUpdatingSignatorySubject$ = new BehaviorSubject<boolean>(false);
  private editSignatoryIDSubject$ = new BehaviorSubject<string>('');

  signatoryID!: string | null;
  signatoryName!: string | null;
  isSavingMandate$!: Observable<boolean>;
  isUpdatingMandate$!: Observable<boolean>;
  isFetchingMandate$!: Observable<boolean>;
  isUpdatingSignatory$ = this.isUpdatingSignatorySubject$.asObservable();
  hasMandate$ = this.hasMandateSubject$.asObservable();
  openEditDialog$ = this.openEditDialogSubject$.asObservable();

  isLoading: boolean = false;
  totalSignatories!: number;
  accountId!: string;
  subs: Subscription[] = [];
  isDialogOpen: boolean = false;
  isDialogLoading: boolean = false;
  signatoryClass = SIGNATORY_CLASS;

  signatoryDetailsForm = this.fb.nonNullable.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    signatory_class: [''],
    account_id: ['', Validators.required], //*required
  });

  editSignatoryForm = this.fb.nonNullable.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    signatory_id: [''],
    profile_id: ['', Validators.required], //user.id
    signatory_class: [''],
  });

  addMandateForm = this.fb.nonNullable.group({
    mandate_note: [''],
    account_id: ['', Validators.required], //*required
  });

  editMandateForm = this.fb.nonNullable.group({
    mandate_note: [''],
    signatory_mandate_id: [''],
  });

  constructor() {
    this.isSavingMandate$ = this.loadingService.getLoadingObservable(
      URL_KEYS.CORPORATE_REGISTRATION.SAVE_SIGNATORY_MANDATE
    );
    this.isFetchingMandate$ = this.loadingService.getLoadingObservable(
      URL_KEYS.CORPORATE_REGISTRATION.GET_ACCOUNT_SIGNATORY_MANDATE
    );
    this.isUpdatingMandate$ = this.loadingService.getLoadingObservable(
      URL_KEYS.CORPORATE_REGISTRATION.UPDATE_ACCOUNT_SIGNATORY_MANDATE
    );
    this.isUpdatingMandate$ = this.loadingService.getLoadingObservable(
      URL_KEYS.CORPORATE_REGISTRATION.UPDATE_ACCOUNT_SIGNATORY_MANDATE
    );
  }

  ngOnInit(): void {
    this.setAccountId();

    if (this.accountId) {
      this.getSignatoryMandate();
    }
  }

  setAccountId() {
    this.accountId = this.route.snapshot.params['accountId'];
    this.signatoryDetailsForm.patchValue({
      account_id: this.accountId,
    });
  }

  submitMandate() {
    this.hasMandate$.pipe(take(1)).subscribe((hasMandate) => {
      hasMandate ? this.updateMandate() : this.saveSignatoryMandate();
    });
  }

  inviteSignatory(): void {
    this.isLoading = true;
    const formData = this.signatoryDetailsForm.getRawValue();
    const sub = this.corporateService.inviteSignatory(formData).subscribe({
      next: (response) => {
        this.isLoading = false;

        const { user, status, signatory_id } = response;
        this.signatoriesList.push({
          status: status ?? 'activated',
          user: {
            id: user.id,
            email: formData.email,
            first_name: formData.first_name,
            last_name: formData.last_name,
            phone_number_1: user.phone_number_1 ?? null,
            phone_number_2: user.phone_number_2 ?? null,
          },
          signatory_class: formData.signatory_class,
          signatory_id: signatory_id,
        });

        this.signatoryDetailsForm.reset();
        this.signatoryDetailsForm.patchValue({
          account_id: this.accountId,
        });

        this.cdr.detectChanges();
        this.toast.success('Successfully invited Signatory');
      },
      error: () => {
        this.isLoading = false;
        this.cdr.detectChanges();
        this.toast.error('Failed to invite signatory');
      },
    });

    this.subs.push(sub);
  }

  deleteSignatory() {
    this.signatoryID;

    this.isDialogLoading = true;
    let signatoryID = '';
    if (this.signatoryID) {
      signatoryID = this.signatoryID;
    }

    const sub = this.signatoryService
      .removeUserFromAccount(signatoryID, this.accountId)
      .subscribe({
        next: () => {
          this.isDialogOpen = false;
          this.isDialogLoading = false;
          this.toast.success('Signatory deleted successfully');
          this.updateSignatoryList();
          this.cdr.detectChanges();
        },
        error: (response) => {
          this.isDialogOpen = false;
          this.isDialogLoading = false;
          this.cdr.detectChanges();
          this.toast.error('Failed to delete signatory, please try again');
        },
      });

    this.subs.push(sub);
  }

  updateSignatoryList() {
    this.signatoriesList = this.signatoriesList.filter(
      (signatory) => signatory.user.id !== this.signatoryID
    );
  }

  saveSignatoryMandate() {
    const payload = {
      account_id: this.accountId,
      mandate_note: this.addMandateForm.getRawValue().mandate_note,
    };

    const sub = this.corporateService.saveSignatoryMandate(payload).subscribe({
      next: (response) => {
        this.toast.success('Successfully saved mandate');
        this.editMandateForm.patchValue({
          signatory_mandate_id: response.id,
          mandate_note: payload.mandate_note,
        });
        this.hasMandateSubject$.next(true);
      },
      error: () => {
        this.toast.error('Failed to save mandate');
      },
    });

    this.subs.push(sub);
  }

  getSignatoryMandate() {
    if (!this.accountId) {
      return;
    }

    const sub = this.corporateService
      .getAccountSignatoryMandate(this.accountId)
      .subscribe({
        next: (response) => {
          if (response) {
            this.hasMandateSubject$.next(true);
            this.editMandateForm.patchValue({
              mandate_note: response.mandate_note,
              signatory_mandate_id: response.id,
            });
          }
        },
        error: () => {
          this.toast.error('Failed to fetch signatory mandate');
        },
      });

    this.subs.push(sub);
  }

  updateMandate() {
    const payload = {
      signatory_mandate_id:
        this.editMandateForm.getRawValue().signatory_mandate_id,
      mandate_note: this.editMandateForm.getRawValue().mandate_note,
    };

    const sub = this.corporateService
      .updateAccountSignatoryMandate(payload)
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated mandate');
        },
        error: () => {
          this.toast.error('Failed to update mandate');
        },
      });

    this.subs.push(sub);
  }

  editSignatory(signatory: GetAccountSignatoriesData) {
    this.editSignatoryForm.patchValue({
      first_name: signatory.user.first_name,
      last_name: signatory.user.last_name,
      email: signatory.user.email,
      profile_id: signatory.user.id,
      signatory_id: signatory.signatory_id,
      signatory_class: signatory.signatory_class ?? '',
    });
    this.editSignatoryName$.next(
      `${signatory.user.first_name} ${signatory.user.last_name}`
    );
    this.editSignatoryIDSubject$.next(signatory.user.id);
    this.openEditDialogSubject$.next(true);
  }

  updateSignatory() {
    this.isUpdatingSignatorySubject$.next(true);
    const formData = this.editSignatoryForm.getRawValue();

    const sub = this.corporateService.updateSignatory(formData).subscribe({
      next: (response) => {
        this.isUpdatingSignatorySubject$.next(false);
        this.openEditDialogSubject$.next(false);

        const signatoryIndex = this.signatoriesList.findIndex(
          (signatory) => signatory.user.id === formData.profile_id
        );

        if (signatoryIndex !== -1) {
          const updatedSignatory = {
            ...this.signatoriesList[signatoryIndex],
            user: {
              ...this.signatoriesList[signatoryIndex].user,
              id: response.user.id,
              first_name: formData.first_name,
              last_name: formData.last_name,
              email: formData.email,
            },
            signatory_class: formData.signatory_class,
            signatory_id: response.signatory_id,
          };

          this.signatoriesList[signatoryIndex] = updatedSignatory;
          this.toast.success('Successfully updated signatory');
        }
      },
      error: () => {
        this.isUpdatingSignatorySubject$.next(false);
        this.toast.error('Failed to update signatory');
      },
    });

    this.subs.push(sub);
  }

  openDialog(signatory: GetAccountSignatoriesData) {
    this.isDialogOpen = true;
    this.signatoryID = signatory.user.id;
    this.signatoryName =
      signatory.user.first_name + ' ' + signatory.user.last_name;
  }

  closeDialog() {
    this.isDialogOpen = false;
    this.signatoryID = null;
    this.signatoryName = null;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
