<div class="grid grid-cols-2 justify-between gap-4 md:grid md:justify-between">
  <h2
    class="translate-y-[4px] text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
  >
    System Users
  </h2>

  <button
    type="button"
    mango-button
    routerLink="/admin/users/register"
    class="w-fit justify-self-end text-nowrap"
  >
    + Add New Admin
  </button>
</div>

<div class="mt-[0.94rem] grid gap-5 lg:grid-cols-3">
  <div class="rounded-md bg-[#EEF4FF] p-[38px] shadow-sm">
    <!-- Your chart component goes here -->
    <div>
      <p class="text-">Total Admin Users</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">{{ totalCount | number }}</h1>
    </div>
  </div>
  <div class="rounded-md bg-[#FFF6E8] p-[38px] shadow-sm">
    <!-- Your chart component goes here -->
    <div>
      <p>Fully Registered Admins</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">{{ fullyRegister() | number }}</h1>
    </div>
  </div>
  <div
    class="flex items-center rounded-md bg-[#E5FFE6] p-[38px] shadow-sm sm:inline-flex"
  >
    <!-- Your chart component goes here -->

    <div>
      <div>
        <p>Active Now</p>
      </div>
      <div>
        <h1 class="number-stat-font text-4xl">15</h1>
      </div>
    </div>

    <!-- images at the End (Right) -->

    <div class="ml-auto flex">
      <div class="relative h-9 w-9 overflow-hidden rounded-full">
        <img
          class="h-full w-full bg-black object-cover"
          src="assets/images/man--small.png"
          alt="Image 1"
        />
      </div>
      <div class="relative -ml-3 h-9 w-9 overflow-hidden rounded-full">
        <img
          class="h-full w-full bg-black object-cover"
          src="assets/images/man--small.png"
          alt="Image 2"
        />
      </div>
      <div class="relative -ml-3 h-9 w-9 overflow-hidden rounded-full">
        <img
          class="h-full w-full bg-black object-cover"
          src="assets/images/man--small.png"
          alt="Image 3"
        />
      </div>
      <div class="relative -ml-3 h-9 w-9 overflow-hidden rounded-full">
        <img
          class="h-full w-full bg-black object-cover"
          src="assets/images/man--small.png"
          alt="Image 4"
        />
      </div>
    </div>
  </div>
</div>
<app-filters [filters]="filterStructure"></app-filters>

<!-- Client Table -->
<div
  class="mt-[0.94rem] rounded-lg border border-solid border-mango-gray200 bg-white"
>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                S/N
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                User
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                Phone Number
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                Email
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                Role(s)
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                Status
              </th>

              <th
                scope="col"
                class="relative border-b-2 border-gray-200 bg-gray-100 py-3.5 pl-3 pr-4 sm:pr-6"
              >
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            @if (usersList.length < 1) {
              <tr>
                <td span="6">No users found!</td>
              </tr>
            } @else {
              @for (
                user of usersList | search: searchText;
                track user.profile_identifier;
                let i = $index;
              ) {
                <tr>
                  <td
                    class="border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <p>{{ i + 1 }}</p>
                  </td>
                  <td
                    class="border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ user.first_name | titlecase }}
                      {{ user?.middle_name | titlecase }}
                      {{ user.last_name | uppercase }}
                    </p>
                  </td>
                  <td
                    class="border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ user.phone_number || '-' }}
                    </p>
                  </td>
                  <td
                    class="border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ user.email }}
                    </p>
                  </td>
                  <td
                    class="border-b border-gray-200 bg-white px-5 py-5 text-sm"
                  >
                    <div class="flex flex-wrap gap-1">
                      @for (role of user.admin_roles; track $index) {
                        <span
                          class="whitespace-no-wrap rounded-full bg-green-100 px-2 py-1 font-medium text-mango-pineGreen"
                        >
                          {{ role | titlecase }}
                        </span>
                      }
                    </div>
                  </td>
                  <td
                    class="border-b border-gray-200 bg-white px-2 py-2 text-xs sm:px-3 sm:py-3 sm:text-sm md:px-4 md:py-4 lg:px-5"
                  >
                    <span
                      class="relative inline-block px-2 py-1 font-semibold leading-tight text-green-900 sm:px-2"
                    >
                      <span
                        aria-hidden
                        class="absolute inset-0 {{
                          user.status === 'activated'
                            ? 'bg-green-200'
                            : 'bg-orange-300'
                        }} opacity-50 rounded-full"
                      ></span>
                      <span
                        class="relative flex  {{
                          user.status === 'activated'
                            ? 'text-[#027A48]'
                            : 'text-orange-700'
                        }} sm:text-[11px]"
                      >
                        {{ user.status | uppercase }}</span
                      >
                    </span>
                  </td>

                  <td
                    class="relative whitespace-nowrap border-b py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <div
                      class="relative inline-block w-fit cursor-pointer border-gray-200 bg-white text-left"
                    >
                      
                        <app-dropdown
                          [id]="user.id"
                          [actionList]="initDropdownList(user)"
                        >
                        </app-dropdown>
                    </div>
                  </td>
                </tr>
              } @empty {
                Empty list of users
              }
            }

            <!-- <tr>
                      <td class="px-2 sm:px-3 md:px-4 lg:px-5 py-2 sm:py-3 md:py-4 border-b border-gray-200 bg-white text-xs sm:text-sm">
                       <span class="relative inline-block px-2 sm:px-2 py-1 font-semibold text-green-900 leading-tight">
                           <span aria-hidden class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                           <span class="relative flex text-[#B42318] sm:text-[11px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                <path d="M9.40039 3L3.40039 9M3.40039 3L9.40039 9" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                             inactive</span>
                       </span>
                   </td>
                   
               -->
          </tbody>
        </table>
        <app-table-navigator
          [total]="totalCount"
          (onNavigation)="navigate($event)"
        ></app-table-navigator>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<app-confirm-dialog
  [confirmQuestion]="adminName ? confirmQuestion : confirmQuestion"
  confirmText="Yes, Delete Admin"
  (dialogAction)="deleteAdmin()"
  [isOpen]="isDeleteDialogOpen"
  [isDialogLoading]="isDeleteInProgress"
  (close)="adminId = null; adminName = null; isDeleteDialogOpen = false"
  bgConfirmButton="danger"
  dialogLoadingText="Deleting Admin..."
>
</app-confirm-dialog>

<app-confirm-dialog
  [confirmQuestion]="adminName ? resendQuestion : resendQuestion"
  confirmText="Yes, Resend Invite"
  (dialogAction)="resendInvite()"
  [isOpen]="isResendDialogOpen"
  [isDialogLoading]="isResendInProgress"
  (close)="adminId = null; adminName = null; isResendDialogOpen = false"
  dialogLoadingText="Resending invitation..."
>
</app-confirm-dialog>
