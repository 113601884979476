<div cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">
    <h1
    *ngIf="mode === 'default'"
    class="number-stat-font truncate text-4xl xs:text-xl sm:text-2xl md:text-3xl"
    (mouseenter)="showOverlay = true"
    (mouseleave)="showOverlay = false"
  >
    {{ displayValue }}
  </h1>

  <p
    *ngIf="mode === 'table'"
    class="whitespace-no-wrap text-gray-900"
    (mouseenter)="showOverlay = true"
    (mouseleave)="showOverlay = false"
  >
    {{ displayValue }}
  </p>
  </div>
  
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayOrigin"
    [cdkConnectedOverlayOpen]="showOverlay"
    [cdkConnectedOverlayHasBackdrop]="false"
  >
    <div class="rounded-md bg-white p-2 shadow-xl">
      {{ fullValue }}
    </div>
  </ng-template>
  