<app-slide-in-right-modal
    modalTitle="Export Wallet Report"
    iconImageSrc="assets/images/svgs/document--outline.svg"
    [slideOpen]="true"
>
    <div class="page__container flex flex-col pb-8">
        <p class="cta__text text-sm text-mango-gray74">
        Kindly Select date range
        </p>

        <form
        class="flex flex-col gap-5 px-0 pt-5"
        [formGroup]="dateRangeForm"

        >
        <!--Start date and end date -->
        <div
            class="form-row flex flex-col gap-5 md:grid md:grid-cols-2 md:gap-x-5"
        >
            <!-- Start date -->
            <div>
            <app-input-label
                labelFor="start-date"
                labelClass="label--bottom-margin"
                >Start Date</app-input-label
            >

            <input
                placeholder="DD/MM/YYYY"
                
                formControlName="start_date"
                class="block w-full rounded-lg border-0 pl-2 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6 cursor-pointer"
                type="date"
                
              >
            </div>

            <!-- end date -->
            <div>
            <app-input-label
                labelFor="end-date"
                labelClass="label--bottom-margin"
                >End Date</app-input-label
            >

            <input
                placeholder="DD/MM/YYYY"
                
                formControlName="end_date"
                class="block w-full rounded-lg border-0 pl-2 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6 cursor-pointer"
                type="date"
                
              >
            </div>
        </div>
      
        <button
        *ngIf="reportUrl"
        (click)="openDocument(reportUrl)"
        [title]="reportTitle"
        class="flex cursor-pointer items-center gap-3 rounded-lg bg-mango-lightOrange px-[0.88rem] py-3 shadow-sm"
      >
        <img src="assets/images/svgs/file-text.svg" alt="" class="h-6 w-6" />
        <span
          class="flex flex-col overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium"
        >
            <span class="overflow-hidden text-ellipsis">
              {{ reportTitle }}</span
            >
          
        </span>

        <div class="ml-auto inline-flex gap-2">
          <span>View</span>
          <!-- Heroicons: arrow-top-right-on-square -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-5 w-5 shrink-0"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </div>
    </button>
        <div class="xl:grid xl:grid-cols-2">
            <div
              [ngClass]="{
                'cursor-not-allowed': isLoading
              }"
              class="col-start-3 justify-self-end"
            >
              <button
                (click)="getWalletReport()"
                class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end cursor-pointer"
                mango-button
                [disabled]="isLoading"
              >
                <span *ngIf="isLoading" spin></span>
                {{ isLoading ? 'Generating...' : 'Generate Report' }}
              </button>
            </div>
          </div>
        </form>
    </div>
</app-slide-in-right-modal>