import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@core/services/loading.service';
import { MutualFundService } from '@core/services/mutual-fund.service';
import { FilterDropdownItemComponent } from '@shared/ui/filter-dropdown-item/filter-dropdown-item.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { TooltipOverlayComponent } from '@shared/ui/tooltip-overlay/tooltip-overlay.component';
import Decimal from 'decimal.js';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-dividend-history',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    // FilterDropdownItemComponent,
    TableSkeletonLoaderComponent,
    TooltipOverlayComponent,
  ],
  templateUrl: './dividend-history.component.html',
  styleUrl: './dividend-history.component.scss',
})
export class DividendHistoryComponent implements OnDestroy, OnInit {
  mutualFundService = inject(MutualFundService);
  change = inject(ChangeDetectorRef);
  activatedRoute = inject(ActivatedRoute);
  loadingService = inject(LoadingService);
  totalDividend: Decimal = new Decimal(0);
  totalValue: number = 0;

  isFetchingTransactionHistoryList$!: Observable<boolean>;
  private transactionHistoryListSubject = new BehaviorSubject<any>(null);
  transactionHistoryList$ = this.transactionHistoryListSubject.asObservable();
  accountNumber = this.activatedRoute.snapshot.params['account_number'];

  subs: Subscription[] = [];

  constructor() {
    this.isFetchingTransactionHistoryList$ =
      this.loadingService.getLoadingObservable('get-dividend-list');
  }

  ngOnInit() {
    this.getDividendList();
    this.getDividendByAccountNumber();
  }

  getDividendList() {
    const sub = this.mutualFundService
      .getDividendHistoryListByAccount(this.accountNumber)
      .subscribe({
        next: (res) => {
          this.transactionHistoryListSubject.next(res.data);
          this.change.detectChanges();
        },
        error: () => {},
      });
    this.subs.push(sub);
  }
  getDividendByAccountNumber() {
    const sub = this.mutualFundService
      .getDividendByAccountNumber(this.accountNumber)
      .subscribe({
        next: (res) => {
          const dividend = new Decimal(res.data.total_dividend || 0);
          this.totalDividend = dividend.div(100);
          this.totalValue = res.data.total_value;
          this.change.detectChanges();
        },
        error: () => {},
      });
    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
