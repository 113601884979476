<app-kyc-accordion
  [accordionTitle]="accordionTitle"
  [accordionKey]="accordionKey"
  [isKycComplete]="isKycComplete"
>
  <div *ngIf="!companyDetails">
    <p>No company details</p>
  </div>
  <div *ngIf="companyDetails" class="grid grid-cols-1 gap-y-5 md:grid-cols-4">
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Company name</li>
      <li class="text-sm font-light">
        {{ companyDetails.corporate_kyc?.name || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Registration number</li>
      <li class="text-sm font-light">
        {{ companyDetails.corporate_kyc?.registration_number || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">TIN</li>
      <li class="text-sm font-light">
        {{ companyDetails.corporate_kyc?.tin || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Business type</li>
      <li class="text-sm font-light">
        {{ (companyDetails.corporate_kyc?.business_type | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Business Address</li>
      <li class="text-sm font-light">
        {{ companyDetails.corporate_kyc?.business_address || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Business Website</li>
      <li class="text-sm font-light">
        {{ companyDetails.corporate_kyc?.business_website || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Date of registration</li>
      <li class="text-sm font-light">
        {{ (companyDetails.corporate_kyc?.registered_date | date) || '—' }}
      </li>
    </ul>
  </div>
</app-kyc-accordion>
