import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { CorporateDirector } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-view-director-details',
  standalone: true,
  imports: [CommonModule, KycAccordionComponent],
  templateUrl: './view-director-details.component.html',
  styleUrl: './view-director-details.component.scss',
})
export class ViewDirectorDetailsComponent {
  @Input() directorDetails!: CorporateDirector[];
  isKycComplete: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['directorDetails']) {
      this.checkKycStatus();
    }
  }

  private checkKycStatus(): void {
    if (!this.directorDetails || this.directorDetails.length === 0) {
      this.isKycComplete = false;
      return;
    }
    this.isKycComplete = true;
    
  }
}
