import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { COUNTRIES } from '@core/data/countries-states';
//
import { NIGERIAN_STATES } from '@core/data/nigerian-states';
import { NotificationFacade } from '@core/facades/notification.facade';
import { UserKyc } from '@core/models/admin/account';
import { IndividualRegistrationService } from '@core/services/individual-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { CustomValidators } from '@core/validators/custom-validator';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import {
  ANNUAL_INCOME,
  GENDER,
  MARITAL_STATUS,
  NIGERIAN_TITLES,
} from 'app/app.constants';
import { BehaviorSubject, Observable, Subscription, first } from 'rxjs';

@Component({
  selector: 'app-edit-customer-information',
  standalone: true,
  imports: [
    KycAccordionComponent,
    CommonModule,
    ReactiveFormsModule,
    InputLabelComponent,
    InputComponent,
    InputDateDirective,
    BaseSelectDirective,
    ButtonDirective,
    SpinDirective,
  ],
  templateUrl: './edit-customer-information.component.html',
  styleUrl: './edit-customer-information.component.scss',
})
export class EditCustomerInformationComponent implements OnInit, OnDestroy {
  @Input() customerInformation!: UserKyc;

  isLoading: boolean = false;
  customerInfoForm!: FormGroup;
  nigerianStates = NIGERIAN_STATES;
  countries = COUNTRIES.data;
  titles = Object.entries(NIGERIAN_TITLES).map(([key, value]) => ({
    key,
    value,
  }));
  annualIncomeRange = ANNUAL_INCOME;
  maritalStatus = MARITAL_STATUS;
  genderList = GENDER;
  subs: Subscription[] = [];
  isLoading$!: Observable<boolean>;

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  individualService = inject(IndividualRegistrationService);
  cdr = inject(ChangeDetectorRef);
  loadingService = inject(LoadingService);
  toast = inject(NotificationFacade);

  ngOnInit(): void {
    this.isLoading$ =
      this.loadingService.getLoadingObservable('update-profile');
    this.createForm();

    if (this.customerInfoForm) {
      this.setFormValues();
    }
  }

  createForm() {
    this.customerInfoForm = this.fb.nonNullable.group({
      title: [''],
      first_name: ['', Validators.minLength(2)],
      last_name: ['', Validators.minLength(2)],
      middle_name: ['', Validators.minLength(2)],
      gender: [''],
      email: ['', [Validators.email, Validators.required]],
      phone_number_1: [''],
      phone_number_2: [''],
      dob: [new Date()],
      marital_status: [''],
      nationality: [''],
      annual_income: [''],
      state_of_origin: [''],
      bvn: ['', [Validators.required, CustomValidators.bvn()]],
      // DO NOT REMOVE
      profile_id: [''],
    });
  }

  setFormValues() {
    if (this.customerInformation) {
      this.customerInfoForm.patchValue({
        title: this.customerInformation.title || '',
        first_name: this.customerInformation.first_name,
        last_name: this.customerInformation.last_name,
        middle_name: this.customerInformation.middle_name,
        gender: this.customerInformation.gender || '',
        email: this.customerInformation.email,
        phone_number_1: this.customerInformation.phone_number_1 || '',
        phone_number_2: this.customerInformation.phone_number_2 || '',
        marital_status: this.customerInformation.marital_status || '',
        nationality: this.customerInformation.nationality || '',
        annual_income: this.customerInformation.annual_income || '',
        state_of_origin: this.customerInformation.state_of_origin || '',
        bvn: this.customerInformation.bvn || '',
        profile_id: this.customerInformation.id,
      });

      // setting this separately because it is a date
      this.customerInfoForm.controls['dob'].setValue(
        formatDate(this.customerInformation.dob, 'yyyy-MM-dd', 'en')
      );
    }
  }

  updateCustomerProfile() {
    const sub = this.individualService
      .updateCustomerProfile(this.customerInfoForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Successfully updated profile');
        },
        error: () => {
          this.toast.error('Failed to update profile');
        },
      });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
