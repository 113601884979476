const ACTION_SCOPE = '[Auth]';
export namespace AuthActions {
  export class Login {
    static readonly type = `${ACTION_SCOPE} Login`;
    constructor(public payload: { email: string; password: string }) {}
  }

  export class SetLoadingStatus {
    static readonly type = `${ACTION_SCOPE} Set Loading Status`;
    constructor(public loading: boolean) {}
  }

  export class SetSessionStatus {
    static readonly type = `${ACTION_SCOPE} Set Session Status`;
    constructor(public payload: boolean) {}
  }

  export class SetTokenExpiredUrl {
    static readonly type = `${ACTION_SCOPE} Set Token Expired Url`;
    constructor(public payload: string) {}
  }

  export class ResetState {
    static readonly type = `${ACTION_SCOPE} Reset Auth State`;
    constructor() {}
  }

  export class SetTokenValue {
    static readonly type = `${ACTION_SCOPE} Set Token Value`;
    constructor(public token: string) {}
  }

  export class HasApiError {
    static readonly type = `${ACTION_SCOPE} Set Api Error Status`;
    constructor(public token: boolean) {}
  }
}
