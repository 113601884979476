@if (profiles$ | async) {
    <div class="flex justify-between">
      <div
        class="flex flex-col items-center gap-2 md:flex md:shrink-0 md:flex-row md:gap-[0.31rem]"
      >
        <a (click)="goBack()" class="translate-y-[3px] cursor-pointer"
          ><img src="assets/images/svgs/chevron-left.svg" alt=""
        /></a>
  
        <h1
          *ngIf="user$ | async as user"
          class="translate-y-1 text-2xl font-normal leading-[1.3125rem] text-mango-black17"
        >
          {{ user.user.first_name | titlecase }}
          {{ user.user.last_name | titlecase }}
        </h1>
      </div>
      <button
        *ngIf="accountId"
        mango-button
        class="w-fit shrink-0 items-center"
        (click)="editAccount()"
      >
        Edit Profile
      </button>
    </div>
  
    <div *ngIf="accountId" class="tab__wrapper mt-[0.94rem]">
      <!-- Desktop tabs -->
      <!-- <div class="hidden w-fit rounded-lg bg-white sm:block"> -->
      <div class="w-fit rounded-lg bg-white sm:block">
        <nav class="flex w-fit space-x-4 p-[0.31rem]" aria-label="Tabs">
          <a
            [routerLink]="userDetailsUrl"
            routerLinkActive="bg-mango-lightOrange text-mango text-2xl"
            class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
            ><span>User Details</span></a
          >
          <a
            [routerLink]="accountsUrl"
            routerLinkActive="bg-mango-lightOrange text-mango"
            class="rounded-lg px-[0.94rem] py-[0.31rem] text-sm font-normal text-gray-500 hover:bg-gray-50"
            ><span>Accounts</span></a
          >
        </nav>
      </div>
    </div>
  
    <!-- Accounts -->
    <div
      class="mt-[0.94rem] flex flex-col gap-5"
      *ngIf="profiles$ | async as userProfiles"
    >
      @for (profile of userProfiles; track $index) {
        <div
          class="flex justify-between rounded-lg border border-solid border-mango-gray200 bg-white p-[0.94rem] shadow-[0_1px_2px_0_rgba(16,24,40,0.06)] hover:border-gray-400"
        >
          <div class="details__wrapper flex gap-[0.62rem]">
            <div
              class="icon__wrapper flex h-[2.4375rem] w-[2.4375rem] shrink-0 items-center justify-center rounded-full bg-mango-papayaWhip"
            >
              <img
                [src]="
                  profile.account_type === 'individual'
                    ? 'assets/images/svgs/user-profile-account.svg'
                    : 'assets/images/svgs/company-profile-account.svg'
                "
                alt="individual account icon"
              />
            </div>
            <ul class="flex flex-col leading-7 text-mango-gray900">
              <li class="text-lg font-normal">
                {{
                  profile.account_type === 'individual'
                    ? 'Individual Account'
                    : 'Company Account'
                }}
              </li>
              <li class="text-base font-light">
                {{ profile.mango_account_number }}
              </li>
            </ul>
          </div>
  
          <div class="details__link flex items-center justify-center">
            <a
              [routerLink]="
                ['/admin/accounts/' + profile.account_type] + '/' + profile.id
              "
              class="text-lg text-mango hover:underline"
              >View details</a
            >
          </div>
        </div>
      }
    </div>
  }
  
  @if (!(profiles$ | async)) {
    <div class="flex flex-col justify-start">
      <div class="flex justify-between">
        <ngx-skeleton-loader
          [theme]="{ height: '2rem', width: '200px' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          [theme]="{ height: '2rem', width: '200px' }"
        ></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader
        [theme]="{ height: '2rem', width: '200px' }"
      ></ngx-skeleton-loader>
    </div>
    <div class="flex flex-col">
      <ngx-skeleton-loader
        [theme]="{ height: '6rem', width: '100%' }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{ height: '6rem', width: '100%' }"
      ></ngx-skeleton-loader>
    </div>
  }
  
  <!-- <app-individual-accounts-loader></app-individual-accounts-loader> -->
  