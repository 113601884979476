import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TransactionsService } from '@core/services/transactions.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { WalletTransactionData } from '@core/models/admin/account';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { LoadingService } from '@core/services/loading.service';
import {
  NgxSkeletonLoaderComponent,
  NgxSkeletonLoaderModule,
} from 'ngx-skeleton-loader';

@Component({
  selector: 'app-view-details',
  standalone: true,
  templateUrl: './view-details.component.html',
  styleUrl: './view-details.component.scss',
  imports: [
    SlideInRightModalComponent,
    InputLabelComponent,
    CommonModule,
    NgxSkeletonLoaderModule,
  ],
})
export class ViewDetailsComponent implements OnInit, OnDestroy {
  transactionId: any = null;
  returnPath!: string;
  // transaction!: WalletTransactionData | TransactionsListDatum;
  transaction!: WalletTransactionData;
  user: any = null;
  isLoading: boolean = false;
  isFetchingDetails$!: Observable<boolean>;

  route = inject(ActivatedRoute);
  router = inject(Router);
  subs: Subscription[] = [];
  change = inject(ChangeDetectorRef);
  transactionService = inject(TransactionsService);
  notify = inject(NotificationFacade);
  private loadingService = inject(LoadingService);

  constructor() {
    this.isFetchingDetails$ = this.loadingService.getLoadingObservable(
      'get-transaction-details'
    );
  }

  ngOnInit(): void {
    // The table returns returns more information than the API
    // if (history.state.transaction) {
    //   this.transaction = history.state.transaction;
    //   // console.log(this.transaction);
    // } else {
    //         this.transactionId = this.route.snapshot.params['transactionId'];
    //         // console.log(this.transactionId);
    // if (this.transactionId) this.getTransactionDetails();
    // }

    this.transactionId = this.route.snapshot.params['transactionId'];
    if (this.transactionId) this.getTransactionDetails();
  }

  goback() {
    history.back();
  }

  getTransactionDetails() {
    this.isLoading = true;
    const sub = this.transactionService
      .getTransactionDetails(this.transactionId)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.transaction = response.data;
          this.change.detectChanges();
        },
        error: (err) => {
          this.isLoading = false;

          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
