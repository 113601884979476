import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';
import { CorporateAccountbankInfo } from '@core/models/admin/account';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { InputComponent } from '@shared/ui/input/input.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { ActivatedRoute } from '@angular/router';
import { CorporateRegistrationService } from '@core/services/corporate-registration.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { CommonService } from '@core/services/common.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { LoadingService } from '@core/services/loading.service';
import { BankRegion, CURRENCY } from 'app/app.constants';
import { TabsComponent } from '@shared/ui/tabs/tabs.component';
import { JointAccountbankinfo } from '@core/models/admin/account/joint';

@Component({
  selector: 'app-edit-bank-details',
  standalone: true,
  imports: [
    CommonModule,
    KycAccordionComponent,
    InputLabelComponent,
    InputComponent,
    ReactiveFormsModule,
    ButtonDirective,
    SpinDirective,
    BaseSelectDirective,
    TabsComponent,
  ],
  templateUrl: './edit-bank-details.component.html',
  styleUrl: './edit-bank-details.component.scss',
})
export class EditBankDetailsComponent implements OnInit, OnDestroy {
  @Input() bankDetails!: CorporateAccountbankInfo[] | JointAccountbankinfo[];

  bankDetailsForm!: FormGroup;
  isLoading: boolean = false;
  subs: Subscription[] = [];
  banks: { bankName: string; bankCode: string }[] = [];
  isFetchingAccountNo: boolean = false;
  bankAccountNo!: string;
  currencyList = CURRENCY;
  accountId!: string;
  bankId!: string;

  private isBankCreatedSubject$ = new BehaviorSubject<boolean>(false);
  private bankAccountIdSubject$ = new BehaviorSubject<string | null>(null);
  private accountIdSubject$ = new BehaviorSubject<string | null>(null);
  private bankRegionSubject$ = new BehaviorSubject<BankRegion>('local');

  isUpdatingBankDetails$!: Observable<boolean>;
  isAddingNewBankInfo$!: Observable<boolean>;
  isFetchingBankList$!: Observable<boolean>;
  isFetchingAcctNo$!: Observable<boolean>;
  bankRegion$ = this.bankRegionSubject$.asObservable();
  isBankCreated$ = this.isBankCreatedSubject$.asObservable();

  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private corporateService = inject(CorporateRegistrationService);
  private toast = inject(NotificationFacade);
  private cdr = inject(ChangeDetectorRef);
  private commonService = inject(CommonService);
  private loadingService = inject(LoadingService);

  constructor() {
    this.isUpdatingBankDetails$ = this.loadingService.getLoadingObservable(
      'update-banking-details'
    );
    this.isAddingNewBankInfo$ = this.loadingService.getLoadingObservable(
      'add-banking-details-to-account'
    );
    this.isFetchingBankList$ =
      this.loadingService.getLoadingObservable('get-bank-list');
    this.isFetchingAcctNo$ = this.loadingService.getLoadingObservable(
      'validate-bank-details'
    );
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.params['accountId'];

    this.createForm();
    if (this.bankDetailsForm) {
      this.setFormValues();
    }

    this.getListOfBanks();
  }

  submitBankDetails() {
    this.isBankCreatedSubject$.value
      ? this.updateBankDetails()
      : this.addBankDetails();
  }

  createForm() {
    if (this.bankDetails?.length === 0) {
      // if no bank details are provided, create a new form
      this.bankDetailsForm = this.fb.nonNullable.group({
        bank_account_name: [''],
        bank_account_number: [''],
        bank_code: [''], // for LOCAL accounts ONLY
        bank_branch: [''],
        currency: [''],
        bank_sort_code: [''],
        account_id: [''],
        bank_name: [''], // for FOREIGN accounts ONLY
        region: [this.bankRegionSubject$.value],
      });

      return;
      console.log('no bank details provided');
    }

    console.log('bank details provided');

    this.bankDetailsForm = this.fb.nonNullable.group({
      id: [''],
      bank_account_name: [''],
      bank_account_number: [''],
      bank_code: [''], // for LOCAL accounts ONLY
      bank_branch: [''],
      currency: [''],
      bank_sort_code: [''],
      account_id: [''],
      bank_name: [''], // for FOREIGN accounts ONLY
      region: [this.bankRegionSubject$.value],
    });

    this.bankDetailsForm.get('bank_branch')?.disable();
    const bankBranchControl = this.bankDetailsForm.get('bank_branch');
    bankBranchControl?.disable();
  }

  setFormValues() {
    this.bankDetailsForm.patchValue({
      account_id: this.accountId,
    });

    if (!this.bankDetails.length) {
      return;
    }

    const bankDetails = this.bankDetails[0];
    this.setBankRegion(bankDetails.region as BankRegion);

    this.isBankCreatedSubject$.next(true);

    this.bankDetailsForm.patchValue({
      id: bankDetails.id,
      bank_account_name: bankDetails.bank_account_name || '',
      bank_account_number: bankDetails.bank_account_number,
      bank_branch: bankDetails.bank_branch || '',
      bank_code: bankDetails.bank_code || '',
      currency: bankDetails.currency,
      bank_sort_code: bankDetails.bank_sort_code || '',
      region: bankDetails.region || 'local',
      bank_name: bankDetails.bank_name || '',
    });

    this.bankId = bankDetails.id;
    this.cdr.detectChanges();
  }

  setBankRegion(region: BankRegion) {
    this.bankRegionSubject$.next(region);
  }

  addBankDetails() {
    const sub = this.corporateService
      .addBankDetails(this.bankDetailsForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.bankId = response.data.id;

          this.toast.success('Sucessfully created bank details');
          window.location.reload();
        },
        error: () => {
          this.toast.error('Failed to add bank details');
        },
      });
    this.subs.push(sub);
  }

  updateBankDetails(): void {
    if (this.bankRegionSubject$.value === 'foreign') {
      this.bankDetailsForm.removeControl('bank_code');
    }

    this.bankDetailsForm?.addControl(
      'id',
      new FormControl(this.bankAccountIdSubject$.value)
    );
    const sub = this.corporateService
      .updateBankDetails(this.bankDetailsForm.getRawValue())
      .subscribe({
        next: () => {
          this.toast.success('Sucessfully updated bank details');
        },
        error: () => {
          this.toast.error('Failed to update bank details');
        },
      });
    this.subs.push(sub);
  }

  getListOfBanks() {
    const sub = this.commonService.getBankList().subscribe({
      next: (res: any) => {
        this.banks = res.data;
        this.cdr.detectChanges();
      },
      error: () => {
        this.toast.info('Unable to fetch bank list.\nKindly refresh');
      },
    });
    this.subs.push(sub);
  }

  checkAccountNoInfo(): void {
    this.bankAccountNo = this.bankDetailsForm.get('bank_account_number')
      ?.value as string;

    const bankCode = this.bankDetailsForm.get('bank_code')?.value as string;

    if (
      this.bankAccountNo?.length === 10 &&
      this.bankDetailsForm.get('bank_code')?.dirty
    ) {
      this.commonService
        .validateBankAccountNo(this.bankAccountNo, bankCode)
        .subscribe({
          next: (response) => {
            this.bankDetailsForm
              .get('bank_account_name')
              ?.setValue(response.data.accountName);
            this.cdr.detectChanges();
          },
          error: () => {
            this.toast.error('Failed to fetch bank account details');
          },
        });
    }
  }

  onAccountNumberChange(): void {
    this.bankRegion$.pipe(take(1)).subscribe((region) => {
      if (region === 'local') {
        this.checkAccountNoInfo();
      }
    });
  }

  onBankSelectionChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const bankCode = selectElement.value;

    this.bankDetailsForm.patchValue({
      bank_code: bankCode,
    });
  }

  toggleBankRegion(event: Event, bankType: BankRegion) {
    event.preventDefault();
    this.bankRegionSubject$.next(bankType);

    if (bankType === 'local') {
      this.bankDetailsForm?.removeControl('bank_name');
      this.bankDetailsForm?.addControl('bank_code', new FormControl(''));

      this.bankDetailsForm.patchValue({
        region: 'local' as BankRegion,
      });
    } else {
      this.bankDetailsForm?.addControl('bank_name', new FormControl(''));
      this.bankDetailsForm?.removeControl('bank_code');
      this.bankDetailsForm.patchValue({
        region: 'foreign' as BankRegion,
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
