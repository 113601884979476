<app-kyc-accordion
  accordionTitle="Location Details"
  accordionKey="locationDetails"
  [isKycComplete]="isKycComplete"
>
  <div *ngIf="!locationDetails">
    <p>No location details</p>
  </div>
  <div *ngIf="locationDetails" class="grid grid-cols-1 gap-y-5 md:grid-cols-4">
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Country</li>
      <li class="text-sm font-light">
        {{ (locationDetails.country | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">State</li>
      <li class="text-sm font-light">
        {{ (locationDetails.state | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">City</li>
      <li class="text-sm font-light">
        {{ (locationDetails.city | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Address</li>
      <li class="text-sm font-light">
        {{ locationDetails.address || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Dual Citizenship</li>
      <li class="text-sm font-light">
        {{ (locationDetails.citizenship_status | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Second Country</li>
      <li class="text-sm font-light">
        {{ (locationDetails.second_country | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Residency Status</li>
      <li class="text-sm font-light">
        {{ (locationDetails.residency_status | titlecase) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Residence Permit Number</li>
      <li class="text-sm font-light">
        {{ locationDetails.residence_permit || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Permit Issue Date</li>
      <li class="text-sm font-light">
        {{ (locationDetails.permit_issue_date | date) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Permit Expiry Date</li>
      <li class="text-sm font-light">
        {{ (locationDetails.permit_expiry_date | date) || '—' }}
      </li>
    </ul>
  </div>
</app-kyc-accordion>
