import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AccountManagementService } from '@core/services/account-management.service';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { IndBankDetailsComponent } from './pages/ind-bank-details/ind-bank-details.component';
import { IndLocationDetailsComponent } from './pages/ind-location-details/ind-location-details.component';
import { IndMeansOfIdComponent } from './pages/ind-means-of-id/ind-means-of-id.component';
import { IndEmploymentDetailsComponent } from './pages/ind-employment-details/ind-employment-details.component';
import { IndNextOfKinComponent } from './pages/ind-next-of-kin/ind-next-of-kin.component';
import { IndDocumentsComponent } from './pages/ind-documents/ind-documents.component';
import {
  Accountbankinfo,
  DocKyc,
  Employerkyc,
  MeansOfIdkyc,
  Locationkyc,
  Nextofkin,
  IndividualAccountKycData,
} from '@core/models/admin/account';

import { environment } from '@core/environments/environment';
import { CommonService } from '@core/services/common.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { LoadingService } from '@core/services/loading.service';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordion,
    CdkAccordionItem,
    CommonModule,
    IndLocationDetailsComponent,
    IndMeansOfIdComponent,
    IndEmploymentDetailsComponent,
    IndNextOfKinComponent,
    IndBankDetailsComponent,
    IndDocumentsComponent,
  ],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss',
})
export class DetailsComponent implements OnInit, OnDestroy {
  accountData!: IndividualAccountKycData;
  locationDetails!: Locationkyc;
  meansOfIdDetails!: MeansOfIdkyc;
  employmentDetails!: Employerkyc;
  nextOfKinDetails!: Nextofkin;
  bankDetails!: Accountbankinfo[];
  documentDetails!: DocKyc[];
  bankName!: string;

  isFetchingBankList$!: Observable<boolean>;

  banks: { bankName: string; bankCode: string }[] = [];

  accountManagementService = inject(AccountManagementService);
  commonService = inject(CommonService);
  toast = inject(NotificationFacade);
  private loadingService = inject(LoadingService);

  subs: Subscription[] = [];

  constructor() {
    this.isFetchingBankList$ =
      this.loadingService.getLoadingObservable('get-bank-list');
  }

  ngOnInit(): void {
    this.watchDataUpdate();
  }

  watchDataUpdate() {
    const sub = this.accountManagementService.accountKYCData$.subscribe({
      next: (data) => {
        this.accountData = data as unknown as IndividualAccountKycData;
        if (data) {
          this.locationDetails = data.location_kyc as unknown as Locationkyc;
          this.meansOfIdDetails = data.id_kyc as unknown as MeansOfIdkyc;
          this.employmentDetails = data.employer_kyc as unknown as Employerkyc;
          this.nextOfKinDetails = data.next_of_kin as unknown as Nextofkin;
          this.bankDetails =
            data.account_bank_info as unknown as Accountbankinfo[];

          if (this.bankDetails.length) {
            this.getBankName(this.bankDetails[0].bank_code)
              .then((bankName) => {
                if (bankName) {
                  this.bankName = bankName;
                } else {
                  this.toast.error('Failed to fetch bank name');
                }
              })
              .catch((error) => {
                this.toast.error('Failed to fetch bank name');
              });
          }

          this.documentDetails = data.doc as unknown as DocKyc[];
        }
      },
      error: (err) => {
        this;
      },
    });
    this.subs.push(sub);
  }

  async getBankName(bankCode: string): Promise<string | undefined> {
    try {
      // Convert the observable to a promise
      const res = await firstValueFrom(this.commonService.getBankList());

      this.banks = res.data as { bankName: string; bankCode: string }[];

      const bank = this.banks.find((bank) => bank.bankCode === bankCode);

      return bank ? bank.bankName : undefined;
    } catch (error) {
      console.error('Error fetching bank name:', error);
      return undefined; // Handle error appropriately
    }
  }

  openDoc(data: any): void {
    window.open(`${environment.FILE_BASE_URL}${data}`, '_blank');
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
