import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Locationkyc } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-ind-location-details',
  standalone: true,
  imports: [KycAccordionComponent, CommonModule],
  templateUrl: './ind-location-details.component.html',
  styleUrl: './ind-location-details.component.scss',
})
export class IndLocationDetailsComponent {
  @Input() locationDetails!: Locationkyc;
  isKycComplete: boolean = false;
  

 ngOnInit(): void {
    this.checkKycStatus();
  }

  private checkKycStatus(): void {
    if (!this.locationDetails) {
      this.isKycComplete = false;
      return;
    }

    this.isKycComplete = !!(
      this.locationDetails.country &&
      this.locationDetails.state &&
      this.locationDetails.address &&
      this.locationDetails.citizenship_status &&
      this.locationDetails.permit_issue_date &&
      this.locationDetails.permit_expiry_date &&
      this.locationDetails.city
    );
  }
}
