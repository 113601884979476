<!-- table skeleton loader -->
@if (isFetchingMutualFundsList$ | async) {
<div class="mt-[0.94rem]">
  <app-table-skeleton-loader></app-table-skeleton-loader>
</div>
}

<!-- Table -->
<div *ngIf="!(isFetchingMutualFundsList$ | async)"
  class="mt-[0.94rem] rounded-lg border border-solid border-mango-gray200 bg-white">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="flex border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold text-gray-600">
                S/N
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M7.99998 3.33301V12.6663M7.99998 12.6663L12.6666 7.99967M7.99998 12.6663L3.33331 7.99967"
                    stroke="#667085" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600">
                Account Number
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600">
                Account Name
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600">
                Amount
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600">
                Type
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold tracking-wider text-gray-600">
                Date
              </th>

             
          </thead>

          <tbody>
            @if ((mutualFundsList$ | async)?.length > 0) {
            @for (
            fund of mutualFundsList$ | async;
            track fund.id;
            let i = $index
            ) {
            <ng-container *ngTemplateOutlet="
                    mutualFundsRow;
                    context: { data: fund, i: i }
                  "></ng-container>
            }
            }
          </tbody>
        </table>

        <app-table-navigator
          [total]="totalCount"
          (onNavigation)="navigate($event)"
        ></app-table-navigator>
      </div>
    </div>
  </div>
</div>

<ng-template #mutualFundsRow let-mutualFund="data" let-i="i">
  <tr>
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p>{{ i + 1 }}</p>
    </td>

    <!-- Account Number -->
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p class="whitespace-no-wrap text-gray-900">
        {{ mutualFund.account_number }}
      </p>
    </td>

    <!-- Account Name -->
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p class="whitespace-no-wrap text-gray-900">
        {{ mutualFund.name }}
      </p>
    </td>
    <!-- Amount -->
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p class="whitespace-no-wrap text-gray-900">
        {{ ((mutualFund.amount && mutualFund.amount !== 0) ? mutualFund.amount : mutualFund.accrued_principal) / 100 | number: '1.2-2' }}
      </p>
    </td>
    <!-- Type -->
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p class="whitespace-no-wrap text-gray-900">
        {{ mutualFund.type }}
      </p>
    </td>
    <!-- Date -->
    <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
      <p class="whitespace-no-wrap text-gray-900">
        {{ mutualFund.dayDate | date: 'dd MMM, yyyy' }}
      </p>
    </td>



  </tr>
</ng-template>