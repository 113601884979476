<app-kyc-accordion
  accordionKey="companyDetails"
  accordionTitle="Company Details"
>
  <form class="" [formGroup]="companyDetailsForm">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- Company Name -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Company name</app-input-label
        >
        <app-input
          placeholder="Enter Company name"
          [inputClass]="'input--padding'"
          formControlName="name"
        >
        </app-input>
      </div>

      <!-- Registration number -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Registration number</app-input-label
        >
        <app-input
          placeholder="Enter Registration number"
          [inputClass]="'input--padding'"
          formControlName="registration_number"
        >
        </app-input>
      </div>

      <!-- TIN -->
      <div>
        <app-input-label labelClass="label--bottom-margin">TIN</app-input-label>
        <app-input
          placeholder="Enter TIN"
          [inputClass]="'input--padding'"
          formControlName="tin"
        >
        </app-input>
      </div>

      <!-- Business type -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Business type</app-input-label
        >
        <app-input
          placeholder="Enter Business type"
          [inputClass]="'input--padding'"
          formControlName="business_type"
        >
        </app-input>
      </div>

      <!-- Business address -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Business address</app-input-label
        >

        <div>
          <textarea
            formControlName="business_address"
            textarea-style
            placeholder="Enter Business Address"
          >
          </textarea>
        </div>
      </div>

      <!-- Business website -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Business website</app-input-label
        >
        <app-input
          placeholder="Enter Business website"
          [inputClass]="'input--padding'"
          formControlName="business_website"
        >
        </app-input>
      </div>

      <!-- Date of registration -->
      <div>
        <app-input-label labelClass="label--bottom-margin"
          >Date of registration</app-input-label
        >
        <input
          type="date"
          input-date
          class="input--padding"
          formControlName="registered_date"
          [value]="companyDetails.corporate_kyc?.registered_date | date"
        />
      </div>
    </div>

    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': (isLoading$ | async)
        }"
      >
        <button
          type="button"
          mango-button
          [loading]="(isLoading$ | async)!!"
          [disabled]="(isLoading$ | async)!!"
          (click)="updateCompanyDetails()"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span *ngIf="isLoading$ | async" spin></span>
          @if (!(isLoading$ | async)) {
            Save Changes
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>
