<app-kyc-accordion
  accordionKey="directorDetails"
  accordionTitle="Director Details"
>
  <p *ngIf="directorDetails?.length === 0" class="mb-10">
    Add new Directors using the form below:
  </p>
  <div
    *ngIf="directorDetails && directorDetails.length > 0"
    class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5"
  >
    @for (director of directorDetails; track $index) {
      <div
        class="director__wrapper rounded-[0.625rem] bg-mango-orangeFF px-[0.88rem] py-[0.62rem]"
      >
        <div class="inline-flex w-full items-center gap-3 xl:px-[0.88rem]">
          <span
            class="flex items-center justify-center rounded-2xl bg-mango px-2 text-white"
          >
            <span class="translate-y-px">{{ $index + 1 }}</span>
          </span>
          <span class="text-base font-bold text-mango-black17"
            >{{ director.first_name }} {{ director.last_name }}</span
          >
        </div>
      </div>
    }
  </div>

  <hr *ngIf="directorDetails && directorDetails.length > 0" class="my-10" />

  <form [formGroup]="directorDetailsForm" (ngSubmit)="addNewDirector()">
    <div class="form-row flex flex-col gap-5 md:grid md:grid-cols-3 md:gap-x-5">
      <!-- First Name -->
      <div>
        <app-input-label
          labelFor="first-name"
          [isRequired]="true"
          labelClass="label--bottom-margin"
          >First Name</app-input-label
        >

        <app-input
          placeholder="Enter First Name"
          [inputClass]="'input--padding'"
          formControlName="firstName"
          formControlName="first_name"
        ></app-input>
      </div>

      <!-- Last Name -->
      <div>
        <app-input-label
          labelFor="last-name"
          [isRequired]="true"
          labelClass="label--bottom-margin"
          >Last Name</app-input-label
        >

        <app-input
          placeholder="Enter Last Name"
          [inputClass]="'input--padding'"
          formControlName="lastName"
          formControlName="last_name"
        ></app-input>
      </div>

      <!-- Email -->
      <div>
        <app-input-label
          labelFor="email"
          [isRequired]="true"
          labelClass="label--bottom-margin"
          >Email</app-input-label
        >
        <app-input
          placeholder="Enter email"
          inputClass="input--padding"
          formControlName="email"
        ></app-input>
      </div>

      <!-- Phone number -->
      <div>
        <app-input-label
          labelFor="phone-number"
          [isRequired]="true"
          labelClass="label--bottom-margin"
          >Phone number</app-input-label
        >
        <app-input
          placeholder="Enter Phone number"
          inputClass="input--padding"
          formControlName="phone_number"
        ></app-input>
      </div>

      <!-- Bank Verification Number -->
      <div>
        <app-input-label
          labelFor="bankVerificationNumber"
          [isRequired]="true"
          labelClass="label--bottom-margin"
          >Bank Verification Number</app-input-label
        >
        <app-input
          placeholder="Enter Bank Verification Number "
          inputClass="input--padding"
          formControlName="bvn"
        ></app-input>
      </div>
    </div>

    <div class="mt-5 flex justify-end">
      <div
        [ngClass]="{
          'cursor-not-allowed': (isLoading$ | async)
        }"
      >
        <button
          type="submit"
          mango-button
          [loading]="(isLoading$ | async)!!"
          [disabled]="(isLoading$ | async)!!"
          class="w-full justify-center xl:col-start-2 xl:w-fit xl:justify-self-end"
        >
          <span *ngIf="isLoading$ | async" spin></span>
          @if (!(isLoading$ | async)) {
            Add New Director
          }
        </button>
      </div>
    </div>
  </form>
</app-kyc-accordion>
