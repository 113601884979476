import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { LoadingService } from '@core/services/loading.service';
import { MutualFundService } from '@core/services/mutual-fund.service';
import {
  ButtonDirective,
  SpinDirective,
} from '@shared/directives/button.directive';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import {
  DropdownComponent,
  DropDownModel,
} from '@shared/ui/dropdown/dropdown.component';
import { FiltersComponent } from '@shared/ui/filters/filters.component';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { IFILTER } from 'app/app.constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthSelectors } from 'app/auth/store/auth.selectors';
import { Store } from '@ngxs/store';
import { AuthActions } from 'app/auth/store/auth.actions';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipOverlayComponent } from '@shared/ui/tooltip-overlay/tooltip-overlay.component';

@Component({
  selector: 'app-mutual-funds',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    DropdownComponent,
    ButtonDirective,
    ReactiveFormsModule,
    FormsModule,
    FiltersComponent,
    RouterLink,
    OverlayModule,
    TooltipOverlayComponent,
  ],
  templateUrl: './mutual-funds.component.html',
  styleUrl: './mutual-funds.component.scss',
})
export class MutualFundsComponent implements OnInit, OnDestroy {
  router = inject(Router);
  private mutualFundService = inject(MutualFundService);
  private breadCrumbService = inject(BreadCrumbService);
  private activatedRoute = inject(ActivatedRoute);
  private change = inject(ChangeDetectorRef);
  private loadingService = inject(LoadingService);
  private notify = inject(NotificationFacade);
  private store = inject(Store);

  subs: Subscription[] = [];
  isFilterActive: boolean = false;
  isFetchingMutualFundsList$!: Observable<boolean>;
  actionList: DropDownModel[] = [];
  selectedDate: string | null = null;
  globalDate!: string;
  isLoading$ = this.store.select(AuthSelectors.getLoadingStatus);

  private mutualFundsListSubject = new BehaviorSubject<any>(null);
  private totalWeightSubject = new BehaviorSubject<any>(null);
  private totalPrincipalSubject = new BehaviorSubject<any>(null);
  private dailyDividendSubject = new BehaviorSubject<any>(null);
  mutualFundsList$ = this.mutualFundsListSubject.asObservable();
  totalWeight$ = this.totalWeightSubject.asObservable();
  totalPrincipal$ = this.totalPrincipalSubject.asObservable();
  dailyDividend$ = this.dailyDividendSubject.asObservable();

  tabs: { path: string; label: string; route: string }[] = [];
  baseRoute = this.router.url.split('/').slice(0, -1).join('/');

  pathMap = [
    {
      label: 'Consolidated',

      route: `${this.baseRoute}/consolidated`,
    },
    {
      label: 'SUB/RED',
      route: `${this.baseRoute}/subred`,
    },
    {
      label: 'Dividend',
      route: `${this.baseRoute}/dividend`,
    },
  ];

  filterStructure: IFILTER[] = [
    {
      filterKey: 'date',
      label: 'Date',
      type: 'date',
    },
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
  ];

  constructor() {
    this.isFetchingMutualFundsList$ = this.loadingService.getLoadingObservable(
      'isFetchingMutualFUndsList'
    );
  }

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);
    this.filterCurrentDateEventListener();
    this.getTotalPrincipal();
    if (this.globalDate) {
      this.getTotalPrincipal(this.globalDate);
      this.getTotalWeight(this.globalDate);
      this.getDailyDividend(this.globalDate);
    }
  }

  filterCurrentDateEventListener() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let date = '';
      if (params[this.filterStructure[0].filterKey]) {
        date = `${params['date']}`;
      }
      if (date) {
        this.globalDate = date;
        this.getTotalPrincipal(this.globalDate);
        this.getDailyDividend(this.globalDate);
        this.getTotalWeight(this.globalDate);
      } else {
        this.globalDate = '';
      }
    });
  }

  initDropdownList(param: any) {
    return (this.actionList = [
      {
        action: () => {
          this.transactionHistory();
        },
        title: 'Transaction History',
        show: true,
      },
    ]);
  }

  mutualFundsActions(): DropDownModel[] {
    return [
      {
        title: 'Add Transaction',
        action: () => {
          this.router.navigateByUrl(
            'admin/transactions/mutual-funds/tab/consolidated/add-transaction'
          );
        },
        show: true,
      },
      {
        title: 'Create Dividend',
        action: () => {
          this.router.navigateByUrl(
            'admin/transactions/mutual-funds/tab/consolidated/dividend/Create'
          );
        },
        show: true,
      },
    ];
  }

  transactionHistory() {
    this.router.navigate([
      'admin/transactions/mutual-funds/mutual-funds-history',
    ]);
  }

  getMutualFundsList() {
    const sub = this.mutualFundService.getMutualFundsList().subscribe({
      next: (res) => {
        this.mutualFundsListSubject.next(res.data);
        this.change.detectChanges();
      },
      error: () => {},
    });
    this.subs.push(sub);
  }
  getTotalWeight(date: string) {
    const sub = this.mutualFundService.getTotalWeight(date).subscribe({
      next: (res) => {
        this.totalWeightSubject.next(res.data);
        this.change.detectChanges();
      },
      error: () => {},
    });
    this.subs.push(sub);
  }
  getTotalPrincipal(date = new Date().toISOString().split('T')[0]) {
    const sub = this.mutualFundService.getTotalPrincipal(date).subscribe({
      next: (res) => {
        this.totalPrincipalSubject.next(res.data.amount);
        this.change.detectChanges();
      },
      error: () => {},
    });
    this.subs.push(sub);
  }
  getDailyDividend(date: string) {
    const sub = this.mutualFundService.getDailyDividend(date).subscribe({
      next: (res) => {
        this.dailyDividendSubject.next(res.data._sum.dividend);
        this.change.detectChanges();
      },
      error: () => {},
    });
    this.subs.push(sub);
  }

  validateDate() {
    if (this.selectedDate) {
      this.selectedDate = this.selectedDate.trim();
    }
  }
  revaluateDivdends(date: string | null) {
    this.store.dispatch(new AuthActions.SetLoadingStatus(true));
    if (!date) {
      this.notify.error('Please select a date before revaluating.');
      return;
    }

    const formattedDate = new Date(date).toISOString().split('T')[0];
    const payload = { date: formattedDate };
    const sub = this.mutualFundService.revaluateDivident(payload).subscribe({
      next: (res) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.getMutualFundsList();
        this.notify.success('Weight revaluation successful');
        this.change.detectChanges();
      },
      error: () => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
      },
    });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  addTransaction() {
    this.router.navigate(['admin/transactions/mutual-funds/add-transaction']);
  }
}
