<app-kyc-accordion
  accordionTitle="Means of Identification"
  accordionKey="meansOfId"
  [isKycComplete]="isKycComplete"
>
  <div *ngIf="!meansOfId">
    <p>No means of identification details</p>
  </div>
  <div *ngIf="meansOfId" class="grid grid-cols-1 gap-y-5 md:grid-cols-4">
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">ID Type</li>
      <li class="text-sm font-light">
        {{ meansOfId.type }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">ID Number</li>
      <li class="text-sm font-light">
        {{ meansOfId.number }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Issue Date</li>
      <li class="text-sm font-light">
        {{ (meansOfId.issue_date | date) || '—' }}
      </li>
    </ul>
    <ul class="text-mango-darkBlack">
      <li class="text-base font-medium">Expiry Date</li>
      <li class="text-sm font-light">
        {{ (meansOfId.expiry_date | date) || '—' }}
      </li>
    </ul>
  </div>
</app-kyc-accordion>
