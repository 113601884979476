import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@core/services/loading.service';
import { MutualFundService } from '@core/services/mutual-fund.service';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { TooltipOverlayComponent } from '@shared/ui/tooltip-overlay/tooltip-overlay.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-dividend-distribution',
  standalone: true,
  imports: [
    SlideInRightModalComponent,
    TableSkeletonLoaderComponent,
    CommonModule,
    TooltipOverlayComponent,
  ],
  templateUrl: './dividend-distribution.component.html',
  styleUrl: './dividend-distribution.component.scss',
})
export class DividendDistributionComponent implements OnInit, OnDestroy {
  mutualFundService = inject(MutualFundService);
  change = inject(ChangeDetectorRef);
  activatedRoute = inject(ActivatedRoute);
  loadingService = inject(LoadingService);
  totalDividend!: number;

  isFetchingTransactionHistoryList$!: Observable<boolean>;
  private transactionHistoryListSubject = new BehaviorSubject<any>(null);
  private dailyDividendSubject = new BehaviorSubject<any>(null);
  transactionHistoryList$ = this.transactionHistoryListSubject.asObservable();
  dailyDividend$ = this.dailyDividendSubject.asObservable();
  date = this.activatedRoute.snapshot.params['date'];

  subs: Subscription[] = [];

  constructor() {
    this.isFetchingTransactionHistoryList$ =
      this.loadingService.getLoadingObservable('get-dividend-distribution');
  }

  ngOnInit() {
    this.getDividendList(this.date);
  }

  getDividendList(date: string) {
    const sub = this.mutualFundService.getDividendDistribution(date).subscribe({
      next: (res) => {
        this.dailyDividendSubject.next(
          res.data.dividend_amount?._sum?.dividend || 0
        );
        this.transactionHistoryListSubject.next(res.data.distributed_dividend);
        this.change.detectChanges();
      },
      error: () => {},
    });
    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
