import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
export const closeButtonAnimation = trigger('closeButtonAnimation', [
  state(
    'visible',
    style({
      opacity: 1,
    })
  ),
  state(
    'hidden',
    style({
      opacity: 0,
    })
  ),
  transition('visible => hidden', animate('300ms ease-in-out')),
  transition('hidden => visible', animate('300ms ease-in-out')),
]);

export const offCanvasBackdropAnimation = trigger(
  'offCanvasBackdropAnimation',
  [
    state(
      'visible',
      style({
        opacity: 1,
      })
    ),
    state(
      'hidden',
      style({
        opacity: 0,
      })
    ),
    transition('visible => hidden', animate('300ms linear')),
    transition('hidden => visible', animate('300ms linear')),
  ]
);

export const offCanvasMenuAnimation = trigger('offCanvasMenuAnimation', [
  state(
    'visible',
    style({
      transform: 'translateX(0)',
    })
  ),
  state(
    'hidden',
    style({
      transform: 'translateX(-100%)',
    })
  ),
  transition('visible => hidden', animate('300ms ease-in-out')),
  transition('hidden => visible', animate('300ms ease-in-out')),
]);

export const dropdownMenuAnimation = trigger('dropdownMenuAnimation', [
  state(
    'visible',
    style({
      transform: 'scale(1) translate(0, 0)',
      opacity: 1,
    })
  ),
  state(
    'hidden',
    style({
      transform: 'scale(0.95) translate(0, -10px)',
      opacity: 0,
    })
  ),
  transition('visible => hidden', animate('75ms ease-in')),
  transition('hidden => visible', animate('100ms ease-out')),
]);
