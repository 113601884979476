import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Nextofkin } from '@core/models/admin/account';
import { KycAccordionComponent } from '@shared/ui/kyc-accordion/kyc-accordion.component';

@Component({
  selector: 'app-ind-next-of-kin',
  standalone: true,
  imports: [KycAccordionComponent, CommonModule],
  templateUrl: './ind-next-of-kin.component.html',
  styleUrl: './ind-next-of-kin.component.scss',
})
export class IndNextOfKinComponent {
  @Input() nextOfKin!: Nextofkin;

  isKycComplete: boolean = false;
  

  ngOnInit(): void {
     this.checkKycStatus();
   }
 
   private checkKycStatus(): void {
     if (!this.nextOfKin) {
       this.isKycComplete = false;
       return;
     }
 
     this.isKycComplete = !!(
       this.nextOfKin.relationship_with_nok &&
       this.nextOfKin.dob &&
       this.nextOfKin.first_name &&
       this.nextOfKin.last_name &&
       this.nextOfKin.title &&
       this.nextOfKin.email &&
       this.nextOfKin.gender &&
       this.nextOfKin.phone_number
     );
   }
}
